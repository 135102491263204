import { Box, Button, Grid } from '@mui/material';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tag, tagSchema } from 'models/tag';
import AutoCompleteControl from 'modules/components/form-controls/auto-complete-control';
import { tagTypeOptions } from 'config/tag-type-options';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';
import { useRef } from 'react';

type TagFormProps = {
  tag: Tag;
  onClose: (save: boolean) => void;
};

export default function TagForm({ tag, onClose }: TagFormProps): JSX.Element {
  const { handleSubmit, control, formState } = useForm<Tag>({
    defaultValues: tag,
    resolver: yupResolver(tagSchema),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<Tag> = (newTagData) => {
    Object.assign(tag, newTagData);
    onClose(true);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.light', px: 9 }}>
        <h2>
          {tag.name !== '' && tag.name}
          {tag.name === '' && 'Nueva Etiqueta'}
        </h2>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mx: 9 }}>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <TextFieldControl
            label="Nombre"
            fieldName={nameof<Tag>('name')}
            control={control}
            schema={tagSchema}
          />

          <AutoCompleteControl
            label="Tipo"
            fieldName={nameof<Tag>('typeComboCode')}
            control={control}
            schema={tagSchema}
            options={tagTypeOptions}
          />

          <Grid item xs={12}>
            <Box sx={{ mt: 3 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DiscardDialog ref={discardDialog} onClose={onClose} isDirty={isDirty} />
    </>
  );
}
