const EVERGINE_CANVAS_ID = 'evergine-canvas';
const EVERGINE_LOADING_BAR_ID = 'evergine-loading-bar';
const assemblyName = 'HvVViewerEvergine.Web';
const className = 'HvVViewerEvergine.Web.Program';

const invoke = <T = void>(methodName: string, ...args: unknown[]): T =>
  window.BINDING?.call_static_method(
    `[${assemblyName}] ${className}:${methodName}`,
    args
  ) as T;

export { EVERGINE_CANVAS_ID, EVERGINE_LOADING_BAR_ID, invoke };
