import { attachedFileSchema } from 'models/attached-file';
import yup from 'services/localized-yup';
import { constants } from 'config/constants';
import { iOptionSchema } from 'modules/components/form-controls/i-option';

export const caseSchema = yup.object({
  id: yup.number(),
  validationState: yup.number(),
  isVisible: yup.boolean(),
  date: yup.string(),
  name: yup.string().required().max(constants.dataValidation.smallStringSize),
  description: yup.string().max(constants.dataValidation.bigStringSize),
  createdById: yup.string(),
  assignedToId: yup
    .string()
    .max(constants.dataValidation.guidStringSize)
    .required()
    .nullable(),
  validationUserId: yup
    .string()
    .max(constants.dataValidation.smallStringSize)
    .nullable(),
  attachedFiles: yup.array(),
  attachedFiles3DModels: yup.array(attachedFileSchema),
  attachedFilesRadiology: yup.array(attachedFileSchema),
  attachedFilesDoc: yup.array(attachedFileSchema),
  hospitalName: yup.string(),
  authorName: yup.string(),
  tags: yup.array(),
  isFavorite: yup.boolean(),
  organId: yup.string().required().nullable(),
  pathologyId: yup.string().required().nullable(),
  procedureId: yup.string().required().nullable(),
  specialityId: yup.string().nullable(),
  tagsComboCodes: yup.array(iOptionSchema),
});

export type Case = yup.InferType<typeof caseSchema> & {
  thumbnailFile?: File;
};
