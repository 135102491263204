import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { getSettings } from 'config/settings';
import App from 'modules/app/app';
import LoginPage from 'modules/app/login-page/login-page';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import { CircularProgress, Container } from '@mui/material';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';
import { getApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import { EvergineState, useEvergineStore } from 'stores/evergine-store';
import { setSASTokenToEvergine } from 'evergine/evergine-program-service';

export default function Main(): JSX.Element {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
  const [counter, setCounter] = useState(0);
  const setSasToken = useAppStateStore((s: AppStateStore) => s.setSasToken);
  const sasToken = useAppStateStore((s: AppStateStore) => s.sasToken);
  const isEvergineReady = useEvergineStore(
    (s: EvergineState) => s.isEvergineReady
  );

  useEffect(() => {
    const loadSasToken = async (): Promise<void> => {
      const responseSAS = await getApi<string>(constants.api.sasToken);
      setSasToken(responseSAS);
    };

    loadSasToken();
  }, []);

  useEffect(() => {
    if (isEvergineReady && sasToken) {
      setSASTokenToEvergine(sasToken as string);
    }
  }, [isEvergineReady, sasToken]);

  useEffect(() => {
    if (
      window['AZURE_AD_CLIENT_ID'] !== undefined &&
      window['AZURE_AD_TENANT_ID'] !== undefined
    ) {
      const msal: Configuration = getSettings(
        window['AZURE_AD_CLIENT_ID'],
        window['AZURE_AD_TENANT_ID']
      ).msal;
      setMsalInstance(new PublicClientApplication(msal));
    } else {
      setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
    }
  }, [counter]);

  if (msalInstance === undefined) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <MsalProvider instance={msalInstance}>
      <ToastContainer />
      <AuthenticatedTemplate>
        <App />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}
