import { Box } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { constants } from 'config/constants';

type FileUploaderListProps = {
  filesList?: File[];
  multiple?: boolean;
  acceptedFiles?: string[];
  dropzoneText?: string;
  onChange?: (files: File[]) => void;
};

export default function FileUploader(
  props: FileUploaderListProps = {
    filesList: [],
    multiple: false,
    acceptedFiles: undefined,
    dropzoneText: '',
  }
): JSX.Element {
  return (
    <Box className="dropzone-area">
      <DropzoneArea
        Icon={props.multiple ? AttachFileIcon : InsertPhotoIcon}
        dropzoneText={
          props.dropzoneText
            ? props.dropzoneText
            : props.multiple
            ? 'Arrastra ficheros aquí o haz click'
            : 'Arrastra una imagen aquí o haz click'
        }
        showPreviewsInDropzone={props.multiple ? false : true}
        onChange={props.onChange}
        showAlerts={true}
        filesLimit={props.multiple ? 100 : 1}
        acceptedFiles={props.acceptedFiles}
        showPreviews={false}
        useChipsForPreview={false}
        fileObjects={props.filesList}
        maxFileSize={constants.maxFileSize}
        getFileAddedMessage={(filename): string =>
          `${filename} añadido correctamente `
        }
        getFileRemovedMessage={(filename): string =>
          `${filename} eliminado correctamente `
        }
        getDropRejectMessage={(filename): string =>
          `Fallo al intentar añadir ${filename} `
        }
      />
    </Box>
  );
}
