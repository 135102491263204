import {
  Paper,
  styled,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';

const StyledToggleButtonGroupInternal = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  })
);

export function StyledToggleButtonGroup(
  props: ToggleButtonGroupProps
): JSX.Element {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'inline-flex',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
      }}
    >
      <StyledToggleButtonGroupInternal {...props} />
    </Paper>
  );
}
