import create from 'zustand';
import { configureWindowStore } from 'evergine/window-store';
import { Vector2 } from 'models/vector2';

export type AppStateStore = {
  example?: Vector2;
  stlLayers?: string;
  setStlLayers: (stlLayers: string) => void;
  isLoading?: boolean;
  setLoading: (isLoading: boolean) => void;
  viewer?: string;
  setViewer: (viewer: string) => void;
  mriCount?: number;
  setMriCount: (mriCount: number) => void;
  userId: string;
  setUserId: (userId: string) => void;
  userRoles: string[];
  setUserRoles: (userRoles: string[]) => void;
  sasToken?: string;
  setSasToken: (sasToken: string) => void;
};

const useStore = create<AppStateStore>((_set) => ({
  example: undefined,
  stlLayers: undefined,
  setStlLayers: (stlLayers: string): void => {
    _set((state) => ({
      ...state,
      stlLayers,
    }));
  },
  isLoading: undefined,
  setLoading: (isLoading: boolean): void => {
    _set((state) => ({
      ...state,
      isLoading,
    }));
  },
  viewer: undefined,
  setViewer: (viewer: string): void => {
    _set((state) => ({
      ...state,
      viewer,
    }));
  },
  mriCount: undefined,
  setMriCount: (mriCount: number): void => {
    _set((state) => ({
      ...state,
      mriCount,
    }));
  },
  userId: '',
  setUserId: (userId: string): void => {
    _set((state) => ({
      ...state,
      userId,
    }));
  },
  userRoles: [],
  setUserRoles: (userRoles: string[]): void => {
    _set((state) => ({
      ...state,
      userRoles,
    }));
  },
  sasToken: undefined,
  setSasToken: (sasToken: string): void => {
    _set((state) => ({
      ...state,
      sasToken,
    }));
  },
}));

configureWindowStore(useStore, 'appStateStore');

export { useStore as useAppStateStore };
