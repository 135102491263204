import { Box, Button, Grid } from '@mui/material';
import FileUploader from 'modules/components/file-uploader/file-uploader';
import FileUploaderList from 'modules/components/file-uploader/file-uploader-list';
import { useState, useRef } from 'react';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AttachedFile } from 'models/attached-file';
import { Case, caseSchema } from 'models/case';
import { AttachedFileTypeEnum } from 'config/attached-file-type-enum';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';

type Create3DModelProps = {
  case: Case;
  onClose: (save: boolean) => void;
};

export default function Create3DModel(props: Create3DModelProps): JSX.Element {
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [lastFiles, setLastFiles] = useState<File[]>([]);

  const { handleSubmit, control, formState } = useForm<Case>({
    defaultValues: props.case,
    resolver: yupResolver(caseSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachedFiles3DModels',
  });

  const onFileUploaderChange = (files: File[]): void => {
    const newFiles = files.filter(
      (file) => !lastFiles.some((lastFile) => lastFile === file)
    );

    setLastFiles(files);

    newFiles.forEach((file) => {
      append({
        id: -1,
        file: file,
        typeComboCode: AttachedFileTypeEnum.Stl.toString(),
        type: AttachedFileTypeEnum.Stl,
        name: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
        description: '',
        path: '',
      });
    });
  };

  const onDeleteAttachedFile = (
    attachedFile: AttachedFile,
    index: number
  ): void => {
    remove(index);
    if (attachedFile.id === -1) {
      const files = uploadFiles.filter((file) => file !== attachedFile.file);
      setUploadFiles(files);
    }
  };

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<Case> = async (newCase) => {
    if (newCase.attachedFiles3DModels !== undefined) {
      props.case.attachedFiles3DModels = newCase.attachedFiles3DModels;
    }

    props.onClose(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ fontSize: 15, fontWeight: 600, lineHeight: 4 }}>
          Ficheros ZIP que contengan STLs
        </Box>
        <FileUploader
          filesList={uploadFiles}
          multiple
          acceptedFiles={['.zip']}
          onChange={onFileUploaderChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <FileUploaderList
              arrayName="attachedFiles3DModels"
              fields={fields}
              case={props.case}
              control={control}
              onDeleteAttachedFile={onDeleteAttachedFile}
              isRadiology={false}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 4, textAlign: 'right' }}>
            <Button
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button type="submit" sx={{ ml: 3 }} variant="contained">
              Guardar y enviar
            </Button>
          </Grid>
        </Box>

        <DiscardDialog
          ref={discardDialog}
          onClose={props.onClose}
          isDirty={isDirty || uploadFiles.length > 0}
        />
      </Grid>
    </Grid>
  );
}
