import { useMsal } from '@azure/msal-react';
import { AccountCircle } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { PageUrl } from 'models/page-url';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'resources/images/logoHvV.png';
import logolina from 'resources/images/logolina.png';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';
import { containsRole, getAdminString } from 'modules/app/home-page/user-type';
import UserDrawer from './user-drawer';

export default function CustomToolbar(): JSX.Element {
  const userRoles = useAppStateStore((s: AppStateStore) => s.userRoles);

  const getNavigationFromUserTye = (): PageUrl[] => {
    const result: PageUrl[] = [];

    if (containsRole(getAdminString, userRoles as string[])) {
      result.push({
        name: 'Administración',
        url: '/administration',
      });
      result.push({
        name: 'Casos',
        url: '/',
      });
    }

    return result;
  };

  const pages: PageUrl[] = getNavigationFromUserTye();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleUserDrawerClose = (): void => {
    setOpenUserDrawer(false);
  };

  const logOut = (): void => {
    handleLogout();
    // navigate('/login');
  };

  const goHome = (): void => {
    navigate('/');
  };

  const { instance } = useMsal();
  const handleLogout = (): void => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const [openUserDrawer, setOpenUserDrawer] = React.useState<boolean>(false);

  const handleOpenUserDrawer = (): void => {
    setAnchorEl(null);
    setOpenUserDrawer(!openUserDrawer);
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ zIndex: 1400 }}>
        <Toolbar>
          <Box sx={{ cursor: 'pointer' }} onClick={goHome}>
            <img src={logo} alt="Hospital Virtual"></img>
          </Box>

          <Divider
            sx={{ paddingRight: '30px' }}
            orientation="vertical"
            flexItem
          />
          <Box
            onClick={goHome}
            sx={{
              paddingRight: '30px',
              paddingLeft: '30px',
              cursor: 'pointer',
            }}
          >
            <img src={logolina} alt="LINA" width={70}></img>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={(): void => navigate(page.url)}
                sx={{ my: 2, color: 'primary', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="primary"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ zIndex: 1500 }}
            >
              <MenuItem onClick={handleOpenUserDrawer}>Mi Perfil</MenuItem>
              <MenuItem onClick={logOut}>Cerrar Sesión</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '300px', flexShrink: 0 }}>
        {openUserDrawer && (
          <UserDrawer opened={openUserDrawer} onClose={handleUserDrawerClose} />
        )}
      </Box>
    </div>
  );
}
