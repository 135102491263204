import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { CardActionArea, Chip, Grid, Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Case } from 'models/case';
import './case-card.css';

import { AppStateStore, useAppStateStore } from 'stores/app-state-store';
import {
  containsRole,
  getAdminString,
  getDataEntryString,
} from 'modules/app/home-page/user-type';
import FavoriteCaseButton from '../favorite-case/favorite-case-button';
import CaseEditDeleteMenu from '../case-edit-delete-menu/case-edit-delete-menu';
import { useEffect, useState } from 'react';
import { getApi } from 'services/http/axios-client';
import { constants } from 'config/constants';

interface CaseCardProps {
  case: Case;
  onDelete?: (id: number) => void;
}

export default function CaseCard(props: CaseCardProps): JSX.Element {
  const maxNameLength = 40;
  const maxDescriptionLength = 90;
  const maxHospitalNameLength = 50;

  const userId = useAppStateStore((s: AppStateStore) => s.userId);
  const userRoles = useAppStateStore((s: AppStateStore) => s.userRoles);
  const canEdit =
    containsRole(getAdminString, userRoles as string[]) ||
    (containsRole(getDataEntryString, userRoles as string[]) &&
      userId == props.case.createdById);

  const navigate = useNavigate();

  const goToCardPage = (): void => {
    navigate('/case/' + props.case.id);
  };

  const [thumbnailURI, setThumbnailURI] = useState<string>();

  useEffect(() => {
    const loadThumbnail = async (): Promise<void> => {
      const uri = await getApi<string>(
        constants.api.cases + '/' + props.case.id + '/thumbnail'
      );
      setThumbnailURI(uri);
    };
    loadThumbnail();
  }, [props.case]);

  return (
    <Card className="case-card" sx={{ borderRadius: 4 }}>
      <CardActionArea onClick={goToCardPage} className="case-card-action-area">
        <CardMedia
          component="img"
          height="140"
          image={(thumbnailURI != null && thumbnailURI != ''
            ? thumbnailURI
            : 'placeholder.png'
          ).toString()}
          alt="thumbnail"
        />
        <Box sx={{ minHeight: '184px' }}>
          <CardContent>
            <Typography
              gutterBottom
              sx={{ fontSize: '16px', fontWeight: '600' }}
              component="div"
            >
              {props.case.name !== undefined && props.case.name !== null
                ? props.case.name.toString().slice(0, maxNameLength) +
                  (props.case.name.toString().length > maxNameLength
                    ? '...'
                    : '')
                : ''}
            </Typography>
            <Typography
              variant="body2"
              color="#838C96"
              sx={{ fontSize: '12px', fontWeight: '400' }}
            >
              {props.case.description !== undefined &&
              props.case.description !== null
                ? props.case.description
                    .toString()
                    .slice(0, maxDescriptionLength) +
                  (props.case.description.toString().length >
                  maxDescriptionLength
                    ? '...'
                    : '')
                : ''}
            </Typography>
            <Grid container gap={1}>
              <Grid item>
                <Box
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#838C96' }}
                >
                  {props.case.date
                    ? new Date(props.case.date.toString()).toLocaleDateString(
                        'es-ES'
                      )
                    : ''}
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#151617' }}
                >
                  {props.case.authorName !== undefined &&
                  props.case.authorName !== null
                    ? props.case.authorName
                    : ''}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '18px',
                color: '#838C96',
                paddingTop: '3px',
              }}
            >
              {props.case.hospitalName !== undefined &&
              props.case.hospitalName !== null
                ? props.case.hospitalName
                    .toString()
                    .slice(0, maxHospitalNameLength) +
                  (props.case.hospitalName.toString().length >
                  maxHospitalNameLength
                    ? '...'
                    : '')
                : ''}
            </Box>
          </CardContent>
          {canEdit &&
            props.case.validationState != null &&
            props.case.validationState < 1 && (
              <Box className="state-chip-container">
                {props.case.validationState == 0 ? (
                  <Chip label="PENDIENTE" color="warning" size="small" />
                ) : (
                  <Chip label="DENEGADO" color="error" size="small" />
                )}
              </Box>
            )}
        </Box>
      </CardActionArea>

      <CardActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%' }}
        >
          <FavoriteCaseButton case={props.case} />

          <CaseEditDeleteMenu
            canEdit={canEdit}
            case={props.case}
            onDelete={props.onDelete}
          />
        </Stack>
      </CardActions>
    </Card>
  );
}
