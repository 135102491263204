import ConfirmationDialog from 'modules/components/dialogs/confirmation-dialog';
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

type DiscardDialogProps = {
  onClose: (confirm: boolean) => void;
  isDirty?: boolean;
};

const DiscardDialog = forwardRef((props: DiscardDialogProps, ref) => {
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  const warnIfChanges = (): void => {
    if (props.isDirty ?? false) {
      setOpenDiscardDialog(true);
    } else {
      props.onClose(false);
    }
  };

  // logic for preventing close or navigate out with changes
  const preventFromLeaving = (e: Event): void => {
    if (props.isDirty ?? false) {
      e.preventDefault();
      e.returnValue = false;
    }
  };
  useEffect(() => {
    window.addEventListener('beforeunload', preventFromLeaving);
    return (): void => {
      window.removeEventListener('beforeunload', preventFromLeaving);
    };
  }, [props.isDirty]);

  useImperativeHandle(ref, () => ({
    handleUnsavedChanges: warnIfChanges,
  }));

  return (
    <ConfirmationDialog
      title="Descartar cambios"
      message={`Hay cambios sin guardar, ¿Está seguro que desea descartar los cambios?`}
      open={openDiscardDialog}
      onClose={async (confirm: boolean): Promise<void> => {
        setOpenDiscardDialog(false);
        if (confirm) {
          props.onClose(false);
        }
      }}
    />
  );
});

export default DiscardDialog;
