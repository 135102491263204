import { Button, Container, Grid } from '@mui/material';
import CaseList, {
  CaseOrderEnum,
} from 'modules/components/case-list/case-list';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';
import {
  containsRole,
  getAdminString,
  getDataEntryString,
  getUserString,
} from 'modules/app/home-page/user-type';
import AskCaseButton from 'modules/app/home-page/ask-case-button';
import { useEffect } from 'react';
import { getApi } from 'services/http/axios-client';
import { Case } from 'models/case';
import { constants } from 'config/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './search-bar';
import { FilterConfig } from './search-drawer';

import './home-page.css';

const drawerWidth = 495;

function HomePage(): JSX.Element {
  const userId = useAppStateStore((s: AppStateStore) => s.userId);
  const userRoles = useAppStateStore((s: AppStateStore) => s.userRoles);
  const [caseList, setCaseList] = React.useState<Case[]>([]);
  const [filteredCaseList, setFilteredCaseList] = React.useState<Case[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<CaseOrderEnum>(CaseOrderEnum.none);
  const [filterFavourites, setFilterFavourites] =
    React.useState<boolean>(false);
  const [filterCreatedByUser, setFilterCreatedByUser] =
    React.useState<boolean>(false);
  const [drawerFilters, setDrawerFilters] = React.useState<FilterConfig>({
    name: '',
    hospital: null,
    clinic: null,
    tags: [],
  });
  const [searchDrawerOpened, setSearchDrawerOpened] = React.useState(true);

  const onSearchDrawerOpen = (): void => {
    setSearchDrawerOpened(true);
  };
  const onSearchDrawerClose = (): void => {
    setSearchDrawerOpened(false);
  };

  // Load Data
  useEffect(() => {
    const loadData = async (): Promise<void> => {
      setIsLoading(true);
      const response = await getApi<Case[]>(constants.api.cases);
      setCaseList(response);
      setIsLoading(false);
    };
    loadData();
  }, []);

  const goToNewCase = (): void => {
    navigate('/create-case');
  };

  // Reset filter when loading data
  useEffect(() => {
    resetFilter();
  }, [caseList]);

  const hideCase = (id: number): void => {
    setCaseList(caseList.filter((x: Case) => x.id != id));
  };

  useEffect(() => {
    const newFilteredList = caseList.filter(function (item) {
      // Check favourites
      if (filterFavourites) {
        if (item.isFavorite != undefined && !item.isFavorite) {
          return false;
        }
      }

      // Check my cases
      if (filterCreatedByUser) {
        if (item.createdById != userId && item.assignedToId != userId) {
          return false;
        }
      }

      //Check drawer
      //Check name
      if (drawerFilters.name) {
        if (
          item.name
            .toLowerCase()
            .trim()
            .indexOf(drawerFilters.name.toLowerCase().trim()) < 0
        ) {
          return false;
        }
      }

      //Check hospital
      if (drawerFilters.hospital != null) {
        if (item.hospitalName != drawerFilters.hospital.name) {
          return false;
        }
      }
      //Check clinic
      if (drawerFilters.clinic != null) {
        if (item.assignedToId != drawerFilters.clinic.id) {
          return false;
        }
      }
      //Check tags
      if (drawerFilters.tags.length > 0) {
        return drawerFilters.tags.some((drawerTag) => {
          return item.tags?.some((itemTag) => itemTag.id === drawerTag.id);
        });
      }
      return true;
    });
    setFilteredCaseList(newFilteredList);
  }, [filterFavourites, filterCreatedByUser, drawerFilters]);

  const resetFilter = (): void => {
    setFilteredCaseList(caseList);
  };

  const handleOrderChange = (newOrder: CaseOrderEnum): void => {
    setOrder(newOrder);
  };

  const handleFilterFavourites = (shouldFilter: boolean): void => {
    setFilterFavourites(shouldFilter);
  };

  const handleMyCasesFilter = (shouldFilter: boolean): void => {
    setFilterCreatedByUser(shouldFilter);
  };

  const handleApplyFilters = (filters: FilterConfig): void => {
    setDrawerFilters(filters);
  };

  return (
    <Container
      maxWidth="xl"
      className={searchDrawerOpened ? 'search-drawer-opened' : ''}
      sx={{
        marginTop: '100px',
        // paddingLeft: searchDrawerOpened ? `${drawerWidth}px` : '50px',
        // width: `calc(100% - ${drawerWidth}px)`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <SearchBar
            drawerWidth={drawerWidth}
            onDrawerClose={onSearchDrawerClose}
            onDrawerOpen={onSearchDrawerOpen}
            onOrderChange={handleOrderChange}
            onToggleFavouriteFilter={handleFilterFavourites}
            onToggleMyCasesFilter={handleMyCasesFilter}
            onApplyFilters={handleApplyFilters}
          />
        </Grid>
        <Grid item xs={3} alignSelf="start" textAlign="right" sx={{ mt: -2 }}>
          {containsRole(getUserString, userRoles) && <AskCaseButton />}
          {(containsRole(getDataEntryString, userRoles) ||
            containsRole(getAdminString, userRoles)) && (
            <Button variant="contained" onClick={goToNewCase}>
              Crear caso
            </Button>
          )}
        </Grid>
      </Grid>
      <CaseList
        cases={filteredCaseList}
        loading={isLoading}
        order={order}
        onDelete={hideCase}
      />
    </Container>
  );
}

export default HomePage;
