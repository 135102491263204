/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';

type DateControlProps = {
  label: string;
  fieldName: string;
  control: Control<any, any>;
  schema: ObjectSchema<any>;
  gridSize?: number;
};

export default function DateControl(props: DateControlProps): JSX.Element {
  const required = (
    props.schema.describe().fields[props.fieldName] as Record<any, any>
  ).tests.some((x: any) => x.name === 'required');

  return (
    <Controller
      render={({ field, fieldState }): JSX.Element => (
        <Grid item xs={props.gridSize ?? 12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={props.label + (required ? ' *' : '')}
              inputFormat="dd/MM/yyyy"
              disableFuture
              value={field.value}
              onChange={(value): void => {
                let result: string;

                try {
                  result = format(value, 'yyyy-MM-dd', {
                    locale: es,
                  });
                } catch {
                  result = '';
                }

                field.onChange(result);
              }}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  color="primary"
                  autoComplete="bday"
                  error={!!fieldState.error}
                  helperText={fieldState.error && fieldState.error.message}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      )}
      name={props.fieldName}
      control={props.control}
    />
  );
}
