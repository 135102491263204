import {
  Box,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import React from 'react';
import SearchDrawer, { FilterConfig } from './search-drawer';
import { CaseOrderEnum } from 'modules/components/case-list/case-list';

export interface SearchBarProps {
  onDrawerClose?: Function;
  onDrawerOpen?: Function;
  onOrderChange?: Function;
  onToggleFavouriteFilter?: Function;
  onToggleMyCasesFilter?: Function;
  onApplyFilters?: Function;
  drawerWidth: number;
}

export default function SearchBar(props: SearchBarProps): JSX.Element {
  const [createdByUserFilterActivated, setCreatedByUserFilterActivated] =
    React.useState(false);
  const [favouriteFilterActivated, setFavouriteFilterActivated] =
    React.useState(false);

  const [searchDrawerOpened, setSearchDrawerOpened] = React.useState(true);

  const [anchorOrderMenu, setAnchorOrderMenu] =
    React.useState<null | HTMLElement>(null);

  const onApplyFilters = (filters: FilterConfig): void => {
    if (props.onApplyFilters != undefined) {
      props.onApplyFilters(filters);
    }
  };

  const toggleCreatedByUserFilter = (): void => {
    if (props.onToggleMyCasesFilter != undefined) {
      props.onToggleMyCasesFilter(!createdByUserFilterActivated);
    }
    setCreatedByUserFilterActivated(!createdByUserFilterActivated);
  };

  const toggleFavouriteFilterActivated = (): void => {
    if (props.onToggleFavouriteFilter != undefined) {
      props.onToggleFavouriteFilter(!favouriteFilterActivated);
    }
    setFavouriteFilterActivated(!favouriteFilterActivated);
  };

  const handleOrderMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOrderMenu(event.currentTarget);
  };
  const handleOrderMenuClose = (): void => {
    setAnchorOrderMenu(null);
  };

  const handleSearchDrawerClose = (): void => {
    setSearchDrawerOpened(false);
    if (props.onDrawerClose != undefined) {
      props.onDrawerClose();
    }
  };

  const orderChange = (order: CaseOrderEnum): void => {
    if (props.onOrderChange != undefined) {
      props.onOrderChange(order);
    }
    handleOrderMenuClose();
  };

  const toggleSearchDrawer = (): void => {
    if (!searchDrawerOpened) {
      if (props.onDrawerOpen != undefined) props.onDrawerOpen();
    } else {
      if (props.onDrawerClose != undefined) props.onDrawerClose();
    }
    setSearchDrawerOpened(!searchDrawerOpened);
  };
  return (
    <Grid container spacing={2}>
      <Box sx={{ marginRight: '30px' }}>
        <IconButton onClick={toggleSearchDrawer}>
          <FilterAltOutlinedIcon color="primary" />
        </IconButton>
        <IconButton onClick={handleOrderMenu}>
          <SortByAlphaIcon color="primary" />
        </IconButton>
      </Box>
      <ToggleButtonGroup>
        <Tooltip title="Filtrar favoritos">
          <ToggleButton
            value="favourite"
            selected={favouriteFilterActivated}
            onClick={toggleFavouriteFilterActivated}
          >
            <BookmarkBorderIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Filtrar mis casos">
          <ToggleButton
            value="createdByUser"
            selected={createdByUserFilterActivated}
            onClick={toggleCreatedByUserFilter}
          >
            <PersonOutlineIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      <Menu
        id={'menu-order-search-bar'}
        anchorEl={anchorOrderMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorOrderMenu)}
        onClose={handleOrderMenuClose}
      >
        <MenuItem onClick={(): void => orderChange(CaseOrderEnum.recents)}>
          <ListItemText>Más Recientes</ListItemText>
        </MenuItem>
        <MenuItem onClick={(): void => orderChange(CaseOrderEnum.descendant)}>
          <ListItemText>Orden descendente (A-Z)</ListItemText>
        </MenuItem>
        <MenuItem onClick={(): void => orderChange(CaseOrderEnum.ascendant)}>
          <ListItemText>Orden ascendente (Z-A)</ListItemText>
        </MenuItem>
      </Menu>
      <SearchDrawer
        opened={searchDrawerOpened}
        onClose={handleSearchDrawerClose}
        drawerWidth={props.drawerWidth}
        onApplyFilters={onApplyFilters}
      />
    </Grid>
  );
}
