// import { useAppStateStore } from 'stores/app-state-store';
import { useEvergineStore } from 'stores/evergine-store';
import { EVERGINE_LOADING_BAR_ID } from 'evergine/evergine-definitions';
import { useAppStateStore } from 'stores/app-state-store';

export type EvergineMessage = { type: string; payload: object };

const initializeEvergine = (): void => {
  window.Module.setProgress = (progress: number): void => {
    const percentage = Math.min(Math.round(progress), 90);

    const loadingBar = document.getElementById(EVERGINE_LOADING_BAR_ID);
    if (loadingBar) {
      loadingBar.style.width = percentage + '%';
    }
  };

  const interval = setInterval(() => {
    if (window.areAllAssetsLoaded()) {
      useEvergineStore.getState().setWebAssemblyLoaded(true);
      clearInterval(interval);
      window.Module.locateFile = (base: string): string =>
        `${process.env.PUBLIC_URL}/evergine/${base}`;
    }
  }, 100);

  window.App = {
    OnStlLayersChanged: (layers: string): void => {
      useAppStateStore.getState().setStlLayers(layers);
    },
    OnloadingChanged: (isLoading: boolean): void => {
      useAppStateStore.getState().setLoading(isLoading);
    },
    OnViewerChanged: (viewer: string): void => {
      useAppStateStore.getState().setViewer(viewer);
    },
    OnMriImagesCountChanged: (count: number): void => {
      useAppStateStore.getState().setMriCount(count);
    },
    OnEvergineReady: (ready: boolean): void => {
      useEvergineStore.getState().setEvergineReady(ready);
    },
  };
};

export { initializeEvergine };
