import { Box } from '@mui/material';
import { DataGrid, esES, GridColumns } from '@mui/x-data-grid';
import { constants } from 'config/constants';
import { User } from 'models/user';
import { useEffect, useState } from 'react';
import { getApi } from 'services/http/axios-client';

const UserListPage = (): JSX.Element => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const response = await getApi<User[]>(constants.api.users);
      setUsers(response);
      setLoading(false);
    };
    loadData();
  }, []);

  const columns: GridColumns<User> = [
    { field: 'id', headerName: 'Id', width: 300 },
    { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 100 },
    { field: 'lastName', headerName: 'Apellidos', flex: 1, minWidth: 200 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 300 },
    { field: 'roles', headerName: 'Roles', flex: 1, minWidth: 150 },
    { field: 'hospital', headerName: 'Centro', flex: 2, minWidth: 200 },
  ];

  return (
    <>
      <Box sx={{ marginTop: 1, height: '80vh', width: '100%' }}>
        <DataGrid
          loading={loading}
          rows={users}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </>
  );
};

export default UserListPage;
