import { Drawer, DrawerProps } from '@mui/material';

export const EditDrawer = ({ ...props }: DrawerProps): JSX.Element => {
  return (
    <Drawer
      {...props}
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '640px',
          marginTop: '68px',
        },
      }}
    />
  );
};
