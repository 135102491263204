import { DeleteOutlined, Edit } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { constants } from 'config/constants';
import ConfirmationDialog from 'modules/components/dialogs/confirmation-dialog';
import { EditDrawer } from 'modules/components/drawers/edit-drawer';
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteApi, getApi, postApi, putApi } from 'services/http/axios-client';
import { EntityCreated } from 'models/entity-created';
import { Tag } from 'models/tag';
import TagForm from './tag-form';
import { tagTypeOptions } from 'config/tag-type-options';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TagListPage = React.forwardRef((_props, ref: any): JSX.Element => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<Tag>();
  const [tagToEdit, setTagToEdit] = useState<Tag>({} as Tag);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const response = await getApi<Tag[]>(constants.api.tags);
      setTags(response);
      setLoading(false);
    };
    loadData();
  }, []);

  const columns: GridColumns<Tag> = [
    { field: 'id', headerName: 'Id', width: 100, type: 'number' },
    {
      field: 'typeDesc',
      headerName: 'Tipo',
      flex: 0.5,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        tagTypeOptions.find((t) => t.code === params.row.type.toString())
          ?.label ?? 'Desconocido',
    },
    { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 100 },
    {
      field: 'Acciones',
      type: 'actions',
      getActions: gridActions,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: `calc(100% - ${ref?.current?.clientWidth + 1 || 162}px)`,
          display: 'inline-flex',
          flexWrap: 'wrap',
          justifyContent: 'end',
        }}
      >
        <Button
          variant="contained"
          onClick={(): void => {
            setTagToEdit(getNewTag());
            setOpenDrawer(true);
          }}
        >
          Nueva etiqueta
        </Button>
      </Box>

      <Box sx={{ marginTop: 1, height: '80vh', width: '100%' }}>
        <DataGrid
          loading={loading}
          rows={tags}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      <ConfirmationDialog
        title="Eliminar etiqueta"
        message={`La etiqueta '${tagToDelete?.name}' será eliminada. ¿Está seguro?`}
        open={openDialog}
        onClose={async (confirm: boolean): Promise<void> => {
          setOpenDialog(false);
          if (confirm && tagToDelete !== undefined) {
            await deleteTag(tagToDelete);
          }
          setTagToDelete(undefined);
        }}
      />

      <EditDrawer open={openDrawer}>
        <TagForm
          tag={tagToEdit}
          onClose={async (save: boolean): Promise<void> => {
            if (save) {
              await createOrUpdateTag(tagToEdit);
            }
            setOpenDrawer(false);
            setTagToEdit({} as Tag);
          }}
        />
      </EditDrawer>
    </>
  );

  function gridActions(params: GridRowParams): JSX.Element[] {
    return [
      <GridActionsCellItem
        icon={<Edit color="primary" />}
        label="Editar"
        onClick={(): void => {
          const tag = params.row;
          tag.typeComboCode = tag.type?.toString();
          setTagToEdit(tag);
          setOpenDrawer(true);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteOutlined color="primary" />}
        label="Eliminar"
        onClick={(): void => {
          setTagToDelete(params.row);
          setOpenDialog(true);
        }}
      />,
    ];
  }

  async function createOrUpdateTag(tagToEdit: Tag): Promise<void> {
    if (tagToEdit.id === 0) {
      const response = await postApi<EntityCreated, Tag>(
        constants.api.tags,
        tagToEdit
      );
      tagToEdit.id = response.id;
      setTags([...tags, tagToEdit]);
    } else {
      await putApi<void, Tag>(
        constants.api.tags + '/' + tagToEdit.id,
        tagToEdit
      );
    }

    toast.success(`Etiqueta guardada: ${tagToEdit.name}`);
  }

  async function deleteTag(tagToDelete: Tag): Promise<void> {
    await deleteApi<void>(constants.api.tags + '/' + tagToDelete?.id);

    toast.success(`Etiqueta eliminada: ${tagToDelete.name}`);
    setTags(tags.filter((h) => h.id !== tagToDelete?.id));
  }

  function getNewTag(): React.SetStateAction<Tag> {
    return {
      id: 0,
      name: '',
      type: 0,
      typeComboCode: null!,
    };
  }
});

export default TagListPage;
