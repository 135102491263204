import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
} from '@mui/material';
import {
  setLayerOpacity,
  setLayerVisible,
} from 'evergine/evergine-program-service';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'modules/theme/theme-options';

export interface LayerDrawerProps {
  isOpened: boolean;
  layers?: string;
  close: Function;
}

export default function LayerDrawer(props: LayerDrawerProps): JSX.Element {
  //   const isOpened = true;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    setLayerVisible(event.target.name, checked);
  };

  const updateSliderValue = (
    event: Event,
    current: number | number[]
  ): void => {
    const t = event.target as unknown as { value: number; name: string };
    setLayerOpacity(t.name, current as number);
  };

  const handleClose = (): void => {
    props.close();
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={props.isOpened}
      sx={{
        width: '300px',
        display: 'block',
        '& .MuiBackdrop-root': {
          display: 'none',
        },
        '& .MuiDrawer-paper': {
          backgroundColor: 'black',
          color: 'white',
          boxSizing: 'border-box',
          width: '300px',
          position: props.isOpened ? 'absolute' : 'fixed',
          transition: 'none !important',
          paddingTop: '10px',
          overflowX: 'hidden',
          zIndex: 1000,
        },
      }}
    >
      <Button variant="contained" endIcon={<CloseIcon />} onClick={handleClose}>
        Cerrar
      </Button>
      <List>
        {props.layers?.split(',').map((text) => (
          <div key={text}>
            <ListItem key={text} sx={{ pb: 0 }}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <Checkbox
                  edge="start"
                  onChange={handleChange}
                  name={text}
                  defaultChecked
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            <ListItem sx={{ pt: 0 }}>
              <Slider
                name={text}
                min={0}
                max={1}
                step={0.1}
                defaultValue={1}
                onChange={updateSliderValue}
                sx={{ pt: 0, mr: '10px' }}
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Drawer>
  );
}
