import {
  Box,
  Button,
  Drawer,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { User } from 'models/user';
import { constants } from 'config/constants';
import { getApi } from 'services/http/axios-client';
import { useEffect, useState } from 'react';

export interface UserDrawerProps {
  opened: boolean;
  onClose?: Function;
}

export default function UserDrawer(props: UserDrawerProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [placeholder, setPlaceholder] = useState('loading...');

  const loadData = async (): Promise<void> => {
    const response = await getApi<User>(constants.api.users + '/me');
    setUser(response);
    setPlaceholder('n/a');
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
    if (props.onClose != undefined) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.opened) {
      loadData();
    }
    setOpen(props.opened);
  }, [props.opened]);

  const idTypes = [
    { id: -1, label: 'n/a' },
    { id: 1, label: 'DNI' },
    { id: 2, label: 'Pasaporte' },
    { id: 3, label: 'Otro' },
  ];

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
      sx={{
        width: '600px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '600px',
          paddingTop: '64px',
          paddingBottom: '16px',
        },
      }}
    >
      <Box sx={{ marginRight: '75px', marginLeft: '75px', marginTop: '75px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="show-name"
              label="Nombre para mostrar"
              value={user?.displayName ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="name"
              label="Nombre"
              value={user?.name ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="apellidos"
              label="Apellidos"
              value={user?.lastName ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email"
              type="email"
              value={user?.email ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="id-type-label">Tipo de ID</InputLabel>
            <Select
              id="idtype"
              labelId="id-type-label"
              label="Tipo de ID"
              value={user?.identityType ?? idTypes[0].id.toString()}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            >
              {idTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="idnumber"
              label="ID"
              value={user?.identity ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="birth"
              label="Fecha de nacimiento"
              value={user?.birthDate ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="centro"
              label="Centro"
              value={user?.hospital ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="especialidad"
              label="Especialidad"
              value={user?.specialField ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="perfilprofesional"
              label="Perfil Profesional"
              value={user?.professionalProfile ?? placeholder}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
            }}
          >
            <Button
              sx={{
                width: '150px',
                marginTop: '25px',
              }}
              variant="contained"
              color="primary"
              onClick={handleDrawerClose}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
