import yup from 'services/localized-yup';
import { constants } from 'config/constants';

export const attachedFileSchema = yup.object({
  id: yup.number(),
  name: yup.string().required().max(constants.dataValidation.smallStringSize),
  description: yup.string().max(constants.dataValidation.mediumStringSize),
  path: yup.string().max(constants.dataValidation.mediumStringSize),
  file: yup.mixed().nullable(),
  typeComboCode: yup.string().required().nullable(),
  type: yup
    .number()
    .when('typeComboCode', (code, schema) =>
      schema.transform(() => parseInt(code))
    )
    .required(),
});

export type AttachedFile = yup.InferType<typeof attachedFileSchema>;
