import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import { getSettings } from 'config/settings';
import { UserCreate } from 'models/user-create';
import { useState } from 'react';
import UserCreateForm from './create-user-form';
import { constants } from 'config/constants';
import { postApi } from 'services/http/axios-client';
import logolina from 'resources/images/logolina.png';
import logoHvV from 'resources/images/logoHvV.png';
import './login-page.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

function LoginOrCreate(): JSX.Element {
  const { instance } = useMsal();

  const [open, setOpen] = useState(false);
  const [userCreateRequest, setUserCreateRequest] = useState<UserCreate>(
    {} as UserCreate
  );

  const handleLogin = async (): Promise<void> => {
    await instance.loginRedirect(getSettings().loginRequest);
  };
  function handleHide(event: {}, reason: string): void {
    if (reason && reason == 'backdropClick') return;
    setOpen(false);
  }

  function handleShow(): void {
    setUserCreateRequest(getNewUserCreate());
    setOpen(true);
  }

  const submitAskUserCreate = async (userCreate: UserCreate): Promise<void> => {
    await postApi<Promise<boolean>, UserCreate>(
      constants.api.sendRegistrationMail,
      userCreate
    );
    toast.success('Petición de alta de usuario enviada');
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={getSettings().recaptcha.siteKey}
      useEnterprise={true}
    >
      <Box className="left-container">
        <Grid container spacing={2} rowSpacing={5}>
          <Grid item xs={12}>
            <img src={logolina} width={200} alt="Logo Lina" />
            <h1>Plataforma de Visionado</h1>
            <img
              src={logoHvV}
              width={175}
              alt="Logo Hospital Virtual de Valdecilla"
            />
          </Grid>

          <Grid item xs={12} marginTop="40px">
            <Stack width={250} spacing={2}>
              <Button onClick={handleLogin} variant="contained">
                Iniciar Sesión
              </Button>
              <Button onClick={handleShow} variant="contained">
                Crear usuario
              </Button>
            </Stack>
          </Grid>
          <Dialog
            open={open}
            onClose={handleHide}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Peticion de alta de usuario</DialogTitle>
            <DialogContent>
              <UserCreateForm
                createUser={userCreateRequest}
                onClose={async (save: boolean): Promise<void> => {
                  if (save) {
                    await submitAskUserCreate(userCreateRequest);
                  }
                  handleHide({}, '');
                }}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Box>
    </GoogleReCaptchaProvider>
  );

  function getNewUserCreate(): React.SetStateAction<UserCreate> {
    return {
      name: '',
      surname: '',
      email: '',
      idType: null!,
      id: '',
      birthDate: '',
      hospital: null!,
      professionProfile: null!,
      specialField: null!,
      token: '',
    };
  }
}

export default LoginOrCreate;
