import { AttachedFile } from 'models/attached-file';
import { Case } from 'models/case';
import FileUploaderFile from 'modules/components/file-uploader/file-uploader-file';
import { Control, FieldArrayWithId } from 'react-hook-form';

type FileUploaderListProps = {
  arrayName: string;
  fields: FieldArrayWithId<Case, 'attachedFiles', 'id'>[];
  case: Case;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<Case, any>;
  onDeleteAttachedFile: (attachedFile: AttachedFile, index: number) => void;
  isRadiology: boolean;
};

export default function FileUploaderList(
  props: FileUploaderListProps
): JSX.Element {
  return (
    <>
      {props.fields.map(
        (field, index): JSX.Element => (
          <FileUploaderFile
            key={field.id}
            index={index}
            attachedFile={field}
            arrayName={props.arrayName}
            control={props.control}
            onDeleteAttachedFile={props.onDeleteAttachedFile}
            isRadiology={props.isRadiology}
          />
        )
      )}
    </>
  );
}
