import { constants } from 'config/constants';
import yup from 'services/localized-yup';

export const askCaseSchema = yup.object({
  subject: yup
    .string()
    .required()
    .max(constants.dataValidation.smallStringSize),
  comments: yup.string().required().max(constants.dataValidation.bigStringSize),
});

export type AskCase = yup.InferType<typeof askCaseSchema>;
