import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserCreate, userSchemaCreate } from 'models/user-create';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { constants } from 'config/constants';
import { getApi } from 'services/http/axios-client';
import { Hospital } from 'models/hospital';
import { ProfessionalProfile } from 'models/professional-profile';
import { SpecialField } from 'models/special-field';
import AutoCompleteControl from 'modules/components/form-controls/auto-complete-control';
import { IOption } from 'modules/components/form-controls/i-option';
import { IdTypeOptions as idTypeOptions } from 'config/id-type-options';
import DateControl from 'modules/components/form-controls/date-control';
import { toast } from 'react-toastify';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';

type UserFormProps = {
  createUser: UserCreate;
  onClose: (save: boolean) => void;
};

export default function UserCreateForm({
  createUser,
  onClose,
}: UserFormProps): JSX.Element {
  const { handleSubmit, control, formState } = useForm<UserCreate>({
    defaultValues: createUser,
    resolver: yupResolver(userSchemaCreate),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);
  const [loaded, setLoaded] = useState(false);
  const [hospitalList, setHospitals] = useState<IOption[]>([]);
  const [professionList, setProfessionList] = useState<IOption[]>([]);
  const [specialFieldList, setSpecialFieldList] = useState<IOption[]>([]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit: SubmitHandler<UserCreate> = async (newUserData) => {
    if (!executeRecaptcha) {
      toast.warn('Recaptcha todavía no está disponible, vuelva a intentarlo');
      return;
    }

    const token = await executeRecaptcha();

    Object.assign(createUser, newUserData);
    createUser.token = token;
    onClose(true);
  };

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const responseHospitals = await getApi<Hospital[]>(
        constants.api.hospitals
      );
      setHospitals(
        responseHospitals.map((h) => ({ code: `${h.id}`, label: h.name }))
      );

      const responseProffesions = await getApi<ProfessionalProfile[]>(
        constants.api.professionalprofiles
      );
      setProfessionList(
        responseProffesions.map((h) => ({ code: `${h.id}`, label: h.name }))
      );

      const responseField = await getApi<SpecialField[]>(
        constants.api.specialfields
      );

      setSpecialFieldList(
        responseField.map((h) => ({ code: `${h.id}`, label: h.name }))
      );
      setLoaded(true);
    };
    loadData();
  }, []);

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mx: 2, mt: 2, mb: 0 }}
      >
        <Grid container rowSpacing={1} columnSpacing={3}>
          <TextFieldControl
            label="Nombre"
            fieldName={nameof<UserCreate>('name')}
            control={control}
            schema={userSchemaCreate}
            gridSize={6}
          />

          <TextFieldControl
            label="Apellidos"
            fieldName={nameof<UserCreate>('surname')}
            control={control}
            schema={userSchemaCreate}
            gridSize={6}
          />

          <TextFieldControl
            label="Email"
            fieldName={nameof<UserCreate>('email')}
            control={control}
            schema={userSchemaCreate}
            gridSize={12}
          />

          <AutoCompleteControl
            label="Tipo ID"
            fieldName={nameof<UserCreate>('idType')}
            control={control}
            schema={userSchemaCreate}
            gridSize={6}
            options={idTypeOptions}
          />

          <TextFieldControl
            label="ID"
            fieldName={nameof<UserCreate>('id')}
            control={control}
            schema={userSchemaCreate}
            gridSize={6}
          />

          <DateControl
            label="Fecha de nacimiento"
            fieldName={nameof<UserCreate>('birthDate')}
            control={control}
            schema={userSchemaCreate}
            gridSize={6}
          />

          <AutoCompleteControl
            disabled={!loaded}
            label="Centro"
            fieldName={nameof<UserCreate>('hospital')}
            control={control}
            schema={userSchemaCreate}
            gridSize={12}
            options={hospitalList}
          />

          <AutoCompleteControl
            disabled={!loaded}
            label="Especialidad"
            fieldName={nameof<UserCreate>('specialField')}
            control={control}
            schema={userSchemaCreate}
            gridSize={12}
            options={specialFieldList}
          />

          <AutoCompleteControl
            disabled={!loaded}
            label="Perfil Profesional"
            fieldName={nameof<UserCreate>('professionProfile')}
            control={control}
            schema={userSchemaCreate}
            gridSize={12}
            options={professionList}
          />

          <Grid item xs={12}>
            <Box sx={{ mt: 2 }}></Box>
          </Grid>

          <Grid item xs={6} marginTop={5} marginBottom={2}>
            <Button
              variant="outlined"
              fullWidth
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
            >
              Descartar
            </Button>
          </Grid>
          <Grid item xs={6} marginTop={5} marginBottom={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!loaded}
            >
              Enviar solicitud
            </Button>
          </Grid>
          <Grid item xs={12} marginBottom={0} marginTop={2}>
            <Typography fontSize={10} textAlign="center">
              This site is protected by reCAPTCHA Enterprise and the Google{' '}
              <a href="https://policies.google.com/privacy"> Privacy Policy </a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms"> Terms of Service </a>{' '}
              apply.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <DiscardDialog ref={discardDialog} onClose={onClose} isDirty={isDirty} />
    </>
  );
}
