/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ObjectSchema } from 'yup';

type TextFieldControlProps = {
  label: string;
  fieldName: string;
  control: Control<any, any>;
  schema: ObjectSchema<any>;
  gridSize?: number;
  multiline?: boolean;
  rows?: number;
  hideRequiredStar?: boolean;
  hideFloatingLabel?: boolean;
};

export default function TextFieldControl(
  props: TextFieldControlProps
): JSX.Element {
  const required = props.hideFloatingLabel
    ? false
    : (
        props.schema.describe().fields[props.fieldName] as Record<any, any>
      ).tests.some((x: any) => x.name === 'required');

  return (
    <Controller
      render={({ field, fieldState }): JSX.Element => (
        <Grid item xs={props.gridSize ?? 12}>
          <TextField
            {...field}
            id={props.fieldName}
            variant="standard"
            margin="normal"
            fullWidth
            label={
              props.hideFloatingLabel
                ? undefined
                : props.label +
                  (required && !props.hideRequiredStar ? ' *' : '')
            }
            error={!!fieldState.error}
            helperText={fieldState.error && fieldState.error.message}
            multiline={props.multiline ?? false}
            rows={props.multiline ?? false ? props.rows || 3 : 1}
            placeholder={props.hideFloatingLabel ? props.label : undefined}
          />
        </Grid>
      )}
      name={props.fieldName}
      control={props.control}
    />
  );
}
