import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { getSettings } from 'config/settings';
import {
  ApiError,
  configureHttpClientErrorHandler,
  errorHandler,
} from 'services/http/axios-error-handler';
import { HttpMethod } from 'services/http/http-methods';
import { attach, detach, getConfig, RetryConfig } from 'retry-axios';

// Create the http client and configure the default error handler
let lastInterceptorId = -1;
const httpClient = createHttpClient({
  baseURL: getSettings().server.url,
});
let errorHandlerId = configureHttpClientErrorHandler(httpClient);
//

function createHttpClient(config: AxiosRequestConfig): AxiosInstance {
  const instance = axios.create(config);
  return instance;
}

function setApiAccessToken(token: string): void {
  httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function setRefreshTokenCallback(callback: () => Promise<void>): void {
  if (lastInterceptorId !== -1) {
    detach(lastInterceptorId);
    lastInterceptorId = -1;
    httpClient.defaults.raxConfig = undefined;
    errorHandlerId = configureHttpClientErrorHandler(httpClient);
  }

  if (callback !== undefined) {
    httpClient.defaults.raxConfig = {
      instance: httpClient,
      retry: 3,
      httpMethodsToRetry: [
        HttpMethod.GET,
        HttpMethod.POST,
        HttpMethod.PUT,
        HttpMethod.DELETE,
        HttpMethod.HEAD,
        HttpMethod.OPTIONS,
      ],
      statusCodesToRetry: [
        [100, 199],
        [400, 499],
        [500, 599],
      ],
      onRetryAttempt: async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: AxiosError<any, any>
      ): Promise<void> => {
        const cfg = getConfig(error);
        if (error?.response?.status === 401 && cfg?.currentRetryAttempt === 1) {
          await callback();
          if (error.config.headers != undefined) {
            error.config.headers['Authorization'] =
              httpClient.defaults.headers.common['Authorization'];
          }
        }

        if (
          isLastAttempt(cfg) ||
          isAnIdempotentActionAndErrorDistinctOfUnauthorized(error)
        ) {
          errorHandler(error as ApiError);
        }
      },
    };
    lastInterceptorId = attach(httpClient);
    httpClient.interceptors.response.eject(errorHandlerId);
  }
}

function isAnIdempotentActionAndErrorDistinctOfUnauthorized(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<any, any>
): boolean {
  return (
    error?.response?.status !== 401 &&
    error?.config?.method?.toUpperCase() === HttpMethod.POST
  );
}

function isLastAttempt(cfg: RetryConfig | undefined): boolean {
  return cfg?.currentRetryAttempt === cfg?.retry;
}

async function fetchApi<TResponse, TData = {}>(
  method: HttpMethod,
  endpoint: string,
  data?: TData
): Promise<TResponse> {
  const result = await httpClient.request<
    TResponse,
    Promise<AxiosResponse<TResponse>>,
    TData
  >({
    method: method,
    url: getSettings().server.apiBase + '/' + endpoint,
    responseType: 'json',
    data: data,
  });

  return result.data;
}

async function getApi<TResponse, TData = {}>(
  endpoint: string,
  data?: TData
): Promise<TResponse> {
  return await fetchApi<TResponse, TData>(HttpMethod.GET, endpoint, data);
}

async function postApi<TResponse, TData = {}>(
  endpoint: string,
  data?: TData
): Promise<TResponse> {
  return await fetchApi<TResponse, TData>(HttpMethod.POST, endpoint, data);
}

async function putApi<TResponse, TData = {}>(
  endpoint: string,
  data?: TData
): Promise<TResponse> {
  return await fetchApi<TResponse, TData>(HttpMethod.PUT, endpoint, data);
}

async function deleteApi<TResponse, TData = {}>(
  endpoint: string,
  data?: TData
): Promise<TResponse> {
  return await fetchApi<TResponse, TData>(HttpMethod.DELETE, endpoint, data);
}

async function putFileApi<TResponse>(
  endpoint: string,
  data?: FormData
): Promise<TResponse> {
  return await fetchApi<TResponse, FormData>(HttpMethod.PUT, endpoint, data);
}

async function postFileApi<TResponse>(
  endpoint: string,
  data?: FormData
): Promise<TResponse> {
  return await fetchApi<TResponse, FormData>(HttpMethod.POST, endpoint, data);
}

export {
  setApiAccessToken,
  setRefreshTokenCallback,
  getApi,
  postApi,
  putApi,
  deleteApi,
  postFileApi,
  putFileApi,
};
