import { Box, Button, Grid } from '@mui/material';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import { SpecialField, specialFieldSchema } from 'models/special-field';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';
import { useRef } from 'react';

type SpecialFieldFormProps = {
  specialField: SpecialField;
  onClose: (save: boolean) => void;
};

export default function SpecialFieldForm({
  specialField,
  onClose,
}: SpecialFieldFormProps): JSX.Element {
  const { handleSubmit, control, formState } = useForm<SpecialField>({
    defaultValues: specialField,
    resolver: yupResolver(specialFieldSchema),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<SpecialField> = (newSpecialFieldData) => {
    Object.assign(specialField, newSpecialFieldData);
    onClose(true);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.light', px: 9 }}>
        <h2>
          {specialField.name !== '' && specialField.name}
          {specialField.name === '' && 'Nueva Especialidad'}
        </h2>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mx: 9 }}>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <TextFieldControl
            label="Nombre"
            fieldName={nameof<SpecialField>('name')}
            control={control}
            schema={specialFieldSchema}
          />

          <Grid item xs={12}>
            <Box sx={{ mt: 3 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DiscardDialog ref={discardDialog} onClose={onClose} isDirty={isDirty} />
    </>
  );
}
