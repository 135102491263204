import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { postApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import { AskCase } from 'models/ask-case';
import AskCaseForm from './ask-case-form';

function AskCaseButton(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [askCaseRequest, setAskCaseRequest] = useState<AskCase>({} as AskCase);

  const submitAskCase = async (acase: AskCase): Promise<void> => {
    await postApi<Promise<boolean>, AskCase>(constants.api.askCase, acase);
  };

  function handleHide(event: {}, reason: string): void {
    if (reason && reason == 'backdropClick') return;
    setOpen(false);
  }

  function handleShow(): void {
    setAskCaseRequest(getNewAskCaseRequest());
    setOpen(true);
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleShow}>
        ¿Necesitas un estudio? Pídenoslo aquí
      </Button>
      <Dialog
        open={open}
        onClose={handleHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <AskCaseForm
            askCase={askCaseRequest}
            onClose={async (save: boolean): Promise<void> => {
              if (save) {
                await submitAskCase(askCaseRequest);
              }
              handleHide({}, '');
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );

  function getNewAskCaseRequest(): AskCase {
    return {
      subject: '',
      comments: '',
    };
  }
}

export default AskCaseButton;
