import { DeleteOutlined, Edit } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
} from '@mui/x-data-grid';
import { constants } from 'config/constants';
import ConfirmationDialog from 'modules/components/dialogs/confirmation-dialog';
import { EditDrawer } from 'modules/components/drawers/edit-drawer';
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteApi, getApi, postApi, putApi } from 'services/http/axios-client';
import { EntityCreated } from 'models/entity-created';
import { SpecialField } from 'models/special-field';
import SpecialFieldForm from './special-field-form';

const SpecialFieldListPage = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (_props, ref: any): JSX.Element => {
    const [specialFields, setSpecialFields] = useState<SpecialField[]>([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [specialFieldToDelete, setSpecialFieldToDelete] =
      useState<SpecialField>();
    const [specialFieldToEdit, setSpecialFieldToEdit] = useState<SpecialField>(
      {} as SpecialField
    );
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
      const loadData = async (): Promise<void> => {
        const response = await getApi<SpecialField[]>(
          constants.api.specialfields
        );
        setSpecialFields(response);
        setLoading(false);
      };
      loadData();
    }, []);

    const columns: GridColumns<SpecialField> = [
      { field: 'id', headerName: 'Id', width: 100, type: 'number' },
      { field: 'name', headerName: 'Nombre', flex: 1 },
      {
        field: 'Acciones',
        type: 'actions',
        getActions: gridActions,
      },
    ];

    return (
      <>
        <Box
          sx={{
            width: `calc(100% - ${ref?.current?.clientWidth + 1 || 162}px)`,
            display: 'inline-flex',
            flexWrap: 'wrap',
            justifyContent: 'end',
          }}
        >
          <Button
            variant="contained"
            onClick={(): void => {
              setSpecialFieldToEdit(getNewSpecialField());
              setOpenDrawer(true);
            }}
          >
            Nueva especialidad
          </Button>
        </Box>

        <Box sx={{ marginTop: 1, height: '80vh', width: '100%' }}>
          <DataGrid
            loading={loading}
            rows={specialFields}
            columns={columns}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>

        <ConfirmationDialog
          title="Eliminar especialidad"
          message={`La especialidad '${specialFieldToDelete?.name}' será eliminada. ¿Está seguro?`}
          open={openDialog}
          onClose={async (confirm: boolean): Promise<void> => {
            setOpenDialog(false);
            if (confirm && specialFieldToDelete !== undefined) {
              await deleteSpecialField(specialFieldToDelete);
            }
            setSpecialFieldToDelete(undefined);
          }}
        />

        <EditDrawer open={openDrawer}>
          <SpecialFieldForm
            specialField={specialFieldToEdit}
            onClose={async (save: boolean): Promise<void> => {
              if (save) {
                await createOrUpdateSpecialField(specialFieldToEdit);
              }
              setOpenDrawer(false);
              setSpecialFieldToEdit({} as SpecialField);
            }}
          />
        </EditDrawer>
      </>
    );

    function gridActions(params: GridRowParams): JSX.Element[] {
      return [
        <GridActionsCellItem
          icon={<Edit color="primary" />}
          label="Editar"
          onClick={(): void => {
            setSpecialFieldToEdit(params.row);
            setOpenDrawer(true);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlined color="primary" />}
          label="Eliminar"
          onClick={(): void => {
            setSpecialFieldToDelete(params.row);
            setOpenDialog(true);
          }}
        />,
      ];
    }

    async function createOrUpdateSpecialField(
      specialFieldToEdit: SpecialField
    ): Promise<void> {
      if (specialFieldToEdit.id === 0) {
        const response = await postApi<EntityCreated, SpecialField>(
          constants.api.specialfields,
          specialFieldToEdit
        );
        specialFieldToEdit.id = response.id;
        setSpecialFields([...specialFields, specialFieldToEdit]);
      } else {
        await putApi<void, SpecialField>(
          constants.api.specialfields + '/' + specialFieldToEdit.id,
          specialFieldToEdit
        );
      }

      toast.success(`Especialidad guardada: ${specialFieldToEdit.name}`);
    }

    async function deleteSpecialField(
      specialFieldToDelete: SpecialField
    ): Promise<void> {
      await deleteApi<void>(
        constants.api.specialfields + '/' + specialFieldToDelete?.id
      );

      toast.success(`Especialidad eliminada: ${specialFieldToDelete.name}`);
      setSpecialFields(
        specialFields.filter((h) => h.id !== specialFieldToDelete?.id)
      );
    }

    function getNewSpecialField(): React.SetStateAction<SpecialField> {
      return {
        id: 0,
        name: '',
      };
    }
  }
);

export default SpecialFieldListPage;
