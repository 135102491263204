import { IconButton, Tooltip } from '@mui/material';
import { Case } from 'models/case';
import { useState } from 'react';
import { deleteApi, putApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

interface FavoriteCaseButtonProps {
  case: Case;
}

export default function FavoriteCaseButton(
  props: FavoriteCaseButtonProps
): JSX.Element {
  const [disabledFavoriteButton, setDisabledFavoriteButton] = useState(false);

  const handleFavoriteButton = (): void => {
    setDisabledFavoriteButton(true);
    if (props.case.isFavorite) {
      removeFavorite();
    } else {
      addFavorite();
    }
  };

  async function removeFavorite(): Promise<void> {
    try {
      await deleteApi<void, void>(
        `${constants.api.favorites}/${props.case.id}`
      );
      props.case.isFavorite = false;
    } catch (error) {
      // console.log('error');
    } finally {
      setDisabledFavoriteButton(false);
    }
  }

  async function addFavorite(): Promise<void> {
    try {
      await putApi<unknown, void>(
        `${constants.api.favorites}/${props.case.id}`
      );
      props.case.isFavorite = true;
    } catch (error) {
      // console.log('error');
    } finally {
      setDisabledFavoriteButton(false);
    }
  }

  return (
    <Tooltip title="Añadir a favoritos">
      <IconButton
        aria-label="add to favorites"
        onClick={handleFavoriteButton}
        disabled={disabledFavoriteButton}
      >
        {props.case.isFavorite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
      </IconButton>
    </Tooltip>
  );
}
