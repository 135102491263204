import { Box, Grid, Chip } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { AttachedFile } from 'models/attached-file';
import { AttachedFileTypeEnum } from 'config/attached-file-type-enum';

export interface SelectableItemProps {
  selected: Boolean;
  item: AttachedFile;
}

export default function SelectableItem(
  props: SelectableItemProps
): JSX.Element {
  return (
    <Box
      sx={{
        '&:hover': {
          backgroundColor: '#9AA4B026',
        },
        ...(props.selected && {
          backgroundColor: '#3886C21F',
        }),
        p: 2,
        borderRadius: '8px',
        cursor: 'pointer',
        marginBottom: '8px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <ImageSearchIcon sx={{ color: '#9AA4B0' }} />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ fontSize: '13px', fontWeight: 'bold', lineHeight: '18px' }}
        >
          {props.item.name}
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ textAlign: 'right' }}>
            <Chip
              label={AttachedFileTypeEnum[props.item.type].toUpperCase()}
              variant="outlined"
              sx={{ cursor: 'pointer' }}
            />
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={11}
          sx={{ color: '#6D757D', fontSize: '12px', lineHeight: '16px' }}
        >
          {props.item.description}
        </Grid>
      </Grid>
    </Box>
  );
}
