import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Grid, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import './case-card.css';

export default function LoadingCard(): JSX.Element {
  return (
    <Card className="case-card">
      <CardActionArea>
        <Skeleton animation="wave" height={140} width={250} />
        <CardContent>
          <Skeleton animation="wave" height={10} width="80%" />
          <Skeleton animation="wave" height={10} width="80%" />
          <Skeleton animation="wave" height={10} width="80%" />
          <Grid container gap={1}>
            <Grid item>
              <Box
                sx={{ fontSize: '14px', fontWeight: '400', color: '#838C96' }}
              >
                <Skeleton animation="wave" height={10} width="80%" />
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{ fontSize: '14px', fontWeight: '400', color: '#151617' }}
              >
                <Skeleton animation="wave" height={10} width="80%" />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Skeleton animation="wave" height={10} width="80%" />
          </Box>
          {/* TODO <Chip label="Aceptada" /> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
