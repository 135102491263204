import { constants } from 'config/constants';
import yup from 'services/localized-yup';

export const tagSchema = yup.object({
  id: yup.number(),
  name: yup.string().required().max(constants.dataValidation.smallStringSize),
  typeComboCode: yup.string().required().nullable(),
  type: yup
    .number()
    .when('typeComboCode', (code, schema) =>
      schema.transform(() => parseInt(code))
    )
    .required(),
});

export type Tag = yup.InferType<typeof tagSchema>;
