import { Box, Button, Grid } from '@mui/material';
import { Hospital, hospitalSchema } from 'models/hospital';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import AutoCompleteControl from 'modules/components/form-controls/auto-complete-control';
import { countries } from 'config/countries';
import { useState, useRef } from 'react';
import { countrySubdivisions } from 'config/country-subdivisions';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';

type HospitalFormProps = {
  hospital: Hospital;
  onClose: (save: boolean) => void;
};

export default function HospitalForm({
  hospital,
  onClose,
}: HospitalFormProps): JSX.Element {
  const [statesDictionary] = useState(
    countrySubdivisions.reduce((acc, x) => {
      acc[x.country] = acc[x.country] || [];
      acc[x.country].push(x);
      return acc;
    }, Object.create(null))
  );
  const [states, setStates] = useState(
    statesDictionary[hospital.country] || []
  );
  const { handleSubmit, control, setValue, formState } = useForm<Hospital>({
    defaultValues: hospital,
    resolver: yupResolver(hospitalSchema),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<Hospital> = (newHospitalData) => {
    Object.assign(hospital, newHospitalData);
    onClose(true);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.light', px: 9 }}>
        <h2>
          {hospital.name !== '' && hospital.name}
          {hospital.name === '' && 'Nuevo centro'}
        </h2>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mx: 9 }}>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <TextFieldControl
            label="Nombre"
            fieldName={nameof<Hospital>('name')}
            control={control}
            schema={hospitalSchema}
          />
          <AutoCompleteControl
            label="País"
            fieldName={nameof<Hospital>('country')}
            control={control}
            schema={hospitalSchema}
            options={countries}
            getOptionImage={(option): JSX.Element => {
              if (option != null) {
                return (
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                );
              }
              return <> </>;
            }}
            onChange={(option): void => {
              setValue(nameof<Hospital>('state'), null!);
              if (option != null) {
                setStates(statesDictionary[option.code] || []);
              } else {
                setStates([]);
              }
            }}
          />
          <AutoCompleteControl
            label="Provincia"
            fieldName={nameof<Hospital>('state')}
            control={control}
            schema={hospitalSchema}
            options={states}
          />
          <TextFieldControl
            label="Ciudad"
            fieldName={nameof<Hospital>('city')}
            control={control}
            schema={hospitalSchema}
          />

          <Grid item xs={12}>
            <Box sx={{ mt: 3 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DiscardDialog ref={discardDialog} onClose={onClose} isDirty={isDirty} />
    </>
  );
}
