import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Case } from 'models/case';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import ConfirmationDialog from 'modules/components/dialogs/confirmation-dialog';
import { deleteApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import { toast } from 'react-toastify';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface FavoriteEditDeleteMenuProps {
  case: Case;
  onDelete?: (id: number) => void;
  canEdit: boolean;
}

export default function CaseEditDeleteMenu(
  props: FavoriteEditDeleteMenuProps
): JSX.Element {
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorMenu(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorMenu(null);
  };
  const handleSoftDelete = (): void => {
    setOpenDeleteDialog(true);
  };
  const handleEdit = (): void => {
    navigate('/edit-case/' + props.case.id);
  };

  async function deleteCase(): Promise<void> {
    await deleteApi<void>(constants.api.cases + '/' + props.case.id);

    toast.success(`Caso eliminado: ${props.case.name}`);
    if (props.onDelete != undefined) {
      props.onDelete(props.case.id!);
    }
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        className="card-icon-right"
        onClick={handleMenu}
      >
        <GridMoreVertIcon />
      </IconButton>
      <Menu
        id={'menu-card-' + props.case.id}
        anchorEl={anchorMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorMenu)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit} disabled={!props.canEdit}>
          <ListItemIcon>
            <EditIcon></EditIcon>
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(): void => handleSoftDelete()}
          disabled={!props.canEdit}
        >
          <ListItemIcon>
            <DeleteOutlineIcon></DeleteOutlineIcon>
          </ListItemIcon>
          <ListItemText>Borrar</ListItemText>
        </MenuItem>
      </Menu>

      <ConfirmationDialog
        title="Eliminar caso"
        message={`El caso '${props.case.name}' será eliminado. ¿Está seguro?`}
        open={openDeleteDialog}
        onClose={async (confirm: boolean): Promise<void> => {
          setOpenDeleteDialog(false);
          if (confirm) {
            await deleteCase();
          }
        }}
      />
    </div>
  );
}
