import { Grid } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AttachedFile } from 'models/attached-file';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { Control } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { Case, caseSchema } from 'models/case';
import AutoCompleteControl from '../form-controls/auto-complete-control';
import { attachedFileRadiologyTypeOptions } from 'config/attached-file-type-enum';

type FileUploaderFileProps = {
  index: number;
  attachedFile: AttachedFile;
  arrayName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<Case, any>;
  onDeleteAttachedFile: (attachedFile: AttachedFile, index: number) => void;
  isRadiology: boolean;
};

export default function FileUploaderFile(
  props: FileUploaderFileProps
): JSX.Element {
  return (
    <Grid container spacing={5}>
      <TextFieldControl
        label="Nombre"
        fieldName={`${getPrefix(props.index)}${nameof<AttachedFile>('name')}`}
        control={props.control}
        schema={caseSchema}
        hideRequiredStar
        hideFloatingLabel
        gridSize={props.isRadiology ? 3 : 4}
      />
      <TextFieldControl
        label="Descripción"
        fieldName={`${getPrefix(props.index)}${nameof<AttachedFile>(
          'description'
        )}`}
        control={props.control}
        schema={caseSchema}
        hideRequiredStar
        hideFloatingLabel
        gridSize={props.isRadiology ? 6 : 7}
      />
      {props.isRadiology && (
        <AutoCompleteControl
          label="Tipo"
          fieldName={`${getPrefix(props.index)}${nameof<AttachedFile>(
            'typeComboCode'
          )}`}
          control={props.control}
          schema={caseSchema}
          options={attachedFileRadiologyTypeOptions}
          gridSize={2}
          hideRequiredStar
          hideFloatingLabel
        />
      )}
      <Grid item xs={1} alignSelf="end" textAlign="right">
        <DeleteOutlineIcon
          cursor="pointer"
          color="primary"
          onClick={(): void =>
            props.onDeleteAttachedFile(props.attachedFile, props.index)
          }
        />
      </Grid>
    </Grid>
  );

  function getPrefix(index: number): string {
    return `${props.arrayName}.${index}.`;
  }
}
