import { constants } from 'config/constants';
import { differenceInYears } from 'date-fns';
import yup from 'services/localized-yup';

export const userSchemaCreate = yup.object({
  name: yup.string().required().max(constants.dataValidation.smallStringSize),
  surname: yup
    .string()
    .required()
    .max(constants.dataValidation.smallStringSize),
  email: yup
    .string()
    .email()
    .required()
    .max(constants.dataValidation.smallStringSize),
  id: yup.string().required().max(constants.dataValidation.smallStringSize),
  idType: yup
    .string()
    .required()
    .max(constants.dataValidation.smallStringSize)
    .nullable(),
  birthDate: yup
    .string()
    .required()
    .test(
      'mayoriaEdad',
      'Debe tener al menos 18 años',
      (value): boolean =>
        differenceInYears(new Date(), new Date(value || '1900/01/01')) >= 18
    )
    .test(
      'añoMinimo',
      'El año debe ser mayor a 1900',
      (value): boolean => new Date(value || '1900/01/01').getFullYear() > 1900
    ),
  hospital: yup
    .string()
    .required()
    .max(constants.dataValidation.smallStringSize)
    .nullable(),
  specialField: yup
    .string()
    .required()
    .max(constants.dataValidation.smallStringSize)
    .nullable(),
  professionProfile: yup
    .string()
    .required()
    .max(constants.dataValidation.smallStringSize)
    .nullable(),
  token: yup.string(),
});

export type UserCreate = yup.InferType<typeof userSchemaCreate>;
