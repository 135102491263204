import CustomToolbar from 'modules/components/toolbar/custom-toolbar';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import HomePage from 'modules/app/home-page/home-page';
import CasePage from 'modules/app/cases/case-page/case-page';
import CreateCasePage from 'modules/app/cases/create-case-page/create-case-page';
import AdministrationPage from 'modules/app/administration/administration-page';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { getSettings } from 'config/settings';
import {
  setApiAccessToken,
  setRefreshTokenCallback,
} from 'services/http/axios-client';
import { ThemeProvider } from '@emotion/react';
import { theme } from 'modules/theme/theme-options';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';

function App(): JSX.Element {
  const { instance, accounts } = useMsal();
  const [tokensLoaded, setTokensLoaded] = useState(false);
  const setUserId = useAppStateStore((s: AppStateStore) => s.setUserId);
  const setUserRoles = useAppStateStore((s: AppStateStore) => s.setUserRoles);

  useEffect(() => {
    const loadApiToken = async (): Promise<void> => {
      const response = await instance.acquireTokenSilent({
        ...getSettings().tokenRequest,
        account: accounts[0],
      });

      setApiAccessToken(response.accessToken);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const roles = (response.idTokenClaims as any).roles as string[];
      setUserId(response.uniqueId);
      setUserRoles(roles);
    };

    const loadTokens = async (): Promise<void> => {
      await loadApiToken();
      setTokensLoaded(true);
    };

    loadTokens();
    setRefreshTokenCallback(loadApiToken);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Container maxWidth="xl" style={{ marginTop: '80px' }}> */}
        {tokensLoaded && (
          <BrowserRouter>
            <CustomToolbar />
            <Routes>
              <Route path="" element={<HomePage />}></Route>
              <Route path="edit-case/:id" element={<CreateCasePage />}></Route>
              <Route path="create-case" element={<CreateCasePage />}></Route>
              <Route path="case/:id" element={<CasePage />}></Route>
              <Route
                path="administration/*"
                element={<AdministrationPage />}
              ></Route>
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </BrowserRouter>
        )}
        {/* </Container> */}
      </ThemeProvider>
    </>
  );
}

export default App;
