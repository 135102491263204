import { DeleteOutlined, Edit } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
} from '@mui/x-data-grid';
import { constants } from 'config/constants';
import ConfirmationDialog from 'modules/components/dialogs/confirmation-dialog';
import { EditDrawer } from 'modules/components/drawers/edit-drawer';
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteApi, getApi, postApi, putApi } from 'services/http/axios-client';
import { EntityCreated } from 'models/entity-created';
import { ProfessionalProfile } from 'models/professional-profile';
import ProfessionalProfileForm from './professional-profile-form';

const ProfessionalProfileListPage = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (_props, ref: any): JSX.Element => {
    const [professionalProfiles, setProfessionalProfiles] = useState<
      ProfessionalProfile[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [professionalProfileToDelete, setProfessionalProfileToDelete] =
      useState<ProfessionalProfile>();
    const [professionalProfileToEdit, setProfessionalProfileToEdit] =
      useState<ProfessionalProfile>({} as ProfessionalProfile);
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
      const loadData = async (): Promise<void> => {
        const response = await getApi<ProfessionalProfile[]>(
          constants.api.professionalprofiles
        );
        setProfessionalProfiles(response);
        setLoading(false);
      };
      loadData();
    }, []);

    const columns: GridColumns<ProfessionalProfile> = [
      { field: 'id', headerName: 'Id', width: 100, type: 'number' },
      { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 100 },
      {
        field: 'Acciones',
        type: 'actions',
        getActions: gridActions,
      },
    ];

    return (
      <>
        <Box
          sx={{
            width: `calc(100% - ${ref?.current?.clientWidth + 1 || 162}px)`,
            display: 'inline-flex',
            flexWrap: 'wrap',
            justifyContent: 'end',
          }}
        >
          <Button
            variant="contained"
            onClick={(): void => {
              setProfessionalProfileToEdit(getNewProfessionalProfile());
              setOpenDrawer(true);
            }}
          >
            Nuevo perfil profesional
          </Button>
        </Box>

        <Box sx={{ marginTop: 1, height: '80vh', width: '100%' }}>
          <DataGrid
            loading={loading}
            rows={professionalProfiles}
            columns={columns}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>

        <ConfirmationDialog
          title="Eliminar perfil profesional"
          message={`El perfil profesional '${professionalProfileToDelete?.name}' será eliminado. ¿Está seguro?`}
          open={openDialog}
          onClose={async (confirm: boolean): Promise<void> => {
            setOpenDialog(false);
            if (confirm && professionalProfileToDelete !== undefined) {
              await deleteProfessionalProfile(professionalProfileToDelete);
            }
            setProfessionalProfileToDelete(undefined);
          }}
        />

        <EditDrawer open={openDrawer}>
          <ProfessionalProfileForm
            professionalProfile={professionalProfileToEdit}
            onClose={async (save: boolean): Promise<void> => {
              if (save) {
                await createOrUpdateProfessionalProfile(
                  professionalProfileToEdit
                );
              }
              setOpenDrawer(false);
              setProfessionalProfileToEdit({} as ProfessionalProfile);
            }}
          />
        </EditDrawer>
      </>
    );

    function gridActions(params: GridRowParams): JSX.Element[] {
      return [
        <GridActionsCellItem
          icon={<Edit color="primary" />}
          label="Editar"
          onClick={(): void => {
            setProfessionalProfileToEdit(params.row);
            setOpenDrawer(true);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlined color="primary" />}
          label="Eliminar"
          onClick={(): void => {
            setProfessionalProfileToDelete(params.row);
            setOpenDialog(true);
          }}
        />,
      ];
    }

    async function createOrUpdateProfessionalProfile(
      professionalProfileToEdit: ProfessionalProfile
    ): Promise<void> {
      if (professionalProfileToEdit.id === 0) {
        const response = await postApi<EntityCreated, ProfessionalProfile>(
          constants.api.professionalprofiles,
          professionalProfileToEdit
        );
        professionalProfileToEdit.id = response.id;
        setProfessionalProfiles([
          ...professionalProfiles,
          professionalProfileToEdit,
        ]);
      } else {
        await putApi<void, ProfessionalProfile>(
          constants.api.professionalprofiles +
            '/' +
            professionalProfileToEdit.id,
          professionalProfileToEdit
        );
      }

      toast.success(
        `Perfil profesional guardado: ${professionalProfileToEdit.name}`
      );
    }

    async function deleteProfessionalProfile(
      professionalProfileToDelete: ProfessionalProfile
    ): Promise<void> {
      await deleteApi<void>(
        constants.api.professionalprofiles +
          '/' +
          professionalProfileToDelete?.id
      );

      toast.success(
        `Perfil profesional eliminado: ${professionalProfileToDelete.name}`
      );
      setProfessionalProfiles(
        professionalProfiles.filter(
          (h) => h.id !== professionalProfileToDelete?.id
        )
      );
    }

    function getNewProfessionalProfile(): React.SetStateAction<ProfessionalProfile> {
      return {
        id: 0,
        name: '',
      };
    }
  }
);

export default ProfessionalProfileListPage;
