/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { IOption } from './i-option';
import { v4 as uuidv4 } from 'uuid';

type AutoCompleteControlProps = {
  label: string;
  fieldName: string;
  control: Control<any, any>;
  schema: ObjectSchema<any>;
  gridSize?: number;
  options: IOption[];
  getOptionImage?: (option: IOption) => JSX.Element;
  onChange?: (option: IOption) => void;
  disabled?: boolean;
  multiple?: boolean;
  hideRequiredStar?: boolean;
  hideFloatingLabel?: boolean;
};

export default function AutoCompleteControl(
  props: AutoCompleteControlProps
): JSX.Element {
  const required = props.hideFloatingLabel
    ? false
    : (
        props.schema.describe().fields[props.fieldName] as Record<any, any>
      ).tests.some((x: any) => x.name === 'required');

  return (
    <Controller
      render={({ field, fieldState }): JSX.Element => (
        <Grid item xs={props.gridSize ?? 12}>
          <Autocomplete
            id={`${props.fieldName}-${uuidv4()}`} // disable browser autocomplete and autofill
            filterSelectedOptions={props.multiple ?? false}
            multiple={props.multiple ?? false}
            disabled={!!props.disabled}
            onChange={(_event, data): void => {
              if (props.multiple) {
                field.onChange(data ?? []);
              } else {
                field.onChange(data?.code ?? null);
              }

              if (props.onChange) {
                props.onChange(data);
              }
            }}
            value={field.value}
            onBlur={field.onBlur}
            options={props.options}
            getOptionLabel={(option): string => {
              if (option === undefined) {
                return '';
              }
              return (
                option?.label ??
                props.options?.find(
                  ({ code }) => code === option || code === option.toString()
                )?.label ??
                ''
              );
            }}
            renderOption={(renderProps, option): JSX.Element => {
              return (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...renderProps}
                >
                  {props.getOptionImage && props.getOptionImage(option)}
                  {option.label}
                </Box>
              );
            }}
            isOptionEqualToValue={(option, value): boolean =>
              value !== undefined &&
              (option.code === value.code ||
                option === value ||
                option.code === value.toString() ||
                option === value.toString())
            }
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                variant="standard"
                margin="normal"
                fullWidth
                label={
                  props.hideFloatingLabel
                    ? undefined
                    : props.label +
                      (required && !props.hideRequiredStar ? ' *' : '')
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable browser autocomplete and autofill
                }}
                error={!!fieldState.error}
                helperText={fieldState.error && fieldState.error.message}
              />
            )}
          />
        </Grid>
      )}
      name={props.fieldName}
      control={props.control}
    />
  );
}
