import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
} from '@mui/material';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import { getApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import { Tag } from 'models/tag';
import { Hospital } from 'models/hospital';
import { User } from 'models/user';

export interface SearchDrawerProps {
  drawerWidth: number;
  opened: boolean;
  onClose?: Function;
  onApplyFilters?: Function;
}

export interface FilterConfig {
  name: string;
  hospital: Hospital | null;
  clinic: User | null;
  tags: Tag[];
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function SearchDrawer(props: SearchDrawerProps): JSX.Element {
  //   const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [specialities, setSpecialities] = React.useState<Tag[]>([]);
  const [organs, setOrgans] = React.useState<Tag[]>([]);
  const [patologies, setPatologies] = React.useState<Tag[]>([]);
  const [procediments, setProcediments] = React.useState<Tag[]>([]);
  const [hospitals, setHospitals] = React.useState<Hospital[]>([]);
  const [clinics, setClinics] = React.useState<User[]>([]);

  const [textFieldFilter, setTextFieldFilter] = React.useState('');
  const [specialityFilters, setSpecialityFilters] = React.useState<Tag[]>([]);
  const [organFilters, setOrganFilters] = React.useState<Tag[]>([]);
  const [patologyFilters, setPatologyFilters] = React.useState<Tag[]>([]);
  const [procedimentFilters, setProcedimentFilters] = React.useState<Tag[]>([]);

  const [clearedFilters, setClearedFilters] = React.useState<Boolean>(false);

  const [selectedHospital, setSelectedHospital] =
    React.useState<Hospital | null>(null);
  const [selectedClinic, setSelectedClinic] = React.useState<User | null>(null);

  const [_isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const loadData = async (): Promise<void> => {
      setIsLoading(true);
      const specialities: Tag[] = [];
      const organs: Tag[] = [];
      const patologies: Tag[] = [];
      const procediments: Tag[] = [];
      const hospitalResponse = await getApi<Hospital[]>(
        constants.api.hospitals
      );
      setHospitals(hospitalResponse);
      const response = await getApi<Tag[]>(constants.api.tags);
      response.map((tag) => {
        switch (tag.type) {
          case 1:
            specialities.push(tag);
            break;
          case 2:
            organs.push(tag);
            break;
          case 3:
            patologies.push(tag);
            break;
          case 4:
            procediments.push(tag);
            break;

          default:
            break;
        }
      });
      // setCaseList(response);
      setSpecialities(specialities);
      setOrgans(organs);
      setPatologies(patologies);
      setProcediments(procediments);

      setIsLoading(false);
    };
    loadData();
  }, []);

  const hospitalHandleChange = (event: SelectChangeEvent<string>): void => {
    let newHospitalFilter = null;
    if (event.target.value != 'all') {
      newHospitalFilter = hospitals.find((element) => {
        if (element.id != undefined && element.id > 0) {
          return element.id.toString() == event.target.value;
        }
        return false;
      });
    }
    if (newHospitalFilter != undefined) {
      setSelectedHospital(newHospitalFilter);
    } else {
      setSelectedHospital(null);
    }
  };

  const clinicHandleChange = (event: SelectChangeEvent<string>): void => {
    let newClinicFilter = null;
    if (event.target.value != 'all') {
      newClinicFilter = clinics.find((element) => {
        if (element.id != undefined) {
          return element.id == event.target.value;
        }
        return false;
      });
    }
    if (newClinicFilter != undefined) {
      setSelectedClinic(newClinicFilter);
    } else {
      setSelectedClinic(null);
    }
  };

  React.useEffect(() => {
    const hospitalId = selectedHospital ? selectedHospital.id! : -1;

    const loadClinics = async (): Promise<void> => {
      setIsLoading(true);
      const clinicsResponse = await getApi<User[]>(
        constants.api.users + '/clinics-in-hospital/' + hospitalId
      );
      setClinics(clinicsResponse);
      setIsLoading(false);
    };
    loadClinics();
  }, [selectedHospital]);

  const handleDrawerClose = (): void => {
    setOpen(false);
    if (props.onClose != undefined) {
      props.onClose();
    }
  };

  const onSpecialitiesChange = (
    event: SyntheticEvent<Element, Event>,
    options: Tag[]
  ): void => {
    setSpecialityFilters(options);
  };
  const onOrgansChange = (
    event: SyntheticEvent<Element, Event>,
    options: Tag[]
  ): void => {
    setOrganFilters(options);
  };
  const onPatologiesChange = (
    event: SyntheticEvent<Element, Event>,
    options: Tag[]
  ): void => {
    setPatologyFilters(options);
  };
  const onProcedimentsChange = (
    event: SyntheticEvent<Element, Event>,
    options: Tag[]
  ): void => {
    setProcedimentFilters(options);
  };

  const onTextFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setTextFieldFilter(e.target.value);
  };

  const applyFilters = (): void => {
    const tagFilters: Tag[] = specialityFilters.concat(
      organFilters.concat(patologyFilters.concat(procedimentFilters))
    );
    const completeFilters: FilterConfig = {
      name: textFieldFilter,
      tags: tagFilters,
      hospital: selectedHospital,
      clinic: selectedClinic,
    };
    if (props.onApplyFilters != undefined) {
      props.onApplyFilters(completeFilters);
    }
  };

  const resetFilters = (): void => {
    setSpecialityFilters([]);
    setOrganFilters([]);
    setPatologyFilters([]);
    setProcedimentFilters([]);
    setSelectedHospital(null);
    setSelectedClinic(null);
    setTextFieldFilter('');

    setClearedFilters(true);
  };

  React.useEffect(() => {
    setOpen(props.opened);
  }, [props.opened]);

  React.useEffect(() => {
    if (clearedFilters) {
      applyFilters();
      setClearedFilters(false);
    }
  }, [clearedFilters]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: props.drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: props.drawerWidth,
          boxSizing: 'border-box',
          paddingTop: '65px',
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem>
          <TextField
            id="search-textfield"
            placeholder="Busca por nombre"
            value={textFieldFilter}
            sx={{ width: '100%' }}
            onChange={onTextFieldChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            onKeyPress={(ev): void => {
              if (ev.key === 'Enter') {
                applyFilters();
                ev.preventDefault();
              }
            }}
          />
        </ListItem>
        <ListItem>
          <Autocomplete
            multiple
            id="specialities"
            options={specialities}
            value={specialityFilters}
            onChange={onSpecialitiesChange}
            getOptionLabel={(option): string => option.name}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                variant="standard"
                label="Especialidad"
                placeholder="Seleccione especialidades"
              />
            )}
            sx={{ width: '100%' }}
          />
        </ListItem>
        <ListItem>
          <Autocomplete
            multiple
            id="organs"
            options={organs}
            value={organFilters}
            onChange={onOrgansChange}
            getOptionLabel={(option): string => option.name}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                variant="standard"
                label="Órgano"
                placeholder="Seleccione órganos"
              />
            )}
            sx={{ width: '100%' }}
          />
        </ListItem>
        <ListItem>
          <Autocomplete
            multiple
            id="patologies"
            options={patologies}
            value={patologyFilters}
            onChange={onPatologiesChange}
            getOptionLabel={(option): string => option.name}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                variant="standard"
                label="Patología"
                placeholder="Seleccione patologías"
              />
            )}
            sx={{ width: '100%' }}
          />
        </ListItem>
        <ListItem>
          <Autocomplete
            multiple
            id="procediments"
            options={procediments}
            value={procedimentFilters}
            onChange={onProcedimentsChange}
            getOptionLabel={(option): string => option.name}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                variant="standard"
                label="Procedimientos"
                placeholder="Seleccione procedimientos"
              />
            )}
            sx={{ width: '100%' }}
          />
        </ListItem>
        <ListItem>
          <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
            <InputLabel id="hospital-select-label">Hospital/Centro</InputLabel>
            <Select
              labelId="hospital-select-label"
              id="hospital-select"
              defaultValue="all"
              value={selectedHospital ? selectedHospital.id!.toString() : 'all'}
              onChange={hospitalHandleChange}
              label="Hospital/Centro"
            >
              <MenuItem value="all">Todos</MenuItem>
              {hospitals.map((hospital) => (
                <MenuItem key={hospital.id} value={hospital.id}>
                  {hospital.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
            <InputLabel id="clinic-select-label">Clínico asignado</InputLabel>
            <Select
              labelId="clinic-select-label"
              id="clinic-select"
              value={selectedClinic ? selectedClinic.id! : 'all'}
              onChange={clinicHandleChange}
              label="Clínico asignado"
            >
              <MenuItem value="all">Todos</MenuItem>
              {clinics.map((clinic) => (
                <MenuItem key={clinic.id} value={clinic.id}>
                  {clinic.displayName} ({clinic.email})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <Grid
            item
            sx={{
              cursor: 'pointer',
              fontSize: '16px',
              paddingTop: '15px',
              paddingBottom: '15px',
              color: '#3886C2',
            }}
            onClick={resetFilters}
          >
            <Box>Borrar todos los filtros</Box>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" onClick={handleDrawerClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilters}
              >
                Aplicar filtros
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Drawer>
  );
}
