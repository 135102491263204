export enum AttachedFileTypeEnum {
  None = 0,
  Stl,
  Tac,
  Scanner,
  Eco,
  Doc,
}

export const attachedFileRadiologyTypeOptions = [
  { code: '2', label: 'Tac' },
  { code: '3', label: 'Scanner' },
  { code: '4', label: 'Eco' },
];
