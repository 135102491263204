import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import rightclick from 'resources/images/helpbox/rightclick.png';
import leftclick from 'resources/images/helpbox/leftclick.png';
import scrollwheel from 'resources/images/helpbox/scrollwheel.png';

export default function EvergineHelpBox(): JSX.Element {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 200,
        bgcolor: 'background.paper',
        borderRadius: '8px',
        border: '1px solid #D1D9E3',
        boxShadow: '0px 6px 12px rgba(26, 26, 26, 0.15)',
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <img src={rightclick} alt="right click" width={30}></img>
        </ListItemAvatar>
        <ListItemText primary="Botón derecho" secondary="Mover" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <img src={scrollwheel} alt="scroll wheel/trackpad" width={30}></img>
        </ListItemAvatar>
        <ListItemText primary="Rueda/Panel táctil" secondary="Zoom" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <img src={leftclick} alt="keep left button" width={30}></img>
        </ListItemAvatar>
        <ListItemText primary="Botón izquierdo" secondary="Rotar" />
      </ListItem>
    </List>
  );
}
