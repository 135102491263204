import { ToggleButton } from '@mui/material';
import { StyledToggleButtonGroup } from 'modules/components/buttons/styled-toggle-button-group';
import React from 'react';
import './button-tabs.css';

export interface ButtonTabsProps {
  callback(newValue: number): void;
  buttons: { id: number; tag: string }[];
  isGrid?: boolean;
  selectedId?: number;
}

export default function ButtonTabs(props: ButtonTabsProps): JSX.Element {
  const [choice, setChoice] = React.useState('Hospitales');

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newChoice: string
  ): void => {
    if (newChoice != null) {
      setChoice(newChoice);
    }
  };

  const onClick = (valueSelected: number): void => {
    props.callback(valueSelected);
  };

  return (
    <StyledToggleButtonGroup
      fullWidth={true}
      size="small"
      // color="secondary"
      value={choice}
      exclusive
      onChange={handleChange}
    >
      {props.buttons.map((tab) => (
        <ToggleButton
          selected={tab.id == props.selectedId ? true : false}
          key={tab.id}
          value={tab.id}
          onClick={(): void => {
            onClick(tab.id);
          }}
        >
          {tab.tag}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
