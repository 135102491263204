import React, { useEffect } from 'react';
import { EvergineCanvas } from 'modules/components/evergine/evergine-canvas';
import { EvergineState, useEvergineStore } from 'stores/evergine-store';
import { Box, Skeleton } from '@mui/material';

export interface PropsEvergine {
  height: number;
  width: number;
}

export default function EvergineLoader(props: PropsEvergine): JSX.Element {
  const isEvergineReady = useEvergineStore(
    (s: EvergineState) => s.isEvergineReady
  );

  const setExperienceStarted = useEvergineStore(
    (s: EvergineState) => s.setExperienceStarted
  );

  useEffect(() => {
    if (isEvergineReady) {
      setExperienceStarted(true);
    }
  }, [isEvergineReady, setExperienceStarted]);

  return (
    <Box sx={{ width: props.width, height: props.height }}>
      <Box sx={isEvergineReady ? { display: 'none' } : { display: 'default' }}>
        <Skeleton
          variant="rectangular"
          width={props.width}
          height={props.height}
        />
      </Box>
      <EvergineCanvas width={props.width} height={props.height} />
    </Box>
  );
}
