import { IOption } from 'modules/components/form-controls/i-option';

export enum TagTypeEnum {
  None = 0,
  Speciality,
  Organ,
  Pathology,
  Procedure,
  Other,
}

export const tagTypeOptions: IOption[] = [
  { code: '1', label: 'Especialidad' },
  { code: '2', label: 'Órgano' },
  { code: '3', label: 'Patología' },
  { code: '4', label: 'Procedimiento' },
  { code: '5', label: 'Otros' },
];
