import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    accent: {
      800: string;
      700: string;
      600: string;
      500: string;
      400: string;
      300: string;
      200: string;
      100: string;
    };
    neutral: {
      900: string;
      800: string;
      700: string;
      600: string;
      500: string;
      400: string;
      300: string;
      200: string;
      100: string;
    };
    system: {
      alert: string;
      alertlight: string;
      success: string;
      successlight: string;
      warning: string;
      warninglight: string;
      info: string;
      infolight: string;
      hover: string;
      press: string;
      selected: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    accent: {
      800: string;
      700: string;
      600: string;
      500: string;
      400: string;
      300: string;
      200: string;
      100: string;
    };
    neutral: {
      900: string;
      800: string;
      700: string;
      600: string;
      500: string;
      400: string;
      300: string;
      200: string;
      100: string;
    };
    system: {
      alert: string;
      alertlight: string;
      success: string;
      successlight: string;
      warning: string;
      warninglight: string;
      info: string;
      infolight: string;
      hover: string;
      press: string;
      selected: string;
    };
  }
}

export const theme = createTheme({
  accent: {
    800: '#1E4766',
    700: '#265C85',
    600: '#3174A8',
    500: '#3886C2',
    400: '#3F97DB',
    300: '#53AEF5',
    200: '#7AC5FF',
    100: '#DEEEFA',
  },
  neutral: {
    900: '#151617',
    800: '#44474A',
    700: '#575D63',
    600: '#6D757D',
    500: '#838C96',
    400: '#9AA4B0',
    300: '#B0BCC9',
    200: '#D1D9E3',
    100: '#FFFFFF',
  },
  system: {
    alert: '#DB5454',
    alertlight: '#DB545426',
    success: '#2E935C',
    successlight: '#2E935C26',
    warning: '#EA8613',
    warninglight: '#EA861326',
    info: '#838C96',
    infolight: '#838C9626',
    hover: '#9AA4B026',
    press: '#9AA4B040',
    selected: '#3886C21F',
  },
  palette: {
    primary: {
      main: '#3886C2',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
    secondary: {
      main: '#6D757D',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#F2F2F2',
          color: '3886C2',
        },
      },
    },
  },
});
