import { Box, Button, Grid, Stack } from '@mui/material';
import React from 'react';
import { constants } from 'config/constants';
import { postApi } from 'services/http/axios-client';
import step1 from 'resources/images/ask-token/step1.png';
import step2 from 'resources/images/ask-token/step2.png';
import step3 from 'resources/images/ask-token/step3.png';
import step4 from 'resources/images/ask-token/step4.png';
import './ask-token.css';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';

export interface AskTokenProps {
  width: number;
  height: number;
  caseID: string;
}

export default function AskToken(props: AskTokenProps): JSX.Element {
  const [isTokenGenerated, setIsTokenGenerated] = React.useState(false);
  const [token, setToken] = React.useState(0);

  const userId = useAppStateStore((s: AppStateStore) => s.userId);

  const generateToken = async (): Promise<void> => {
    let responseType: number;
    if (userId != '') {
      responseType = await postApi<number>(
        constants.api.generateToken + '/' + props.caseID
      );
    } else {
      responseType = 0;
    }

    setIsTokenGenerated(true);
    setToken(responseType);
  };

  return (
    <Box
      width={props.width}
      minHeight={props.height}
      className="ask-token-container"
    >
      <Grid container spacing={4} sx={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <h2>Instrucciones para iniciar la visualización virtual</h2>
          </Box>
        </Grid>

        <Grid item xs={6} spacing={5}>
          <Stack className="stack" paddingLeft="20%">
            <Box sx={{ textAlign: 'center' }}>
              <img src={step1} alt="download app" width="70%" />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <h3>Instala LINA en tus gafas</h3>
            </Box>
            <p>
              Si es la primera vez que usas Lina en tus Oculus (VR) u Hololens
              (AR), abre la Store desde el menú Inicio de las gafas y busca e
              instala la aplicación.
            </p>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack className="stack" paddingRight="20%">
            <Box sx={{ textAlign: 'center' }}>
              <img src={step2} alt="open app" width="70%" />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <h3>Abre la aplicación en las gafas</h3>
            </Box>
            <p>
              Al abrir la app. se le solicitará un código PIN y quedará en
              espera hasta que lo introduzca.
            </p>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack className="stack" paddingLeft="20%">
            <Box sx={{ textAlign: 'center', position: 'relative' }}>
              <img
                src={step3}
                alt="generar pin"
                width="70%"
                className="third-step-image"
              />
              <Box className="container-center">
                {isTokenGenerated && (
                  <p className="generated-token">
                    {String(token).padStart(4, '0')}
                  </p>
                )}
                {!isTokenGenerated && (
                  <Button onClick={generateToken} variant="contained">
                    Pulsa aquí para generar PIN
                  </Button>
                )}
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <h3>Generar PIN</h3>
            </Box>
            <p>
              Pulsa el botón superior Generar PIN. Los codigos se resetean cada
              día.
            </p>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack className="stack" paddingRight="20%">
            <Box sx={{ textAlign: 'center' }}>
              <img src={step4} alt="insert token" width="70%" />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <h3>Abre la aplicación e inserta el PIN</h3>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <p>
                Introduce el código PIN generado en el campo específico de la
                aplicación. ¡Y ya está!
              </p>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
