import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Slider,
} from '@mui/material';
import {
  randomizeColors,
  resetView,
  setCarouselIndex,
  showViewerFromPath,
} from 'evergine/evergine-program-service';
import AskToken from 'modules/app/cases/case-page/components/ask-token/ask-token';
import React from 'react';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';
import EvergineLoader from './evergine-loader';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LayerDrawer from 'modules/app/cases/case-page/components/layer-drawer';
import { EvergineState, useEvergineStore } from 'stores/evergine-store';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import EvergineHelpBox from './evergine-help-box';
import { Tooltip } from '@mui/material';

export interface EvergineControllerProps {
  caseID: string;
  stlPath: string;
  height: number;
  width: number;
  drawerOpened?: boolean;
}

export default function EvergineController(
  props: EvergineControllerProps
): JSX.Element {
  //STORAGE
  const isEvergineReady = useEvergineStore(
    (s: EvergineState) => s.isEvergineReady
  );
  const viewerType = useAppStateStore((s: AppStateStore) => s.viewer);
  const sliderMaxValue = useAppStateStore((s: AppStateStore) => s.mriCount);
  const stlLayers = useAppStateStore((s: AppStateStore) => s.stlLayers);
  const isLoading = useAppStateStore((s: AppStateStore) => s.isLoading);

  //HOOKS
  const [is3Dvisualization, setIs3Dvisualization] = React.useState(true);
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [openHelper, setOpenHelper] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [wheeling, setWheeling] = React.useState(0);
  const [hovering, setHovering] = React.useState(false);
  const [evergineSize, setEvergineSize] = React.useState({
    width: props.width,
    height: props.height,
  });
  const [evergineSizeBeforeFullscreen, setEvergineSizeBeforeFullscreen] =
    React.useState({ width: 0, height: 0 });

  // FULLSCREEN
  const evergineFullScreenHandle = useFullScreenHandle();

  React.useEffect(() => {
    if (evergineFullScreenHandle.active) {
      if (evergineSize.height > 0 && evergineSize.width > 0) {
        setEvergineSizeBeforeFullscreen(evergineSize);
      } else {
        if (props.height > 0 && props.width > 0) {
          setEvergineSizeBeforeFullscreen({
            height: props.height,
            width: props.width,
          });
        }
      }

      setEvergineSize({
        width: window.outerWidth,
        height: window.outerHeight,
      });
    } else {
      if (
        evergineSizeBeforeFullscreen.width > 0 &&
        evergineSizeBeforeFullscreen.height > 0
      ) {
        setEvergineSize({
          width: evergineSizeBeforeFullscreen.width,
          height: evergineSizeBeforeFullscreen.height,
        });
      }
    }
  }, [evergineFullScreenHandle.active]);

  React.useEffect(() => {
    if (!evergineFullScreenHandle.active) {
      setEvergineSize({
        width: props.width,
        height: props.height,
      });
    }
  }, [props.height, props.width]);

  React.useEffect(() => {
    if (sliderMaxValue != undefined && hovering) {
      const newValue = Math.max(
        Math.min(sliderValue + wheeling, sliderMaxValue),
        0
      );
      setSliderValue(newValue);
      setCarouselIndex(newValue);
    }
    setWheeling(0);
  }, [wheeling]);

  React.useEffect(() => {
    if (isEvergineReady && props.stlPath) {
      selectItem(props.stlPath);
    }
  }, [props.stlPath, isEvergineReady]);

  //EVENTS
  const selectItem = (path: string): void => {
    showViewerFromPath(path);
    setOpenDrawer(true);
  };

  const setARView = (): void => {
    setIs3Dvisualization(false);
  };

  const set3DView = (): void => {
    setIs3Dvisualization(true);
  };

  const openCloseLayerDrawer = (): void => {
    setOpenDrawer(!openDrawer);
  };

  const openCloseHelper = (): void => {
    setOpenHelper(!openHelper);
  };

  React.useEffect(() => {
    window.addEventListener('wheel', onMouseWheel);
    return () => window.removeEventListener('wheel', onMouseWheel);
  }, []);

  const onMouseWheel = (e: WheelEvent): void => {
    setWheeling(e.deltaY > 0 ? 1 : -1);
  };
  //EVERGINE
  const updateSliderValue = (
    event: Event,
    current: number | number[]
  ): void => {
    const c = current as number;
    setSliderValue(c);
    setCarouselIndex(c);
  };

  const rndColors = (): void => {
    randomizeColors();
  };

  const resetCamera = (): void => {
    resetView();
  };

  return (
    <FullScreen handle={evergineFullScreenHandle}>
      <div
        onMouseEnter={(): void => setHovering(true)}
        onMouseLeave={(): void => setHovering(false)}
      >
        <Box className="evergine-container">
          {is3Dvisualization && (
            <EvergineLoader
              height={evergineSize.height}
              width={evergineSize.width}
            />
          )}
          {!is3Dvisualization && (
            <AskToken
              height={evergineSize.height}
              width={evergineSize.width}
              caseID={props.caseID}
            />
          )}

          {viewerType == 'stl' && (
            <Box
              className={
                openDrawer
                  ? 'change-view-buttons-opened'
                  : 'change-view-buttons-closed'
              }
            >
              <Button
                variant={is3Dvisualization ? 'contained' : 'outlined'}
                startIcon={<ThreeDRotationIcon />}
                onClick={set3DView}
              >
                Visualización 3D
              </Button>
              <Button
                variant={is3Dvisualization ? 'outlined' : 'contained'}
                startIcon={<ViewInArIcon />}
                onClick={setARView}
              >
                Visualización AR-VR
              </Button>
            </Box>
          )}

          {is3Dvisualization && (
            <Box>
              {viewerType == 'mri' && (
                <Slider
                  className="slider"
                  min={0}
                  step={1}
                  max={sliderMaxValue}
                  value={sliderValue}
                  onChange={(e, val): void => updateSliderValue(e, val)}
                ></Slider>
              )}
              {viewerType == 'stl' && (
                <Box>
                  <ButtonGroup
                    className={
                      openDrawer
                        ? 'buttons-opened-drawer'
                        : 'buttons-closed-drawer'
                    }
                    orientation="vertical"
                  >
                    <Tooltip title="Menú de capas" placement="left">
                      <Button onClick={openCloseLayerDrawer}>
                        <LayersOutlinedIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Ayuda" placement="left">
                      <Button onClick={openCloseHelper}>
                        <InfoOutlinedIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Cambiar colores" placement="left">
                      <Button onClick={rndColors}>
                        <PaletteOutlinedIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Reiniciar posición" placement="left">
                      <Button>
                        <RestartAltIcon onClick={resetCamera} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Pantalla completa" placement="left">
                      {evergineFullScreenHandle.active ? (
                        <Button onClick={evergineFullScreenHandle.exit}>
                          <FullscreenIcon />
                        </Button>
                      ) : (
                        <Button onClick={evergineFullScreenHandle.enter}>
                          <FullscreenIcon />
                        </Button>
                      )}
                    </Tooltip>
                  </ButtonGroup>
                  {openHelper && (
                    <Box
                      className="evergine-helper-box"
                      sx={{
                        position: 'absolute',
                        top: '60px',
                        right: openDrawer ? '360px' : '60px',
                      }}
                    >
                      <EvergineHelpBox></EvergineHelpBox>
                    </Box>
                  )}
                </Box>
              )}
              {isLoading && (
                <Box className="loadingContainer">
                  <CircularProgress className="circularProgress" />
                </Box>
              )}
            </Box>
          )}

          {!isLoading && is3Dvisualization && viewerType == 'stl' && (
            <LayerDrawer
              isOpened={openDrawer}
              layers={stlLayers}
              close={openCloseLayerDrawer}
            />
          )}
        </Box>
      </div>
    </FullScreen>
  );
}
