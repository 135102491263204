import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Case } from 'models/case';
import React from 'react';
import CaseCard from '../case-card/case-card';
import LoadingCard from '../case-card/loading-card';

interface CaseListProps {
  cases: Case[];
  loading?: boolean;
  order?: CaseOrderEnum;
  onDelete?: (id: number) => void;
}
export enum CaseOrderEnum {
  descendant,
  ascendant,
  recents,
  none,
}

export default function CaseList(props: CaseListProps): JSX.Element {
  const [orderedCases, setOrderedCases] = React.useState<Case[]>([]);

  const toTimestamp = (strDate: string): number => {
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  React.useEffect(() => {
    let sortedCases = props.cases;
    if (props.order != undefined) {
      switch (props.order) {
        case CaseOrderEnum.descendant:
          sortedCases = props.cases.sort((case1, case2): number =>
            case1.name.localeCompare(case2.name)
          );
          break;
        case CaseOrderEnum.ascendant:
          sortedCases = props.cases.sort(
            (case1, case2): number => -1 * case1.name.localeCompare(case2.name)
          );
          break;
        case CaseOrderEnum.recents:
          sortedCases = props.cases.sort((case1, case2) => {
            const ts1 = toTimestamp(case1.date!);
            const ts2 = toTimestamp(case2.date!);
            if (ts1 > ts2) {
              return -1;
            } else if (ts1 < ts2) {
              return 1;
            } else {
              return 0;
            }
          });
          break;
        default:
          break;
      }
    }
    setOrderedCases([...sortedCases]);
  }, [props.order, props.cases]);

  return (
    <Box
      sx={{
        marginTop: '30px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 250px)',
        justifyContent: 'space-between',
        gridGap: '20px',
      }}
    >
      {!props.loading
        ? orderedCases.map((rcase) => (
            <Grid item key={rcase.id}>
              <CaseCard case={rcase} onDelete={props.onDelete} />
            </Grid>
          ))
        : [...Array(10)].map((_value, index) => (
            <Grid item key={index.toString()}>
              <LoadingCard />
            </Grid>
          ))}
    </Box>
  );
}
