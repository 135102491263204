import { Box, Container } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdministrationToolbar from 'modules/app/administration/administration-toolbar';
import HospitalListPage from 'modules/app/administration/hospital/hospital-list-page';
import TagListPage from 'modules/app/administration/tag/tag-list-page';
import { useRef } from 'react';
import ProfessionalProfileListPage from 'modules/app/administration/professional-profile/professional-profile-list-page';
import SpecialFieldListPage from 'modules/app/administration/special-field/special-field-list-page';
import UserListPage from './user/user-list-page';

function AdministrationPage(): JSX.Element {
  const admToolbarRef = useRef<HTMLDivElement>(null);

  return (
    <Container maxWidth="xl" style={{ marginTop: '80px' }}>
      <Box ref={admToolbarRef} sx={{ display: 'inline-flex' }}>
        <AdministrationToolbar />
      </Box>
      <Routes>
        <Route
          path="center"
          element={<HospitalListPage ref={admToolbarRef} />}
        ></Route>
        <Route
          path="professionalprofile"
          element={<ProfessionalProfileListPage ref={admToolbarRef} />}
        ></Route>
        <Route
          path="specialfield"
          element={<SpecialFieldListPage ref={admToolbarRef} />}
        ></Route>
        <Route path="user" element={<UserListPage />}></Route>
        <Route path="tag" element={<TagListPage ref={admToolbarRef} />}></Route>
        <Route path="*" element={<Navigate to="center" />} />
      </Routes>
    </Container>
  );
}

export default AdministrationPage;
