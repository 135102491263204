export const constants = {
  defaultLanguage: 'es',
  api: {
    hospitals: 'hospitals',
    userRoles: 'users/roles',
    professionalprofiles: 'professionalprofiles',
    specialfields: 'specialfields',
    tags: 'tags',
    users: 'users',
    usersInMyHospital: 'users/in-my-hospital',
    sendRegistrationMail: 'mail/register',
    askCase: 'mail/askcase',
    updatecasestate: 'cases/updatecasestate',
    generateToken: 'tokens/generate',
    getToken: 'tokens/get',
    sasToken: 'tokens/sastoken',
    cases: 'cases',
    casesid: 'cases/',
    favorites: 'cases/favorites',
    attachedFiles: 'attached-files',
  },
  dataValidation: {
    codeStringSize: 10,
    guidStringSize: 36,
    smallStringSize: 100,
    mediumStringSize: 250,
    bigStringSize: 500,
  },
  maxFileSize: 1024 * 1024 * 1024,
};
