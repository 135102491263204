import { Box, Button, Card, CardMedia, Grid } from '@mui/material';
import { Case, caseSchema } from 'models/case';
import { Tag } from 'models/tag';
import FileUploader from 'modules/components/file-uploader/file-uploader';
import { IOption } from 'modules/components/form-controls/i-option';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { getApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import AutoCompleteControl from 'modules/components/form-controls/auto-complete-control';
import { tagTypeOptions } from 'config/tag-type-options';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { User } from 'models/user';
import CheckboxControl from 'modules/components/form-controls/checkbox-control';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';
import { useRef } from 'react';

type CreateInformationProps = {
  case: Case;
  onClose: (save: boolean) => void;
};

export default function CreateInformation(
  props: CreateInformationProps
): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const [organList, setOrganList] = useState<IOption[]>([]);
  const [pathologyList, setPathologyList] = useState<IOption[]>([]);
  const [procedureList, setProcedureList] = useState<IOption[]>([]);
  const [specialityList, setSpecialityList] = useState<IOption[]>([]);
  const [othersList, setOthersList] = useState<IOption[]>([]);
  const [usersList, setUsersList] = useState<IOption[]>([]);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const responseTags = await getApi<Tag[]>(constants.api.tags);

      setOrganList(filterTagsAndGetIOptionsArray(responseTags, 'Órgano'));
      setPathologyList(
        filterTagsAndGetIOptionsArray(responseTags, 'Patología')
      );
      setProcedureList(
        filterTagsAndGetIOptionsArray(responseTags, 'Procedimiento')
      );
      setSpecialityList(
        filterTagsAndGetIOptionsArray(responseTags, 'Especialidad')
      );
      setOthersList(filterTagsAndGetIOptionsArray(responseTags, 'Otros'));

      const responseUsers = await getApi<User[]>(
        constants.api.usersInMyHospital
      );
      setUsersList(
        responseUsers.map((user) => ({
          code: user.id,
          label: user.displayName + ' (' + user.email + ')',
        }))
      );

      setLoaded(true);
    };
    loadData();
  }, []);

  function filterTagsAndGetIOptionsArray(
    response: Tag[],
    tagName: string
  ): IOption[] {
    const code = tagTypeOptions.find((t) => t.label === tagName)?.code || '0';
    return response
      .filter((tag) => tag.type.toString() === code)
      .map((tag) => ({ code: `${tag.id}`, label: tag.name }));
  }

  const { handleSubmit, control, formState } = useForm<Case>({
    defaultValues: props.case,
    resolver: yupResolver(caseSchema),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<Case> = async (newCaseData) => {
    Object.assign(props.case, newCaseData);
    if (uploadFiles.length == 1) {
      props.case.thumbnailFile = uploadFiles[0];
    }
    props.onClose(true);
  };

  const onFileUploaderChange = (files: File[]): void => {
    setUploadFiles(files);
  };

  const [thumbnailURI, setThumbnailURI] = useState<string>();

  useEffect(() => {
    const loadThumbnail = async (): Promise<void> => {
      const uri = await getApi<string>(
        constants.api.cases + '/' + props.case.id + '/thumbnail'
      );
      setThumbnailURI(uri);
    };
    if (props.case.id != -1) {
      loadThumbnail();
    }
  }, [props.case]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box sx={{ fontSize: 15, fontWeight: 600, lineHeight: 4 }}>
          Imagen de portada
        </Box>
        {thumbnailURI && (
          <Card
            variant="outlined"
            sx={{ maxWidth: '250px', margin: 'auto', marginBottom: 1 }}
          >
            <CardMedia
              component="img"
              height="140"
              image={(thumbnailURI != null && thumbnailURI != ''
                ? thumbnailURI
                : 'placeholder.png'
              ).toString()}
              alt="thumbnail"
            />
          </Card>
        )}
        <FileUploader
          acceptedFiles={['image/*']}
          onChange={onFileUploaderChange}
          filesList={uploadFiles}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}
        >
          <TextFieldControl
            label="Nombre"
            fieldName={nameof<Case>('name')}
            control={control}
            schema={caseSchema}
          />
          <AutoCompleteControl
            disabled={!loaded}
            label="Órgano"
            fieldName={nameof<Case>('organId')}
            control={control}
            schema={caseSchema}
            options={organList}
          />
          <AutoCompleteControl
            disabled={!loaded}
            label="Patología"
            fieldName={nameof<Case>('pathologyId')}
            control={control}
            schema={caseSchema}
            options={pathologyList}
          />
          <AutoCompleteControl
            disabled={!loaded}
            label="Procedimiento"
            fieldName={nameof<Case>('procedureId')}
            control={control}
            schema={caseSchema}
            options={procedureList}
          />
          <AutoCompleteControl
            disabled={!loaded}
            label="Especialidad"
            fieldName={nameof<Case>('specialityId')}
            control={control}
            schema={caseSchema}
            options={specialityList}
          />
          <AutoCompleteControl
            disabled={!loaded}
            label="Clínico asignado"
            fieldName={nameof<Case>('assignedToId')}
            control={control}
            schema={caseSchema}
            options={usersList}
          />
          <CheckboxControl
            label="Visible para el resto de los usuarios"
            fieldName={nameof<Case>('isVisible')}
            control={control}
            schema={caseSchema}
          />
          <TextFieldControl
            label="Descripción breve"
            fieldName={nameof<Case>('description')}
            control={control}
            schema={caseSchema}
            multiline
          />
          <AutoCompleteControl
            disabled={!loaded}
            label="Etiquetas"
            fieldName={nameof<Case>('tagsComboCodes')}
            control={control}
            schema={caseSchema}
            options={othersList}
            multiple
          />

          <Grid item xs={12} sx={{ mt: 4, textAlign: 'right' }}>
            <Button
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button type="submit" sx={{ ml: 3 }} variant="contained">
              Guardar y enviar
            </Button>
          </Grid>
        </Box>

        <DiscardDialog
          ref={discardDialog}
          onClose={props.onClose}
          isDirty={isDirty || uploadFiles.length > 0}
        />
      </Grid>
    </Grid>
  );
}
