import Button from '@mui/material/Button';
import { Box, Grid } from '@mui/material';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import { AskCase, askCaseSchema } from 'models/ask-case';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';
import { useRef } from 'react';

type AskCaseFormProps = {
  askCase: AskCase;
  onClose: (save: boolean) => void;
};

export default function AskCaseForm({
  askCase,
  onClose,
}: AskCaseFormProps): JSX.Element {
  const { handleSubmit, control, formState } = useForm<AskCase>({
    defaultValues: askCase,
    resolver: yupResolver(askCaseSchema),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<AskCase> = (newAskCaseData) => {
    Object.assign(askCase, newAskCaseData);
    onClose(true);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mx: 9 }}>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <Grid item xs={12}>
            <h2>Solicitud de estudio</h2>
            <p>
              Desde el Hospital Virtual Valdecilla, como coordinadores de la
              plataforma Lina, estamos encantados de poder ayudarle en sus
              planificaciones futuras. Para ello, nos ponemos a su disposición
              para poder ayudarle con sus reconstrucciones y obtener los
              biomodelos 3D necesarios. Póngase en contacto con nosotros por
              medio del siguiente formulario.
            </p>
          </Grid>

          <TextFieldControl
            label="Asunto"
            fieldName={nameof<AskCase>('subject')}
            control={control}
            schema={askCaseSchema}
          />
          <TextFieldControl
            label="Comentarios"
            fieldName={nameof<AskCase>('comments')}
            control={control}
            schema={askCaseSchema}
            gridSize={12}
            multiline
            rows={4}
          />
          <Grid item xs={12}>
            <Box sx={{ mt: 3 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
            >
              Descartar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth type="submit">
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DiscardDialog ref={discardDialog} onClose={onClose} isDirty={isDirty} />
    </>
  );
}
