import { constants } from 'config/constants';
import yup from 'services/localized-yup';

export const professionalProfileSchema = yup.object({
  id: yup.number(),
  name: yup.string().required().max(constants.dataValidation.smallStringSize),
});

export type ProfessionalProfile = yup.InferType<
  typeof professionalProfileSchema
>;
