import { Container, Grid } from '@mui/material';
import LoginOrCreate from './login-or-create';
import bg from 'resources/images/bg.png';
import { Box } from '@mui/system';
import './login-page.css';
import EvergineController from 'modules/components/evergine/evergine-controller';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
import { getApi } from 'services/http/axios-client';
import { constants } from 'config/constants';

export default function LoginPage(): JSX.Element {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [sampleModel, setSampleModel] = useState<string>('');

  useEffect(() => {
    function handleResize(element: HTMLElement | null): void {
      if (element) {
        setWidth(element.getBoundingClientRect().width);
        setHeight(element.getBoundingClientRect().height);
      }
    }
    const element = document.getElementById('evergine-login-container');
    window.addEventListener('resize', () =>
      setTimeout(() => handleResize(element), 100)
    );
    handleResize(element);
    return (): void =>
      window.removeEventListener('resize', () =>
        setTimeout(() => handleResize(element), 100)
      );
  }, []);

  useEffect(() => {
    const loadSasToken = async (): Promise<void> => {
      const models = await getApi<string[]>(constants.api.getToken + '/0000');
      if (models.length > 0) {
        setSampleModel(models[0]);
      }
    };

    loadSasToken();
  }, []);

  return (
    <Box
      className="background"
      sx={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <Container className="container" maxWidth="xl">
        <Grid container>
          <Grid item xs={4}>
            <LoginOrCreate />
          </Grid>
          <Grid item xs={8} marginTop={5} marginBottom={5}>
            <div
              id="evergine-login-container"
              className="evergine-login-container"
            >
              <ErrorBoundary
                FallbackComponent={(): JSX.Element => (
                  <Box>Ouch! Algo no fue bien 😟</Box>
                )}
              >
                {sampleModel && (
                  <EvergineController
                    height={height}
                    width={width}
                    stlPath={sampleModel}
                    caseID="1"
                  />
                )}
              </ErrorBoundary>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
