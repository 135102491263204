const getAdminString: string = 'Admin';
const getDataEntryString: string = 'DataEntry';
const getUserString: string = 'User';

const containsRole = (role: string, roles: string[]): boolean => {
  if (roles) {
    return roles.includes(role);
  }
  return false;
};

export { getAdminString, getDataEntryString, getUserString, containsRole };
