declare global {
  interface Window {
    AZURE_AD_CLIENT_ID: string;
    AZURE_AD_TENANT_ID: string;
  }
}

type Settings = {
  server: {
    url: string;
    apiBase: string;
  };
  msal: {
    auth: {
      clientId: string;
      authority: string;
      redirectUri: string;
    };
    cache: {
      cacheLocation: string; // This configures where your cache will be stored
      storeAuthStateInCookie: boolean;
    };
  };
  tokenRequest: {
    scopes: string[];
  };
  loginRequest: {
    scopes: string[];
  };
  graphConfig: {
    graphMeEndpoint: string;
  };
  recaptcha: {
    siteKey: string;
  };
  blobConfig: {
    blobName: string;
  };
};

export const getSettings = (
  clientId: string = window['AZURE_AD_CLIENT_ID'],
  tenantId: string = window['AZURE_AD_TENANT_ID']
): Settings => ({
  server: {
    url: '',
    apiBase: '/api',
  },
  msal: {
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      redirectUri: '/',
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  tokenRequest: {
    scopes: [`api://${clientId}/access_as_user`],
  },
  loginRequest: {
    scopes: ['User.Read'],
  },
  graphConfig: {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  },
  recaptcha: {
    siteKey: '6LeoEXEgAAAAAB2dk9Ppsjum_fMewj40ngJiVStX',
  },
  blobConfig: {
    blobName: 'cases',
  },
});
