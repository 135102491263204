/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ObjectSchema } from 'yup';

type CheckboxControlProps = {
  label: string;
  fieldName: string;
  control: Control<any, any>;
  schema: ObjectSchema<any>;
  gridSize?: number;
};

export default function CheckboxControl(
  props: CheckboxControlProps
): JSX.Element {
  return (
    <Controller
      render={({ field }): JSX.Element => (
        <Grid item xs={props.gridSize ?? 12}>
          <FormControlLabel
            sx={{ mt: 4, ml: 0 }}
            control={
              <Checkbox
                id={props.fieldName}
                checked={field.value}
                onChange={(_event, data): void => {
                  field.onChange(data);
                }}
              />
            }
            label={props.label}
          />
        </Grid>
      )}
      name={props.fieldName}
      control={props.control}
    />
  );
}
