import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import SelectableItem from 'modules/app/cases/case-page/components/selectable-item';
import './case-page.css';
import { AppStateStore, useAppStateStore } from 'stores/app-state-store';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getApi, putApi } from 'services/http/axios-client';
import { constants } from 'config/constants';
import { toast } from 'react-toastify';
import ButtonTags from './components/button-tabs';
import { Case } from 'models/case';
import {
  containsRole,
  getAdminString,
  getDataEntryString,
} from 'modules/app/home-page/user-type';
import EvergineController from 'modules/components/evergine/evergine-controller';
import { ErrorBoundary } from 'react-error-boundary';
import { AttachedFile } from 'models/attached-file';
import { AttachedFileTypeEnum } from 'config/attached-file-type-enum';
import { TabPanel } from 'modules/components/form-controls/tab-panel';
import { saveAs } from 'file-saver';
import { getSettings } from 'config/settings';
import FavoriteCaseButton from 'modules/components/favorite-case/favorite-case-button';
import CaseEditDeleteMenu from 'modules/components/case-edit-delete-menu/case-edit-delete-menu';

export default function CasePage(): JSX.Element {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [itemPath, setItemPath] = useState('');
  const [selectedItem, setSelectedItem] = useState(0);
  const [isADocument, setIsADocument] = useState(false);
  const [stlFiles, setStlFiles] = useState<AttachedFile[]>([]);
  const [radioFiles, setRadioFiles] = useState<AttachedFile[]>([]);
  const [docFiles, setDocFiles] = useState<AttachedFile[]>([]);
  const [caseDetails, setCaseDetails] = useState<Case>();
  const [loadingCaseData, setLoadingCaseData] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [evergineDimensions, setEvergineDimensions] = useState({
    width: 100,
    height: 100,
  });
  const navigate = useNavigate();
  const measureCallbackRef = useCallback((domNode) => {
    function handleResize(): void {
      const dimensions = domNode.getBoundingClientRect();
      setEvergineDimensions({
        width: dimensions.width - 26,
        height: window.innerHeight - 75,
      });
    }
    if (domNode) {
      window.addEventListener('resize', () => setTimeout(handleResize, 100));
      handleResize();
    }
  }, []);
  const sasToken = useAppStateStore((s: AppStateStore) => s.sasToken);
  const [blobUrl, setBlobUrl] = useState('');
  const [blobSasTokenQueryString, setBlobSasTokenQueryString] = useState('');

  const userRoles = useAppStateStore((s: AppStateStore) => s.userRoles);
  const userId = useAppStateStore((s: AppStateStore) => s.userId);
  const canEdit =
    containsRole(getAdminString, userRoles as string[]) ||
    (containsRole(getDataEntryString, userRoles as string[]) &&
      userId == caseDetails?.createdById);

  const onDelete = (_id: number): void => {
    navigate('/');
  };

  useEffect(() => {
    const parameters: Map<string, string> = new Map();
    const keyAndValues = sasToken?.split(';');

    keyAndValues?.forEach((keyValue) => {
      const array = keyValue.split('=');
      parameters.set(array[0], array.slice(1).join('='));
    });

    const blobPrefix =
      `${parameters.get('BlobEndpoint')}${getSettings().blobConfig.blobName}` ||
      '';
    const blobQueryString = parameters.get('SharedAccessSignature') || '';

    setBlobUrl(blobPrefix);
    setBlobSasTokenQueryString(blobQueryString);
  }, [sasToken]);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      setLoadingCaseData(true);
      const response = await getApi<Case>(constants.api.cases + '/' + id);

      setCaseDetails(response);

      const stlFilteredFiles =
        response?.attachedFiles?.filter(
          (file) => file.type === AttachedFileTypeEnum.Stl
        ) || [];

      setStlFiles(stlFilteredFiles);

      const radioFilteredFiles =
        response?.attachedFiles?.filter(
          (file) =>
            file.type === AttachedFileTypeEnum.Tac ||
            file.type === AttachedFileTypeEnum.Scanner ||
            file.type === AttachedFileTypeEnum.Eco
        ) || [];
      setRadioFiles(radioFilteredFiles);

      const docFilteredFiles =
        response?.attachedFiles?.filter(
          (file) => file.type === AttachedFileTypeEnum.Doc
        ) || [];
      setDocFiles(docFilteredFiles);

      if (stlFilteredFiles.length > 0) {
        selectItem(stlFilteredFiles[0]);
      }
      setLoadingCaseData(false);
    };

    if (id) {
      loadData();
    }
    setReloadData(false);
  }, [reloadData]);

  const buttontabs = [
    { id: 0, tag: 'Modelo 3D' },
    { id: 1, tag: 'Radiología' },
    { id: 2, tag: 'Documentación' },
  ];

  const handleChange = (newValue: number): void => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        {
          const firstAttachedFile: AttachedFile | undefined =
            caseDetails?.attachedFiles?.filter(
              (a) => a.type === AttachedFileTypeEnum.Stl
            )[0];

          if (firstAttachedFile !== undefined) {
            selectItem(firstAttachedFile);
          }
        }
        break;
      case 1:
        {
          const firstAttachedFile: AttachedFile | undefined =
            caseDetails?.attachedFiles?.filter(
              (a) =>
                a.type === AttachedFileTypeEnum.Tac ||
                a.type === AttachedFileTypeEnum.Scanner ||
                a.type === AttachedFileTypeEnum.Eco
            )[0];

          if (firstAttachedFile !== undefined) {
            selectItem(firstAttachedFile);
          }
        }
        break;
      case 2:
        {
          const firstAttachedFile: AttachedFile | undefined =
            caseDetails?.attachedFiles?.filter(
              (a) => a.type === AttachedFileTypeEnum.Doc
            )[0];

          if (firstAttachedFile !== undefined) {
            selectItem(firstAttachedFile);
          }
        }
        break;
    }
  };

  const selectItem = (attachedFile: AttachedFile): void => {
    setIsADocument(attachedFile.type === AttachedFileTypeEnum.Doc);
    setSelectedItem(attachedFile.id!);
    setItemPath(attachedFile.path || '');
  };

  const handleAccept = async (isAccepted: boolean): Promise<void> => {
    const newState = isAccepted ? 1 : -1;
    const response = await putApi<boolean>(
      `${constants.api.updatecasestate}/${id}`,
      {
        newState: newState,
      }
    );

    if (response && caseDetails != undefined) {
      if (isAccepted) {
        toast.success('Caso aceptado');
      } else {
        toast.success('Caso denegado');
      }
      setReloadData(true);
    } else {
      toast.error('Ha ocurrido un error, por favor intentelo mas tarde');
    }
  };

  return (
    <Box sx={{ marginTop: '100px' }}>
      <Grid
        className="grid-container"
        container
        direction="row"
        spacing={'30px'}
      >
        {loadingCaseData ? (
          <Grid
            item
            xs={4}
            sx={{ paddingTop: '40px', paddingLeft: '75px', fontSize: '14px' }}
          >
            {' '}
            <Box sx={{ marginLeft: '40%', marginTop: '30%' }}>
              <CircularProgress size={100} />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={4} sx={{ paddingTop: '40px', fontSize: '14px' }}>
            <Box sx={{ marginLeft: '75px' }}>
              <Grid container width={'100%'}>
                <Grid item xs={8}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingTop: '3px', color: '#3886C2' }}
                  >
                    <Grid
                      item
                      sx={{ cursor: 'pointer' }}
                      onClick={(): void => navigate(-1)}
                    >
                      <ArrowBack />
                    </Grid>
                    <Grid
                      item
                      sx={{ cursor: 'pointer', fontSize: '16px' }}
                      onClick={(): void => navigate('/')}
                    >
                      <Box>Lista de casos</Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Stack sx={{ textAlign: 'right' }} direction="row">
                    {containsRole(getAdminString, userRoles as string[]) && (
                      <Stack direction="row">
                        <Tooltip title="Aceptar caso">
                          <IconButton
                            aria-label="accept"
                            disabled={caseDetails?.validationState == 1}
                            sx={
                              caseDetails?.validationState == 1
                                ? {
                                    backgroundColor: '#2E935C !important',
                                    color: 'white !important',
                                  }
                                : {}
                            }
                            onClick={(): Promise<void> => handleAccept(true)}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Denegar caso">
                          <IconButton
                            aria-label="decline"
                            disabled={caseDetails?.validationState == -1}
                            sx={
                              caseDetails?.validationState == -1
                                ? {
                                    backgroundColor: '#DB5454 !important',
                                    color: 'white !important',
                                  }
                                : {}
                            }
                            onClick={(): Promise<void> => handleAccept(false)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                    {caseDetails != undefined && (
                      <FavoriteCaseButton case={caseDetails} />
                    )}
                    {caseDetails != undefined && (
                      <CaseEditDeleteMenu
                        canEdit={canEdit}
                        case={caseDetails}
                        onDelete={onDelete}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>

              {caseDetails?.validationState == 0 && (
                <Box
                  sx={{
                    backgroundColor: '#EA8613',
                    textAlign: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    borderRadius: '4px',
                    marginTop: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <Box
                    sx={{
                      color: '#FFFFFF',
                      fontSize: '10px',
                      fontWeight: '600',
                      lineHeight: '18px',
                    }}
                  >
                    CASO PENDIENTE DE VALIDACIÓN
                  </Box>
                </Box>
              )}
              {caseDetails?.validationState == -1 && (
                <Box
                  sx={{
                    backgroundColor: '#DB5454',
                    textAlign: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    borderRadius: '4px',
                    marginTop: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <Box
                    sx={{
                      color: '#FFFFFF',
                      fontSize: '10px',
                      fontWeight: '600',
                      lineHeight: '18px',
                    }}
                  >
                    CASO DENEGADO
                  </Box>
                </Box>
              )}
              <Box sx={{ fontSize: '22px', fontWeight: '600' }}>
                {caseDetails?.name}
              </Box>
              <Box sx={{ color: '#838C96', paddingTop: '10px' }}>
                {caseDetails?.description}
              </Box>

              <p>
                {new Date(caseDetails?.date || '').toLocaleDateString('es-ES')}{' '}
                <b>
                  {caseDetails?.authorName !== undefined &&
                  caseDetails?.authorName !== null
                    ? caseDetails?.authorName
                    : ''}
                </b>
              </p>
              <p>
                {
                  <b>
                    {caseDetails?.hospitalName !== undefined &&
                    caseDetails.hospitalName !== null
                      ? caseDetails.hospitalName
                      : ''}
                  </b>
                }
              </p>
              <Grid container spacing={1}>
                {caseDetails?.tags?.map((tagLabel) => {
                  return (
                    <Grid item key={tagLabel.id}>
                      <Chip label={tagLabel.name} />{' '}
                    </Grid>
                  );
                })}
              </Grid>
              <Box
                className="button-tags"
                sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
              >
                <ButtonTags
                  buttons={buttontabs}
                  selectedId={selectedTab}
                  callback={handleChange}
                />
              </Box>

              <TabPanel value={selectedTab} index={0} key={0}>
                {stlFiles.map((item) => {
                  return (
                    <div key={item.id} onClick={(): void => selectItem(item)}>
                      <SelectableItem
                        item={item}
                        selected={selectedItem == item.id}
                      />
                    </div>
                  );
                })}
              </TabPanel>
              <TabPanel value={selectedTab} index={1} key={1}>
                {radioFiles.map((item) => {
                  return (
                    <div key={item.id} onClick={(): void => selectItem(item)}>
                      <SelectableItem
                        item={item}
                        selected={selectedItem == item.id}
                      />
                    </div>
                  );
                })}
              </TabPanel>
              <TabPanel value={selectedTab} index={2} key={2}>
                {docFiles.map((item) => {
                  return (
                    <div key={item.id} onClick={(): void => selectItem(item)}>
                      <SelectableItem
                        item={item}
                        selected={selectedItem == item.id}
                      />
                    </div>
                  );
                })}
              </TabPanel>
            </Box>
          </Grid>
        )}
        <Grid
          ref={measureCallbackRef}
          item
          xs={8}
          sx={{
            paddingTop: '0px !important',
            overflowX: 'hidden',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <ErrorBoundary
            FallbackComponent={(): JSX.Element => (
              <Box>Ouch! Algo no fue bien 😟</Box>
            )}
          >
            {!isADocument && itemPath !== '' && (
              <EvergineController
                height={evergineDimensions.height}
                width={evergineDimensions.width}
                stlPath={itemPath ? itemPath : ''}
                caseID={id ? (id as string) : ''}
              />
            )}

            {isADocument && itemPath !== '' && (
              <Button
                variant="outlined"
                size="large"
                startIcon={<GetAppIcon />}
                onClick={(): void => {
                  const nameToDownload = getNameToDownload(itemPath);
                  if (
                    nameToDownload.substring(nameToDownload.length - 3) == 'pdf'
                  ) {
                    const oReq = new XMLHttpRequest();
                    const URLToPDF = `${blobUrl}/${itemPath}?${blobSasTokenQueryString}`;
                    oReq.open('GET', URLToPDF, true);
                    oReq.responseType = 'blob';
                    oReq.onload = function (): void {
                      const file = new Blob([oReq.response], {
                        type: 'application/pdf',
                      });

                      const fileURL = URL.createObjectURL(file);
                      window.open(fileURL, '_blank');
                    };
                    oReq.send();
                  } else {
                    saveAs(
                      `${blobUrl}/${itemPath}?${blobSasTokenQueryString}`,
                      nameToDownload
                    );
                  }
                }}
              >
                Descargar documento
              </Button>
            )}
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Box>
  );

  function getNameToDownload(path: string): string {
    let nameToDownload = path;

    const indexOfFirstUnderscore = path.indexOf('_');
    if (indexOfFirstUnderscore !== -1) {
      nameToDownload = path.substring(indexOfFirstUnderscore + 1);
    } else {
      const indexOfLastSlash = path.lastIndexOf('/');
      if (indexOfLastSlash !== -1) {
        nameToDownload = path.substring(indexOfLastSlash + 1);
      }
    }

    return nameToDownload;
  }
}
