import { constants } from 'config/constants';
import yup from 'services/localized-yup';

export const hospitalSchema = yup.object({
  id: yup.number(),
  name: yup.string().required().max(constants.dataValidation.smallStringSize),
  country: yup
    .string()
    .required()
    .max(constants.dataValidation.codeStringSize)
    .nullable(),
  state: yup
    .string()
    .required()
    .max(constants.dataValidation.codeStringSize)
    .nullable(),
  city: yup.string().required().max(constants.dataValidation.smallStringSize),
});

export type Hospital = yup.InferType<typeof hospitalSchema>;
