import { Box, Button, Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameof } from 'typings/nameof';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ProfessionalProfile,
  professionalProfileSchema,
} from 'models/professional-profile';
import TextFieldControl from 'modules/components/form-controls/text-field-control';
import DiscardDialog from 'modules/components/dialogs/discard-dialog';
import { useRef } from 'react';

type ProfessionalProfileFormProps = {
  professionalProfile: ProfessionalProfile;
  onClose: (save: boolean) => void;
};

export default function ProfessionalProfileForm({
  professionalProfile,
  onClose,
}: ProfessionalProfileFormProps): JSX.Element {
  const { handleSubmit, control, formState } = useForm<ProfessionalProfile>({
    defaultValues: professionalProfile,
    resolver: yupResolver(professionalProfileSchema),
  });

  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discardDialog = useRef<any>(null);

  const onSubmit: SubmitHandler<ProfessionalProfile> = (
    newProfessionalProfileData
  ) => {
    Object.assign(professionalProfile, newProfessionalProfileData);
    onClose(true);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.light', px: 9 }}>
        <h2>
          {professionalProfile.name !== '' && professionalProfile.name}
          {professionalProfile.name === '' && 'Nuevo Perfil Professional'}
        </h2>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mx: 9 }}>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <TextFieldControl
            label="Nombre"
            fieldName={nameof<ProfessionalProfile>('name')}
            control={control}
            schema={professionalProfileSchema}
          />

          <Grid item xs={12}>
            <Box sx={{ mt: 3 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={(): void =>
                discardDialog!.current!.handleUnsavedChanges()
              }
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DiscardDialog ref={discardDialog} onClose={onClose} isDirty={isDirty} />
    </>
  );
}
