import { invoke } from 'evergine/evergine-definitions';

const devicePixelRatio = window.devicePixelRatio || 1;

const initializeEvergineCanvas = (id: string): void => {
  window.Module.canvas = document.getElementById(id) as HTMLCanvasElement;
  invoke('Run', id);
};

const updateCanvasSize = (id: string): void => invoke('UpdateCanvasSize', id);
const getDevicePixelRatio: () => number = () => devicePixelRatio;

const randomizeColors = (): void => invoke('RandomizeColors');
const resetView = (): void => invoke('ResetView');
const showViewerFromPath = (filepath: string): void =>
  invoke('ShowViewerFromPath', filepath);
const setLayerVisible = (layer: string, visibility: boolean): void =>
  invoke('SetLayerVisible', layer, visibility);
const setLayerOpacity = (layer: string, opacity: number): void =>
  invoke('SetLayerOpacity', layer, opacity);
const setCarouselIndex = (index: number): void =>
  invoke('SetCarouselIndex', index);
const setSASTokenToEvergine = (sasToken: string): void =>
  invoke('SetSASTokenToEvergine', sasToken);
const destroyEvergineCanvas = (id: string): void => {
  invoke('DestroyEvergineCanvas', id);
  if (window.Module?.canvas !== undefined) {
    window.Module.canvas = undefined;
  }
};

export {
  initializeEvergineCanvas,
  updateCanvasSize,
  getDevicePixelRatio,
  destroyEvergineCanvas,
  randomizeColors,
  resetView,
  showViewerFromPath,
  setLayerVisible,
  setLayerOpacity,
  setCarouselIndex,
  setSASTokenToEvergine,
};
