import {
  Box,
  Tabs,
  Tab,
  Chip,
  Grid,
  Container,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Create3DModel from './components/create-3dmodel';
import CreateDocumentation from './components/create-documentation';
import CreateInformation from './components/create-information';
import CreateRadiology from './components/create-radiology';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Case } from 'models/case';
import { TabPanel } from 'modules/components/form-controls/tab-panel';
import {
  deleteApi,
  getApi,
  postApi,
  postFileApi,
  putApi,
  putFileApi,
} from 'services/http/axios-client';
import { constants } from 'config/constants';
import { toast } from 'react-toastify';
import { Tag } from 'models/tag';
import { useNavigate, useParams } from 'react-router-dom';
import { EntityCreated } from 'models/entity-created';
import { AttachedFileTypeEnum } from 'config/attached-file-type-enum';
import { TagTypeEnum } from 'config/tag-type-options';
import { AttachedFile } from 'models/attached-file';

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function CreateCasePage(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const [panelSelector, setPanelSelector] = useState(0);
  const [dataCase, setDataCase] = useState<Case | null>(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loadingCaseData, setLoadingCaseData] = useState(true);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const response = await getApi<Case>(constants.api.cases + '/' + id);
      pushDataToTabs(response);
      setDataCase(response);
      setLoadingCaseData(false);
    };

    if (id) {
      loadData();
    } else {
      setDataCase(getNewCase());
      setLoadingCaseData(false);
    }
  }, []);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setPanelSelector(newValue);
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '100px' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.snackbar + 1 }}
        open={openBackdrop}
      >
        <Box sx={{ display: 'block', textAlign: 'center' }}>
          <CircularProgress size={100} />
          <Box>
            <h4>Actualizando datos del caso. Esto puede tardar unos minutos</h4>
          </Box>
        </Box>
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            value={panelSelector}
            onChange={handleTabChange}
            aria-label="Vertical tabs"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Información" {...a11yProps(0)} />
            <Tab
              disabled={dataCase?.id === -1}
              label="Modelo 3D"
              {...a11yProps(1)}
            />
            <Tab
              disabled={dataCase?.id === -1}
              label="Radiología"
              {...a11yProps(2)}
            />
            <Tab
              disabled={dataCase?.id === -1}
              label="Documentación"
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ mx: 2, mt: 1 }}>
            <Chip
              sx={{
                py: 3,
                px: 2,
                width: '100%',
                justifyContent: 'start',
                borderRadius: '50px',
              }}
              variant="outlined"
              icon={<InfoOutlinedIcon />}
              label="Podrás modificar el caso tantas veces como quieras, pero cada vez que guardes se volverá a enviar al validador y se quedará PENDIENTE"
            />
            <TabPanel value={panelSelector} index={0}>
              {!loadingCaseData && (
                <CreateInformation
                  case={dataCase!}
                  onClose={async (save: boolean): Promise<void> => {
                    if (save) {
                      await createOrUpdateCase(dataCase!);
                    } else {
                      navigate(-1);
                    }
                  }}
                />
              )}
            </TabPanel>
            <TabPanel value={panelSelector} index={1}>
              {!loadingCaseData && (
                <Create3DModel
                  case={dataCase!}
                  onClose={async (save: boolean): Promise<void> => {
                    if (save) {
                      await syncFiles(
                        dataCase!.id!,
                        dataCase!.name,
                        dataCase!.attachedFiles3DModels || [],
                        dataCase!.attachedFiles || [],
                        [AttachedFileTypeEnum.Stl]
                      );
                    } else {
                      navigate(-1);
                    }
                  }}
                />
              )}
            </TabPanel>
            <TabPanel value={panelSelector} index={2}>
              {!loadingCaseData && (
                <CreateRadiology
                  case={dataCase!}
                  onClose={async (save: boolean): Promise<void> => {
                    if (save) {
                      await syncFiles(
                        dataCase!.id!,
                        dataCase!.name,
                        dataCase!.attachedFilesRadiology || [],
                        dataCase!.attachedFiles || [],
                        [
                          AttachedFileTypeEnum.Tac,
                          AttachedFileTypeEnum.Scanner,
                          AttachedFileTypeEnum.Eco,
                        ]
                      );
                    } else {
                      navigate(-1);
                    }
                  }}
                />
              )}
            </TabPanel>
            <TabPanel value={panelSelector} index={3}>
              {!loadingCaseData && (
                <CreateDocumentation
                  case={dataCase!}
                  onClose={async (save: boolean): Promise<void> => {
                    if (save) {
                      await syncFiles(
                        dataCase!.id!,
                        dataCase!.name,
                        dataCase!.attachedFilesDoc || [],
                        dataCase!.attachedFiles || [],
                        [AttachedFileTypeEnum.Doc]
                      );
                    } else {
                      navigate(-1);
                    }
                  }}
                />
              )}
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  function pushDataToTabs(aCase: Case): void {
    aCase.organId = getTagIdByType(aCase, TagTypeEnum.Organ);
    aCase.pathologyId = getTagIdByType(aCase, TagTypeEnum.Pathology);
    aCase.procedureId = getTagIdByType(aCase, TagTypeEnum.Procedure);
    aCase.specialityId = getTagIdByType(aCase, TagTypeEnum.Speciality);

    if (aCase.tagsComboCodes === undefined || aCase.tagsComboCodes === null) {
      aCase.tagsComboCodes = [];
    }

    aCase.tags
      ?.filter((tag) => tag.type === TagTypeEnum.Other)
      .forEach((tag) => {
        aCase.tagsComboCodes?.push({
          code: `${tag.id}`,
          label: tag.name,
        });
      });

    aCase.attachedFiles3DModels = getFilteredFilesByTypes(
      aCase.attachedFiles || [],
      [AttachedFileTypeEnum.Stl]
    );
    aCase.attachedFilesRadiology = getFilteredFilesByTypes(
      aCase.attachedFiles || [],
      [
        AttachedFileTypeEnum.Tac,
        AttachedFileTypeEnum.Scanner,
        AttachedFileTypeEnum.Eco,
      ]
    );
    aCase.attachedFilesDoc = getFilteredFilesByTypes(
      aCase.attachedFiles || [],
      [AttachedFileTypeEnum.Doc]
    );
  }

  function getFilteredFilesByTypes(
    source: AttachedFile[],
    types: AttachedFileTypeEnum[]
  ): AttachedFile[] {
    const result = source.filter((file) => types.includes(file.type));
    result?.forEach((file) => {
      file.typeComboCode = file.type.toString();
    });
    return result;
  }

  function getTagIdByType(aCase: Case, type: TagTypeEnum): string {
    return aCase.tags?.find((tag) => tag.type === type)?.id;
  }

  function pullTagDataFromInformationTab(aCase: Case): void {
    aCase.tags = [];
    createTag(aCase, aCase.organId);
    createTag(aCase, aCase.pathologyId);
    createTag(aCase, aCase.procedureId);
    createTag(aCase, aCase.specialityId);

    aCase.tagsComboCodes?.forEach((tag) => {
      createTag(aCase, tag.code);
    });
  }

  function createTag(aCase: Case, tagId: string | null | undefined): void {
    if (tagId !== undefined && tagId !== null) {
      aCase.tags?.push({
        id: parseInt(tagId),
      } as Tag);
    }
  }

  function getFilesToCreateUpdateAndDeleteByTypes(
    source: AttachedFile[],
    target: AttachedFile[],
    types: AttachedFileTypeEnum[]
  ): [AttachedFile[], AttachedFile[], AttachedFile[]] {
    const files = target?.filter((file) => types.includes(file.type)) || [];

    const filesToDelete =
      files?.filter((file) => !source?.find((f) => f.id === file.id)) || [];

    const filesToUpdate =
      source?.filter((file) => files?.find((f) => f.id === file.id)) || [];

    const filesToCreate = source?.filter((file) => file.id === -1) || [];

    return [filesToCreate, filesToUpdate, filesToDelete];
  }

  function updateAttachedFiles(
    attachedFiles: AttachedFile[],
    filesToCreate: AttachedFile[],
    filesToUpdate: AttachedFile[],
    filesToDelete: AttachedFile[]
  ): void {
    filesToCreate?.forEach((file) => {
      attachedFiles?.push(file);
    });

    filesToUpdate?.forEach((file) => {
      const attachedFile = attachedFiles?.find((f) => f.id === file.id);

      if (attachedFile !== undefined) {
        attachedFile.name = file.name;
        attachedFile.type = file.type;
        attachedFile.typeComboCode = file.typeComboCode;
        attachedFile.description = file.description;
      }
    });

    filesToDelete?.forEach((file) => {
      const index = attachedFiles?.findIndex((f) => f.id === file.id);
      if (index !== -1) {
        attachedFiles?.splice(index, 1);
      }
    });
  }

  function getNewCase(): Case {
    return {
      id: -1,
      name: '',
      assignedToId: null!,
      validationUserId: '',
      createdById: '',
      authorName: '',
      date: '',
      description: '',
      hospitalName: '',
      validationState: 0,
      isVisible: true,
      attachedFiles: [],
      attachedFiles3DModels: [],
      attachedFilesRadiology: [],
      attachedFilesDoc: [],
      tags: [],
      isFavorite: false,
      organId: null!,
      pathologyId: null!,
      procedureId: null!,
      specialityId: null!,
      tagsComboCodes: [],
    };
  }

  async function createOrUpdateCase(aCase: Case): Promise<void> {
    setOpenBackdrop(true);
    pullTagDataFromInformationTab(aCase);

    if (aCase.id === -1) {
      const entityCreated = await postApi<Promise<EntityCreated>, Case>(
        constants.api.cases,
        aCase
      );
      aCase.id = entityCreated.id;
    } else {
      await putApi<Promise<EntityCreated>, Case>(constants.api.cases, aCase);
    }

    if (aCase.thumbnailFile !== undefined && aCase.thumbnailFile !== null) {
      const formData = new FormData();
      formData.append('file', aCase.thumbnailFile);
      await putFileApi<Promise<string>>(
        `${constants.api.cases}/${aCase.id}/thumbnail`,
        formData
      );
    }

    if (panelSelector < 3) {
      setPanelSelector(panelSelector + 1);
    }
    setOpenBackdrop(false);
    toast.success(`Caso guardado: ${aCase.name}`);
  }

  async function syncFiles(
    caseId: number,
    caseName: string,
    source: AttachedFile[],
    target: AttachedFile[],
    types: AttachedFileTypeEnum[]
  ): Promise<void> {
    const [filesToCreate, filesToUpdate, filesToDelete] =
      getFilesToCreateUpdateAndDeleteByTypes(source || [], target || [], types);

    setOpenBackdrop(true);

    // Create
    const promises = filesToCreate.map(async (attachedFile: AttachedFile) => {
      toast.info(`Subiendo fichero: ${attachedFile.name}`);
      const formData = new FormData();
      formData.append('file', attachedFile.file);
      formData.append('name', attachedFile.name);
      formData.append('description', attachedFile.description || '');
      formData.append('type', attachedFile.type.toString());
      const entityCreated = await postFileApi<Promise<EntityCreated>>(
        `${constants.api.cases}/${caseId}/${constants.api.attachedFiles}`,
        formData
      );
      toast.info(`Fichero guardado: ${attachedFile.name}`);
      attachedFile.id = entityCreated.id;
    });

    await Promise.all(promises || []);

    // Update
    filesToUpdate.forEach(async (attachedFile: AttachedFile) => {
      await putApi<Promise<void>, AttachedFile>(
        `${constants.api.cases}/${caseId}/${constants.api.attachedFiles}/${attachedFile.id}`,
        attachedFile
      );
    });

    // Delete
    filesToDelete.forEach(async (attachedFile) => {
      await deleteApi<Promise<boolean>>(
        `${constants.api.cases}/${caseId}/${constants.api.attachedFiles}/${attachedFile.id}`
      );
    });
    updateAttachedFiles(target, filesToCreate, filesToUpdate, filesToDelete);

    setOpenBackdrop(false);

    if (panelSelector < 3) {
      setPanelSelector(panelSelector + 1);
    } else {
      navigate('/case/' + caseId);
    }

    toast.success(`Caso guardado: ${caseName}`);
  }
}
