import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Box } from '@mui/system';

type ConfirmationDialogProps = {
  title: string;
  message: string;
  open: boolean;
  onClose: (confirm: boolean) => void;
};

export default function ConfirmationDialog(
  props: ConfirmationDialogProps
): JSX.Element {
  function handleCancel(): void {
    props.onClose(false);
  }

  function handleOk(): void {
    props.onClose(true);
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningIcon color="warning" sx={{ marginRight: 2 }} /> {props.title}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleOk}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
