import { useMemo, memo, useEffect } from 'react';
import { EVERGINE_CANVAS_ID } from 'evergine/evergine-definitions';
import { EvergineState, useEvergineStore } from 'stores/evergine-store';
import {
  initializeEvergineCanvas,
  getDevicePixelRatio,
  destroyEvergineCanvas,
  updateCanvasSize,
} from 'evergine/evergine-program-service';
import { SizeInfo } from 'models/size-info';
import styled from '@emotion/styled';

const devicePixelRatio = getDevicePixelRatio();

const calculateSizeWithPixelRatio = (size: number): number =>
  size * devicePixelRatio;

const StyledCanvas = styled.canvas(
  (props: { isExperienceStarted: boolean }) => ({
    zIndex: 10,
    display: props.isExperienceStarted ? 'inline' : 'none',
    overflow: 'hidden',
  })
);

type EvergineCanvasCanvasProps = {
  height: number;
  width: number;
};

const EvergineCanvas = memo(({ height, width }: EvergineCanvasCanvasProps) => {
  const isWebAssemblyLoaded = useEvergineStore(
    (s: EvergineState) => s.isWebAssemblyLoaded
  );
  const isEvergineReady = useEvergineStore(
    (s: EvergineState) => s.isEvergineReady
  );
  const isExperienceStarted = useEvergineStore(
    (s: EvergineState) => s.isExperienceStarted
  );

  const setExperienceStarted = useEvergineStore(
    (s: EvergineState) => s.setExperienceStarted
  );

  const size: SizeInfo = useMemo(
    () => ({
      height: height,
      width: width,
      pixelRatioAwareHeight: calculateSizeWithPixelRatio(height),
      pixelRatioAwareWidth: calculateSizeWithPixelRatio(width),
    }),
    [height, width]
  );

  useEffect(() => {
    if (isEvergineReady) {
      updateCanvasSize(EVERGINE_CANVAS_ID);
    }
  }, [isEvergineReady, size]);

  useEffect(() => {
    if (isWebAssemblyLoaded) {
      initializeEvergineCanvas(EVERGINE_CANVAS_ID);
    }
  }, [isWebAssemblyLoaded]);

  useEffect(() => {
    if (isExperienceStarted) {
      window.Module.canvas?.focus();
    }
  }, [isExperienceStarted]);

  useEffect(() => {
    return (): void => {
      destroyEvergineCanvas(EVERGINE_CANVAS_ID);
      setExperienceStarted(false);
    };
  }, []);

  return (
    <StyledCanvas
      id={EVERGINE_CANVAS_ID}
      width={size.pixelRatioAwareWidth}
      height={size.pixelRatioAwareHeight}
      onContextMenu={(ev: React.SyntheticEvent): void => {
        ev.preventDefault();
      }}
      tabIndex={1}
      isExperienceStarted={isExperienceStarted}
      style={{
        height: `${size.height}px`,
        width: `${size.width}px`,
      }}
    />
  );
});

EvergineCanvas.displayName = 'EvergineCanvas';
export { EvergineCanvas };
