// Thanks to: https://github.com/wooorm/iso-3166

export const countrySubdivisions = [
  {
    code: 'AD-02',
    label: 'Canillo',
    country: 'AD',
  },
  {
    code: 'AD-03',
    label: 'Encamp',
    country: 'AD',
  },
  {
    code: 'AD-04',
    label: 'La Massana',
    country: 'AD',
  },
  {
    code: 'AD-05',
    label: 'Ordino',
    country: 'AD',
  },
  {
    code: 'AD-06',
    label: 'Sant Julià de Lòria',
    country: 'AD',
  },
  {
    code: 'AD-07',
    label: 'Andorra la Vella',
    country: 'AD',
  },
  {
    code: 'AD-08',
    label: 'Escaldes-Engordany',
    country: 'AD',
  },
  {
    code: 'AE-AJ',
    label: '‘Ajmān',
    country: 'AE',
  },
  {
    code: 'AE-AZ',
    label: 'Abū Z̧aby',
    country: 'AE',
  },
  {
    code: 'AE-DU',
    label: 'Dubayy',
    country: 'AE',
  },
  {
    code: 'AE-FU',
    label: 'Al Fujayrah',
    country: 'AE',
  },
  {
    code: 'AE-RK',
    label: 'Ra’s al Khaymah',
    country: 'AE',
  },
  {
    code: 'AE-SH',
    label: 'Ash Shāriqah',
    country: 'AE',
  },
  {
    code: 'AE-UQ',
    label: 'Umm al Qaywayn',
    country: 'AE',
  },
  {
    code: 'AF-BAL',
    label: 'Balkh',
    country: 'AF',
  },
  {
    code: 'AF-BAM',
    label: 'Bāmyān',
    country: 'AF',
  },
  {
    code: 'AF-BDG',
    label: 'Bādghīs',
    country: 'AF',
  },
  {
    code: 'AF-BDS',
    label: 'Badakhshān',
    country: 'AF',
  },
  {
    code: 'AF-BGL',
    label: 'Baghlān',
    country: 'AF',
  },
  {
    code: 'AF-DAY',
    label: 'Dāykundī',
    country: 'AF',
  },
  {
    code: 'AF-FRA',
    label: 'Farāh',
    country: 'AF',
  },
  {
    code: 'AF-FYB',
    label: 'Fāryāb',
    country: 'AF',
  },
  {
    code: 'AF-GHA',
    label: 'Ghaznī',
    country: 'AF',
  },
  {
    code: 'AF-GHO',
    label: 'Ghōr',
    country: 'AF',
  },
  {
    code: 'AF-HEL',
    label: 'Helmand',
    country: 'AF',
  },
  {
    code: 'AF-HER',
    label: 'Herāt',
    country: 'AF',
  },
  {
    code: 'AF-JOW',
    label: 'Jowzjān',
    country: 'AF',
  },
  {
    code: 'AF-KAB',
    label: 'Kābul',
    country: 'AF',
  },
  {
    code: 'AF-KAN',
    label: 'Kandahār',
    country: 'AF',
  },
  {
    code: 'AF-KAP',
    label: 'Kāpīsā',
    country: 'AF',
  },
  {
    code: 'AF-KDZ',
    label: 'Kunduz',
    country: 'AF',
  },
  {
    code: 'AF-KHO',
    label: 'Khōst',
    country: 'AF',
  },
  {
    code: 'AF-KNR',
    label: 'Kunaṟ',
    country: 'AF',
  },
  {
    code: 'AF-LAG',
    label: 'Laghmān',
    country: 'AF',
  },
  {
    code: 'AF-LOG',
    label: 'Lōgar',
    country: 'AF',
  },
  {
    code: 'AF-NAN',
    label: 'Nangarhār',
    country: 'AF',
  },
  {
    code: 'AF-NIM',
    label: 'Nīmrōz',
    country: 'AF',
  },
  {
    code: 'AF-NUR',
    label: 'Nūristān',
    country: 'AF',
  },
  {
    code: 'AF-PAN',
    label: 'Panjshayr',
    country: 'AF',
  },
  {
    code: 'AF-PAR',
    label: 'Parwān',
    country: 'AF',
  },
  {
    code: 'AF-PIA',
    label: 'Paktiyā',
    country: 'AF',
  },
  {
    code: 'AF-PKA',
    label: 'Paktīkā',
    country: 'AF',
  },
  {
    code: 'AF-SAM',
    label: 'Samangān',
    country: 'AF',
  },
  {
    code: 'AF-SAR',
    label: 'Sar-e Pul',
    country: 'AF',
  },
  {
    code: 'AF-TAK',
    label: 'Takhār',
    country: 'AF',
  },
  {
    code: 'AF-URU',
    label: 'Uruzgān',
    country: 'AF',
  },
  {
    code: 'AF-WAR',
    label: 'Wardak',
    country: 'AF',
  },
  {
    code: 'AF-ZAB',
    label: 'Zābul',
    country: 'AF',
  },
  {
    code: 'AG-03',
    label: 'Saint George',
    country: 'AG',
  },
  {
    code: 'AG-04',
    label: 'Saint John',
    country: 'AG',
  },
  {
    code: 'AG-05',
    label: 'Saint Mary',
    country: 'AG',
  },
  {
    code: 'AG-06',
    label: 'Saint Paul',
    country: 'AG',
  },
  {
    code: 'AG-07',
    label: 'Saint Peter',
    country: 'AG',
  },
  {
    code: 'AG-08',
    label: 'Saint Philip',
    country: 'AG',
  },
  {
    code: 'AG-10',
    label: 'Barbuda',
    country: 'AG',
  },
  {
    code: 'AG-11',
    label: 'Redonda',
    country: 'AG',
  },
  {
    code: 'AL-01',
    label: 'Berat',
    country: 'AL',
  },
  {
    code: 'AL-02',
    label: 'Durrës',
    country: 'AL',
  },
  {
    code: 'AL-03',
    label: 'Elbasan',
    country: 'AL',
  },
  {
    code: 'AL-04',
    label: 'Fier',
    country: 'AL',
  },
  {
    code: 'AL-05',
    label: 'Gjirokastër',
    country: 'AL',
  },
  {
    code: 'AL-06',
    label: 'Korçë',
    country: 'AL',
  },
  {
    code: 'AL-07',
    label: 'Kukës',
    country: 'AL',
  },
  {
    code: 'AL-08',
    label: 'Lezhë',
    country: 'AL',
  },
  {
    code: 'AL-09',
    label: 'Dibër',
    country: 'AL',
  },
  {
    code: 'AL-10',
    label: 'Shkodër',
    country: 'AL',
  },
  {
    code: 'AL-11',
    label: 'Tiranë',
    country: 'AL',
  },
  {
    code: 'AL-12',
    label: 'Vlorë',
    country: 'AL',
  },
  {
    code: 'AM-AG',
    label: 'Aragac̣otn',
    country: 'AM',
  },
  {
    code: 'AM-AR',
    label: 'Ararat',
    country: 'AM',
  },
  {
    code: 'AM-AV',
    label: 'Armavir',
    country: 'AM',
  },
  {
    code: 'AM-ER',
    label: 'Erevan',
    country: 'AM',
  },
  {
    code: 'AM-GR',
    label: "Geġark'unik'",
    country: 'AM',
  },
  {
    code: 'AM-KT',
    label: "Kotayk'",
    country: 'AM',
  },
  {
    code: 'AM-LO',
    label: 'Loṙi',
    country: 'AM',
  },
  {
    code: 'AM-SH',
    label: 'Širak',
    country: 'AM',
  },
  {
    code: 'AM-SU',
    label: "Syunik'",
    country: 'AM',
  },
  {
    code: 'AM-TV',
    label: 'Tavuš',
    country: 'AM',
  },
  {
    code: 'AM-VD',
    label: 'Vayoć Jor',
    country: 'AM',
  },
  {
    code: 'AO-BGO',
    label: 'Bengo',
    country: 'AO',
  },
  {
    code: 'AO-BGU',
    label: 'Benguela',
    country: 'AO',
  },
  {
    code: 'AO-BIE',
    label: 'Bié',
    country: 'AO',
  },
  {
    code: 'AO-CAB',
    label: 'Cabinda',
    country: 'AO',
  },
  {
    code: 'AO-CCU',
    label: 'Cuando Cubango',
    country: 'AO',
  },
  {
    code: 'AO-CNN',
    label: 'Cunene',
    country: 'AO',
  },
  {
    code: 'AO-CNO',
    label: 'Cuanza-Norte',
    country: 'AO',
  },
  {
    code: 'AO-CUS',
    label: 'Cuanza-Sul',
    country: 'AO',
  },
  {
    code: 'AO-HUA',
    label: 'Huambo',
    country: 'AO',
  },
  {
    code: 'AO-HUI',
    label: 'Huíla',
    country: 'AO',
  },
  {
    code: 'AO-LNO',
    label: 'Lunda-Norte',
    country: 'AO',
  },
  {
    code: 'AO-LSU',
    label: 'Lunda-Sul',
    country: 'AO',
  },
  {
    code: 'AO-LUA',
    label: 'Luanda',
    country: 'AO',
  },
  {
    code: 'AO-MAL',
    label: 'Malange',
    country: 'AO',
  },
  {
    code: 'AO-MOX',
    label: 'Moxico',
    country: 'AO',
  },
  {
    code: 'AO-NAM',
    label: 'Namibe',
    country: 'AO',
  },
  {
    code: 'AO-UIG',
    label: 'Uíge',
    country: 'AO',
  },
  {
    code: 'AO-ZAI',
    label: 'Zaire',
    country: 'AO',
  },
  {
    code: 'AR-A',
    label: 'Salta',
    country: 'AR',
  },
  {
    code: 'AR-B',
    label: 'Buenos Aires',
    country: 'AR',
  },
  {
    code: 'AR-C',
    label: 'Ciudad Autónoma de Buenos Aires',
    country: 'AR',
  },
  {
    code: 'AR-D',
    label: 'San Luis',
    country: 'AR',
  },
  {
    code: 'AR-E',
    label: 'Entre Ríos',
    country: 'AR',
  },
  {
    code: 'AR-F',
    label: 'La Rioja',
    country: 'AR',
  },
  {
    code: 'AR-G',
    label: 'Santiago del Estero',
    country: 'AR',
  },
  {
    code: 'AR-H',
    label: 'Chaco',
    country: 'AR',
  },
  {
    code: 'AR-J',
    label: 'San Juan',
    country: 'AR',
  },
  {
    code: 'AR-K',
    label: 'Catamarca',
    country: 'AR',
  },
  {
    code: 'AR-L',
    label: 'La Pampa',
    country: 'AR',
  },
  {
    code: 'AR-M',
    label: 'Mendoza',
    country: 'AR',
  },
  {
    code: 'AR-N',
    label: 'Misiones',
    country: 'AR',
  },
  {
    code: 'AR-P',
    label: 'Formosa',
    country: 'AR',
  },
  {
    code: 'AR-Q',
    label: 'Neuquén',
    country: 'AR',
  },
  {
    code: 'AR-R',
    label: 'Río Negro',
    country: 'AR',
  },
  {
    code: 'AR-S',
    label: 'Santa Fe',
    country: 'AR',
  },
  {
    code: 'AR-T',
    label: 'Tucumán',
    country: 'AR',
  },
  {
    code: 'AR-U',
    label: 'Chubut',
    country: 'AR',
  },
  {
    code: 'AR-V',
    label: 'Tierra del Fuego',
    country: 'AR',
  },
  {
    code: 'AR-W',
    label: 'Corrientes',
    country: 'AR',
  },
  {
    code: 'AR-X',
    label: 'Córdoba',
    country: 'AR',
  },
  {
    code: 'AR-Y',
    label: 'Jujuy',
    country: 'AR',
  },
  {
    code: 'AR-Z',
    label: 'Santa Cruz',
    country: 'AR',
  },
  {
    code: 'AT-1',
    label: 'Burgenland',
    country: 'AT',
  },
  {
    code: 'AT-2',
    label: 'Kärnten',
    country: 'AT',
  },
  {
    code: 'AT-3',
    label: 'Niederösterreich',
    country: 'AT',
  },
  {
    code: 'AT-4',
    label: 'Oberösterreich',
    country: 'AT',
  },
  {
    code: 'AT-5',
    label: 'Salzburg',
    country: 'AT',
  },
  {
    code: 'AT-6',
    label: 'Steiermark',
    country: 'AT',
  },
  {
    code: 'AT-7',
    label: 'Tirol',
    country: 'AT',
  },
  {
    code: 'AT-8',
    label: 'Vorarlberg',
    country: 'AT',
  },
  {
    code: 'AT-9',
    label: 'Wien',
    country: 'AT',
  },
  {
    code: 'AU-ACT',
    label: 'Australian Capital Territory',
    country: 'AU',
  },
  {
    code: 'AU-NSW',
    label: 'New South Wales',
    country: 'AU',
  },
  {
    code: 'AU-NT',
    label: 'Northern Territory',
    country: 'AU',
  },
  {
    code: 'AU-QLD',
    label: 'Queensland',
    country: 'AU',
  },
  {
    code: 'AU-SA',
    label: 'South Australia',
    country: 'AU',
  },
  {
    code: 'AU-TAS',
    label: 'Tasmania',
    country: 'AU',
  },
  {
    code: 'AU-VIC',
    label: 'Victoria',
    country: 'AU',
  },
  {
    code: 'AU-WA',
    label: 'Western Australia',
    country: 'AU',
  },
  {
    code: 'AZ-ABS',
    label: 'Abşeron',
    country: 'AZ',
  },
  {
    code: 'AZ-AGA',
    label: 'Ağstafa',
    country: 'AZ',
  },
  {
    code: 'AZ-AGC',
    label: 'Ağcabədi',
    country: 'AZ',
  },
  {
    code: 'AZ-AGM',
    label: 'Ağdam',
    country: 'AZ',
  },
  {
    code: 'AZ-AGS',
    label: 'Ağdaş',
    country: 'AZ',
  },
  {
    code: 'AZ-AGU',
    label: 'Ağsu',
    country: 'AZ',
  },
  {
    code: 'AZ-AST',
    label: 'Astara',
    country: 'AZ',
  },
  {
    code: 'AZ-BA',
    label: 'Bakı',
    country: 'AZ',
  },
  {
    code: 'AZ-BAB',
    label: 'Babək',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-BAL',
    label: 'Balakən',
    country: 'AZ',
  },
  {
    code: 'AZ-BAR',
    label: 'Bərdə',
    country: 'AZ',
  },
  {
    code: 'AZ-BEY',
    label: 'Beyləqan',
    country: 'AZ',
  },
  {
    code: 'AZ-BIL',
    label: 'Biləsuvar',
    country: 'AZ',
  },
  {
    code: 'AZ-CAB',
    label: 'Cəbrayıl',
    country: 'AZ',
  },
  {
    code: 'AZ-CAL',
    label: 'Cəlilabad',
    country: 'AZ',
  },
  {
    code: 'AZ-CUL',
    label: 'Culfa',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-DAS',
    label: 'Daşkəsən',
    country: 'AZ',
  },
  {
    code: 'AZ-FUZ',
    label: 'Füzuli',
    country: 'AZ',
  },
  {
    code: 'AZ-GA',
    label: 'Gəncə',
    country: 'AZ',
  },
  {
    code: 'AZ-GAD',
    label: 'Gədəbəy',
    country: 'AZ',
  },
  {
    code: 'AZ-GOR',
    label: 'Goranboy',
    country: 'AZ',
  },
  {
    code: 'AZ-GOY',
    label: 'Göyçay',
    country: 'AZ',
  },
  {
    code: 'AZ-GYG',
    label: 'Göygöl',
    country: 'AZ',
  },
  {
    code: 'AZ-HAC',
    label: 'Hacıqabul',
    country: 'AZ',
  },
  {
    code: 'AZ-IMI',
    label: 'İmişli',
    country: 'AZ',
  },
  {
    code: 'AZ-ISM',
    label: 'İsmayıllı',
    country: 'AZ',
  },
  {
    code: 'AZ-KAL',
    label: 'Kəlbəcər',
    country: 'AZ',
  },
  {
    code: 'AZ-KAN',
    label: 'Kǝngǝrli',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-KUR',
    label: 'Kürdəmir',
    country: 'AZ',
  },
  {
    code: 'AZ-LA',
    label: 'Lənkəran',
    country: 'AZ',
  },
  {
    code: 'AZ-LAC',
    label: 'Laçın',
    country: 'AZ',
  },
  {
    code: 'AZ-LAN',
    label: 'Lənkəran',
    country: 'AZ',
  },
  {
    code: 'AZ-LER',
    label: 'Lerik',
    country: 'AZ',
  },
  {
    code: 'AZ-MAS',
    label: 'Masallı',
    country: 'AZ',
  },
  {
    code: 'AZ-MI',
    label: 'Mingəçevir',
    country: 'AZ',
  },
  {
    code: 'AZ-NA',
    label: 'Naftalan',
    country: 'AZ',
  },
  {
    code: 'AZ-NEF',
    label: 'Neftçala',
    country: 'AZ',
  },
  {
    code: 'AZ-NV',
    label: 'Naxçıvan',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-NX',
    label: 'Naxçıvan',
    country: 'AZ',
  },
  {
    code: 'AZ-OGU',
    label: 'Oğuz',
    country: 'AZ',
  },
  {
    code: 'AZ-ORD',
    label: 'Ordubad',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-QAB',
    label: 'Qəbələ',
    country: 'AZ',
  },
  {
    code: 'AZ-QAX',
    label: 'Qax',
    country: 'AZ',
  },
  {
    code: 'AZ-QAZ',
    label: 'Qazax',
    country: 'AZ',
  },
  {
    code: 'AZ-QBA',
    label: 'Quba',
    country: 'AZ',
  },
  {
    code: 'AZ-QBI',
    label: 'Qubadlı',
    country: 'AZ',
  },
  {
    code: 'AZ-QOB',
    label: 'Qobustan',
    country: 'AZ',
  },
  {
    code: 'AZ-QUS',
    label: 'Qusar',
    country: 'AZ',
  },
  {
    code: 'AZ-SA',
    label: 'Şəki',
    country: 'AZ',
  },
  {
    code: 'AZ-SAB',
    label: 'Sabirabad',
    country: 'AZ',
  },
  {
    code: 'AZ-SAD',
    label: 'Sədərək',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-SAH',
    label: 'Şahbuz',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-SAK',
    label: 'Şəki',
    country: 'AZ',
  },
  {
    code: 'AZ-SAL',
    label: 'Salyan',
    country: 'AZ',
  },
  {
    code: 'AZ-SAR',
    label: 'Şərur',
    country: 'AZ-NX',
  },
  {
    code: 'AZ-SAT',
    label: 'Saatlı',
    country: 'AZ',
  },
  {
    code: 'AZ-SBN',
    label: 'Şabran',
    country: 'AZ',
  },
  {
    code: 'AZ-SIY',
    label: 'Siyəzən',
    country: 'AZ',
  },
  {
    code: 'AZ-SKR',
    label: 'Şəmkir',
    country: 'AZ',
  },
  {
    code: 'AZ-SM',
    label: 'Sumqayıt',
    country: 'AZ',
  },
  {
    code: 'AZ-SMI',
    label: 'Şamaxı',
    country: 'AZ',
  },
  {
    code: 'AZ-SMX',
    label: 'Samux',
    country: 'AZ',
  },
  {
    code: 'AZ-SR',
    label: 'Şirvan',
    country: 'AZ',
  },
  {
    code: 'AZ-SUS',
    label: 'Şuşa',
    country: 'AZ',
  },
  {
    code: 'AZ-TAR',
    label: 'Tərtər',
    country: 'AZ',
  },
  {
    code: 'AZ-TOV',
    label: 'Tovuz',
    country: 'AZ',
  },
  {
    code: 'AZ-UCA',
    label: 'Ucar',
    country: 'AZ',
  },
  {
    code: 'AZ-XA',
    label: 'Xankəndi',
    country: 'AZ',
  },
  {
    code: 'AZ-XAC',
    label: 'Xaçmaz',
    country: 'AZ',
  },
  {
    code: 'AZ-XCI',
    label: 'Xocalı',
    country: 'AZ',
  },
  {
    code: 'AZ-XIZ',
    label: 'Xızı',
    country: 'AZ',
  },
  {
    code: 'AZ-XVD',
    label: 'Xocavənd',
    country: 'AZ',
  },
  {
    code: 'AZ-YAR',
    label: 'Yardımlı',
    country: 'AZ',
  },
  {
    code: 'AZ-YE',
    label: 'Yevlax',
    country: 'AZ',
  },
  {
    code: 'AZ-YEV',
    label: 'Yevlax',
    country: 'AZ',
  },
  {
    code: 'AZ-ZAN',
    label: 'Zəngilan',
    country: 'AZ',
  },
  {
    code: 'AZ-ZAQ',
    label: 'Zaqatala',
    country: 'AZ',
  },
  {
    code: 'AZ-ZAR',
    label: 'Zərdab',
    country: 'AZ',
  },
  {
    code: 'BA-BIH',
    label: 'Federacija Bosne i Hercegovine',
    country: 'BA',
  },
  {
    code: 'BA-BRC',
    label: 'Brčko distrikt',
    country: 'BA',
  },
  {
    code: 'BA-SRP',
    label: 'Republika Srpska',
    country: 'BA',
  },
  {
    code: 'BB-01',
    label: 'Christ Church',
    country: 'BB',
  },
  {
    code: 'BB-02',
    label: 'Saint Andrew',
    country: 'BB',
  },
  {
    code: 'BB-03',
    label: 'Saint George',
    country: 'BB',
  },
  {
    code: 'BB-04',
    label: 'Saint James',
    country: 'BB',
  },
  {
    code: 'BB-05',
    label: 'Saint John',
    country: 'BB',
  },
  {
    code: 'BB-06',
    label: 'Saint Joseph',
    country: 'BB',
  },
  {
    code: 'BB-07',
    label: 'Saint Lucy',
    country: 'BB',
  },
  {
    code: 'BB-08',
    label: 'Saint Michael',
    country: 'BB',
  },
  {
    code: 'BB-09',
    label: 'Saint Peter',
    country: 'BB',
  },
  {
    code: 'BB-10',
    label: 'Saint Philip',
    country: 'BB',
  },
  {
    code: 'BB-11',
    label: 'Saint Thomas',
    country: 'BB',
  },
  {
    code: 'BD-01',
    label: 'Bandarban',
    country: 'BD-B',
  },
  {
    code: 'BD-02',
    label: 'Barguna',
    country: 'BD-A',
  },
  {
    code: 'BD-03',
    label: 'Bogura',
    country: 'BD-E',
  },
  {
    code: 'BD-04',
    label: 'Brahmanbaria',
    country: 'BD-B',
  },
  {
    code: 'BD-05',
    label: 'Bagerhat',
    country: 'BD-D',
  },
  {
    code: 'BD-06',
    label: 'Barishal',
    country: 'BD-A',
  },
  {
    code: 'BD-07',
    label: 'Bhola',
    country: 'BD-A',
  },
  {
    code: 'BD-08',
    label: 'Cumilla',
    country: 'BD-B',
  },
  {
    code: 'BD-09',
    label: 'Chandpur',
    country: 'BD-B',
  },
  {
    code: 'BD-10',
    label: 'Chattogram',
    country: 'BD-B',
  },
  {
    code: 'BD-11',
    label: "Cox's Bazar",
    country: 'BD-B',
  },
  {
    code: 'BD-12',
    label: 'Chuadanga',
    country: 'BD-D',
  },
  {
    code: 'BD-13',
    label: 'Dhaka',
    country: 'BD-C',
  },
  {
    code: 'BD-14',
    label: 'Dinajpur',
    country: 'BD-F',
  },
  {
    code: 'BD-15',
    label: 'Faridpur',
    country: 'BD-C',
  },
  {
    code: 'BD-16',
    label: 'Feni',
    country: 'BD-B',
  },
  {
    code: 'BD-17',
    label: 'Gopalganj',
    country: 'BD-C',
  },
  {
    code: 'BD-18',
    label: 'Gazipur',
    country: 'BD-C',
  },
  {
    code: 'BD-19',
    label: 'Gaibandha',
    country: 'BD-F',
  },
  {
    code: 'BD-20',
    label: 'Habiganj',
    country: 'BD-G',
  },
  {
    code: 'BD-21',
    label: 'Jamalpur',
    country: 'BD-H',
  },
  {
    code: 'BD-22',
    label: 'Jashore',
    country: 'BD-D',
  },
  {
    code: 'BD-23',
    label: 'Jhenaidah',
    country: 'BD-D',
  },
  {
    code: 'BD-24',
    label: 'Joypurhat',
    country: 'BD-E',
  },
  {
    code: 'BD-25',
    label: 'Jhalakathi',
    country: 'BD-A',
  },
  {
    code: 'BD-26',
    label: 'Kishoreganj',
    country: 'BD-C',
  },
  {
    code: 'BD-27',
    label: 'Khulna',
    country: 'BD-D',
  },
  {
    code: 'BD-28',
    label: 'Kurigram',
    country: 'BD-F',
  },
  {
    code: 'BD-29',
    label: 'Khagrachhari',
    country: 'BD-B',
  },
  {
    code: 'BD-30',
    label: 'Kushtia',
    country: 'BD-D',
  },
  {
    code: 'BD-31',
    label: 'Lakshmipur',
    country: 'BD-B',
  },
  {
    code: 'BD-32',
    label: 'Lalmonirhat',
    country: 'BD-F',
  },
  {
    code: 'BD-33',
    label: 'Manikganj',
    country: 'BD-C',
  },
  {
    code: 'BD-34',
    label: 'Mymensingh',
    country: 'BD-H',
  },
  {
    code: 'BD-35',
    label: 'Munshiganj',
    country: 'BD-C',
  },
  {
    code: 'BD-36',
    label: 'Madaripur',
    country: 'BD-C',
  },
  {
    code: 'BD-37',
    label: 'Magura',
    country: 'BD-D',
  },
  {
    code: 'BD-38',
    label: 'Moulvibazar',
    country: 'BD-G',
  },
  {
    code: 'BD-39',
    label: 'Meherpur',
    country: 'BD-D',
  },
  {
    code: 'BD-40',
    label: 'Narayanganj',
    country: 'BD-C',
  },
  {
    code: 'BD-41',
    label: 'Netrakona',
    country: 'BD-H',
  },
  {
    code: 'BD-42',
    label: 'Narsingdi',
    country: 'BD-C',
  },
  {
    code: 'BD-43',
    label: 'Narail',
    country: 'BD-D',
  },
  {
    code: 'BD-44',
    label: 'Natore',
    country: 'BD-E',
  },
  {
    code: 'BD-45',
    label: 'Chapai Nawabganj',
    country: 'BD-E',
  },
  {
    code: 'BD-46',
    label: 'Nilphamari',
    country: 'BD-F',
  },
  {
    code: 'BD-47',
    label: 'Noakhali',
    country: 'BD-B',
  },
  {
    code: 'BD-48',
    label: 'Naogaon',
    country: 'BD-E',
  },
  {
    code: 'BD-49',
    label: 'Pabna',
    country: 'BD-E',
  },
  {
    code: 'BD-50',
    label: 'Pirojpur',
    country: 'BD-A',
  },
  {
    code: 'BD-51',
    label: 'Patuakhali',
    country: 'BD-A',
  },
  {
    code: 'BD-52',
    label: 'Panchagarh',
    country: 'BD-F',
  },
  {
    code: 'BD-53',
    label: 'Rajbari',
    country: 'BD-C',
  },
  {
    code: 'BD-54',
    label: 'Rajshahi',
    country: 'BD-E',
  },
  {
    code: 'BD-55',
    label: 'Rangpur',
    country: 'BD-F',
  },
  {
    code: 'BD-56',
    label: 'Rangamati',
    country: 'BD-B',
  },
  {
    code: 'BD-57',
    label: 'Sherpur',
    country: 'BD-H',
  },
  {
    code: 'BD-58',
    label: 'Satkhira',
    country: 'BD-D',
  },
  {
    code: 'BD-59',
    label: 'Sirajganj',
    country: 'BD-E',
  },
  {
    code: 'BD-60',
    label: 'Sylhet',
    country: 'BD-G',
  },
  {
    code: 'BD-61',
    label: 'Sunamganj',
    country: 'BD-G',
  },
  {
    code: 'BD-62',
    label: 'Shariatpur',
    country: 'BD-C',
  },
  {
    code: 'BD-63',
    label: 'Tangail',
    country: 'BD-C',
  },
  {
    code: 'BD-64',
    label: 'Thakurgaon',
    country: 'BD-F',
  },
  {
    code: 'BD-A',
    label: 'Barishal',
    country: 'BD',
  },
  {
    code: 'BD-B',
    label: 'Chattogram',
    country: 'BD',
  },
  {
    code: 'BD-C',
    label: 'Dhaka',
    country: 'BD',
  },
  {
    code: 'BD-D',
    label: 'Khulna',
    country: 'BD',
  },
  {
    code: 'BD-E',
    label: 'Rajshahi',
    country: 'BD',
  },
  {
    code: 'BD-F',
    label: 'Rangpur',
    country: 'BD',
  },
  {
    code: 'BD-G',
    label: 'Sylhet',
    country: 'BD',
  },
  {
    code: 'BD-H',
    label: 'Mymensingh',
    country: 'BD',
  },
  {
    code: 'BE-BRU',
    label: 'Brussels Hoofdstedelijk Gewest',
    country: 'BE',
  },
  {
    code: 'BE-VAN',
    label: 'Antwerpen',
    country: 'BE-VLG',
  },
  {
    code: 'BE-VBR',
    label: 'Vlaams-Brabant',
    country: 'BE-VLG',
  },
  {
    code: 'BE-VLG',
    label: 'Vlaams Gewest',
    country: 'BE',
  },
  {
    code: 'BE-VLI',
    label: 'Limburg',
    country: 'BE-VLG',
  },
  {
    code: 'BE-VOV',
    label: 'Oost-Vlaanderen',
    country: 'BE-VLG',
  },
  {
    code: 'BE-VWV',
    label: 'West-Vlaanderen',
    country: 'BE-VLG',
  },
  {
    code: 'BE-WAL',
    label: 'Waals Gewest',
    country: 'BE',
  },
  {
    code: 'BE-WBR',
    label: 'Brabant wallon',
    country: 'BE-WAL',
  },
  {
    code: 'BE-WHT',
    label: 'Hainaut',
    country: 'BE-WAL',
  },
  {
    code: 'BE-WLG',
    label: 'Liège',
    country: 'BE-WAL',
  },
  {
    code: 'BE-WLX',
    label: 'Luxembourg',
    country: 'BE-WAL',
  },
  {
    code: 'BE-WNA',
    label: 'Namur',
    country: 'BE-WAL',
  },
  {
    code: 'BF-01',
    label: 'Boucle du Mouhoun',
    country: 'BF',
  },
  {
    code: 'BF-02',
    label: 'Cascades',
    country: 'BF',
  },
  {
    code: 'BF-03',
    label: 'Centre',
    country: 'BF',
  },
  {
    code: 'BF-04',
    label: 'Centre-Est',
    country: 'BF',
  },
  {
    code: 'BF-05',
    label: 'Centre-Nord',
    country: 'BF',
  },
  {
    code: 'BF-06',
    label: 'Centre-Ouest',
    country: 'BF',
  },
  {
    code: 'BF-07',
    label: 'Centre-Sud',
    country: 'BF',
  },
  {
    code: 'BF-08',
    label: 'Est',
    country: 'BF',
  },
  {
    code: 'BF-09',
    label: 'Hauts-Bassins',
    country: 'BF',
  },
  {
    code: 'BF-10',
    label: 'Nord',
    country: 'BF',
  },
  {
    code: 'BF-11',
    label: 'Plateau-Central',
    country: 'BF',
  },
  {
    code: 'BF-12',
    label: 'Sahel',
    country: 'BF',
  },
  {
    code: 'BF-13',
    label: 'Sud-Ouest',
    country: 'BF',
  },
  {
    code: 'BF-BAL',
    label: 'Balé',
    country: 'BF-01',
  },
  {
    code: 'BF-BAM',
    label: 'Bam',
    country: 'BF-05',
  },
  {
    code: 'BF-BAN',
    label: 'Banwa',
    country: 'BF-01',
  },
  {
    code: 'BF-BAZ',
    label: 'Bazèga',
    country: 'BF-07',
  },
  {
    code: 'BF-BGR',
    label: 'Bougouriba',
    country: 'BF-13',
  },
  {
    code: 'BF-BLG',
    label: 'Boulgou',
    country: 'BF-04',
  },
  {
    code: 'BF-BLK',
    label: 'Boulkiemdé',
    country: 'BF-06',
  },
  {
    code: 'BF-COM',
    label: 'Comoé',
    country: 'BF-02',
  },
  {
    code: 'BF-GAN',
    label: 'Ganzourgou',
    country: 'BF-11',
  },
  {
    code: 'BF-GNA',
    label: 'Gnagna',
    country: 'BF-08',
  },
  {
    code: 'BF-GOU',
    label: 'Gourma',
    country: 'BF-08',
  },
  {
    code: 'BF-HOU',
    label: 'Houet',
    country: 'BF-09',
  },
  {
    code: 'BF-IOB',
    label: 'Ioba',
    country: 'BF-13',
  },
  {
    code: 'BF-KAD',
    label: 'Kadiogo',
    country: 'BF-03',
  },
  {
    code: 'BF-KEN',
    label: 'Kénédougou',
    country: 'BF-09',
  },
  {
    code: 'BF-KMD',
    label: 'Komondjari',
    country: 'BF-08',
  },
  {
    code: 'BF-KMP',
    label: 'Kompienga',
    country: 'BF-08',
  },
  {
    code: 'BF-KOP',
    label: 'Koulpélogo',
    country: 'BF-04',
  },
  {
    code: 'BF-KOS',
    label: 'Kossi',
    country: 'BF-01',
  },
  {
    code: 'BF-KOT',
    label: 'Kouritenga',
    country: 'BF-04',
  },
  {
    code: 'BF-KOW',
    label: 'Kourwéogo',
    country: 'BF-11',
  },
  {
    code: 'BF-LER',
    label: 'Léraba',
    country: 'BF-02',
  },
  {
    code: 'BF-LOR',
    label: 'Loroum',
    country: 'BF-10',
  },
  {
    code: 'BF-MOU',
    label: 'Mouhoun',
    country: 'BF-01',
  },
  {
    code: 'BF-NAM',
    label: 'labelntenga',
    country: 'BF-05',
  },
  {
    code: 'BF-NAO',
    label: 'Nahouri',
    country: 'BF-07',
  },
  {
    code: 'BF-NAY',
    label: 'Nayala',
    country: 'BF-01',
  },
  {
    code: 'BF-NOU',
    label: 'Noumbiel',
    country: 'BF-13',
  },
  {
    code: 'BF-OUB',
    label: 'Oubritenga',
    country: 'BF-11',
  },
  {
    code: 'BF-OUD',
    label: 'Oudalan',
    country: 'BF-12',
  },
  {
    code: 'BF-PAS',
    label: 'Passoré',
    country: 'BF-10',
  },
  {
    code: 'BF-PON',
    label: 'Poni',
    country: 'BF-13',
  },
  {
    code: 'BF-SEN',
    label: 'Séno',
    country: 'BF-12',
  },
  {
    code: 'BF-SIS',
    label: 'Sissili',
    country: 'BF-06',
  },
  {
    code: 'BF-SMT',
    label: 'Sanmatenga',
    country: 'BF-05',
  },
  {
    code: 'BF-SNG',
    label: 'Sanguié',
    country: 'BF-06',
  },
  {
    code: 'BF-SOM',
    label: 'Soum',
    country: 'BF-12',
  },
  {
    code: 'BF-SOR',
    label: 'Sourou',
    country: 'BF-01',
  },
  {
    code: 'BF-TAP',
    label: 'Tapoa',
    country: 'BF-08',
  },
  {
    code: 'BF-TUI',
    label: 'Tuy',
    country: 'BF-09',
  },
  {
    code: 'BF-YAG',
    label: 'Yagha',
    country: 'BF-12',
  },
  {
    code: 'BF-YAT',
    label: 'Yatenga',
    country: 'BF-10',
  },
  {
    code: 'BF-ZIR',
    label: 'Ziro',
    country: 'BF-06',
  },
  {
    code: 'BF-ZON',
    label: 'Zondoma',
    country: 'BF-10',
  },
  {
    code: 'BF-ZOU',
    label: 'Zoundwéogo',
    country: 'BF-07',
  },
  {
    code: 'BG-01',
    label: 'Blagoevgrad',
    country: 'BG',
  },
  {
    code: 'BG-02',
    label: 'Burgas',
    country: 'BG',
  },
  {
    code: 'BG-03',
    label: 'Varna',
    country: 'BG',
  },
  {
    code: 'BG-04',
    label: 'Veliko Tarnovo',
    country: 'BG',
  },
  {
    code: 'BG-05',
    label: 'Vidin',
    country: 'BG',
  },
  {
    code: 'BG-06',
    label: 'Vratsa',
    country: 'BG',
  },
  {
    code: 'BG-07',
    label: 'Gabrovo',
    country: 'BG',
  },
  {
    code: 'BG-08',
    label: 'Dobrich',
    country: 'BG',
  },
  {
    code: 'BG-09',
    label: 'Kardzhali',
    country: 'BG',
  },
  {
    code: 'BG-10',
    label: 'Kyustendil',
    country: 'BG',
  },
  {
    code: 'BG-11',
    label: 'Lovech',
    country: 'BG',
  },
  {
    code: 'BG-12',
    label: 'Montana',
    country: 'BG',
  },
  {
    code: 'BG-13',
    label: 'Pazardzhik',
    country: 'BG',
  },
  {
    code: 'BG-14',
    label: 'Pernik',
    country: 'BG',
  },
  {
    code: 'BG-15',
    label: 'Pleven',
    country: 'BG',
  },
  {
    code: 'BG-16',
    label: 'Plovdiv',
    country: 'BG',
  },
  {
    code: 'BG-17',
    label: 'Razgrad',
    country: 'BG',
  },
  {
    code: 'BG-18',
    label: 'Ruse',
    country: 'BG',
  },
  {
    code: 'BG-19',
    label: 'Silistra',
    country: 'BG',
  },
  {
    code: 'BG-20',
    label: 'Sliven',
    country: 'BG',
  },
  {
    code: 'BG-21',
    label: 'Smolyan',
    country: 'BG',
  },
  {
    code: 'BG-22',
    label: 'Sofia (stolitsa)',
    country: 'BG',
  },
  {
    code: 'BG-23',
    label: 'Sofia',
    country: 'BG',
  },
  {
    code: 'BG-24',
    label: 'Stara Zagora',
    country: 'BG',
  },
  {
    code: 'BG-25',
    label: 'Targovishte',
    country: 'BG',
  },
  {
    code: 'BG-26',
    label: 'Haskovo',
    country: 'BG',
  },
  {
    code: 'BG-27',
    label: 'Shumen',
    country: 'BG',
  },
  {
    code: 'BG-28',
    label: 'Yambol',
    country: 'BG',
  },
  {
    code: 'BH-13',
    label: 'Al ‘Āşimah',
    country: 'BH',
  },
  {
    code: 'BH-14',
    label: 'Al Janūbīyah',
    country: 'BH',
  },
  {
    code: 'BH-15',
    label: 'Al Muḩarraq',
    country: 'BH',
  },
  {
    code: 'BH-17',
    label: 'Ash Shamālīyah',
    country: 'BH',
  },
  {
    code: 'BI-BB',
    label: 'Bubanza',
    country: 'BI',
  },
  {
    code: 'BI-BL',
    label: 'Bujumbura Rural',
    country: 'BI',
  },
  {
    code: 'BI-BM',
    label: 'Bujumbura Mairie',
    country: 'BI',
  },
  {
    code: 'BI-BR',
    label: 'Bururi',
    country: 'BI',
  },
  {
    code: 'BI-CA',
    label: 'Cankuzo',
    country: 'BI',
  },
  {
    code: 'BI-CI',
    label: 'Cibitoke',
    country: 'BI',
  },
  {
    code: 'BI-GI',
    label: 'Gitega',
    country: 'BI',
  },
  {
    code: 'BI-KI',
    label: 'Kirundo',
    country: 'BI',
  },
  {
    code: 'BI-KR',
    label: 'Karuzi',
    country: 'BI',
  },
  {
    code: 'BI-KY',
    label: 'Kayanza',
    country: 'BI',
  },
  {
    code: 'BI-MA',
    label: 'Makamba',
    country: 'BI',
  },
  {
    code: 'BI-MU',
    label: 'Muramvya',
    country: 'BI',
  },
  {
    code: 'BI-MW',
    label: 'Mwaro',
    country: 'BI',
  },
  {
    code: 'BI-MY',
    label: 'Muyinga',
    country: 'BI',
  },
  {
    code: 'BI-NG',
    label: 'Ngozi',
    country: 'BI',
  },
  {
    code: 'BI-RM',
    label: 'Rumonge',
    country: 'BI',
  },
  {
    code: 'BI-RT',
    label: 'Rutana',
    country: 'BI',
  },
  {
    code: 'BI-RY',
    label: 'Ruyigi',
    country: 'BI',
  },
  {
    code: 'BJ-AK',
    label: 'Atacora',
    country: 'BJ',
  },
  {
    code: 'BJ-AL',
    label: 'Alibori',
    country: 'BJ',
  },
  {
    code: 'BJ-AQ',
    label: 'Atlantique',
    country: 'BJ',
  },
  {
    code: 'BJ-BO',
    label: 'Borgou',
    country: 'BJ',
  },
  {
    code: 'BJ-CO',
    label: 'Collines',
    country: 'BJ',
  },
  {
    code: 'BJ-DO',
    label: 'Donga',
    country: 'BJ',
  },
  {
    code: 'BJ-KO',
    label: 'Couffo',
    country: 'BJ',
  },
  {
    code: 'BJ-LI',
    label: 'Littoral',
    country: 'BJ',
  },
  {
    code: 'BJ-MO',
    label: 'Mono',
    country: 'BJ',
  },
  {
    code: 'BJ-OU',
    label: 'Ouémé',
    country: 'BJ',
  },
  {
    code: 'BJ-PL',
    label: 'Plateau',
    country: 'BJ',
  },
  {
    code: 'BJ-ZO',
    label: 'Zou',
    country: 'BJ',
  },
  {
    code: 'BN-BE',
    label: 'Belait',
    country: 'BN',
  },
  {
    code: 'BN-BM',
    label: 'Brunei-Muara',
    country: 'BN',
  },
  {
    code: 'BN-TE',
    label: 'Temburong',
    country: 'BN',
  },
  {
    code: 'BN-TU',
    label: 'Tutong',
    country: 'BN',
  },
  {
    code: 'BO-B',
    label: 'El Beni',
    country: 'BO',
  },
  {
    code: 'BO-C',
    label: 'Cochabamba',
    country: 'BO',
  },
  {
    code: 'BO-H',
    label: 'Chuquisaca',
    country: 'BO',
  },
  {
    code: 'BO-L',
    label: 'La Paz',
    country: 'BO',
  },
  {
    code: 'BO-N',
    label: 'Pando',
    country: 'BO',
  },
  {
    code: 'BO-O',
    label: 'Oruro',
    country: 'BO',
  },
  {
    code: 'BO-P',
    label: 'Potosí',
    country: 'BO',
  },
  {
    code: 'BO-S',
    label: 'Santa Cruz',
    country: 'BO',
  },
  {
    code: 'BO-T',
    label: 'Tarija',
    country: 'BO',
  },
  {
    code: 'BQ-BO',
    label: 'Bonaire',
    country: 'BQ',
  },
  {
    code: 'BQ-SA',
    label: 'Saba',
    country: 'BQ',
  },
  {
    code: 'BQ-SE',
    label: 'Sint Eustatius',
    country: 'BQ',
  },
  {
    code: 'BR-AC',
    label: 'Acre',
    country: 'BR',
  },
  {
    code: 'BR-AL',
    label: 'Alagoas',
    country: 'BR',
  },
  {
    code: 'BR-AM',
    label: 'Amazonas',
    country: 'BR',
  },
  {
    code: 'BR-AP',
    label: 'Amapá',
    country: 'BR',
  },
  {
    code: 'BR-BA',
    label: 'Bahia',
    country: 'BR',
  },
  {
    code: 'BR-CE',
    label: 'Ceará',
    country: 'BR',
  },
  {
    code: 'BR-DF',
    label: 'Distrito Federal',
    country: 'BR',
  },
  {
    code: 'BR-ES',
    label: 'Espírito Santo',
    country: 'BR',
  },
  {
    code: 'BR-GO',
    label: 'Goiás',
    country: 'BR',
  },
  {
    code: 'BR-MA',
    label: 'Maranhão',
    country: 'BR',
  },
  {
    code: 'BR-MG',
    label: 'Minas Gerais',
    country: 'BR',
  },
  {
    code: 'BR-MS',
    label: 'Mato Grosso do Sul',
    country: 'BR',
  },
  {
    code: 'BR-MT',
    label: 'Mato Grosso',
    country: 'BR',
  },
  {
    code: 'BR-PA',
    label: 'Pará',
    country: 'BR',
  },
  {
    code: 'BR-PB',
    label: 'Paraíba',
    country: 'BR',
  },
  {
    code: 'BR-PE',
    label: 'Pernambuco',
    country: 'BR',
  },
  {
    code: 'BR-PI',
    label: 'Piauí',
    country: 'BR',
  },
  {
    code: 'BR-PR',
    label: 'Paraná',
    country: 'BR',
  },
  {
    code: 'BR-RJ',
    label: 'Rio de Janeiro',
    country: 'BR',
  },
  {
    code: 'BR-RN',
    label: 'Rio Grande do Norte',
    country: 'BR',
  },
  {
    code: 'BR-RO',
    label: 'Rondônia',
    country: 'BR',
  },
  {
    code: 'BR-RR',
    label: 'Roraima',
    country: 'BR',
  },
  {
    code: 'BR-RS',
    label: 'Rio Grande do Sul',
    country: 'BR',
  },
  {
    code: 'BR-SC',
    label: 'Santa Catarina',
    country: 'BR',
  },
  {
    code: 'BR-SE',
    label: 'Sergipe',
    country: 'BR',
  },
  {
    code: 'BR-SP',
    label: 'São Paulo',
    country: 'BR',
  },
  {
    code: 'BR-TO',
    label: 'Tocantins',
    country: 'BR',
  },
  {
    code: 'BS-AK',
    label: 'Acklins',
    country: 'BS',
  },
  {
    code: 'BS-BI',
    label: 'Bimini',
    country: 'BS',
  },
  {
    code: 'BS-BP',
    label: 'Black Point',
    country: 'BS',
  },
  {
    code: 'BS-BY',
    label: 'Berry Islands',
    country: 'BS',
  },
  {
    code: 'BS-CE',
    label: 'Central Eleuthera',
    country: 'BS',
  },
  {
    code: 'BS-CI',
    label: 'Cat Island',
    country: 'BS',
  },
  {
    code: 'BS-CK',
    label: 'Crooked Island and Long Cay',
    country: 'BS',
  },
  {
    code: 'BS-CO',
    label: 'Central Abaco',
    country: 'BS',
  },
  {
    code: 'BS-CS',
    label: 'Central Andros',
    country: 'BS',
  },
  {
    code: 'BS-EG',
    label: 'East Grand Bahama',
    country: 'BS',
  },
  {
    code: 'BS-EX',
    label: 'Exuma',
    country: 'BS',
  },
  {
    code: 'BS-FP',
    label: 'City of Freeport',
    country: 'BS',
  },
  {
    code: 'BS-GC',
    label: 'Grand Cay',
    country: 'BS',
  },
  {
    code: 'BS-HI',
    label: 'Harbour Island',
    country: 'BS',
  },
  {
    code: 'BS-HT',
    label: 'Hope Town',
    country: 'BS',
  },
  {
    code: 'BS-IN',
    label: 'Inagua',
    country: 'BS',
  },
  {
    code: 'BS-LI',
    label: 'Long Island',
    country: 'BS',
  },
  {
    code: 'BS-MC',
    label: 'Mangrove Cay',
    country: 'BS',
  },
  {
    code: 'BS-MG',
    label: 'Mayaguana',
    country: 'BS',
  },
  {
    code: 'BS-MI',
    label: "Moore's Island",
    country: 'BS',
  },
  {
    code: 'BS-NE',
    label: 'North Eleuthera',
    country: 'BS',
  },
  {
    code: 'BS-NO',
    label: 'North Abaco',
    country: 'BS',
  },
  {
    code: 'BS-NP',
    label: 'New Providence',
    country: 'BS',
  },
  {
    code: 'BS-NS',
    label: 'North Andros',
    country: 'BS',
  },
  {
    code: 'BS-RC',
    label: 'Rum Cay',
    country: 'BS',
  },
  {
    code: 'BS-RI',
    label: 'Ragged Island',
    country: 'BS',
  },
  {
    code: 'BS-SA',
    label: 'South Andros',
    country: 'BS',
  },
  {
    code: 'BS-SE',
    label: 'South Eleuthera',
    country: 'BS',
  },
  {
    code: 'BS-SO',
    label: 'South Abaco',
    country: 'BS',
  },
  {
    code: 'BS-SS',
    label: 'San Salvador',
    country: 'BS',
  },
  {
    code: 'BS-SW',
    label: 'Spanish Wells',
    country: 'BS',
  },
  {
    code: 'BS-WG',
    label: 'West Grand Bahama',
    country: 'BS',
  },
  {
    code: 'BT-11',
    label: 'Paro',
    country: 'BT',
  },
  {
    code: 'BT-12',
    label: 'Chhukha',
    country: 'BT',
  },
  {
    code: 'BT-13',
    label: 'Haa',
    country: 'BT',
  },
  {
    code: 'BT-14',
    label: 'Samtse',
    country: 'BT',
  },
  {
    code: 'BT-15',
    label: 'Thimphu',
    country: 'BT',
  },
  {
    code: 'BT-21',
    label: 'Tsirang',
    country: 'BT',
  },
  {
    code: 'BT-22',
    label: 'Dagana',
    country: 'BT',
  },
  {
    code: 'BT-23',
    label: 'Punakha',
    country: 'BT',
  },
  {
    code: 'BT-24',
    label: 'Wangdue Phodrang',
    country: 'BT',
  },
  {
    code: 'BT-31',
    label: 'Sarpang',
    country: 'BT',
  },
  {
    code: 'BT-32',
    label: 'Trongsa',
    country: 'BT',
  },
  {
    code: 'BT-33',
    label: 'Bumthang',
    country: 'BT',
  },
  {
    code: 'BT-34',
    label: 'Zhemgang',
    country: 'BT',
  },
  {
    code: 'BT-41',
    label: 'Trashigang',
    country: 'BT',
  },
  {
    code: 'BT-42',
    label: 'Monggar',
    country: 'BT',
  },
  {
    code: 'BT-43',
    label: 'Pema Gatshel',
    country: 'BT',
  },
  {
    code: 'BT-44',
    label: 'Lhuentse',
    country: 'BT',
  },
  {
    code: 'BT-45',
    label: 'Samdrup Jongkhar',
    country: 'BT',
  },
  {
    code: 'BT-GA',
    label: 'Gasa',
    country: 'BT',
  },
  {
    code: 'BT-TY',
    label: 'Trashi Yangtse',
    country: 'BT',
  },
  {
    code: 'BW-CE',
    label: 'Central',
    country: 'BW',
  },
  {
    code: 'BW-CH',
    label: 'Chobe',
    country: 'BW',
  },
  {
    code: 'BW-FR',
    label: 'Francistown',
    country: 'BW',
  },
  {
    code: 'BW-GA',
    label: 'Gaborone',
    country: 'BW',
  },
  {
    code: 'BW-GH',
    label: 'Ghanzi',
    country: 'BW',
  },
  {
    code: 'BW-JW',
    label: 'Jwaneng',
    country: 'BW',
  },
  {
    code: 'BW-KG',
    label: 'Kgalagadi',
    country: 'BW',
  },
  {
    code: 'BW-KL',
    label: 'Kgatleng',
    country: 'BW',
  },
  {
    code: 'BW-KW',
    label: 'Kweneng',
    country: 'BW',
  },
  {
    code: 'BW-LO',
    label: 'Lobatse',
    country: 'BW',
  },
  {
    code: 'BW-NE',
    label: 'North East',
    country: 'BW',
  },
  {
    code: 'BW-NW',
    label: 'North West',
    country: 'BW',
  },
  {
    code: 'BW-SE',
    label: 'South East',
    country: 'BW',
  },
  {
    code: 'BW-SO',
    label: 'Southern',
    country: 'BW',
  },
  {
    code: 'BW-SP',
    label: 'Selibe Phikwe',
    country: 'BW',
  },
  {
    code: 'BW-ST',
    label: 'Sowa Town',
    country: 'BW',
  },
  {
    code: 'BY-BR',
    label: "Brestskaya voblasts'",
    country: 'BY',
  },
  {
    code: 'BY-HM',
    label: 'Horad Minsk',
    country: 'BY',
  },
  {
    code: 'BY-HO',
    label: "Homyel'skaya voblasts'",
    country: 'BY',
  },
  {
    code: 'BY-HR',
    label: "Hrodzyenskaya voblasts'",
    country: 'BY',
  },
  {
    code: 'BY-MA',
    label: "Mahilyowskaya voblasts'",
    country: 'BY',
  },
  {
    code: 'BY-MI',
    label: "Minskaya voblasts'",
    country: 'BY',
  },
  {
    code: 'BY-VI',
    label: "Vitsyebskaya voblasts'",
    country: 'BY',
  },
  {
    code: 'BZ-BZ',
    label: 'Belize',
    country: 'BZ',
  },
  {
    code: 'BZ-CY',
    label: 'Cayo',
    country: 'BZ',
  },
  {
    code: 'BZ-CZL',
    label: 'Corozal',
    country: 'BZ',
  },
  {
    code: 'BZ-OW',
    label: 'Orange Walk',
    country: 'BZ',
  },
  {
    code: 'BZ-SC',
    label: 'Stann Creek',
    country: 'BZ',
  },
  {
    code: 'BZ-TOL',
    label: 'Toledo',
    country: 'BZ',
  },
  {
    code: 'CA-AB',
    label: 'Alberta',
    country: 'CA',
  },
  {
    code: 'CA-BC',
    label: 'British Columbia',
    country: 'CA',
  },
  {
    code: 'CA-MB',
    label: 'Manitoba',
    country: 'CA',
  },
  {
    code: 'CA-NB',
    label: 'New Brunswick',
    country: 'CA',
  },
  {
    code: 'CA-NL',
    label: 'Newfoundland and Labrador',
    country: 'CA',
  },
  {
    code: 'CA-NS',
    label: 'Nova Scotia',
    country: 'CA',
  },
  {
    code: 'CA-NT',
    label: 'Northwest Territories',
    country: 'CA',
  },
  {
    code: 'CA-NU',
    label: 'Nunavut',
    country: 'CA',
  },
  {
    code: 'CA-ON',
    label: 'Ontario',
    country: 'CA',
  },
  {
    code: 'CA-PE',
    label: 'Prince Edward Island',
    country: 'CA',
  },
  {
    code: 'CA-QC',
    label: 'Quebec',
    country: 'CA',
  },
  {
    code: 'CA-SK',
    label: 'Saskatchewan',
    country: 'CA',
  },
  {
    code: 'CA-YT',
    label: 'Yukon',
    country: 'CA',
  },
  {
    code: 'CD-BC',
    label: 'Kongo Central',
    country: 'CD',
  },
  {
    code: 'CD-BU',
    label: 'Bas-Uélé',
    country: 'CD',
  },
  {
    code: 'CD-EQ',
    label: 'Équateur',
    country: 'CD',
  },
  {
    code: 'CD-HK',
    label: 'Haut-Katanga',
    country: 'CD',
  },
  {
    code: 'CD-HL',
    label: 'Haut-Lomami',
    country: 'CD',
  },
  {
    code: 'CD-HU',
    label: 'Haut-Uélé',
    country: 'CD',
  },
  {
    code: 'CD-IT',
    label: 'Ituri',
    country: 'CD',
  },
  {
    code: 'CD-KC',
    label: 'Kasaï Central',
    country: 'CD',
  },
  {
    code: 'CD-KE',
    label: 'Kasaï Oriental',
    country: 'CD',
  },
  {
    code: 'CD-KG',
    label: 'Kwango',
    country: 'CD',
  },
  {
    code: 'CD-KL',
    label: 'Kwilu',
    country: 'CD',
  },
  {
    code: 'CD-KN',
    label: 'Kinshasa',
    country: 'CD',
  },
  {
    code: 'CD-KS',
    label: 'Kasaï',
    country: 'CD',
  },
  {
    code: 'CD-LO',
    label: 'Lomami',
    country: 'CD',
  },
  {
    code: 'CD-LU',
    label: 'Lualaba',
    country: 'CD',
  },
  {
    code: 'CD-MA',
    label: 'Maniema',
    country: 'CD',
  },
  {
    code: 'CD-MN',
    label: 'Mai-Ndombe',
    country: 'CD',
  },
  {
    code: 'CD-MO',
    label: 'Mongala',
    country: 'CD',
  },
  {
    code: 'CD-NK',
    label: 'Nord-Kivu',
    country: 'CD',
  },
  {
    code: 'CD-NU',
    label: 'Nord-Ubangi',
    country: 'CD',
  },
  {
    code: 'CD-SA',
    label: 'Sankuru',
    country: 'CD',
  },
  {
    code: 'CD-SK',
    label: 'Sud-Kivu',
    country: 'CD',
  },
  {
    code: 'CD-SU',
    label: 'Sud-Ubangi',
    country: 'CD',
  },
  {
    code: 'CD-TA',
    label: 'Tanganyika',
    country: 'CD',
  },
  {
    code: 'CD-TO',
    label: 'Tshopo',
    country: 'CD',
  },
  {
    code: 'CD-TU',
    label: 'Tshuapa',
    country: 'CD',
  },
  {
    code: 'CF-AC',
    label: 'Ouham',
    country: 'CF',
  },
  {
    code: 'CF-BB',
    label: 'Bamingui-Bangoran',
    country: 'CF',
  },
  {
    code: 'CF-BGF',
    label: 'Bangui',
    country: 'CF',
  },
  {
    code: 'CF-BK',
    label: 'Basse-Kotto',
    country: 'CF',
  },
  {
    code: 'CF-HK',
    label: 'Haute-Kotto',
    country: 'CF',
  },
  {
    code: 'CF-HM',
    label: 'Haut-Mbomou',
    country: 'CF',
  },
  {
    code: 'CF-HS',
    label: 'Haute-Sangha/Mambéré-Kadéï',
    country: 'CF',
  },
  {
    code: 'CF-KB',
    label: 'Gribingui',
    country: 'CF',
  },
  {
    code: 'CF-KG',
    label: 'Kémo-Gribingui',
    country: 'CF',
  },
  {
    code: 'CF-LB',
    label: 'Lobaye',
    country: 'CF',
  },
  {
    code: 'CF-MB',
    label: 'Mbomou',
    country: 'CF',
  },
  {
    code: 'CF-MP',
    label: 'Ombella-Mpoko',
    country: 'CF',
  },
  {
    code: 'CF-NM',
    label: 'Nana-Mambéré',
    country: 'CF',
  },
  {
    code: 'CF-OP',
    label: 'Ouham-Pendé',
    country: 'CF',
  },
  {
    code: 'CF-SE',
    label: 'Sangha',
    country: 'CF',
  },
  {
    code: 'CF-UK',
    label: 'Ouaka',
    country: 'CF',
  },
  {
    code: 'CF-VK',
    label: 'Vakaga',
    country: 'CF',
  },
  {
    code: 'CG-11',
    label: 'Bouenza',
    country: 'CG',
  },
  {
    code: 'CG-12',
    label: 'Pool',
    country: 'CG',
  },
  {
    code: 'CG-13',
    label: 'Sangha',
    country: 'CG',
  },
  {
    code: 'CG-14',
    label: 'Plateaux',
    country: 'CG',
  },
  {
    code: 'CG-15',
    label: 'Cuvette-Ouest',
    country: 'CG',
  },
  {
    code: 'CG-16',
    label: 'Pointe-Noire',
    country: 'CG',
  },
  {
    code: 'CG-2',
    label: 'Lékoumou',
    country: 'CG',
  },
  {
    code: 'CG-5',
    label: 'Kouilou',
    country: 'CG',
  },
  {
    code: 'CG-7',
    label: 'Likouala',
    country: 'CG',
  },
  {
    code: 'CG-8',
    label: 'Cuvette',
    country: 'CG',
  },
  {
    code: 'CG-9',
    label: 'Niari',
    country: 'CG',
  },
  {
    code: 'CG-BZV',
    label: 'Brazzaville',
    country: 'CG',
  },
  {
    code: 'CH-AG',
    label: 'Aargau',
    country: 'CH',
  },
  {
    code: 'CH-AI',
    label: 'Appenzell Innerrhoden',
    country: 'CH',
  },
  {
    code: 'CH-AR',
    label: 'Appenzell Ausserrhoden',
    country: 'CH',
  },
  {
    code: 'CH-BE',
    label: 'Bern',
    country: 'CH',
  },
  {
    code: 'CH-BL',
    label: 'Basel-Landschaft',
    country: 'CH',
  },
  {
    code: 'CH-BS',
    label: 'Basel-Stadt',
    country: 'CH',
  },
  {
    code: 'CH-FR',
    label: 'Fribourg',
    country: 'CH',
  },
  {
    code: 'CH-GE',
    label: 'Genève',
    country: 'CH',
  },
  {
    code: 'CH-GL',
    label: 'Glarus',
    country: 'CH',
  },
  {
    code: 'CH-GR',
    label: 'Graubünden',
    country: 'CH',
  },
  {
    code: 'CH-JU',
    label: 'Jura',
    country: 'CH',
  },
  {
    code: 'CH-LU',
    label: 'Luzern',
    country: 'CH',
  },
  {
    code: 'CH-NE',
    label: 'Neuchâtel',
    country: 'CH',
  },
  {
    code: 'CH-NW',
    label: 'Nidwalden',
    country: 'CH',
  },
  {
    code: 'CH-OW',
    label: 'Obwalden',
    country: 'CH',
  },
  {
    code: 'CH-SG',
    label: 'Sankt Gallen',
    country: 'CH',
  },
  {
    code: 'CH-SH',
    label: 'Schaffhausen',
    country: 'CH',
  },
  {
    code: 'CH-SO',
    label: 'Solothurn',
    country: 'CH',
  },
  {
    code: 'CH-SZ',
    label: 'Schwyz',
    country: 'CH',
  },
  {
    code: 'CH-TG',
    label: 'Thurgau',
    country: 'CH',
  },
  {
    code: 'CH-TI',
    label: 'Ticino',
    country: 'CH',
  },
  {
    code: 'CH-UR',
    label: 'Uri',
    country: 'CH',
  },
  {
    code: 'CH-VD',
    label: 'Vaud',
    country: 'CH',
  },
  {
    code: 'CH-VS',
    label: 'Valais',
    country: 'CH',
  },
  {
    code: 'CH-ZG',
    label: 'Zug',
    country: 'CH',
  },
  {
    code: 'CH-ZH',
    label: 'Zürich',
    country: 'CH',
  },
  {
    code: 'CI-AB',
    label: 'Abidjan',
    country: 'CI',
  },
  {
    code: 'CI-BS',
    label: 'Bas-Sassandra',
    country: 'CI',
  },
  {
    code: 'CI-CM',
    label: 'Comoé',
    country: 'CI',
  },
  {
    code: 'CI-DN',
    label: 'Denguélé',
    country: 'CI',
  },
  {
    code: 'CI-GD',
    label: 'Gôh-Djiboua',
    country: 'CI',
  },
  {
    code: 'CI-LC',
    label: 'Lacs',
    country: 'CI',
  },
  {
    code: 'CI-LG',
    label: 'Lagunes',
    country: 'CI',
  },
  {
    code: 'CI-MG',
    label: 'Montagnes',
    country: 'CI',
  },
  {
    code: 'CI-SM',
    label: 'Sassandra-Marahoué',
    country: 'CI',
  },
  {
    code: 'CI-SV',
    label: 'Savanes',
    country: 'CI',
  },
  {
    code: 'CI-VB',
    label: 'Vallée du Bandama',
    country: 'CI',
  },
  {
    code: 'CI-WR',
    label: 'Woroba',
    country: 'CI',
  },
  {
    code: 'CI-YM',
    label: 'Yamoussoukro',
    country: 'CI',
  },
  {
    code: 'CI-ZZ',
    label: 'Zanzan',
    country: 'CI',
  },
  {
    code: 'CL-AI',
    label: 'Aisén del General Carlos Ibañez del Campo',
    country: 'CL',
  },
  {
    code: 'CL-AN',
    label: 'Antofagasta',
    country: 'CL',
  },
  {
    code: 'CL-AP',
    label: 'Arica y Parinacota',
    country: 'CL',
  },
  {
    code: 'CL-AR',
    label: 'La Araucanía',
    country: 'CL',
  },
  {
    code: 'CL-AT',
    label: 'Atacama',
    country: 'CL',
  },
  {
    code: 'CL-BI',
    label: 'Biobío',
    country: 'CL',
  },
  {
    code: 'CL-CO',
    label: 'Coquimbo',
    country: 'CL',
  },
  {
    code: 'CL-LI',
    label: "Libertador General Bernardo O'Higgins",
    country: 'CL',
  },
  {
    code: 'CL-LL',
    label: 'Los Lagos',
    country: 'CL',
  },
  {
    code: 'CL-LR',
    label: 'Los Ríos',
    country: 'CL',
  },
  {
    code: 'CL-MA',
    label: 'Magallanes',
    country: 'CL',
  },
  {
    code: 'CL-ML',
    label: 'Maule',
    country: 'CL',
  },
  {
    code: 'CL-NB',
    label: 'Ñuble',
    country: 'CL',
  },
  {
    code: 'CL-RM',
    label: 'Región Metropolitana de Santiago',
    country: 'CL',
  },
  {
    code: 'CL-TA',
    label: 'Tarapacá',
    country: 'CL',
  },
  {
    code: 'CL-VS',
    label: 'Valparaíso',
    country: 'CL',
  },
  {
    code: 'CM-AD',
    label: 'Adamaoua',
    country: 'CM',
  },
  {
    code: 'CM-CE',
    label: 'Centre',
    country: 'CM',
  },
  {
    code: 'CM-EN',
    label: 'Far North',
    country: 'CM',
  },
  {
    code: 'CM-ES',
    label: 'East',
    country: 'CM',
  },
  {
    code: 'CM-LT',
    label: 'Littoral',
    country: 'CM',
  },
  {
    code: 'CM-NO',
    label: 'North',
    country: 'CM',
  },
  {
    code: 'CM-NW',
    label: 'North-West',
    country: 'CM',
  },
  {
    code: 'CM-OU',
    label: 'West',
    country: 'CM',
  },
  {
    code: 'CM-SU',
    label: 'South',
    country: 'CM',
  },
  {
    code: 'CM-SW',
    label: 'South-West',
    country: 'CM',
  },
  {
    code: 'CN-AH',
    label: 'Anhui Sheng',
    country: 'CN',
  },
  {
    code: 'CN-BJ',
    label: 'Beijing Shi',
    country: 'CN',
  },
  {
    code: 'CN-CQ',
    label: 'Chongqing Shi',
    country: 'CN',
  },
  {
    code: 'CN-FJ',
    label: 'Fujian Sheng',
    country: 'CN',
  },
  {
    code: 'CN-GD',
    label: 'Guangdong Sheng',
    country: 'CN',
  },
  {
    code: 'CN-GS',
    label: 'Gansu Sheng',
    country: 'CN',
  },
  {
    code: 'CN-GX',
    label: 'Guangxi Zhuangzu Zizhiqu',
    country: 'CN',
  },
  {
    code: 'CN-GZ',
    label: 'Guizhou Sheng',
    country: 'CN',
  },
  {
    code: 'CN-HA',
    label: 'Henan Sheng',
    country: 'CN',
  },
  {
    code: 'CN-HB',
    label: 'Hubei Sheng',
    country: 'CN',
  },
  {
    code: 'CN-HE',
    label: 'Hebei Sheng',
    country: 'CN',
  },
  {
    code: 'CN-HI',
    label: 'Hainan Sheng',
    country: 'CN',
  },
  {
    code: 'CN-HK',
    label: 'Hong Kong SAR',
    country: 'CN',
  },
  {
    code: 'CN-HL',
    label: 'Heilongjiang Sheng',
    country: 'CN',
  },
  {
    code: 'CN-HN',
    label: 'Hunan Sheng',
    country: 'CN',
  },
  {
    code: 'CN-JL',
    label: 'Jilin Sheng',
    country: 'CN',
  },
  {
    code: 'CN-JS',
    label: 'Jiangsu Sheng',
    country: 'CN',
  },
  {
    code: 'CN-JX',
    label: 'Jiangxi Sheng',
    country: 'CN',
  },
  {
    code: 'CN-LN',
    label: 'Liaoning Sheng',
    country: 'CN',
  },
  {
    code: 'CN-MO',
    label: 'Macao SAR',
    country: 'CN',
  },
  {
    code: 'CN-NM',
    label: 'Nei Mongol Zizhiqu',
    country: 'CN',
  },
  {
    code: 'CN-NX',
    label: 'Ningxia Huizi Zizhiqu',
    country: 'CN',
  },
  {
    code: 'CN-QH',
    label: 'Qinghai Sheng',
    country: 'CN',
  },
  {
    code: 'CN-SC',
    label: 'Sichuan Sheng',
    country: 'CN',
  },
  {
    code: 'CN-SD',
    label: 'Shandong Sheng',
    country: 'CN',
  },
  {
    code: 'CN-SH',
    label: 'Shanghai Shi',
    country: 'CN',
  },
  {
    code: 'CN-SN',
    label: 'Shaanxi Sheng',
    country: 'CN',
  },
  {
    code: 'CN-SX',
    label: 'Shanxi Sheng',
    country: 'CN',
  },
  {
    code: 'CN-TJ',
    label: 'Tianjin Shi',
    country: 'CN',
  },
  {
    code: 'CN-TW',
    label: 'Taiwan Sheng',
    country: 'CN',
  },
  {
    code: 'CN-XJ',
    label: 'Xinjiang Uygur Zizhiqu',
    country: 'CN',
  },
  {
    code: 'CN-XZ',
    label: 'Xizang Zizhiqu',
    country: 'CN',
  },
  {
    code: 'CN-YN',
    label: 'Yunnan Sheng',
    country: 'CN',
  },
  {
    code: 'CN-ZJ',
    label: 'Zhejiang Sheng',
    country: 'CN',
  },
  {
    code: 'CO-AMA',
    label: 'Amazonas',
    country: 'CO',
  },
  {
    code: 'CO-ANT',
    label: 'Antioquia',
    country: 'CO',
  },
  {
    code: 'CO-ARA',
    label: 'Arauca',
    country: 'CO',
  },
  {
    code: 'CO-ATL',
    label: 'Atlántico',
    country: 'CO',
  },
  {
    code: 'CO-BOL',
    label: 'Bolívar',
    country: 'CO',
  },
  {
    code: 'CO-BOY',
    label: 'Boyacá',
    country: 'CO',
  },
  {
    code: 'CO-CAL',
    label: 'Caldas',
    country: 'CO',
  },
  {
    code: 'CO-CAQ',
    label: 'Caquetá',
    country: 'CO',
  },
  {
    code: 'CO-CAS',
    label: 'Casanare',
    country: 'CO',
  },
  {
    code: 'CO-CAU',
    label: 'Cauca',
    country: 'CO',
  },
  {
    code: 'CO-CES',
    label: 'Cesar',
    country: 'CO',
  },
  {
    code: 'CO-CHO',
    label: 'Chocó',
    country: 'CO',
  },
  {
    code: 'CO-COR',
    label: 'Córdoba',
    country: 'CO',
  },
  {
    code: 'CO-CUN',
    label: 'Cundinamarca',
    country: 'CO',
  },
  {
    code: 'CO-DC',
    label: 'Distrito Capital de Bogotá',
    country: 'CO',
  },
  {
    code: 'CO-GUA',
    label: 'Guainía',
    country: 'CO',
  },
  {
    code: 'CO-GUV',
    label: 'Guaviare',
    country: 'CO',
  },
  {
    code: 'CO-HUI',
    label: 'Huila',
    country: 'CO',
  },
  {
    code: 'CO-LAG',
    label: 'La Guajira',
    country: 'CO',
  },
  {
    code: 'CO-MAG',
    label: 'Magdalena',
    country: 'CO',
  },
  {
    code: 'CO-MET',
    label: 'Meta',
    country: 'CO',
  },
  {
    code: 'CO-NAR',
    label: 'Nariño',
    country: 'CO',
  },
  {
    code: 'CO-NSA',
    label: 'Norte de Santander',
    country: 'CO',
  },
  {
    code: 'CO-PUT',
    label: 'Putumayo',
    country: 'CO',
  },
  {
    code: 'CO-QUI',
    label: 'Quindío',
    country: 'CO',
  },
  {
    code: 'CO-RIS',
    label: 'Risaralda',
    country: 'CO',
  },
  {
    code: 'CO-SAN',
    label: 'Santander',
    country: 'CO',
  },
  {
    code: 'CO-SAP',
    label: 'San Andrés, Providencia y Santa Catalina',
    country: 'CO',
  },
  {
    code: 'CO-SUC',
    label: 'Sucre',
    country: 'CO',
  },
  {
    code: 'CO-TOL',
    label: 'Tolima',
    country: 'CO',
  },
  {
    code: 'CO-VAC',
    label: 'Valle del Cauca',
    country: 'CO',
  },
  {
    code: 'CO-VAU',
    label: 'Vaupés',
    country: 'CO',
  },
  {
    code: 'CO-VID',
    label: 'Vichada',
    country: 'CO',
  },
  {
    code: 'CR-A',
    label: 'Alajuela',
    country: 'CR',
  },
  {
    code: 'CR-C',
    label: 'Cartago',
    country: 'CR',
  },
  {
    code: 'CR-G',
    label: 'Guanacaste',
    country: 'CR',
  },
  {
    code: 'CR-H',
    label: 'Heredia',
    country: 'CR',
  },
  {
    code: 'CR-L',
    label: 'Limón',
    country: 'CR',
  },
  {
    code: 'CR-P',
    label: 'Puntarenas',
    country: 'CR',
  },
  {
    code: 'CR-SJ',
    label: 'San José',
    country: 'CR',
  },
  {
    code: 'CU-01',
    label: 'Pinar del Río',
    country: 'CU',
  },
  {
    code: 'CU-03',
    label: 'La Habana',
    country: 'CU',
  },
  {
    code: 'CU-04',
    label: 'Matanzas',
    country: 'CU',
  },
  {
    code: 'CU-05',
    label: 'Villa Clara',
    country: 'CU',
  },
  {
    code: 'CU-06',
    label: 'Cienfuegos',
    country: 'CU',
  },
  {
    code: 'CU-07',
    label: 'Sancti Spíritus',
    country: 'CU',
  },
  {
    code: 'CU-08',
    label: 'Ciego de Ávila',
    country: 'CU',
  },
  {
    code: 'CU-09',
    label: 'Camagüey',
    country: 'CU',
  },
  {
    code: 'CU-10',
    label: 'Las Tunas',
    country: 'CU',
  },
  {
    code: 'CU-11',
    label: 'Holguín',
    country: 'CU',
  },
  {
    code: 'CU-12',
    label: 'Granma',
    country: 'CU',
  },
  {
    code: 'CU-13',
    label: 'Santiago de Cuba',
    country: 'CU',
  },
  {
    code: 'CU-14',
    label: 'Guantánamo',
    country: 'CU',
  },
  {
    code: 'CU-15',
    label: 'Artemisa',
    country: 'CU',
  },
  {
    code: 'CU-16',
    label: 'Mayabeque',
    country: 'CU',
  },
  {
    code: 'CU-99',
    label: 'Isla de la Juventud',
    country: 'CU',
  },
  {
    code: 'CV-B',
    label: 'Ilhas de Barlavento',
    country: 'CV',
  },
  {
    code: 'CV-BR',
    label: 'Brava',
    country: 'CV-S',
  },
  {
    code: 'CV-BV',
    label: 'Boa Vista',
    country: 'CV-B',
  },
  {
    code: 'CV-CA',
    label: 'Santa Catarina',
    country: 'CV-S',
  },
  {
    code: 'CV-CF',
    label: 'Santa Catarina do Fogo',
    country: 'CV-S',
  },
  {
    code: 'CV-CR',
    label: 'Santa Cruz',
    country: 'CV-S',
  },
  {
    code: 'CV-MA',
    label: 'Maio',
    country: 'CV-S',
  },
  {
    code: 'CV-MO',
    label: 'Mosteiros',
    country: 'CV-S',
  },
  {
    code: 'CV-PA',
    label: 'Paul',
    country: 'CV-B',
  },
  {
    code: 'CV-PN',
    label: 'Porto Novo',
    country: 'CV-B',
  },
  {
    code: 'CV-PR',
    label: 'Praia',
    country: 'CV-S',
  },
  {
    code: 'CV-RB',
    label: 'Ribeira Brava',
    country: 'CV-B',
  },
  {
    code: 'CV-RG',
    label: 'Ribeira Grande',
    country: 'CV-B',
  },
  {
    code: 'CV-RS',
    label: 'Ribeira Grande de Santiago',
    country: 'CV-S',
  },
  {
    code: 'CV-S',
    label: 'Ilhas de Sotavento',
    country: 'CV',
  },
  {
    code: 'CV-SD',
    label: 'São Domingos',
    country: 'CV-S',
  },
  {
    code: 'CV-SF',
    label: 'São Filipe',
    country: 'CV-S',
  },
  {
    code: 'CV-SL',
    label: 'Sal',
    country: 'CV-B',
  },
  {
    code: 'CV-SM',
    label: 'São Miguel',
    country: 'CV-S',
  },
  {
    code: 'CV-SO',
    label: 'São Lourenço dos Órgãos',
    country: 'CV-S',
  },
  {
    code: 'CV-SS',
    label: 'São Salvador do Mundo',
    country: 'CV-S',
  },
  {
    code: 'CV-SV',
    label: 'São Vicente',
    country: 'CV-B',
  },
  {
    code: 'CV-TA',
    label: 'Tarrafal',
    country: 'CV-S',
  },
  {
    code: 'CV-TS',
    label: 'Tarrafal de São Nicolau',
    country: 'CV-B',
  },
  {
    code: 'CY-01',
    label: 'Lefkosia',
    country: 'CY',
  },
  {
    code: 'CY-02',
    label: 'Lemesos',
    country: 'CY',
  },
  {
    code: 'CY-03',
    label: 'Larnaka',
    country: 'CY',
  },
  {
    code: 'CY-04',
    label: 'Ammochostos',
    country: 'CY',
  },
  {
    code: 'CY-05',
    label: 'Pafos',
    country: 'CY',
  },
  {
    code: 'CY-06',
    label: 'Keryneia',
    country: 'CY',
  },
  {
    code: 'CZ-10',
    label: 'Praha, Hlavní město',
    country: 'CZ',
  },
  {
    code: 'CZ-20',
    label: 'Středočeský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-201',
    label: 'Benešov',
    country: 'CZ-20',
  },
  {
    code: 'CZ-202',
    label: 'Beroun',
    country: 'CZ-20',
  },
  {
    code: 'CZ-203',
    label: 'Kladno',
    country: 'CZ-20',
  },
  {
    code: 'CZ-204',
    label: 'Kolín',
    country: 'CZ-20',
  },
  {
    code: 'CZ-205',
    label: 'Kutná Hora',
    country: 'CZ-20',
  },
  {
    code: 'CZ-206',
    label: 'Mělník',
    country: 'CZ-20',
  },
  {
    code: 'CZ-207',
    label: 'Mladá Boleslav',
    country: 'CZ-20',
  },
  {
    code: 'CZ-208',
    label: 'Nymburk',
    country: 'CZ-20',
  },
  {
    code: 'CZ-209',
    label: 'Praha-východ',
    country: 'CZ-20',
  },
  {
    code: 'CZ-20A',
    label: 'Praha-západ',
    country: 'CZ-20',
  },
  {
    code: 'CZ-20B',
    label: 'Příbram',
    country: 'CZ-20',
  },
  {
    code: 'CZ-20C',
    label: 'Rakovník',
    country: 'CZ-20',
  },
  {
    code: 'CZ-31',
    label: 'Jihočeský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-311',
    label: 'České Budějovice',
    country: 'CZ-31',
  },
  {
    code: 'CZ-312',
    label: 'Český Krumlov',
    country: 'CZ-31',
  },
  {
    code: 'CZ-313',
    label: 'Jindřichův Hradec',
    country: 'CZ-31',
  },
  {
    code: 'CZ-314',
    label: 'Písek',
    country: 'CZ-31',
  },
  {
    code: 'CZ-315',
    label: 'Prachatice',
    country: 'CZ-31',
  },
  {
    code: 'CZ-316',
    label: 'Strakonice',
    country: 'CZ-31',
  },
  {
    code: 'CZ-317',
    label: 'Tábor',
    country: 'CZ-31',
  },
  {
    code: 'CZ-32',
    label: 'Plzeňský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-321',
    label: 'Domažlice',
    country: 'CZ-32',
  },
  {
    code: 'CZ-322',
    label: 'Klatovy',
    country: 'CZ-32',
  },
  {
    code: 'CZ-323',
    label: 'Plzeň-město',
    country: 'CZ-32',
  },
  {
    code: 'CZ-324',
    label: 'Plzeň-jih',
    country: 'CZ-32',
  },
  {
    code: 'CZ-325',
    label: 'Plzeň-sever',
    country: 'CZ-32',
  },
  {
    code: 'CZ-326',
    label: 'Rokycany',
    country: 'CZ-32',
  },
  {
    code: 'CZ-327',
    label: 'Tachov',
    country: 'CZ-32',
  },
  {
    code: 'CZ-41',
    label: 'Karlovarský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-411',
    label: 'Cheb',
    country: 'CZ-41',
  },
  {
    code: 'CZ-412',
    label: 'Karlovy Vary',
    country: 'CZ-41',
  },
  {
    code: 'CZ-413',
    label: 'Sokolov',
    country: 'CZ-41',
  },
  {
    code: 'CZ-42',
    label: 'Ústecký kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-421',
    label: 'Děčín',
    country: 'CZ-42',
  },
  {
    code: 'CZ-422',
    label: 'Chomutov',
    country: 'CZ-42',
  },
  {
    code: 'CZ-423',
    label: 'Litoměřice',
    country: 'CZ-42',
  },
  {
    code: 'CZ-424',
    label: 'Louny',
    country: 'CZ-42',
  },
  {
    code: 'CZ-425',
    label: 'Most',
    country: 'CZ-42',
  },
  {
    code: 'CZ-426',
    label: 'Teplice',
    country: 'CZ-42',
  },
  {
    code: 'CZ-427',
    label: 'Ústí nad Labem',
    country: 'CZ-42',
  },
  {
    code: 'CZ-51',
    label: 'Liberecký kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-511',
    label: 'Česká Lípa',
    country: 'CZ-51',
  },
  {
    code: 'CZ-512',
    label: 'Jablonec nad Nisou',
    country: 'CZ-51',
  },
  {
    code: 'CZ-513',
    label: 'Liberec',
    country: 'CZ-51',
  },
  {
    code: 'CZ-514',
    label: 'Semily',
    country: 'CZ-51',
  },
  {
    code: 'CZ-52',
    label: 'Královéhradecký kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-521',
    label: 'Hradec Králové',
    country: 'CZ-52',
  },
  {
    code: 'CZ-522',
    label: 'Jičín',
    country: 'CZ-52',
  },
  {
    code: 'CZ-523',
    label: 'Náchod',
    country: 'CZ-52',
  },
  {
    code: 'CZ-524',
    label: 'Rychnov nad Kněžnou',
    country: 'CZ-52',
  },
  {
    code: 'CZ-525',
    label: 'Trutnov',
    country: 'CZ-52',
  },
  {
    code: 'CZ-53',
    label: 'Pardubický kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-531',
    label: 'Chrudim',
    country: 'CZ-53',
  },
  {
    code: 'CZ-532',
    label: 'Pardubice',
    country: 'CZ-53',
  },
  {
    code: 'CZ-533',
    label: 'Svitavy',
    country: 'CZ-53',
  },
  {
    code: 'CZ-534',
    label: 'Ústí nad Orlicí',
    country: 'CZ-53',
  },
  {
    code: 'CZ-63',
    label: 'Kraj Vysočina',
    country: 'CZ',
  },
  {
    code: 'CZ-631',
    label: 'Havlíčkův Brod',
    country: 'CZ-63',
  },
  {
    code: 'CZ-632',
    label: 'Jihlava',
    country: 'CZ-63',
  },
  {
    code: 'CZ-633',
    label: 'Pelhřimov',
    country: 'CZ-63',
  },
  {
    code: 'CZ-634',
    label: 'Třebíč',
    country: 'CZ-63',
  },
  {
    code: 'CZ-635',
    label: 'Žďár nad Sázavou',
    country: 'CZ-63',
  },
  {
    code: 'CZ-64',
    label: 'Jihomoravský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-641',
    label: 'Blansko',
    country: 'CZ-64',
  },
  {
    code: 'CZ-642',
    label: 'Brno-město',
    country: 'CZ-64',
  },
  {
    code: 'CZ-643',
    label: 'Brno-venkov',
    country: 'CZ-64',
  },
  {
    code: 'CZ-644',
    label: 'Břeclav',
    country: 'CZ-64',
  },
  {
    code: 'CZ-645',
    label: 'Hodonín',
    country: 'CZ-64',
  },
  {
    code: 'CZ-646',
    label: 'Vyškov',
    country: 'CZ-64',
  },
  {
    code: 'CZ-647',
    label: 'Znojmo',
    country: 'CZ-64',
  },
  {
    code: 'CZ-71',
    label: 'Olomoucký kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-711',
    label: 'Jeseník',
    country: 'CZ-71',
  },
  {
    code: 'CZ-712',
    label: 'Olomouc',
    country: 'CZ-71',
  },
  {
    code: 'CZ-713',
    label: 'Prostějov',
    country: 'CZ-71',
  },
  {
    code: 'CZ-714',
    label: 'Přerov',
    country: 'CZ-71',
  },
  {
    code: 'CZ-715',
    label: 'Šumperk',
    country: 'CZ-71',
  },
  {
    code: 'CZ-72',
    label: 'Zlínský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-721',
    label: 'Kroměříž',
    country: 'CZ-72',
  },
  {
    code: 'CZ-722',
    label: 'Uherské Hradiště',
    country: 'CZ-72',
  },
  {
    code: 'CZ-723',
    label: 'Vsetín',
    country: 'CZ-72',
  },
  {
    code: 'CZ-724',
    label: 'Zlín',
    country: 'CZ-72',
  },
  {
    code: 'CZ-80',
    label: 'Moravskoslezský kraj',
    country: 'CZ',
  },
  {
    code: 'CZ-801',
    label: 'Bruntál',
    country: 'CZ-80',
  },
  {
    code: 'CZ-802',
    label: 'Frýdek-Místek',
    country: 'CZ-80',
  },
  {
    code: 'CZ-803',
    label: 'Karviná',
    country: 'CZ-80',
  },
  {
    code: 'CZ-804',
    label: 'Nový Jičín',
    country: 'CZ-80',
  },
  {
    code: 'CZ-805',
    label: 'Opava',
    country: 'CZ-80',
  },
  {
    code: 'CZ-806',
    label: 'Ostrava-město',
    country: 'CZ-80',
  },
  {
    code: 'DE-BB',
    label: 'Brandenburg',
    country: 'DE',
  },
  {
    code: 'DE-BE',
    label: 'Berlin',
    country: 'DE',
  },
  {
    code: 'DE-BW',
    label: 'Baden-Württemberg',
    country: 'DE',
  },
  {
    code: 'DE-BY',
    label: 'Bayern',
    country: 'DE',
  },
  {
    code: 'DE-HB',
    label: 'Bremen',
    country: 'DE',
  },
  {
    code: 'DE-HE',
    label: 'Hessen',
    country: 'DE',
  },
  {
    code: 'DE-HH',
    label: 'Hamburg',
    country: 'DE',
  },
  {
    code: 'DE-MV',
    label: 'Mecklenburg-Vorpommern',
    country: 'DE',
  },
  {
    code: 'DE-NI',
    label: 'Niedersachsen',
    country: 'DE',
  },
  {
    code: 'DE-NW',
    label: 'Nordrhein-Westfalen',
    country: 'DE',
  },
  {
    code: 'DE-RP',
    label: 'Rheinland-Pfalz',
    country: 'DE',
  },
  {
    code: 'DE-SH',
    label: 'Schleswig-Holstein',
    country: 'DE',
  },
  {
    code: 'DE-SL',
    label: 'Saarland',
    country: 'DE',
  },
  {
    code: 'DE-SN',
    label: 'Sachsen',
    country: 'DE',
  },
  {
    code: 'DE-ST',
    label: 'Sachsen-Anhalt',
    country: 'DE',
  },
  {
    code: 'DE-TH',
    label: 'Thüringen',
    country: 'DE',
  },
  {
    code: 'DJ-AR',
    label: 'Arta',
    country: 'DJ',
  },
  {
    code: 'DJ-AS',
    label: 'Ali Sabieh',
    country: 'DJ',
  },
  {
    code: 'DJ-DI',
    label: 'Dikhil',
    country: 'DJ',
  },
  {
    code: 'DJ-DJ',
    label: 'Djibouti',
    country: 'DJ',
  },
  {
    code: 'DJ-OB',
    label: 'Obock',
    country: 'DJ',
  },
  {
    code: 'DJ-TA',
    label: 'Tadjourah',
    country: 'DJ',
  },
  {
    code: 'DK-81',
    label: 'Nordjylland',
    country: 'DK',
  },
  {
    code: 'DK-82',
    label: 'Midtjylland',
    country: 'DK',
  },
  {
    code: 'DK-83',
    label: 'Syddanmark',
    country: 'DK',
  },
  {
    code: 'DK-84',
    label: 'Hovedstaden',
    country: 'DK',
  },
  {
    code: 'DK-85',
    label: 'Sjælland',
    country: 'DK',
  },
  {
    code: 'DM-02',
    label: 'Saint Andrew',
    country: 'DM',
  },
  {
    code: 'DM-03',
    label: 'Saint David',
    country: 'DM',
  },
  {
    code: 'DM-04',
    label: 'Saint George',
    country: 'DM',
  },
  {
    code: 'DM-05',
    label: 'Saint John',
    country: 'DM',
  },
  {
    code: 'DM-06',
    label: 'Saint Joseph',
    country: 'DM',
  },
  {
    code: 'DM-07',
    label: 'Saint Luke',
    country: 'DM',
  },
  {
    code: 'DM-08',
    label: 'Saint Mark',
    country: 'DM',
  },
  {
    code: 'DM-09',
    label: 'Saint Patrick',
    country: 'DM',
  },
  {
    code: 'DM-10',
    label: 'Saint Paul',
    country: 'DM',
  },
  {
    code: 'DM-11',
    label: 'Saint Peter',
    country: 'DM',
  },
  {
    code: 'DO-01',
    label: 'Distrito Nacional (Santo Domingo)',
    country: 'DO-40',
  },
  {
    code: 'DO-02',
    label: 'Azua',
    country: 'DO-41',
  },
  {
    code: 'DO-03',
    label: 'Baoruco',
    country: 'DO-38',
  },
  {
    code: 'DO-04',
    label: 'Barahona',
    country: 'DO-38',
  },
  {
    code: 'DO-05',
    label: 'Dajabón',
    country: 'DO-34',
  },
  {
    code: 'DO-06',
    label: 'Duarte',
    country: 'DO-33',
  },
  {
    code: 'DO-07',
    label: 'Elías Piña',
    country: 'DO-37',
  },
  {
    code: 'DO-08',
    label: 'El Seibo',
    country: 'DO-42',
  },
  {
    code: 'DO-09',
    label: 'Espaillat',
    country: 'DO-35',
  },
  {
    code: 'DO-10',
    label: 'Independencia',
    country: 'DO-38',
  },
  {
    code: 'DO-11',
    label: 'La Altagracia',
    country: 'DO-42',
  },
  {
    code: 'DO-12',
    label: 'La Romana',
    country: 'DO-42',
  },
  {
    code: 'DO-13',
    label: 'La Vega',
    country: 'DO-36',
  },
  {
    code: 'DO-14',
    label: 'María Trinidad Sánchez',
    country: 'DO-33',
  },
  {
    code: 'DO-15',
    label: 'Monte Cristi',
    country: 'DO-34',
  },
  {
    code: 'DO-16',
    label: 'Pedernales',
    country: 'DO-38',
  },
  {
    code: 'DO-17',
    label: 'Peravia',
    country: 'DO-41',
  },
  {
    code: 'DO-18',
    label: 'Puerto Plata',
    country: 'DO-35',
  },
  {
    code: 'DO-19',
    label: 'Hermanas Mirabal',
    country: 'DO-33',
  },
  {
    code: 'DO-20',
    label: 'Samaná',
    country: 'DO-33',
  },
  {
    code: 'DO-21',
    label: 'San Cristóbal',
    country: 'DO-41',
  },
  {
    code: 'DO-22',
    label: 'San Juan',
    country: 'DO-37',
  },
  {
    code: 'DO-23',
    label: 'San Pedro de Macorís',
    country: 'DO-39',
  },
  {
    code: 'DO-24',
    label: 'Sánchez Ramírez',
    country: 'DO-36',
  },
  {
    code: 'DO-25',
    label: 'Santiago',
    country: 'DO-35',
  },
  {
    code: 'DO-26',
    label: 'Santiago Rodríguez',
    country: 'DO-34',
  },
  {
    code: 'DO-27',
    label: 'Valverde',
    country: 'DO-34',
  },
  {
    code: 'DO-28',
    label: 'Monseñor Nouel',
    country: 'DO-36',
  },
  {
    code: 'DO-29',
    label: 'Monte Plata',
    country: 'DO-39',
  },
  {
    code: 'DO-30',
    label: 'Hato Mayor',
    country: 'DO-39',
  },
  {
    code: 'DO-31',
    label: 'San José de Ocoa',
    country: 'DO-41',
  },
  {
    code: 'DO-32',
    label: 'Santo Domingo',
    country: 'DO-40',
  },
  {
    code: 'DO-33',
    label: 'Cibao Nordeste',
    country: 'DO',
  },
  {
    code: 'DO-34',
    label: 'Cibao Noroeste',
    country: 'DO',
  },
  {
    code: 'DO-35',
    label: 'Cibao Norte',
    country: 'DO',
  },
  {
    code: 'DO-36',
    label: 'Cibao Sur',
    country: 'DO',
  },
  {
    code: 'DO-37',
    label: 'El Valle',
    country: 'DO',
  },
  {
    code: 'DO-38',
    label: 'Enriquillo',
    country: 'DO',
  },
  {
    code: 'DO-39',
    label: 'Higuamo',
    country: 'DO',
  },
  {
    code: 'DO-40',
    label: 'Ozama',
    country: 'DO',
  },
  {
    code: 'DO-41',
    label: 'Valdesia',
    country: 'DO',
  },
  {
    code: 'DO-42',
    label: 'Yuma',
    country: 'DO',
  },
  {
    code: 'DZ-01',
    label: 'Adrar',
    country: 'DZ',
  },
  {
    code: 'DZ-02',
    label: 'Chlef',
    country: 'DZ',
  },
  {
    code: 'DZ-03',
    label: 'Laghouat',
    country: 'DZ',
  },
  {
    code: 'DZ-04',
    label: 'Oum el Bouaghi',
    country: 'DZ',
  },
  {
    code: 'DZ-05',
    label: 'Batna',
    country: 'DZ',
  },
  {
    code: 'DZ-06',
    label: 'Béjaïa',
    country: 'DZ',
  },
  {
    code: 'DZ-07',
    label: 'Biskra',
    country: 'DZ',
  },
  {
    code: 'DZ-08',
    label: 'Béchar',
    country: 'DZ',
  },
  {
    code: 'DZ-09',
    label: 'Blida',
    country: 'DZ',
  },
  {
    code: 'DZ-10',
    label: 'Bouira',
    country: 'DZ',
  },
  {
    code: 'DZ-11',
    label: 'Tamanrasset',
    country: 'DZ',
  },
  {
    code: 'DZ-12',
    label: 'Tébessa',
    country: 'DZ',
  },
  {
    code: 'DZ-13',
    label: 'Tlemcen',
    country: 'DZ',
  },
  {
    code: 'DZ-14',
    label: 'Tiaret',
    country: 'DZ',
  },
  {
    code: 'DZ-15',
    label: 'Tizi Ouzou',
    country: 'DZ',
  },
  {
    code: 'DZ-16',
    label: 'Alger',
    country: 'DZ',
  },
  {
    code: 'DZ-17',
    label: 'Djelfa',
    country: 'DZ',
  },
  {
    code: 'DZ-18',
    label: 'Jijel',
    country: 'DZ',
  },
  {
    code: 'DZ-19',
    label: 'Sétif',
    country: 'DZ',
  },
  {
    code: 'DZ-20',
    label: 'Saïda',
    country: 'DZ',
  },
  {
    code: 'DZ-21',
    label: 'Skikda',
    country: 'DZ',
  },
  {
    code: 'DZ-22',
    label: 'Sidi Bel Abbès',
    country: 'DZ',
  },
  {
    code: 'DZ-23',
    label: 'Annaba',
    country: 'DZ',
  },
  {
    code: 'DZ-24',
    label: 'Guelma',
    country: 'DZ',
  },
  {
    code: 'DZ-25',
    label: 'Constantine',
    country: 'DZ',
  },
  {
    code: 'DZ-26',
    label: 'Médéa',
    country: 'DZ',
  },
  {
    code: 'DZ-27',
    label: 'Mostaganem',
    country: 'DZ',
  },
  {
    code: 'DZ-28',
    label: "M'sila",
    country: 'DZ',
  },
  {
    code: 'DZ-29',
    label: 'Mascara',
    country: 'DZ',
  },
  {
    code: 'DZ-30',
    label: 'Ouargla',
    country: 'DZ',
  },
  {
    code: 'DZ-31',
    label: 'Oran',
    country: 'DZ',
  },
  {
    code: 'DZ-32',
    label: 'El Bayadh',
    country: 'DZ',
  },
  {
    code: 'DZ-33',
    label: 'Illizi',
    country: 'DZ',
  },
  {
    code: 'DZ-34',
    label: 'Bordj Bou Arréridj',
    country: 'DZ',
  },
  {
    code: 'DZ-35',
    label: 'Boumerdès',
    country: 'DZ',
  },
  {
    code: 'DZ-36',
    label: 'El Tarf',
    country: 'DZ',
  },
  {
    code: 'DZ-37',
    label: 'Tindouf',
    country: 'DZ',
  },
  {
    code: 'DZ-38',
    label: 'Tissemsilt',
    country: 'DZ',
  },
  {
    code: 'DZ-39',
    label: 'El Oued',
    country: 'DZ',
  },
  {
    code: 'DZ-40',
    label: 'Khenchela',
    country: 'DZ',
  },
  {
    code: 'DZ-41',
    label: 'Souk Ahras',
    country: 'DZ',
  },
  {
    code: 'DZ-42',
    label: 'Tipaza',
    country: 'DZ',
  },
  {
    code: 'DZ-43',
    label: 'Mila',
    country: 'DZ',
  },
  {
    code: 'DZ-44',
    label: 'Aïn Defla',
    country: 'DZ',
  },
  {
    code: 'DZ-45',
    label: 'Naama',
    country: 'DZ',
  },
  {
    code: 'DZ-46',
    label: 'Aïn Témouchent',
    country: 'DZ',
  },
  {
    code: 'DZ-47',
    label: 'Ghardaïa',
    country: 'DZ',
  },
  {
    code: 'DZ-48',
    label: 'Relizane',
    country: 'DZ',
  },
  {
    code: 'EC-A',
    label: 'Azuay',
    country: 'EC',
  },
  {
    code: 'EC-B',
    label: 'Bolívar',
    country: 'EC',
  },
  {
    code: 'EC-C',
    label: 'Carchi',
    country: 'EC',
  },
  {
    code: 'EC-D',
    label: 'Orellana',
    country: 'EC',
  },
  {
    code: 'EC-E',
    label: 'Esmeraldas',
    country: 'EC',
  },
  {
    code: 'EC-F',
    label: 'Cañar',
    country: 'EC',
  },
  {
    code: 'EC-G',
    label: 'Guayas',
    country: 'EC',
  },
  {
    code: 'EC-H',
    label: 'Chimborazo',
    country: 'EC',
  },
  {
    code: 'EC-I',
    label: 'Imbabura',
    country: 'EC',
  },
  {
    code: 'EC-L',
    label: 'Loja',
    country: 'EC',
  },
  {
    code: 'EC-M',
    label: 'Manabí',
    country: 'EC',
  },
  {
    code: 'EC-N',
    label: 'Napo',
    country: 'EC',
  },
  {
    code: 'EC-O',
    label: 'El Oro',
    country: 'EC',
  },
  {
    code: 'EC-P',
    label: 'Pichincha',
    country: 'EC',
  },
  {
    code: 'EC-R',
    label: 'Los Ríos',
    country: 'EC',
  },
  {
    code: 'EC-S',
    label: 'Morona Santiago',
    country: 'EC',
  },
  {
    code: 'EC-SD',
    label: 'Santo Domingo de los Tsáchilas',
    country: 'EC',
  },
  {
    code: 'EC-SE',
    label: 'Santa Elena',
    country: 'EC',
  },
  {
    code: 'EC-T',
    label: 'Tungurahua',
    country: 'EC',
  },
  {
    code: 'EC-U',
    label: 'Sucumbíos',
    country: 'EC',
  },
  {
    code: 'EC-W',
    label: 'Galápagos',
    country: 'EC',
  },
  {
    code: 'EC-X',
    label: 'Cotopaxi',
    country: 'EC',
  },
  {
    code: 'EC-Y',
    label: 'Pastaza',
    country: 'EC',
  },
  {
    code: 'EC-Z',
    label: 'Zamora Chinchipe',
    country: 'EC',
  },
  {
    code: 'EE-130',
    label: 'Alutaguse',
    country: 'EE',
  },
  {
    code: 'EE-141',
    label: 'Anija',
    country: 'EE',
  },
  {
    code: 'EE-142',
    label: 'Antsla',
    country: 'EE',
  },
  {
    code: 'EE-171',
    label: 'Elva',
    country: 'EE',
  },
  {
    code: 'EE-184',
    label: 'Haapsalu',
    country: 'EE',
  },
  {
    code: 'EE-191',
    label: 'Haljala',
    country: 'EE',
  },
  {
    code: 'EE-198',
    label: 'Harku',
    country: 'EE',
  },
  {
    code: 'EE-205',
    label: 'Hiiumaa',
    country: 'EE',
  },
  {
    code: 'EE-214',
    label: 'Häädemeeste',
    country: 'EE',
  },
  {
    code: 'EE-245',
    label: 'Jõelähtme',
    country: 'EE',
  },
  {
    code: 'EE-247',
    label: 'Jõgeva',
    country: 'EE',
  },
  {
    code: 'EE-251',
    label: 'Jõhvi',
    country: 'EE',
  },
  {
    code: 'EE-255',
    label: 'Järva',
    country: 'EE',
  },
  {
    code: 'EE-272',
    label: 'Kadrina',
    country: 'EE',
  },
  {
    code: 'EE-283',
    label: 'Kambja',
    country: 'EE',
  },
  {
    code: 'EE-284',
    label: 'Kanepi',
    country: 'EE',
  },
  {
    code: 'EE-291',
    label: 'Kastre',
    country: 'EE',
  },
  {
    code: 'EE-293',
    label: 'Kehtna',
    country: 'EE',
  },
  {
    code: 'EE-296',
    label: 'Keila',
    country: 'EE',
  },
  {
    code: 'EE-303',
    label: 'Kihnu',
    country: 'EE',
  },
  {
    code: 'EE-305',
    label: 'Kiili',
    country: 'EE',
  },
  {
    code: 'EE-317',
    label: 'Kohila',
    country: 'EE',
  },
  {
    code: 'EE-321',
    label: 'Kohtla-Järve',
    country: 'EE',
  },
  {
    code: 'EE-338',
    label: 'Kose',
    country: 'EE',
  },
  {
    code: 'EE-353',
    label: 'Kuusalu',
    country: 'EE',
  },
  {
    code: 'EE-37',
    label: 'Harjumaa',
    country: 'EE',
  },
  {
    code: 'EE-39',
    label: 'Hiiumaa',
    country: 'EE',
  },
  {
    code: 'EE-424',
    label: 'Loksa',
    country: 'EE',
  },
  {
    code: 'EE-430',
    label: 'Lääneranna',
    country: 'EE',
  },
  {
    code: 'EE-431',
    label: 'Lääne-Harju',
    country: 'EE',
  },
  {
    code: 'EE-432',
    label: 'Luunja',
    country: 'EE',
  },
  {
    code: 'EE-441',
    label: 'Lääne-Nigula',
    country: 'EE',
  },
  {
    code: 'EE-442',
    label: 'Lüganuse',
    country: 'EE',
  },
  {
    code: 'EE-446',
    label: 'Maardu',
    country: 'EE',
  },
  {
    code: 'EE-45',
    label: 'Ida-Virumaa',
    country: 'EE',
  },
  {
    code: 'EE-478',
    label: 'Muhu',
    country: 'EE',
  },
  {
    code: 'EE-480',
    label: 'Mulgi',
    country: 'EE',
  },
  {
    code: 'EE-486',
    label: 'Mustvee',
    country: 'EE',
  },
  {
    code: 'EE-50',
    label: 'Jõgevamaa',
    country: 'EE',
  },
  {
    code: 'EE-503',
    label: 'Märjamaa',
    country: 'EE',
  },
  {
    code: 'EE-511',
    label: 'Narva',
    country: 'EE',
  },
  {
    code: 'EE-514',
    label: 'Narva-Jõesuu',
    country: 'EE',
  },
  {
    code: 'EE-52',
    label: 'Järvamaa',
    country: 'EE',
  },
  {
    code: 'EE-528',
    label: 'Nõo',
    country: 'EE',
  },
  {
    code: 'EE-557',
    label: 'Otepää',
    country: 'EE',
  },
  {
    code: 'EE-56',
    label: 'Läänemaa',
    country: 'EE',
  },
  {
    code: 'EE-567',
    label: 'Paide',
    country: 'EE',
  },
  {
    code: 'EE-586',
    label: 'Peipsiääre',
    country: 'EE',
  },
  {
    code: 'EE-60',
    label: 'Lääne-Virumaa',
    country: 'EE',
  },
  {
    code: 'EE-615',
    label: 'Põhja-Sakala',
    country: 'EE',
  },
  {
    code: 'EE-618',
    label: 'Põltsamaa',
    country: 'EE',
  },
  {
    code: 'EE-622',
    label: 'Põlva',
    country: 'EE',
  },
  {
    code: 'EE-624',
    label: 'Pärnu',
    country: 'EE',
  },
  {
    code: 'EE-638',
    label: 'Põhja-Pärnumaa',
    country: 'EE',
  },
  {
    code: 'EE-64',
    label: 'Põlvamaa',
    country: 'EE',
  },
  {
    code: 'EE-651',
    label: 'Raasiku',
    country: 'EE',
  },
  {
    code: 'EE-653',
    label: 'Rae',
    country: 'EE',
  },
  {
    code: 'EE-661',
    label: 'Rakvere',
    country: 'EE',
  },
  {
    code: 'EE-663',
    label: 'Rakvere',
    country: 'EE',
  },
  {
    code: 'EE-668',
    label: 'Rapla',
    country: 'EE',
  },
  {
    code: 'EE-68',
    label: 'Pärnumaa',
    country: 'EE',
  },
  {
    code: 'EE-689',
    label: 'Ruhnu',
    country: 'EE',
  },
  {
    code: 'EE-698',
    label: 'Rõuge',
    country: 'EE',
  },
  {
    code: 'EE-708',
    label: 'Räpina',
    country: 'EE',
  },
  {
    code: 'EE-71',
    label: 'Raplamaa',
    country: 'EE',
  },
  {
    code: 'EE-712',
    label: 'Saarde',
    country: 'EE',
  },
  {
    code: 'EE-714',
    label: 'Saaremaa',
    country: 'EE',
  },
  {
    code: 'EE-719',
    label: 'Saku',
    country: 'EE',
  },
  {
    code: 'EE-726',
    label: 'Saue',
    country: 'EE',
  },
  {
    code: 'EE-732',
    label: 'Setomaa',
    country: 'EE',
  },
  {
    code: 'EE-735',
    label: 'Sillamäe',
    country: 'EE',
  },
  {
    code: 'EE-74',
    label: 'Saaremaa',
    country: 'EE',
  },
  {
    code: 'EE-784',
    label: 'Tallinn',
    country: 'EE',
  },
  {
    code: 'EE-79',
    label: 'Tartumaa',
    country: 'EE',
  },
  {
    code: 'EE-792',
    label: 'Tapa',
    country: 'EE',
  },
  {
    code: 'EE-793',
    label: 'Tartu',
    country: 'EE',
  },
  {
    code: 'EE-796',
    label: 'Tartu',
    country: 'EE',
  },
  {
    code: 'EE-803',
    label: 'Toila',
    country: 'EE',
  },
  {
    code: 'EE-809',
    label: 'Tori',
    country: 'EE',
  },
  {
    code: 'EE-81',
    label: 'Valgamaa',
    country: 'EE',
  },
  {
    code: 'EE-824',
    label: 'Tõrva',
    country: 'EE',
  },
  {
    code: 'EE-834',
    label: 'Türi',
    country: 'EE',
  },
  {
    code: 'EE-84',
    label: 'Viljandimaa',
    country: 'EE',
  },
  {
    code: 'EE-855',
    label: 'Valga',
    country: 'EE',
  },
  {
    code: 'EE-87',
    label: 'Võrumaa',
    country: 'EE',
  },
  {
    code: 'EE-890',
    label: 'Viimsi',
    country: 'EE',
  },
  {
    code: 'EE-897',
    label: 'Viljandi',
    country: 'EE',
  },
  {
    code: 'EE-899',
    label: 'Viljandi',
    country: 'EE',
  },
  {
    code: 'EE-901',
    label: 'Vinni',
    country: 'EE',
  },
  {
    code: 'EE-903',
    label: 'Viru-Nigula',
    country: 'EE',
  },
  {
    code: 'EE-907',
    label: 'Vormsi',
    country: 'EE',
  },
  {
    code: 'EE-917',
    label: 'Võru',
    country: 'EE',
  },
  {
    code: 'EE-919',
    label: 'Võru',
    country: 'EE',
  },
  {
    code: 'EE-928',
    label: 'Väike-Maarja',
    country: 'EE',
  },
  {
    code: 'EG-ALX',
    label: 'Al Iskandarīyah',
    country: 'EG',
  },
  {
    code: 'EG-ASN',
    label: 'Aswān',
    country: 'EG',
  },
  {
    code: 'EG-AST',
    label: 'Asyūţ',
    country: 'EG',
  },
  {
    code: 'EG-BA',
    label: 'Al Baḩr al Aḩmar',
    country: 'EG',
  },
  {
    code: 'EG-BH',
    label: 'Al Buḩayrah',
    country: 'EG',
  },
  {
    code: 'EG-BNS',
    label: 'Banī Suwayf',
    country: 'EG',
  },
  {
    code: 'EG-C',
    label: 'Al Qāhirah',
    country: 'EG',
  },
  {
    code: 'EG-DK',
    label: 'Ad Daqahlīyah',
    country: 'EG',
  },
  {
    code: 'EG-DT',
    label: 'Dumyāţ',
    country: 'EG',
  },
  {
    code: 'EG-FYM',
    label: 'Al Fayyūm',
    country: 'EG',
  },
  {
    code: 'EG-GH',
    label: 'Al Gharbīyah',
    country: 'EG',
  },
  {
    code: 'EG-GZ',
    label: 'Al Jīzah',
    country: 'EG',
  },
  {
    code: 'EG-IS',
    label: "Al Ismā'īlīyah",
    country: 'EG',
  },
  {
    code: 'EG-JS',
    label: "Janūb Sīnā'",
    country: 'EG',
  },
  {
    code: 'EG-KB',
    label: 'Al Qalyūbīyah',
    country: 'EG',
  },
  {
    code: 'EG-KFS',
    label: 'Kafr ash Shaykh',
    country: 'EG',
  },
  {
    code: 'EG-KN',
    label: 'Qinā',
    country: 'EG',
  },
  {
    code: 'EG-LX',
    label: 'Al Uqşur',
    country: 'EG',
  },
  {
    code: 'EG-MN',
    label: 'Al Minyā',
    country: 'EG',
  },
  {
    code: 'EG-MNF',
    label: 'Al Minūfīyah',
    country: 'EG',
  },
  {
    code: 'EG-MT',
    label: 'Maţrūḩ',
    country: 'EG',
  },
  {
    code: 'EG-PTS',
    label: 'Būr Sa‘īd',
    country: 'EG',
  },
  {
    code: 'EG-SHG',
    label: 'Sūhāj',
    country: 'EG',
  },
  {
    code: 'EG-SHR',
    label: 'Ash Sharqīyah',
    country: 'EG',
  },
  {
    code: 'EG-SIN',
    label: "Shamāl Sīnā'",
    country: 'EG',
  },
  {
    code: 'EG-SUZ',
    label: 'As Suways',
    country: 'EG',
  },
  {
    code: 'EG-WAD',
    label: 'Al Wādī al Jadīd',
    country: 'EG',
  },
  {
    code: 'ER-AN',
    label: 'Ansabā',
    country: 'ER',
  },
  {
    code: 'ER-DK',
    label: 'Janūbī al Baḩrī al Aḩmar',
    country: 'ER',
  },
  {
    code: 'ER-DU',
    label: 'Al Janūbī',
    country: 'ER',
  },
  {
    code: 'ER-GB',
    label: 'Qāsh-Barkah',
    country: 'ER',
  },
  {
    code: 'ER-MA',
    label: 'Al Awsaţ',
    country: 'ER',
  },
  {
    code: 'ER-SK',
    label: 'Shimālī al Baḩrī al Aḩmar',
    country: 'ER',
  },
  {
    code: 'ES-A',
    label: 'Alicante',
    country: 'ES',
  },
  {
    code: 'ES-AB',
    label: 'Albacete',
    country: 'ES',
  },
  {
    code: 'ES-AL',
    label: 'Almería',
    country: 'ES',
  },
  {
    code: 'ES-AV',
    label: 'Ávila',
    country: 'ES',
  },
  {
    code: 'ES-B',
    label: 'Barcelona',
    country: 'ES',
  },
  {
    code: 'ES-BA',
    label: 'Badajoz',
    country: 'ES',
  },
  {
    code: 'ES-BI',
    label: 'Bizkaia',
    country: 'ES',
  },
  {
    code: 'ES-BU',
    label: 'Burgos',
    country: 'ES',
  },
  {
    code: 'ES-C',
    label: 'A Coruña',
    country: 'ES',
  },
  {
    code: 'ES-CA',
    label: 'Cádiz',
    country: 'ES',
  },
  {
    code: 'ES-CC',
    label: 'Cáceres',
    country: 'ES',
  },
  {
    code: 'ES-CE',
    label: 'Ceuta',
    country: 'ES',
  },
  {
    code: 'ES-CO',
    label: 'Córdoba',
    country: 'ES',
  },
  {
    code: 'ES-CR',
    label: 'Ciudad Real',
    country: 'ES',
  },
  {
    code: 'ES-CS',
    label: 'Castellón',
    country: 'ES',
  },
  {
    code: 'ES-CU',
    label: 'Cuenca',
    country: 'ES',
  },
  {
    code: 'ES-GC',
    label: 'Las Palmas',
    country: 'ES',
  },
  {
    code: 'ES-GI',
    label: 'Girona',
    country: 'ES',
  },
  {
    code: 'ES-GR',
    label: 'Granada',
    country: 'ES',
  },
  {
    code: 'ES-GU',
    label: 'Guadalajara',
    country: 'ES',
  },
  {
    code: 'ES-H',
    label: 'Huelva',
    country: 'ES',
  },
  {
    code: 'ES-HU',
    label: 'Huesca',
    country: 'ES',
  },
  {
    code: 'ES-J',
    label: 'Jaén',
    country: 'ES',
  },
  {
    code: 'ES-L',
    label: 'Lleida',
    country: 'ES',
  },
  {
    code: 'ES-LE',
    label: 'León',
    country: 'ES',
  },
  {
    code: 'ES-LO',
    label: 'La Rioja',
    country: 'ES',
  },
  {
    code: 'ES-LU',
    label: 'Lugo',
    country: 'ES',
  },
  {
    code: 'ES-M',
    label: 'Madrid',
    country: 'ES',
  },
  {
    code: 'ES-MA',
    label: 'Málaga',
    country: 'ES',
  },
  {
    code: 'ES-ML',
    label: 'Melilla',
    country: 'ES',
  },
  {
    code: 'ES-MU',
    label: 'Murcia',
    country: 'ES',
  },
  {
    code: 'ES-NA',
    label: 'Navarra',
    country: 'ES',
  },
  {
    code: 'ES-O',
    label: 'Asturias',
    country: 'ES',
  },
  {
    code: 'ES-OR',
    label: 'Ourense',
    country: 'ES',
  },
  {
    code: 'ES-P',
    label: 'Palencia',
    country: 'ES',
  },
  {
    code: 'ES-PM',
    label: 'Illes Balears',
    country: 'ES',
  },
  {
    code: 'ES-PO',
    label: 'Pontevedra',
    country: 'ES',
  },
  {
    code: 'ES-S',
    label: 'Cantabria',
    country: 'ES',
  },
  {
    code: 'ES-SA',
    label: 'Salamanca',
    country: 'ES',
  },
  {
    code: 'ES-SE',
    label: 'Sevilla',
    country: 'ES',
  },
  {
    code: 'ES-SG',
    label: 'Segovia',
    country: 'ES',
  },
  {
    code: 'ES-SO',
    label: 'Soria',
    country: 'ES',
  },
  {
    code: 'ES-SS',
    label: 'Gipuzkoa',
    country: 'ES',
  },
  {
    code: 'ES-T',
    label: 'Tarragona',
    country: 'ES',
  },
  {
    code: 'ES-TE',
    label: 'Teruel',
    country: 'ES',
  },
  {
    code: 'ES-TF',
    label: 'Santa Cruz de Tenerife',
    country: 'ES',
  },
  {
    code: 'ES-TO',
    label: 'Toledo',
    country: 'ES',
  },
  {
    code: 'ES-V',
    label: 'Valencia',
    country: 'ES',
  },
  {
    code: 'ES-VA',
    label: 'Valladolid',
    country: 'ES',
  },
  {
    code: 'ES-VI',
    label: 'Álava',
    country: 'ES',
  },
  {
    code: 'ES-Z',
    label: 'Zaragoza',
    country: 'ES',
  },
  {
    code: 'ES-ZA',
    label: 'Zamora',
    country: 'ES',
  },
  {
    code: 'ET-AA',
    label: 'Ādīs Ābeba',
    country: 'ET',
  },
  {
    code: 'ET-AF',
    label: 'Āfar',
    country: 'ET',
  },
  {
    code: 'ET-AM',
    label: 'Āmara',
    country: 'ET',
  },
  {
    code: 'ET-BE',
    label: 'Bīnshangul Gumuz',
    country: 'ET',
  },
  {
    code: 'ET-DD',
    label: 'Dirē Dawa',
    country: 'ET',
  },
  {
    code: 'ET-GA',
    label: 'Gambēla Hizboch',
    country: 'ET',
  },
  {
    code: 'ET-HA',
    label: 'Hārerī Hizb',
    country: 'ET',
  },
  {
    code: 'ET-OR',
    label: 'Oromīya',
    country: 'ET',
  },
  {
    code: 'ET-SN',
    label: 'YeDebub Bihēroch Bihēreseboch na Hizboch',
    country: 'ET',
  },
  {
    code: 'ET-SO',
    label: 'Sumalē',
    country: 'ET',
  },
  {
    code: 'ET-TI',
    label: 'Tigray',
    country: 'ET',
  },
  {
    code: 'FI-01',
    label: 'Ahvenanmaan maakunta',
    country: 'FI',
  },
  {
    code: 'FI-02',
    label: 'Etelä-Karjala',
    country: 'FI',
  },
  {
    code: 'FI-03',
    label: 'Etelä-Pohjanmaa',
    country: 'FI',
  },
  {
    code: 'FI-04',
    label: 'Etelä-Savo',
    country: 'FI',
  },
  {
    code: 'FI-05',
    label: 'Kainuu',
    country: 'FI',
  },
  {
    code: 'FI-06',
    label: 'Kanta-Häme',
    country: 'FI',
  },
  {
    code: 'FI-07',
    label: 'Keski-Pohjanmaa',
    country: 'FI',
  },
  {
    code: 'FI-08',
    label: 'Keski-Suomi',
    country: 'FI',
  },
  {
    code: 'FI-09',
    label: 'Kymenlaakso',
    country: 'FI',
  },
  {
    code: 'FI-10',
    label: 'Lappi',
    country: 'FI',
  },
  {
    code: 'FI-11',
    label: 'Pirkanmaa',
    country: 'FI',
  },
  {
    code: 'FI-12',
    label: 'Pohjanmaa',
    country: 'FI',
  },
  {
    code: 'FI-13',
    label: 'Pohjois-Karjala',
    country: 'FI',
  },
  {
    code: 'FI-14',
    label: 'Pohjois-Pohjanmaa',
    country: 'FI',
  },
  {
    code: 'FI-15',
    label: 'Pohjois-Savo',
    country: 'FI',
  },
  {
    code: 'FI-16',
    label: 'Päijät-Häme',
    country: 'FI',
  },
  {
    code: 'FI-17',
    label: 'Satakunta',
    country: 'FI',
  },
  {
    code: 'FI-18',
    label: 'Uusimaa',
    country: 'FI',
  },
  {
    code: 'FI-19',
    label: 'Varsinais-Suomi',
    country: 'FI',
  },
  {
    code: 'FJ-01',
    label: 'Ba',
    country: 'FJ-W',
  },
  {
    code: 'FJ-02',
    label: 'Bua',
    country: 'FJ-N',
  },
  {
    code: 'FJ-03',
    label: 'Cakaudrove',
    country: 'FJ-N',
  },
  {
    code: 'FJ-04',
    label: 'Kadavu',
    country: 'FJ-E',
  },
  {
    code: 'FJ-05',
    label: 'Lau',
    country: 'FJ-E',
  },
  {
    code: 'FJ-06',
    label: 'Lomaiviti',
    country: 'FJ-E',
  },
  {
    code: 'FJ-07',
    label: 'Macuata',
    country: 'FJ-N',
  },
  {
    code: 'FJ-08',
    label: 'Nadroga and Navosa',
    country: 'FJ-W',
  },
  {
    code: 'FJ-09',
    label: 'Naitasiri',
    country: 'FJ-C',
  },
  {
    code: 'FJ-10',
    label: 'Namosi',
    country: 'FJ-C',
  },
  {
    code: 'FJ-11',
    label: 'Ra',
    country: 'FJ-W',
  },
  {
    code: 'FJ-12',
    label: 'Rewa',
    country: 'FJ-C',
  },
  {
    code: 'FJ-13',
    label: 'Serua',
    country: 'FJ-C',
  },
  {
    code: 'FJ-14',
    label: 'Tailevu',
    country: 'FJ-C',
  },
  {
    code: 'FJ-C',
    label: 'Central',
    country: 'FJ',
  },
  {
    code: 'FJ-E',
    label: 'Eastern',
    country: 'FJ',
  },
  {
    code: 'FJ-N',
    label: 'Northern',
    country: 'FJ',
  },
  {
    code: 'FJ-R',
    label: 'Rotuma',
    country: 'FJ',
  },
  {
    code: 'FJ-W',
    label: 'Western',
    country: 'FJ',
  },
  {
    code: 'FM-KSA',
    label: 'Kosrae',
    country: 'FM',
  },
  {
    code: 'FM-PNI',
    label: 'Pohnpei',
    country: 'FM',
  },
  {
    code: 'FM-TRK',
    label: 'Chuuk',
    country: 'FM',
  },
  {
    code: 'FM-YAP',
    label: 'Yap',
    country: 'FM',
  },
  {
    code: 'FR-01',
    label: 'Ain',
    country: 'FR-ARA',
  },
  {
    code: 'FR-02',
    label: 'Aisne',
    country: 'FR-HDF',
  },
  {
    code: 'FR-03',
    label: 'Allier',
    country: 'FR-ARA',
  },
  {
    code: 'FR-04',
    label: 'Alpes-de-Haute-Provence',
    country: 'FR-PAC',
  },
  {
    code: 'FR-05',
    label: 'Hautes-Alpes',
    country: 'FR-PAC',
  },
  {
    code: 'FR-06',
    label: 'Alpes-Maritimes',
    country: 'FR-PAC',
  },
  {
    code: 'FR-07',
    label: 'Ardèche',
    country: 'FR-ARA',
  },
  {
    code: 'FR-08',
    label: 'Ardennes',
    country: 'FR-GES',
  },
  {
    code: 'FR-09',
    label: 'Ariège',
    country: 'FR-OCC',
  },
  {
    code: 'FR-10',
    label: 'Aube',
    country: 'FR-GES',
  },
  {
    code: 'FR-11',
    label: 'Aude',
    country: 'FR-OCC',
  },
  {
    code: 'FR-12',
    label: 'Aveyron',
    country: 'FR-OCC',
  },
  {
    code: 'FR-13',
    label: 'Bouches-du-Rhône',
    country: 'FR-PAC',
  },
  {
    code: 'FR-14',
    label: 'Calvados',
    country: 'FR-NOR',
  },
  {
    code: 'FR-15',
    label: 'Cantal',
    country: 'FR-ARA',
  },
  {
    code: 'FR-16',
    label: 'Charente',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-17',
    label: 'Charente-Maritime',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-18',
    label: 'Cher',
    country: 'FR-CVL',
  },
  {
    code: 'FR-19',
    label: 'Corrèze',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-20R',
    label: 'Corse',
    country: 'FR',
  },
  {
    code: 'FR-21',
    label: "Côte-d'Or",
    country: 'FR-BFC',
  },
  {
    code: 'FR-22',
    label: "Côtes-d'Armor",
    country: 'FR-BRE',
  },
  {
    code: 'FR-23',
    label: 'Creuse',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-24',
    label: 'Dordogne',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-25',
    label: 'Doubs',
    country: 'FR-BFC',
  },
  {
    code: 'FR-26',
    label: 'Drôme',
    country: 'FR-ARA',
  },
  {
    code: 'FR-27',
    label: 'Eure',
    country: 'FR-NOR',
  },
  {
    code: 'FR-28',
    label: 'Eure-et-Loir',
    country: 'FR-CVL',
  },
  {
    code: 'FR-29',
    label: 'Finistère',
    country: 'FR-BRE',
  },
  {
    code: 'FR-2A',
    label: 'Corse-du-Sud',
    country: 'FR-20R',
  },
  {
    code: 'FR-2B',
    label: 'Haute-Corse',
    country: 'FR-20R',
  },
  {
    code: 'FR-30',
    label: 'Gard',
    country: 'FR-OCC',
  },
  {
    code: 'FR-31',
    label: 'Haute-Garonne',
    country: 'FR-OCC',
  },
  {
    code: 'FR-32',
    label: 'Gers',
    country: 'FR-OCC',
  },
  {
    code: 'FR-33',
    label: 'Gironde',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-34',
    label: 'Hérault',
    country: 'FR-OCC',
  },
  {
    code: 'FR-35',
    label: 'Ille-et-Vilaine',
    country: 'FR-BRE',
  },
  {
    code: 'FR-36',
    label: 'Indre',
    country: 'FR-CVL',
  },
  {
    code: 'FR-37',
    label: 'Indre-et-Loire',
    country: 'FR-CVL',
  },
  {
    code: 'FR-38',
    label: 'Isère',
    country: 'FR-ARA',
  },
  {
    code: 'FR-39',
    label: 'Jura',
    country: 'FR-BFC',
  },
  {
    code: 'FR-40',
    label: 'Landes',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-41',
    label: 'Loir-et-Cher',
    country: 'FR-CVL',
  },
  {
    code: 'FR-42',
    label: 'Loire',
    country: 'FR-ARA',
  },
  {
    code: 'FR-43',
    label: 'Haute-Loire',
    country: 'FR-ARA',
  },
  {
    code: 'FR-44',
    label: 'Loire-Atlantique',
    country: 'FR-PDL',
  },
  {
    code: 'FR-45',
    label: 'Loiret',
    country: 'FR-CVL',
  },
  {
    code: 'FR-46',
    label: 'Lot',
    country: 'FR-OCC',
  },
  {
    code: 'FR-47',
    label: 'Lot-et-Garonne',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-48',
    label: 'Lozère',
    country: 'FR-OCC',
  },
  {
    code: 'FR-49',
    label: 'Maine-et-Loire',
    country: 'FR-PDL',
  },
  {
    code: 'FR-50',
    label: 'Manche',
    country: 'FR-NOR',
  },
  {
    code: 'FR-51',
    label: 'Marne',
    country: 'FR-GES',
  },
  {
    code: 'FR-52',
    label: 'Haute-Marne',
    country: 'FR-GES',
  },
  {
    code: 'FR-53',
    label: 'Mayenne',
    country: 'FR-PDL',
  },
  {
    code: 'FR-54',
    label: 'Meurthe-et-Moselle',
    country: 'FR-GES',
  },
  {
    code: 'FR-55',
    label: 'Meuse',
    country: 'FR-GES',
  },
  {
    code: 'FR-56',
    label: 'Morbihan',
    country: 'FR-BRE',
  },
  {
    code: 'FR-57',
    label: 'Moselle',
    country: 'FR-GES',
  },
  {
    code: 'FR-58',
    label: 'Nièvre',
    country: 'FR-BFC',
  },
  {
    code: 'FR-59',
    label: 'Nord',
    country: 'FR-HDF',
  },
  {
    code: 'FR-60',
    label: 'Oise',
    country: 'FR-HDF',
  },
  {
    code: 'FR-61',
    label: 'Orne',
    country: 'FR-NOR',
  },
  {
    code: 'FR-62',
    label: 'Pas-de-Calais',
    country: 'FR-HDF',
  },
  {
    code: 'FR-63',
    label: 'Puy-de-Dôme',
    country: 'FR-ARA',
  },
  {
    code: 'FR-64',
    label: 'Pyrénées-Atlantiques',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-65',
    label: 'Hautes-Pyrénées',
    country: 'FR-OCC',
  },
  {
    code: 'FR-66',
    label: 'Pyrénées-Orientales',
    country: 'FR-OCC',
  },
  {
    code: 'FR-67',
    label: 'Bas-Rhin',
    country: 'FR-GES',
  },
  {
    code: 'FR-68',
    label: 'Haut-Rhin',
    country: 'FR-GES',
  },
  {
    code: 'FR-69',
    label: 'Rhône',
    country: 'FR-ARA',
  },
  {
    code: 'FR-70',
    label: 'Haute-Saône',
    country: 'FR-BFC',
  },
  {
    code: 'FR-71',
    label: 'Saône-et-Loire',
    country: 'FR-BFC',
  },
  {
    code: 'FR-72',
    label: 'Sarthe',
    country: 'FR-PDL',
  },
  {
    code: 'FR-73',
    label: 'Savoie',
    country: 'FR-ARA',
  },
  {
    code: 'FR-74',
    label: 'Haute-Savoie',
    country: 'FR-ARA',
  },
  {
    code: 'FR-75',
    label: 'Paris',
    country: 'FR-IDF',
  },
  {
    code: 'FR-76',
    label: 'Seine-Maritime',
    country: 'FR-NOR',
  },
  {
    code: 'FR-77',
    label: 'Seine-et-Marne',
    country: 'FR-IDF',
  },
  {
    code: 'FR-78',
    label: 'Yvelines',
    country: 'FR-IDF',
  },
  {
    code: 'FR-79',
    label: 'Deux-Sèvres',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-80',
    label: 'Somme',
    country: 'FR-HDF',
  },
  {
    code: 'FR-81',
    label: 'Tarn',
    country: 'FR-OCC',
  },
  {
    code: 'FR-82',
    label: 'Tarn-et-Garonne',
    country: 'FR-OCC',
  },
  {
    code: 'FR-83',
    label: 'Var',
    country: 'FR-PAC',
  },
  {
    code: 'FR-84',
    label: 'Vaucluse',
    country: 'FR-PAC',
  },
  {
    code: 'FR-85',
    label: 'Vendée',
    country: 'FR-PDL',
  },
  {
    code: 'FR-86',
    label: 'Vienne',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-87',
    label: 'Haute-Vienne',
    country: 'FR-NAQ',
  },
  {
    code: 'FR-88',
    label: 'Vosges',
    country: 'FR-GES',
  },
  {
    code: 'FR-89',
    label: 'Yonne',
    country: 'FR-BFC',
  },
  {
    code: 'FR-90',
    label: 'Territoire de Belfort',
    country: 'FR-BFC',
  },
  {
    code: 'FR-91',
    label: 'Essonne',
    country: 'FR-IDF',
  },
  {
    code: 'FR-92',
    label: 'Hauts-de-Seine',
    country: 'FR-IDF',
  },
  {
    code: 'FR-93',
    label: 'Seine-Saint-Denis',
    country: 'FR-IDF',
  },
  {
    code: 'FR-94',
    label: 'Val-de-Marne',
    country: 'FR-IDF',
  },
  {
    code: 'FR-95',
    label: "Val-d'Oise",
    country: 'FR-IDF',
  },
  {
    code: 'FR-971',
    label: 'Guadeloupe',
    country: 'FR-GP',
  },
  {
    code: 'FR-972',
    label: 'Martinique',
    country: 'FR-MQ',
  },
  {
    code: 'FR-973',
    label: 'Guyane (française)',
    country: 'FR-GF',
  },
  {
    code: 'FR-974',
    label: 'La Réunion',
    country: 'FR-RE',
  },
  {
    code: 'FR-976',
    label: 'Mayotte',
    country: 'FR-YT',
  },
  {
    code: 'FR-ARA',
    label: 'Auvergne-Rhône-Alpes',
    country: 'FR',
  },
  {
    code: 'FR-BFC',
    label: 'Bourgogne-Franche-Comté',
    country: 'FR',
  },
  {
    code: 'FR-BL',
    label: 'Saint-Barthélemy',
    country: 'FR',
  },
  {
    code: 'FR-BRE',
    label: 'Bretagne',
    country: 'FR',
  },
  {
    code: 'FR-CP',
    label: 'Clipperton',
    country: 'FR',
  },
  {
    code: 'FR-CVL',
    label: 'Centre-Val de Loire',
    country: 'FR',
  },
  {
    code: 'FR-GES',
    label: 'Grand-Est',
    country: 'FR',
  },
  {
    code: 'FR-GF',
    label: 'Guyane (française)',
    country: 'FR',
  },
  {
    code: 'FR-GP',
    label: 'Guadeloupe',
    country: 'FR',
  },
  {
    code: 'FR-HDF',
    label: 'Hauts-de-France',
    country: 'FR',
  },
  {
    code: 'FR-IDF',
    label: 'Île-de-France',
    country: 'FR',
  },
  {
    code: 'FR-MF',
    label: 'Saint-Martin',
    country: 'FR',
  },
  {
    code: 'FR-MQ',
    label: 'Martinique',
    country: 'FR',
  },
  {
    code: 'FR-NAQ',
    label: 'Nouvelle-Aquitaine',
    country: 'FR',
  },
  {
    code: 'FR-NC',
    label: 'Nouvelle-Calédonie',
    country: 'FR',
  },
  {
    code: 'FR-NOR',
    label: 'Normandie',
    country: 'FR',
  },
  {
    code: 'FR-OCC',
    label: 'Occitanie',
    country: 'FR',
  },
  {
    code: 'FR-PAC',
    label: 'Provence-Alpes-Côte-d’Azur',
    country: 'FR',
  },
  {
    code: 'FR-PDL',
    label: 'Pays-de-la-Loire',
    country: 'FR',
  },
  {
    code: 'FR-PF',
    label: 'Polynésie française',
    country: 'FR',
  },
  {
    code: 'FR-PM',
    label: 'Saint-Pierre-et-Miquelon',
    country: 'FR',
  },
  {
    code: 'FR-RE',
    label: 'La Réunion',
    country: 'FR',
  },
  {
    code: 'FR-TF',
    label: 'Terres australes françaises',
    country: 'FR',
  },
  {
    code: 'FR-WF',
    label: 'Wallis-et-Futuna',
    country: 'FR',
  },
  {
    code: 'FR-YT',
    label: 'Mayotte',
    country: 'FR',
  },
  {
    code: 'GA-1',
    label: 'Estuaire',
    country: 'GA',
  },
  {
    code: 'GA-2',
    label: 'Haut-Ogooué',
    country: 'GA',
  },
  {
    code: 'GA-3',
    label: 'Moyen-Ogooué',
    country: 'GA',
  },
  {
    code: 'GA-4',
    label: 'Ngounié',
    country: 'GA',
  },
  {
    code: 'GA-5',
    label: 'Nyanga',
    country: 'GA',
  },
  {
    code: 'GA-6',
    label: 'Ogooué-Ivindo',
    country: 'GA',
  },
  {
    code: 'GA-7',
    label: 'Ogooué-Lolo',
    country: 'GA',
  },
  {
    code: 'GA-8',
    label: 'Ogooué-Maritime',
    country: 'GA',
  },
  {
    code: 'GA-9',
    label: 'Woleu-Ntem',
    country: 'GA',
  },
  {
    code: 'GB-ABC',
    label: 'Armagh City, Banbridge and Craigavon',
    country: 'GB-NIR',
  },
  {
    code: 'GB-ABD',
    label: 'Aberdeenshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-ABE',
    label: 'Aberdeen City',
    country: 'GB-SCT',
  },
  {
    code: 'GB-AGB',
    label: 'Argyll and Bute',
    country: 'GB-SCT',
  },
  {
    code: 'GB-AGY',
    label: 'Isle of Anglesey',
    country: 'GB-WLS',
  },
  {
    code: 'GB-AND',
    label: 'Ards and North Down',
    country: 'GB-NIR',
  },
  {
    code: 'GB-ANN',
    label: 'Antrim and Newtownabbey',
    country: 'GB-NIR',
  },
  {
    code: 'GB-ANS',
    label: 'Angus',
    country: 'GB-SCT',
  },
  {
    code: 'GB-BAS',
    label: 'Bath and North East Somerset',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BBD',
    label: 'Blackburn with Darwen',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BCP',
    label: 'Bournemouth, Christchurch and Poole',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BDF',
    label: 'Bedford',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BDG',
    label: 'Barking and Dagenham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BEN',
    label: 'Brent',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BEX',
    label: 'Bexley',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BFS',
    label: 'Belfast City',
    country: 'GB-NIR',
  },
  {
    code: 'GB-BGE',
    label: 'Bridgend',
    country: 'GB-WLS',
  },
  {
    code: 'GB-BGW',
    label: 'Blaenau Gwent',
    country: 'GB-WLS',
  },
  {
    code: 'GB-BIR',
    label: 'Birmingham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BKM',
    label: 'Buckinghamshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BNE',
    label: 'Barnet',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BNH',
    label: 'Brighton and Hove',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BNS',
    label: 'Barnsley',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BOL',
    label: 'Bolton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BPL',
    label: 'Blackpool',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BRC',
    label: 'Bracknell Forest',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BRD',
    label: 'Bradford',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BRY',
    label: 'Bromley',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BST',
    label: 'Bristol, City of',
    country: 'GB-ENG',
  },
  {
    code: 'GB-BUR',
    label: 'Bury',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CAM',
    label: 'Cambridgeshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CAY',
    label: 'Caerphilly',
    country: 'GB-WLS',
  },
  {
    code: 'GB-CBF',
    label: 'Central Bedfordshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CCG',
    label: 'Causeway Coast and Glens',
    country: 'GB-NIR',
  },
  {
    code: 'GB-CGN',
    label: 'Ceredigion',
    country: 'GB-WLS',
  },
  {
    code: 'GB-CHE',
    label: 'Cheshire East',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CHW',
    label: 'Cheshire West and Chester',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CLD',
    label: 'Calderdale',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CLK',
    label: 'Clackmannanshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-CMA',
    label: 'Cumbria',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CMD',
    label: 'Camden',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CMN',
    label: 'Carmarthenshire',
    country: 'GB-WLS',
  },
  {
    code: 'GB-CON',
    label: 'Cornwall',
    country: 'GB-ENG',
  },
  {
    code: 'GB-COV',
    label: 'Coventry',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CRF',
    label: 'Cardiff',
    country: 'GB-WLS',
  },
  {
    code: 'GB-CRY',
    label: 'Croydon',
    country: 'GB-ENG',
  },
  {
    code: 'GB-CWY',
    label: 'Conwy',
    country: 'GB-WLS',
  },
  {
    code: 'GB-DAL',
    label: 'Darlington',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DBY',
    label: 'Derbyshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DEN',
    label: 'Denbighshire',
    country: 'GB-WLS',
  },
  {
    code: 'GB-DER',
    label: 'Derby',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DEV',
    label: 'Devon',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DGY',
    label: 'Dumfries and Galloway',
    country: 'GB-SCT',
  },
  {
    code: 'GB-DNC',
    label: 'Doncaster',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DND',
    label: 'Dundee City',
    country: 'GB-SCT',
  },
  {
    code: 'GB-DOR',
    label: 'Dorset',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DRS',
    label: 'Derry and Strabane',
    country: 'GB-NIR',
  },
  {
    code: 'GB-DUD',
    label: 'Dudley',
    country: 'GB-ENG',
  },
  {
    code: 'GB-DUR',
    label: 'Durham, County',
    country: 'GB-ENG',
  },
  {
    code: 'GB-EAL',
    label: 'Ealing',
    country: 'GB-ENG',
  },
  {
    code: 'GB-EAW',
    label: 'England and Wales',
    country: 'GB',
  },
  {
    code: 'GB-EAY',
    label: 'East Ayrshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-EDH',
    label: 'Edinburgh, City of',
    country: 'GB-SCT',
  },
  {
    code: 'GB-EDU',
    label: 'East Dunbartonshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-ELN',
    label: 'East Lothian',
    country: 'GB-SCT',
  },
  {
    code: 'GB-ELS',
    label: 'Eilean Siar',
    country: 'GB-SCT',
  },
  {
    code: 'GB-ENF',
    label: 'Enfield',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ENG',
    label: 'England',
    country: 'GB',
  },
  {
    code: 'GB-ERW',
    label: 'East Renfrewshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-ERY',
    label: 'East Riding of Yorkshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ESS',
    label: 'Essex',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ESX',
    label: 'East Sussex',
    country: 'GB-ENG',
  },
  {
    code: 'GB-FAL',
    label: 'Falkirk',
    country: 'GB-SCT',
  },
  {
    code: 'GB-FIF',
    label: 'Fife',
    country: 'GB-SCT',
  },
  {
    code: 'GB-FLN',
    label: 'Flintshire',
    country: 'GB-WLS',
  },
  {
    code: 'GB-FMO',
    label: 'Fermanagh and Omagh',
    country: 'GB-NIR',
  },
  {
    code: 'GB-GAT',
    label: 'Gateshead',
    country: 'GB-ENG',
  },
  {
    code: 'GB-GBN',
    label: 'Great Britain',
    country: 'GB',
  },
  {
    code: 'GB-GLG',
    label: 'Glasgow City',
    country: 'GB-SCT',
  },
  {
    code: 'GB-GLS',
    label: 'Gloucestershire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-GRE',
    label: 'Greenwich',
    country: 'GB-ENG',
  },
  {
    code: 'GB-GWN',
    label: 'Gwynedd',
    country: 'GB-WLS',
  },
  {
    code: 'GB-HAL',
    label: 'Halton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HAM',
    label: 'Hampshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HAV',
    label: 'Havering',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HCK',
    label: 'Hackney',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HEF',
    label: 'Herefordshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HIL',
    label: 'Hillingdon',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HLD',
    label: 'Highland',
    country: 'GB-SCT',
  },
  {
    code: 'GB-HMF',
    label: 'Hammersmith and Fulham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HNS',
    label: 'Hounslow',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HPL',
    label: 'Hartlepool',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HRT',
    label: 'Hertfordshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HRW',
    label: 'Harrow',
    country: 'GB-ENG',
  },
  {
    code: 'GB-HRY',
    label: 'Haringey',
    country: 'GB-ENG',
  },
  {
    code: 'GB-IOS',
    label: 'Isles of Scilly',
    country: 'GB-ENG',
  },
  {
    code: 'GB-IOW',
    label: 'Isle of Wight',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ISL',
    label: 'Islington',
    country: 'GB-ENG',
  },
  {
    code: 'GB-IVC',
    label: 'Inverclyde',
    country: 'GB-SCT',
  },
  {
    code: 'GB-KEC',
    label: 'Kensington and Chelsea',
    country: 'GB-ENG',
  },
  {
    code: 'GB-KEN',
    label: 'Kent',
    country: 'GB-ENG',
  },
  {
    code: 'GB-KHL',
    label: 'Kingston upon Hull',
    country: 'GB-ENG',
  },
  {
    code: 'GB-KIR',
    label: 'Kirklees',
    country: 'GB-ENG',
  },
  {
    code: 'GB-KTT',
    label: 'Kingston upon Thames',
    country: 'GB-ENG',
  },
  {
    code: 'GB-KWL',
    label: 'Knowsley',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LAN',
    label: 'Lancashire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LBC',
    label: 'Lisburn and Castlereagh',
    country: 'GB-NIR',
  },
  {
    code: 'GB-LBH',
    label: 'Lambeth',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LCE',
    label: 'Leicester',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LDS',
    label: 'Leeds',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LEC',
    label: 'Leicestershire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LEW',
    label: 'Lewisham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LIN',
    label: 'Lincolnshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LIV',
    label: 'Liverpool',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LND',
    label: 'London, City of',
    country: 'GB-ENG',
  },
  {
    code: 'GB-LUT',
    label: 'Luton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-MAN',
    label: 'Manchester',
    country: 'GB-ENG',
  },
  {
    code: 'GB-MDB',
    label: 'Middlesbrough',
    country: 'GB-ENG',
  },
  {
    code: 'GB-MDW',
    label: 'Medway',
    country: 'GB-ENG',
  },
  {
    code: 'GB-MEA',
    label: 'Mid and East Antrim',
    country: 'GB-NIR',
  },
  {
    code: 'GB-MIK',
    label: 'Milton Keynes',
    country: 'GB-ENG',
  },
  {
    code: 'GB-MLN',
    label: 'Midlothian',
    country: 'GB-SCT',
  },
  {
    code: 'GB-MON',
    label: 'Monmouthshire',
    country: 'GB-WLS',
  },
  {
    code: 'GB-MRT',
    label: 'Merton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-MRY',
    label: 'Moray',
    country: 'GB-SCT',
  },
  {
    code: 'GB-MTY',
    label: 'Merthyr Tydfil',
    country: 'GB-WLS',
  },
  {
    code: 'GB-MUL',
    label: 'Mid-Ulster',
    country: 'GB-NIR',
  },
  {
    code: 'GB-NAY',
    label: 'North Ayrshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-NBL',
    label: 'Northumberland',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NEL',
    label: 'North East Lincolnshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NET',
    label: 'Newcastle upon Tyne',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NFK',
    label: 'Norfolk',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NGM',
    label: 'Nottingham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NIR',
    label: 'Northern Ireland',
    country: 'GB',
  },
  {
    code: 'GB-NLK',
    label: 'North Lanarkshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-NLN',
    label: 'North Lincolnshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NMD',
    label: 'Newry, Mourne and Down',
    country: 'GB-NIR',
  },
  {
    code: 'GB-NSM',
    label: 'North Somerset',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NTH',
    label: 'Northamptonshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NTL',
    label: 'Neath Port Talbot',
    country: 'GB-WLS',
  },
  {
    code: 'GB-NTT',
    label: 'Nottinghamshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NTY',
    label: 'North Tyneside',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NWM',
    label: 'Newham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-NWP',
    label: 'Newport',
    country: 'GB-WLS',
  },
  {
    code: 'GB-NYK',
    label: 'North Yorkshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-OLD',
    label: 'Oldham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ORK',
    label: 'Orkney Islands',
    country: 'GB-SCT',
  },
  {
    code: 'GB-OXF',
    label: 'Oxfordshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-PEM',
    label: 'Pembrokeshire',
    country: 'GB-WLS',
  },
  {
    code: 'GB-PKN',
    label: 'Perth and Kinross',
    country: 'GB-SCT',
  },
  {
    code: 'GB-PLY',
    label: 'Plymouth',
    country: 'GB-ENG',
  },
  {
    code: 'GB-POR',
    label: 'Portsmouth',
    country: 'GB-ENG',
  },
  {
    code: 'GB-POW',
    label: 'Powys',
    country: 'GB-WLS',
  },
  {
    code: 'GB-PTE',
    label: 'Peterborough',
    country: 'GB-ENG',
  },
  {
    code: 'GB-RCC',
    label: 'Redcar and Cleveland',
    country: 'GB-ENG',
  },
  {
    code: 'GB-RCH',
    label: 'Rochdale',
    country: 'GB-ENG',
  },
  {
    code: 'GB-RCT',
    label: 'Rhondda Cynon Taff',
    country: 'GB-WLS',
  },
  {
    code: 'GB-RDB',
    label: 'Redbridge',
    country: 'GB-ENG',
  },
  {
    code: 'GB-RDG',
    label: 'Reading',
    country: 'GB-ENG',
  },
  {
    code: 'GB-RFW',
    label: 'Renfrewshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-RIC',
    label: 'Richmond upon Thames',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ROT',
    label: 'Rotherham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-RUT',
    label: 'Rutland',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SAW',
    label: 'Sandwell',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SAY',
    label: 'South Ayrshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-SCB',
    label: 'Scottish Borders',
    country: 'GB-SCT',
  },
  {
    code: 'GB-SCT',
    label: 'Scotland',
    country: 'GB',
  },
  {
    code: 'GB-SFK',
    label: 'Suffolk',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SFT',
    label: 'Sefton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SGC',
    label: 'South Gloucestershire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SHF',
    label: 'Sheffield',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SHN',
    label: 'St. Helens',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SHR',
    label: 'Shropshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SKP',
    label: 'Stockport',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SLF',
    label: 'Salford',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SLG',
    label: 'Slough',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SLK',
    label: 'South Lanarkshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-SND',
    label: 'Sunderland',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SOL',
    label: 'Solihull',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SOM',
    label: 'Somerset',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SOS',
    label: 'Southend-on-Sea',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SRY',
    label: 'Surrey',
    country: 'GB-ENG',
  },
  {
    code: 'GB-STE',
    label: 'Stoke-on-Trent',
    country: 'GB-ENG',
  },
  {
    code: 'GB-STG',
    label: 'Stirling',
    country: 'GB-SCT',
  },
  {
    code: 'GB-STH',
    label: 'Southampton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-STN',
    label: 'Sutton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-STS',
    label: 'Staffordshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-STT',
    label: 'Stockton-on-Tees',
    country: 'GB-ENG',
  },
  {
    code: 'GB-STY',
    label: 'South Tyneside',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SWA',
    label: 'Swansea',
    country: 'GB-WLS',
  },
  {
    code: 'GB-SWD',
    label: 'Swindon',
    country: 'GB-ENG',
  },
  {
    code: 'GB-SWK',
    label: 'Southwark',
    country: 'GB-ENG',
  },
  {
    code: 'GB-TAM',
    label: 'Tameside',
    country: 'GB-ENG',
  },
  {
    code: 'GB-TFW',
    label: 'Telford and Wrekin',
    country: 'GB-ENG',
  },
  {
    code: 'GB-THR',
    label: 'Thurrock',
    country: 'GB-ENG',
  },
  {
    code: 'GB-TOB',
    label: 'Torbay',
    country: 'GB-ENG',
  },
  {
    code: 'GB-TOF',
    label: 'Torfaen',
    country: 'GB-WLS',
  },
  {
    code: 'GB-TRF',
    label: 'Trafford',
    country: 'GB-ENG',
  },
  {
    code: 'GB-TWH',
    label: 'Tower Hamlets',
    country: 'GB-ENG',
  },
  {
    code: 'GB-UKM',
    label: 'United Kingdom',
    country: 'GB',
  },
  {
    code: 'GB-VGL',
    label: 'Vale of Glamorgan, The',
    country: 'GB-WLS',
  },
  {
    code: 'GB-WAR',
    label: 'Warwickshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WBK',
    label: 'West Berkshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WDU',
    label: 'West Dunbartonshire',
    country: 'GB-SCT',
  },
  {
    code: 'GB-WFT',
    label: 'Waltham Forest',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WGN',
    label: 'Wigan',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WIL',
    label: 'Wiltshire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WKF',
    label: 'Wakefield',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WLL',
    label: 'Walsall',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WLN',
    label: 'West Lothian',
    country: 'GB-SCT',
  },
  {
    code: 'GB-WLS',
    label: 'Wales',
    country: 'GB',
  },
  {
    code: 'GB-WLV',
    label: 'Wolverhampton',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WND',
    label: 'Wandsworth',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WNM',
    label: 'Windsor and Maidenhead',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WOK',
    label: 'Wokingham',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WOR',
    label: 'Worcestershire',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WRL',
    label: 'Wirral',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WRT',
    label: 'Warrington',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WRX',
    label: 'Wrexham',
    country: 'GB-WLS',
  },
  {
    code: 'GB-WSM',
    label: 'Westminster',
    country: 'GB-ENG',
  },
  {
    code: 'GB-WSX',
    label: 'West Sussex',
    country: 'GB-ENG',
  },
  {
    code: 'GB-YOR',
    label: 'York',
    country: 'GB-ENG',
  },
  {
    code: 'GB-ZET',
    label: 'Shetland Islands',
    country: 'GB-SCT',
  },
  {
    code: 'GD-01',
    label: 'Saint Andrew',
    country: 'GD',
  },
  {
    code: 'GD-02',
    label: 'Saint David',
    country: 'GD',
  },
  {
    code: 'GD-03',
    label: 'Saint George',
    country: 'GD',
  },
  {
    code: 'GD-04',
    label: 'Saint John',
    country: 'GD',
  },
  {
    code: 'GD-05',
    label: 'Saint Mark',
    country: 'GD',
  },
  {
    code: 'GD-06',
    label: 'Saint Patrick',
    country: 'GD',
  },
  {
    code: 'GD-10',
    label: 'Southern Grenadine Islands',
    country: 'GD',
  },
  {
    code: 'GE-AB',
    label: 'Abkhazia',
    country: 'GE',
  },
  {
    code: 'GE-AJ',
    label: 'Ajaria',
    country: 'GE',
  },
  {
    code: 'GE-GU',
    label: 'Guria',
    country: 'GE',
  },
  {
    code: 'GE-IM',
    label: 'Imereti',
    country: 'GE',
  },
  {
    code: 'GE-KA',
    label: "K'akheti",
    country: 'GE',
  },
  {
    code: 'GE-KK',
    label: 'Kvemo Kartli',
    country: 'GE',
  },
  {
    code: 'GE-MM',
    label: 'Mtskheta-Mtianeti',
    country: 'GE',
  },
  {
    code: 'GE-RL',
    label: "Rach'a-Lechkhumi-Kvemo Svaneti",
    country: 'GE',
  },
  {
    code: 'GE-SJ',
    label: 'Samtskhe-Javakheti',
    country: 'GE',
  },
  {
    code: 'GE-SK',
    label: 'Shida Kartli',
    country: 'GE',
  },
  {
    code: 'GE-SZ',
    label: 'Samegrelo-Zemo Svaneti',
    country: 'GE',
  },
  {
    code: 'GE-TB',
    label: 'Tbilisi',
    country: 'GE',
  },
  {
    code: 'GH-AA',
    label: 'Greater Accra',
    country: 'GH',
  },
  {
    code: 'GH-AF',
    label: 'Ahafo',
    country: 'GH',
  },
  {
    code: 'GH-AH',
    label: 'Ashanti',
    country: 'GH',
  },
  {
    code: 'GH-BA',
    label: 'Brong-Ahafo',
    country: 'GH',
  },
  {
    code: 'GH-BE',
    label: 'Bono East',
    country: 'GH',
  },
  {
    code: 'GH-BO',
    label: 'Bono',
    country: 'GH',
  },
  {
    code: 'GH-CP',
    label: 'Central',
    country: 'GH',
  },
  {
    code: 'GH-EP',
    label: 'Eastern',
    country: 'GH',
  },
  {
    code: 'GH-NE',
    label: 'North East',
    country: 'GH',
  },
  {
    code: 'GH-NP',
    label: 'Northern',
    country: 'GH',
  },
  {
    code: 'GH-OT',
    label: 'Oti',
    country: 'GH',
  },
  {
    code: 'GH-SV',
    label: 'Savannah',
    country: 'GH',
  },
  {
    code: 'GH-TV',
    label: 'Volta',
    country: 'GH',
  },
  {
    code: 'GH-UE',
    label: 'Upper East',
    country: 'GH',
  },
  {
    code: 'GH-UW',
    label: 'Upper West',
    country: 'GH',
  },
  {
    code: 'GH-WN',
    label: 'Western North',
    country: 'GH',
  },
  {
    code: 'GH-WP',
    label: 'Western',
    country: 'GH',
  },
  {
    code: 'GL-AV',
    label: 'Avannaata Kommunia',
    country: 'GL',
  },
  {
    code: 'GL-KU',
    label: 'Kommune Kujalleq',
    country: 'GL',
  },
  {
    code: 'GL-QE',
    label: 'Qeqqata Kommunia',
    country: 'GL',
  },
  {
    code: 'GL-QT',
    label: 'Kommune Qeqertalik',
    country: 'GL',
  },
  {
    code: 'GL-SM',
    label: 'Kommuneqarfik Sermersooq',
    country: 'GL',
  },
  {
    code: 'GM-B',
    label: 'Banjul',
    country: 'GM',
  },
  {
    code: 'GM-L',
    label: 'Lower River',
    country: 'GM',
  },
  {
    code: 'GM-M',
    label: 'Central River',
    country: 'GM',
  },
  {
    code: 'GM-N',
    label: 'North Bank',
    country: 'GM',
  },
  {
    code: 'GM-U',
    label: 'Upper River',
    country: 'GM',
  },
  {
    code: 'GM-W',
    label: 'Western',
    country: 'GM',
  },
  {
    code: 'GN-B',
    label: 'Boké',
    country: 'GN',
  },
  {
    code: 'GN-BE',
    label: 'Beyla',
    country: 'GN-N',
  },
  {
    code: 'GN-BF',
    label: 'Boffa',
    country: 'GN-B',
  },
  {
    code: 'GN-BK',
    label: 'Boké',
    country: 'GN-B',
  },
  {
    code: 'GN-C',
    label: 'Conakry',
    country: 'GN',
  },
  {
    code: 'GN-CO',
    label: 'Coyah',
    country: 'GN-D',
  },
  {
    code: 'GN-D',
    label: 'Kindia',
    country: 'GN',
  },
  {
    code: 'GN-DB',
    label: 'Dabola',
    country: 'GN-F',
  },
  {
    code: 'GN-DI',
    label: 'Dinguiraye',
    country: 'GN-F',
  },
  {
    code: 'GN-DL',
    label: 'Dalaba',
    country: 'GN-M',
  },
  {
    code: 'GN-DU',
    label: 'Dubréka',
    country: 'GN-D',
  },
  {
    code: 'GN-F',
    label: 'Faranah',
    country: 'GN',
  },
  {
    code: 'GN-FA',
    label: 'Faranah',
    country: 'GN-F',
  },
  {
    code: 'GN-FO',
    label: 'Forécariah',
    country: 'GN-D',
  },
  {
    code: 'GN-FR',
    label: 'Fria',
    country: 'GN-B',
  },
  {
    code: 'GN-GA',
    label: 'Gaoual',
    country: 'GN-B',
  },
  {
    code: 'GN-GU',
    label: 'Guékédou',
    country: 'GN-N',
  },
  {
    code: 'GN-K',
    label: 'Kankan',
    country: 'GN',
  },
  {
    code: 'GN-KA',
    label: 'Kankan',
    country: 'GN-K',
  },
  {
    code: 'GN-KB',
    label: 'Koubia',
    country: 'GN-L',
  },
  {
    code: 'GN-KD',
    label: 'Kindia',
    country: 'GN-D',
  },
  {
    code: 'GN-KE',
    label: 'Kérouané',
    country: 'GN-K',
  },
  {
    code: 'GN-KN',
    label: 'Koundara',
    country: 'GN-B',
  },
  {
    code: 'GN-KO',
    label: 'Kouroussa',
    country: 'GN-K',
  },
  {
    code: 'GN-KS',
    label: 'Kissidougou',
    country: 'GN-F',
  },
  {
    code: 'GN-L',
    label: 'Labé',
    country: 'GN',
  },
  {
    code: 'GN-LA',
    label: 'Labé',
    country: 'GN-L',
  },
  {
    code: 'GN-LE',
    label: 'Lélouma',
    country: 'GN-L',
  },
  {
    code: 'GN-LO',
    label: 'Lola',
    country: 'GN-N',
  },
  {
    code: 'GN-M',
    label: 'Mamou',
    country: 'GN',
  },
  {
    code: 'GN-MC',
    label: 'Macenta',
    country: 'GN-N',
  },
  {
    code: 'GN-MD',
    label: 'Mandiana',
    country: 'GN-K',
  },
  {
    code: 'GN-ML',
    label: 'Mali',
    country: 'GN-L',
  },
  {
    code: 'GN-MM',
    label: 'Mamou',
    country: 'GN-M',
  },
  {
    code: 'GN-N',
    label: 'Nzérékoré',
    country: 'GN',
  },
  {
    code: 'GN-NZ',
    label: 'Nzérékoré',
    country: 'GN-N',
  },
  {
    code: 'GN-PI',
    label: 'Pita',
    country: 'GN-M',
  },
  {
    code: 'GN-SI',
    label: 'Siguiri',
    country: 'GN-K',
  },
  {
    code: 'GN-TE',
    label: 'Télimélé',
    country: 'GN-D',
  },
  {
    code: 'GN-TO',
    label: 'Tougué',
    country: 'GN-L',
  },
  {
    code: 'GN-YO',
    label: 'Yomou',
    country: 'GN-N',
  },
  {
    code: 'GQ-AN',
    label: 'Annobón',
    country: 'GQ-I',
  },
  {
    code: 'GQ-BN',
    label: 'Bioko Norte',
    country: 'GQ-I',
  },
  {
    code: 'GQ-BS',
    label: 'Bioko Sur',
    country: 'GQ-I',
  },
  {
    code: 'GQ-C',
    label: 'Región Continental',
    country: 'GQ',
  },
  {
    code: 'GQ-CS',
    label: 'Centro Sur',
    country: 'GQ-C',
  },
  {
    code: 'GQ-DJ',
    label: 'Djibloho',
    country: 'GQ-C',
  },
  {
    code: 'GQ-I',
    label: 'Región Insular',
    country: 'GQ',
  },
  {
    code: 'GQ-KN',
    label: 'Kié-Ntem',
    country: 'GQ-C',
  },
  {
    code: 'GQ-LI',
    label: 'Litoral',
    country: 'GQ-C',
  },
  {
    code: 'GQ-WN',
    label: 'Wele-Nzas',
    country: 'GQ-C',
  },
  {
    code: 'GR-69',
    label: 'Ágion Óros',
    country: 'GR',
  },
  {
    code: 'GR-A',
    label: 'Anatolikí Makedonía kai Thráki',
    country: 'GR',
  },
  {
    code: 'GR-B',
    label: 'Kentrikí Makedonía',
    country: 'GR',
  },
  {
    code: 'GR-C',
    label: 'Dytikí Makedonía',
    country: 'GR',
  },
  {
    code: 'GR-D',
    label: 'Ípeiros',
    country: 'GR',
  },
  {
    code: 'GR-E',
    label: 'Thessalía',
    country: 'GR',
  },
  {
    code: 'GR-F',
    label: 'Ionía Nísia',
    country: 'GR',
  },
  {
    code: 'GR-G',
    label: 'Dytikí Elláda',
    country: 'GR',
  },
  {
    code: 'GR-H',
    label: 'Stereá Elláda',
    country: 'GR',
  },
  {
    code: 'GR-I',
    label: 'Attikí',
    country: 'GR',
  },
  {
    code: 'GR-J',
    label: 'Pelopónnisos',
    country: 'GR',
  },
  {
    code: 'GR-K',
    label: 'Vóreio Aigaío',
    country: 'GR',
  },
  {
    code: 'GR-L',
    label: 'Nótio Aigaío',
    country: 'GR',
  },
  {
    code: 'GR-M',
    label: 'Kríti',
    country: 'GR',
  },
  {
    code: 'GT-AV',
    label: 'Alta Verapaz',
    country: 'GT',
  },
  {
    code: 'GT-BV',
    label: 'Baja Verapaz',
    country: 'GT',
  },
  {
    code: 'GT-CM',
    label: 'Chimaltenango',
    country: 'GT',
  },
  {
    code: 'GT-CQ',
    label: 'Chiquimula',
    country: 'GT',
  },
  {
    code: 'GT-ES',
    label: 'Escuintla',
    country: 'GT',
  },
  {
    code: 'GT-GU',
    label: 'Guatemala',
    country: 'GT',
  },
  {
    code: 'GT-HU',
    label: 'Huehuetenango',
    country: 'GT',
  },
  {
    code: 'GT-IZ',
    label: 'Izabal',
    country: 'GT',
  },
  {
    code: 'GT-JA',
    label: 'Jalapa',
    country: 'GT',
  },
  {
    code: 'GT-JU',
    label: 'Jutiapa',
    country: 'GT',
  },
  {
    code: 'GT-PE',
    label: 'Petén',
    country: 'GT',
  },
  {
    code: 'GT-PR',
    label: 'El Progreso',
    country: 'GT',
  },
  {
    code: 'GT-QC',
    label: 'Quiché',
    country: 'GT',
  },
  {
    code: 'GT-QZ',
    label: 'Quetzaltenango',
    country: 'GT',
  },
  {
    code: 'GT-RE',
    label: 'Retalhuleu',
    country: 'GT',
  },
  {
    code: 'GT-SA',
    label: 'Sacatepéquez',
    country: 'GT',
  },
  {
    code: 'GT-SM',
    label: 'San Marcos',
    country: 'GT',
  },
  {
    code: 'GT-SO',
    label: 'Sololá',
    country: 'GT',
  },
  {
    code: 'GT-SR',
    label: 'Santa Rosa',
    country: 'GT',
  },
  {
    code: 'GT-SU',
    label: 'Suchitepéquez',
    country: 'GT',
  },
  {
    code: 'GT-TO',
    label: 'Totonicapán',
    country: 'GT',
  },
  {
    code: 'GT-ZA',
    label: 'Zacapa',
    country: 'GT',
  },
  {
    code: 'GW-BA',
    label: 'Bafatá',
    country: 'GW-L',
  },
  {
    code: 'GW-BL',
    label: 'Bolama / Bijagós',
    country: 'GW-S',
  },
  {
    code: 'GW-BM',
    label: 'Biombo',
    country: 'GW-N',
  },
  {
    code: 'GW-BS',
    label: 'Bissau',
    country: 'GW',
  },
  {
    code: 'GW-CA',
    label: 'Cacheu',
    country: 'GW-N',
  },
  {
    code: 'GW-GA',
    label: 'Gabú',
    country: 'GW-L',
  },
  {
    code: 'GW-L',
    label: 'Leste',
    country: 'GW',
  },
  {
    code: 'GW-N',
    label: 'Norte',
    country: 'GW',
  },
  {
    code: 'GW-OI',
    label: 'Oio',
    country: 'GW-N',
  },
  {
    code: 'GW-QU',
    label: 'Quinara',
    country: 'GW-S',
  },
  {
    code: 'GW-S',
    label: 'Sul',
    country: 'GW',
  },
  {
    code: 'GW-TO',
    label: 'Tombali',
    country: 'GW-S',
  },
  {
    code: 'GY-BA',
    label: 'Barima-Waini',
    country: 'GY',
  },
  {
    code: 'GY-CU',
    label: 'Cuyuni-Mazaruni',
    country: 'GY',
  },
  {
    code: 'GY-DE',
    label: 'Demerara-Mahaica',
    country: 'GY',
  },
  {
    code: 'GY-EB',
    label: 'East Berbice-Corentyne',
    country: 'GY',
  },
  {
    code: 'GY-ES',
    label: 'Essequibo Islands-West Demerara',
    country: 'GY',
  },
  {
    code: 'GY-MA',
    label: 'Mahaica-Berbice',
    country: 'GY',
  },
  {
    code: 'GY-PM',
    label: 'Pomeroon-Supenaam',
    country: 'GY',
  },
  {
    code: 'GY-PT',
    label: 'Potaro-Siparuni',
    country: 'GY',
  },
  {
    code: 'GY-UD',
    label: 'Upper Demerara-Berbice',
    country: 'GY',
  },
  {
    code: 'GY-UT',
    label: 'Upper Takutu-Upper Essequibo',
    country: 'GY',
  },
  {
    code: 'HN-AT',
    label: 'Atlántida',
    country: 'HN',
  },
  {
    code: 'HN-CH',
    label: 'Choluteca',
    country: 'HN',
  },
  {
    code: 'HN-CL',
    label: 'Colón',
    country: 'HN',
  },
  {
    code: 'HN-CM',
    label: 'Comayagua',
    country: 'HN',
  },
  {
    code: 'HN-CP',
    label: 'Copán',
    country: 'HN',
  },
  {
    code: 'HN-CR',
    label: 'Cortés',
    country: 'HN',
  },
  {
    code: 'HN-EP',
    label: 'El Paraíso',
    country: 'HN',
  },
  {
    code: 'HN-FM',
    label: 'Francisco Morazán',
    country: 'HN',
  },
  {
    code: 'HN-GD',
    label: 'Gracias a Dios',
    country: 'HN',
  },
  {
    code: 'HN-IB',
    label: 'Islas de la Bahía',
    country: 'HN',
  },
  {
    code: 'HN-IN',
    label: 'Intibucá',
    country: 'HN',
  },
  {
    code: 'HN-LE',
    label: 'Lempira',
    country: 'HN',
  },
  {
    code: 'HN-LP',
    label: 'La Paz',
    country: 'HN',
  },
  {
    code: 'HN-OC',
    label: 'Ocotepeque',
    country: 'HN',
  },
  {
    code: 'HN-OL',
    label: 'Olancho',
    country: 'HN',
  },
  {
    code: 'HN-SB',
    label: 'Santa Bárbara',
    country: 'HN',
  },
  {
    code: 'HN-VA',
    label: 'Valle',
    country: 'HN',
  },
  {
    code: 'HN-YO',
    label: 'Yoro',
    country: 'HN',
  },
  {
    code: 'HR-01',
    label: 'Zagrebačka županija',
    country: 'HR',
  },
  {
    code: 'HR-02',
    label: 'Krapinsko-zagorska županija',
    country: 'HR',
  },
  {
    code: 'HR-03',
    label: 'Sisačko-moslavačka županija',
    country: 'HR',
  },
  {
    code: 'HR-04',
    label: 'Karlovačka županija',
    country: 'HR',
  },
  {
    code: 'HR-05',
    label: 'Varaždinska županija',
    country: 'HR',
  },
  {
    code: 'HR-06',
    label: 'Koprivničko-križevačka županija',
    country: 'HR',
  },
  {
    code: 'HR-07',
    label: 'Bjelovarsko-bilogorska županija',
    country: 'HR',
  },
  {
    code: 'HR-08',
    label: 'Primorsko-goranska županija',
    country: 'HR',
  },
  {
    code: 'HR-09',
    label: 'Ličko-senjska županija',
    country: 'HR',
  },
  {
    code: 'HR-10',
    label: 'Virovitičko-podravska županija',
    country: 'HR',
  },
  {
    code: 'HR-11',
    label: 'Požeško-slavonska županija',
    country: 'HR',
  },
  {
    code: 'HR-12',
    label: 'Brodsko-posavska županija',
    country: 'HR',
  },
  {
    code: 'HR-13',
    label: 'Zadarska županija',
    country: 'HR',
  },
  {
    code: 'HR-14',
    label: 'Osječko-baranjska županija',
    country: 'HR',
  },
  {
    code: 'HR-15',
    label: 'Šibensko-kninska županija',
    country: 'HR',
  },
  {
    code: 'HR-16',
    label: 'Vukovarsko-srijemska županija',
    country: 'HR',
  },
  {
    code: 'HR-17',
    label: 'Splitsko-dalmatinska županija',
    country: 'HR',
  },
  {
    code: 'HR-18',
    label: 'Istarska županija',
    country: 'HR',
  },
  {
    code: 'HR-19',
    label: 'Dubrovačko-neretvanska županija',
    country: 'HR',
  },
  {
    code: 'HR-20',
    label: 'Međimurska županija',
    country: 'HR',
  },
  {
    code: 'HR-21',
    label: 'Grad Zagreb',
    country: 'HR',
  },
  {
    code: 'HT-AR',
    label: 'Artibonite',
    country: 'HT',
  },
  {
    code: 'HT-CE',
    label: 'Centre',
    country: 'HT',
  },
  {
    code: 'HT-GA',
    label: 'Grande’Anse',
    country: 'HT',
  },
  {
    code: 'HT-ND',
    label: 'Nord',
    country: 'HT',
  },
  {
    code: 'HT-NE',
    label: 'Nord-Est',
    country: 'HT',
  },
  {
    code: 'HT-NI',
    label: 'Nippes',
    country: 'HT',
  },
  {
    code: 'HT-NO',
    label: 'Nord-Ouest',
    country: 'HT',
  },
  {
    code: 'HT-OU',
    label: 'Ouest',
    country: 'HT',
  },
  {
    code: 'HT-SD',
    label: 'Sud',
    country: 'HT',
  },
  {
    code: 'HT-SE',
    label: 'Sud-Est',
    country: 'HT',
  },
  {
    code: 'HU-BA',
    label: 'Baranya',
    country: 'HU',
  },
  {
    code: 'HU-BC',
    label: 'Békéscsaba',
    country: 'HU',
  },
  {
    code: 'HU-BE',
    label: 'Békés',
    country: 'HU',
  },
  {
    code: 'HU-BK',
    label: 'Bács-Kiskun',
    country: 'HU',
  },
  {
    code: 'HU-BU',
    label: 'Budapest',
    country: 'HU',
  },
  {
    code: 'HU-BZ',
    label: 'Borsod-Abaúj-Zemplén',
    country: 'HU',
  },
  {
    code: 'HU-CS',
    label: 'Csongrád',
    country: 'HU',
  },
  {
    code: 'HU-DE',
    label: 'Debrecen',
    country: 'HU',
  },
  {
    code: 'HU-DU',
    label: 'Dunaújváros',
    country: 'HU',
  },
  {
    code: 'HU-EG',
    label: 'Eger',
    country: 'HU',
  },
  {
    code: 'HU-ER',
    label: 'Érd',
    country: 'HU',
  },
  {
    code: 'HU-FE',
    label: 'Fejér',
    country: 'HU',
  },
  {
    code: 'HU-GS',
    label: 'Győr-Moson-Sopron',
    country: 'HU',
  },
  {
    code: 'HU-GY',
    label: 'Győr',
    country: 'HU',
  },
  {
    code: 'HU-HB',
    label: 'Hajdú-Bihar',
    country: 'HU',
  },
  {
    code: 'HU-HE',
    label: 'Heves',
    country: 'HU',
  },
  {
    code: 'HU-HV',
    label: 'Hódmezővásárhely',
    country: 'HU',
  },
  {
    code: 'HU-JN',
    label: 'Jász-Nagykun-Szolnok',
    country: 'HU',
  },
  {
    code: 'HU-KE',
    label: 'Komárom-Esztergom',
    country: 'HU',
  },
  {
    code: 'HU-KM',
    label: 'Kecskemét',
    country: 'HU',
  },
  {
    code: 'HU-KV',
    label: 'Kaposvár',
    country: 'HU',
  },
  {
    code: 'HU-MI',
    label: 'Miskolc',
    country: 'HU',
  },
  {
    code: 'HU-NK',
    label: 'Nagykanizsa',
    country: 'HU',
  },
  {
    code: 'HU-NO',
    label: 'Nógrád',
    country: 'HU',
  },
  {
    code: 'HU-NY',
    label: 'Nyíregyháza',
    country: 'HU',
  },
  {
    code: 'HU-PE',
    label: 'Pest',
    country: 'HU',
  },
  {
    code: 'HU-PS',
    label: 'Pécs',
    country: 'HU',
  },
  {
    code: 'HU-SD',
    label: 'Szeged',
    country: 'HU',
  },
  {
    code: 'HU-SF',
    label: 'Székesfehérvár',
    country: 'HU',
  },
  {
    code: 'HU-SH',
    label: 'Szombathely',
    country: 'HU',
  },
  {
    code: 'HU-SK',
    label: 'Szolnok',
    country: 'HU',
  },
  {
    code: 'HU-SN',
    label: 'Sopron',
    country: 'HU',
  },
  {
    code: 'HU-SO',
    label: 'Somogy',
    country: 'HU',
  },
  {
    code: 'HU-SS',
    label: 'Szekszárd',
    country: 'HU',
  },
  {
    code: 'HU-ST',
    label: 'Salgótarján',
    country: 'HU',
  },
  {
    code: 'HU-SZ',
    label: 'Szabolcs-Szatmár-Bereg',
    country: 'HU',
  },
  {
    code: 'HU-TB',
    label: 'Tatabánya',
    country: 'HU',
  },
  {
    code: 'HU-TO',
    label: 'Tolna',
    country: 'HU',
  },
  {
    code: 'HU-VA',
    label: 'Vas',
    country: 'HU',
  },
  {
    code: 'HU-VE',
    label: 'Veszprém',
    country: 'HU',
  },
  {
    code: 'HU-VM',
    label: 'Veszprém',
    country: 'HU',
  },
  {
    code: 'HU-ZA',
    label: 'Zala',
    country: 'HU',
  },
  {
    code: 'HU-ZE',
    label: 'Zalaegerszeg',
    country: 'HU',
  },
  {
    code: 'ID-AC',
    label: 'Aceh',
    country: 'ID-SM',
  },
  {
    code: 'ID-BA',
    label: 'Bali',
    country: 'ID-NU',
  },
  {
    code: 'ID-BB',
    label: 'Kepulauan Bangka Belitung',
    country: 'ID-SM',
  },
  {
    code: 'ID-BE',
    label: 'Bengkulu',
    country: 'ID-SM',
  },
  {
    code: 'ID-BT',
    label: 'Banten',
    country: 'ID-JW',
  },
  {
    code: 'ID-GO',
    label: 'Gorontalo',
    country: 'ID-SL',
  },
  {
    code: 'ID-JA',
    label: 'Jambi',
    country: 'ID-SM',
  },
  {
    code: 'ID-JB',
    label: 'Jawa Barat',
    country: 'ID-JW',
  },
  {
    code: 'ID-JI',
    label: 'Jawa Timur',
    country: 'ID-JW',
  },
  {
    code: 'ID-JK',
    label: 'Jakarta Raya',
    country: 'ID-JW',
  },
  {
    code: 'ID-JT',
    label: 'Jawa Tengah',
    country: 'ID-JW',
  },
  {
    code: 'ID-JW',
    label: 'Jawa',
    country: 'ID',
  },
  {
    code: 'ID-KA',
    label: 'Kalimantan',
    country: 'ID',
  },
  {
    code: 'ID-KB',
    label: 'Kalimantan Barat',
    country: 'ID-KA',
  },
  {
    code: 'ID-KI',
    label: 'Kalimantan Timur',
    country: 'ID-KA',
  },
  {
    code: 'ID-KR',
    label: 'Kepulauan Riau',
    country: 'ID-SM',
  },
  {
    code: 'ID-KS',
    label: 'Kalimantan Selatan',
    country: 'ID-KA',
  },
  {
    code: 'ID-KT',
    label: 'Kalimantan Tengah',
    country: 'ID-KA',
  },
  {
    code: 'ID-KU',
    label: 'Kalimantan Utara',
    country: 'ID-KA',
  },
  {
    code: 'ID-LA',
    label: 'Lampung',
    country: 'ID-SM',
  },
  {
    code: 'ID-MA',
    label: 'Maluku',
    country: 'ID-ML',
  },
  {
    code: 'ID-ML',
    label: 'Maluku',
    country: 'ID',
  },
  {
    code: 'ID-MU',
    label: 'Maluku Utara',
    country: 'ID-ML',
  },
  {
    code: 'ID-NB',
    label: 'Nusa Tenggara Barat',
    country: 'ID-NU',
  },
  {
    code: 'ID-NT',
    label: 'Nusa Tenggara Timur',
    country: 'ID-NU',
  },
  {
    code: 'ID-NU',
    label: 'Nusa Tenggara',
    country: 'ID',
  },
  {
    code: 'ID-PA',
    label: 'Papua',
    country: 'ID-PP',
  },
  {
    code: 'ID-PB',
    label: 'Papua Barat',
    country: 'ID-PP',
  },
  {
    code: 'ID-PP',
    label: 'Papua',
    country: 'ID',
  },
  {
    code: 'ID-RI',
    label: 'Riau',
    country: 'ID-SM',
  },
  {
    code: 'ID-SA',
    label: 'Sulawesi Utara',
    country: 'ID-SL',
  },
  {
    code: 'ID-SB',
    label: 'Sumatera Barat',
    country: 'ID-SM',
  },
  {
    code: 'ID-SG',
    label: 'Sulawesi Tenggara',
    country: 'ID-SL',
  },
  {
    code: 'ID-SL',
    label: 'Sulawesi',
    country: 'ID',
  },
  {
    code: 'ID-SM',
    label: 'Sumatera',
    country: 'ID',
  },
  {
    code: 'ID-SN',
    label: 'Sulawesi Selatan',
    country: 'ID-SL',
  },
  {
    code: 'ID-SR',
    label: 'Sulawesi Barat',
    country: 'ID-SL',
  },
  {
    code: 'ID-SS',
    label: 'Sumatera Selatan',
    country: 'ID-SM',
  },
  {
    code: 'ID-ST',
    label: 'Sulawesi Tengah',
    country: 'ID-SL',
  },
  {
    code: 'ID-SU',
    label: 'Sumatera Utara',
    country: 'ID-SM',
  },
  {
    code: 'ID-YO',
    label: 'Yogyakarta',
    country: 'ID-JW',
  },
  {
    code: 'IE-C',
    label: 'Connaught',
    country: 'IE',
  },
  {
    code: 'IE-CE',
    label: 'Clare',
    country: 'IE-M',
  },
  {
    code: 'IE-CN',
    label: 'Cavan',
    country: 'IE-U',
  },
  {
    code: 'IE-CO',
    label: 'Cork',
    country: 'IE-M',
  },
  {
    code: 'IE-CW',
    label: 'Carlow',
    country: 'IE-L',
  },
  {
    code: 'IE-D',
    label: 'Dublin',
    country: 'IE-L',
  },
  {
    code: 'IE-DL',
    label: 'Donegal',
    country: 'IE-U',
  },
  {
    code: 'IE-G',
    label: 'Galway',
    country: 'IE-C',
  },
  {
    code: 'IE-KE',
    label: 'Kildare',
    country: 'IE-L',
  },
  {
    code: 'IE-KK',
    label: 'Kilkenny',
    country: 'IE-L',
  },
  {
    code: 'IE-KY',
    label: 'Kerry',
    country: 'IE-M',
  },
  {
    code: 'IE-L',
    label: 'Leinster',
    country: 'IE',
  },
  {
    code: 'IE-LD',
    label: 'Longford',
    country: 'IE-L',
  },
  {
    code: 'IE-LH',
    label: 'Louth',
    country: 'IE-L',
  },
  {
    code: 'IE-LK',
    label: 'Limerick',
    country: 'IE-M',
  },
  {
    code: 'IE-LM',
    label: 'Leitrim',
    country: 'IE-C',
  },
  {
    code: 'IE-LS',
    label: 'Laois',
    country: 'IE-L',
  },
  {
    code: 'IE-M',
    label: 'Munster',
    country: 'IE',
  },
  {
    code: 'IE-MH',
    label: 'Meath',
    country: 'IE-L',
  },
  {
    code: 'IE-MN',
    label: 'Monaghan',
    country: 'IE-U',
  },
  {
    code: 'IE-MO',
    label: 'Mayo',
    country: 'IE-C',
  },
  {
    code: 'IE-OY',
    label: 'Offaly',
    country: 'IE-L',
  },
  {
    code: 'IE-RN',
    label: 'Roscommon',
    country: 'IE-C',
  },
  {
    code: 'IE-SO',
    label: 'Sligo',
    country: 'IE-C',
  },
  {
    code: 'IE-TA',
    label: 'Tipperary',
    country: 'IE-M',
  },
  {
    code: 'IE-U',
    label: 'Ulster',
    country: 'IE',
  },
  {
    code: 'IE-WD',
    label: 'Waterford',
    country: 'IE-M',
  },
  {
    code: 'IE-WH',
    label: 'Westmeath',
    country: 'IE-L',
  },
  {
    code: 'IE-WW',
    label: 'Wicklow',
    country: 'IE-L',
  },
  {
    code: 'IE-WX',
    label: 'Wexford',
    country: 'IE-L',
  },
  {
    code: 'IL-D',
    label: 'HaDarom',
    country: 'IL',
  },
  {
    code: 'IL-HA',
    label: 'H̱efa',
    country: 'IL',
  },
  {
    code: 'IL-JM',
    label: 'Yerushalayim',
    country: 'IL',
  },
  {
    code: 'IL-M',
    label: 'HaMerkaz',
    country: 'IL',
  },
  {
    code: 'IL-TA',
    label: 'Tel Aviv',
    country: 'IL',
  },
  {
    code: 'IL-Z',
    label: 'HaTsafon',
    country: 'IL',
  },
  {
    code: 'IN-AN',
    label: 'Andaman and Nicobar Islands',
    country: 'IN',
  },
  {
    code: 'IN-AP',
    label: 'Andhra Pradesh',
    country: 'IN',
  },
  {
    code: 'IN-AR',
    label: 'Arunāchal Pradesh',
    country: 'IN',
  },
  {
    code: 'IN-AS',
    label: 'Assam',
    country: 'IN',
  },
  {
    code: 'IN-BR',
    label: 'Bihār',
    country: 'IN',
  },
  {
    code: 'IN-CH',
    label: 'Chandigarh',
    country: 'IN',
  },
  {
    code: 'IN-CT',
    label: 'Chhattīsgarh',
    country: 'IN',
  },
  {
    code: 'IN-DH',
    label: 'Dādra and Nagar Haveli and Damān and Diu',
    country: 'IN',
  },
  {
    code: 'IN-DL',
    label: 'Delhi',
    country: 'IN',
  },
  {
    code: 'IN-GA',
    label: 'Goa',
    country: 'IN',
  },
  {
    code: 'IN-GJ',
    label: 'Gujarāt',
    country: 'IN',
  },
  {
    code: 'IN-HP',
    label: 'Himāchal Pradesh',
    country: 'IN',
  },
  {
    code: 'IN-HR',
    label: 'Haryāna',
    country: 'IN',
  },
  {
    code: 'IN-JH',
    label: 'Jhārkhand',
    country: 'IN',
  },
  {
    code: 'IN-JK',
    label: 'Jammu and Kashmīr',
    country: 'IN',
  },
  {
    code: 'IN-KA',
    label: 'Karnātaka',
    country: 'IN',
  },
  {
    code: 'IN-KL',
    label: 'Kerala',
    country: 'IN',
  },
  {
    code: 'IN-LA',
    label: 'Ladākh',
    country: 'IN',
  },
  {
    code: 'IN-LD',
    label: 'Lakshadweep',
    country: 'IN',
  },
  {
    code: 'IN-MH',
    label: 'Mahārāshtra',
    country: 'IN',
  },
  {
    code: 'IN-ML',
    label: 'Meghālaya',
    country: 'IN',
  },
  {
    code: 'IN-MN',
    label: 'Manipur',
    country: 'IN',
  },
  {
    code: 'IN-MP',
    label: 'Madhya Pradesh',
    country: 'IN',
  },
  {
    code: 'IN-MZ',
    label: 'Mizoram',
    country: 'IN',
  },
  {
    code: 'IN-NL',
    label: 'Nāgāland',
    country: 'IN',
  },
  {
    code: 'IN-OR',
    label: 'Odisha',
    country: 'IN',
  },
  {
    code: 'IN-PB',
    label: 'Punjab',
    country: 'IN',
  },
  {
    code: 'IN-PY',
    label: 'Puducherry',
    country: 'IN',
  },
  {
    code: 'IN-RJ',
    label: 'Rājasthān',
    country: 'IN',
  },
  {
    code: 'IN-SK',
    label: 'Sikkim',
    country: 'IN',
  },
  {
    code: 'IN-TG',
    label: 'Telangāna',
    country: 'IN',
  },
  {
    code: 'IN-TN',
    label: 'Tamil Nādu',
    country: 'IN',
  },
  {
    code: 'IN-TR',
    label: 'Tripura',
    country: 'IN',
  },
  {
    code: 'IN-UP',
    label: 'Uttar Pradesh',
    country: 'IN',
  },
  {
    code: 'IN-UT',
    label: 'Uttarākhand',
    country: 'IN',
  },
  {
    code: 'IN-WB',
    label: 'West Bengal',
    country: 'IN',
  },
  {
    code: 'IQ-AN',
    label: 'Al Anbār',
    country: 'IQ',
  },
  {
    code: 'IQ-AR',
    label: 'Arbīl',
    country: 'IQ',
  },
  {
    code: 'IQ-BA',
    label: 'Al Başrah',
    country: 'IQ',
  },
  {
    code: 'IQ-BB',
    label: 'Bābil',
    country: 'IQ',
  },
  {
    code: 'IQ-BG',
    label: 'Baghdād',
    country: 'IQ',
  },
  {
    code: 'IQ-DA',
    label: 'Dahūk',
    country: 'IQ',
  },
  {
    code: 'IQ-DI',
    label: 'Diyālá',
    country: 'IQ',
  },
  {
    code: 'IQ-DQ',
    label: 'Dhī Qār',
    country: 'IQ',
  },
  {
    code: 'IQ-HA',
    label: 'Halabja',
    country: 'IQ',
  },
  {
    code: 'IQ-KA',
    label: 'Karbalā’',
    country: 'IQ',
  },
  {
    code: 'IQ-KI',
    label: 'Kirkūk',
    country: 'IQ',
  },
  {
    code: 'IQ-MA',
    label: 'Maysān',
    country: 'IQ',
  },
  {
    code: 'IQ-MU',
    label: 'Al Muthanná',
    country: 'IQ',
  },
  {
    code: 'IQ-NA',
    label: 'An Najaf',
    country: 'IQ',
  },
  {
    code: 'IQ-NI',
    label: 'Nīnawá',
    country: 'IQ',
  },
  {
    code: 'IQ-QA',
    label: 'Al Qādisīyah',
    country: 'IQ',
  },
  {
    code: 'IQ-SD',
    label: 'Şalāḩ ad Dīn',
    country: 'IQ',
  },
  {
    code: 'IQ-SU',
    label: 'As Sulaymānīyah',
    country: 'IQ',
  },
  {
    code: 'IQ-WA',
    label: 'Wāsiţ',
    country: 'IQ',
  },
  {
    code: 'IR-00',
    label: 'Markazī',
    country: 'IR',
  },
  {
    code: 'IR-01',
    label: 'Gīlān',
    country: 'IR',
  },
  {
    code: 'IR-02',
    label: 'Māzandarān',
    country: 'IR',
  },
  {
    code: 'IR-03',
    label: 'Āz̄ārbāyjān-e Shārqī',
    country: 'IR',
  },
  {
    code: 'IR-04',
    label: 'Āz̄ārbāyjān-e Ghārbī',
    country: 'IR',
  },
  {
    code: 'IR-05',
    label: 'Kermānshāh',
    country: 'IR',
  },
  {
    code: 'IR-06',
    label: 'Khūzestān',
    country: 'IR',
  },
  {
    code: 'IR-07',
    label: 'Fārs',
    country: 'IR',
  },
  {
    code: 'IR-08',
    label: 'Kermān',
    country: 'IR',
  },
  {
    code: 'IR-09',
    label: 'Khorāsān-e Raẕavī',
    country: 'IR',
  },
  {
    code: 'IR-10',
    label: 'Eşfahān',
    country: 'IR',
  },
  {
    code: 'IR-11',
    label: 'Sīstān va Balūchestān',
    country: 'IR',
  },
  {
    code: 'IR-12',
    label: 'Kordestān',
    country: 'IR',
  },
  {
    code: 'IR-13',
    label: 'Hamadān',
    country: 'IR',
  },
  {
    code: 'IR-14',
    label: 'Chahār Maḩāl va Bakhtīārī',
    country: 'IR',
  },
  {
    code: 'IR-15',
    label: 'Lorestān',
    country: 'IR',
  },
  {
    code: 'IR-16',
    label: 'Īlām',
    country: 'IR',
  },
  {
    code: 'IR-17',
    label: 'Kohgīlūyeh va Bowyer Aḩmad',
    country: 'IR',
  },
  {
    code: 'IR-18',
    label: 'Būshehr',
    country: 'IR',
  },
  {
    code: 'IR-19',
    label: 'Zanjān',
    country: 'IR',
  },
  {
    code: 'IR-20',
    label: 'Semnān',
    country: 'IR',
  },
  {
    code: 'IR-21',
    label: 'Yazd',
    country: 'IR',
  },
  {
    code: 'IR-22',
    label: 'Hormozgān',
    country: 'IR',
  },
  {
    code: 'IR-23',
    label: 'Tehrān',
    country: 'IR',
  },
  {
    code: 'IR-24',
    label: 'Ardabīl',
    country: 'IR',
  },
  {
    code: 'IR-25',
    label: 'Qom',
    country: 'IR',
  },
  {
    code: 'IR-26',
    label: 'Qazvīn',
    country: 'IR',
  },
  {
    code: 'IR-27',
    label: 'Golestān',
    country: 'IR',
  },
  {
    code: 'IR-28',
    label: 'Khorāsān-e Shomālī',
    country: 'IR',
  },
  {
    code: 'IR-29',
    label: 'Khorāsān-e Jonūbī',
    country: 'IR',
  },
  {
    code: 'IR-30',
    label: 'Alborz',
    country: 'IR',
  },
  {
    code: 'IS-1',
    label: 'Höfuðborgarsvæði',
    country: 'IS',
  },
  {
    code: 'IS-2',
    label: 'Suðurnes',
    country: 'IS',
  },
  {
    code: 'IS-3',
    label: 'Vesturland',
    country: 'IS',
  },
  {
    code: 'IS-4',
    label: 'Vestfirðir',
    country: 'IS',
  },
  {
    code: 'IS-5',
    label: 'Norðurland vestra',
    country: 'IS',
  },
  {
    code: 'IS-6',
    label: 'Norðurland eystra',
    country: 'IS',
  },
  {
    code: 'IS-7',
    label: 'Austurland',
    country: 'IS',
  },
  {
    code: 'IS-8',
    label: 'Suðurland',
    country: 'IS',
  },
  {
    code: 'IS-AKH',
    label: 'Akrahreppur',
    country: 'IS',
  },
  {
    code: 'IS-AKN',
    label: 'Akraneskaupstaður',
    country: 'IS',
  },
  {
    code: 'IS-AKU',
    label: 'Akureyrarbær',
    country: 'IS',
  },
  {
    code: 'IS-ARN',
    label: 'Árneshreppur',
    country: 'IS',
  },
  {
    code: 'IS-ASA',
    label: 'Ásahreppur',
    country: 'IS',
  },
  {
    code: 'IS-BFJ',
    label: 'Borgarfjarðarhreppur',
    country: 'IS',
  },
  {
    code: 'IS-BLA',
    label: 'Bláskógabyggð',
    country: 'IS',
  },
  {
    code: 'IS-BLO',
    label: 'Blönduósbær',
    country: 'IS',
  },
  {
    code: 'IS-BOG',
    label: 'Borgarbyggð',
    country: 'IS',
  },
  {
    code: 'IS-BOL',
    label: 'Bolungarvíkurkaupstaður',
    country: 'IS',
  },
  {
    code: 'IS-DAB',
    label: 'Dalabyggð',
    country: 'IS',
  },
  {
    code: 'IS-DAV',
    label: 'Dalvíkurbyggð',
    country: 'IS',
  },
  {
    code: 'IS-DJU',
    label: 'Djúpavogshreppur',
    country: 'IS',
  },
  {
    code: 'IS-EOM',
    label: 'Eyja- og Miklaholtshreppur',
    country: 'IS',
  },
  {
    code: 'IS-EYF',
    label: 'Eyjafjarðarsveit',
    country: 'IS',
  },
  {
    code: 'IS-FJD',
    label: 'Fjarðabyggð',
    country: 'IS',
  },
  {
    code: 'IS-FJL',
    label: 'Fjallabyggð',
    country: 'IS',
  },
  {
    code: 'IS-FLA',
    label: 'Flóahreppur',
    country: 'IS',
  },
  {
    code: 'IS-FLD',
    label: 'Fljótsdalshérað',
    country: 'IS',
  },
  {
    code: 'IS-FLR',
    label: 'Fljótsdalshreppur',
    country: 'IS',
  },
  {
    code: 'IS-GAR',
    label: 'Garðabær',
    country: 'IS',
  },
  {
    code: 'IS-GOG',
    label: 'Grímsnes- og Grafningshreppur',
    country: 'IS',
  },
  {
    code: 'IS-GRN',
    label: 'Grindavíkurbær',
    country: 'IS',
  },
  {
    code: 'IS-GRU',
    label: 'Grundarfjarðarbær',
    country: 'IS',
  },
  {
    code: 'IS-GRY',
    label: 'Grýtubakkahreppur',
    country: 'IS',
  },
  {
    code: 'IS-HAF',
    label: 'Hafnarfjarðarkaupstaður',
    country: 'IS',
  },
  {
    code: 'IS-HEL',
    label: 'Helgafellssveit',
    country: 'IS',
  },
  {
    code: 'IS-HRG',
    label: 'Hörgársveit',
    country: 'IS',
  },
  {
    code: 'IS-HRU',
    label: 'Hrunamannahreppur',
    country: 'IS',
  },
  {
    code: 'IS-HUT',
    label: 'Húnavatnshreppur',
    country: 'IS',
  },
  {
    code: 'IS-HUV',
    label: 'Húnaþing vestra',
    country: 'IS',
  },
  {
    code: 'IS-HVA',
    label: 'Hvalfjarðarsveit',
    country: 'IS',
  },
  {
    code: 'IS-HVE',
    label: 'Hveragerðisbær',
    country: 'IS',
  },
  {
    code: 'IS-ISA',
    label: 'Ísafjarðarbær',
    country: 'IS',
  },
  {
    code: 'IS-KAL',
    label: 'Kaldrananeshreppur',
    country: 'IS',
  },
  {
    code: 'IS-KJO',
    label: 'Kjósarhreppur',
    country: 'IS',
  },
  {
    code: 'IS-KOP',
    label: 'Kópavogsbær',
    country: 'IS',
  },
  {
    code: 'IS-LAN',
    label: 'Langanesbyggð',
    country: 'IS',
  },
  {
    code: 'IS-MOS',
    label: 'Mosfellsbær',
    country: 'IS',
  },
  {
    code: 'IS-MYR',
    label: 'Mýrdalshreppur',
    country: 'IS',
  },
  {
    code: 'IS-NOR',
    label: 'Norðurþing',
    country: 'IS',
  },
  {
    code: 'IS-RGE',
    label: 'Rangárþing eystra',
    country: 'IS',
  },
  {
    code: 'IS-RGY',
    label: 'Rangárþing ytra',
    country: 'IS',
  },
  {
    code: 'IS-RHH',
    label: 'Reykhólahreppur',
    country: 'IS',
  },
  {
    code: 'IS-RKN',
    label: 'Reykjanesbær',
    country: 'IS',
  },
  {
    code: 'IS-RKV',
    label: 'Reykjavíkurborg',
    country: 'IS',
  },
  {
    code: 'IS-SBH',
    label: 'Svalbarðshreppur',
    country: 'IS',
  },
  {
    code: 'IS-SBT',
    label: 'Svalbarðsstrandarhreppur',
    country: 'IS',
  },
  {
    code: 'IS-SDN',
    label: 'Suðurnesjabær',
    country: 'IS',
  },
  {
    code: 'IS-SDV',
    label: 'Súðavíkurhreppur',
    country: 'IS',
  },
  {
    code: 'IS-SEL',
    label: 'Seltjarnarnesbær',
    country: 'IS',
  },
  {
    code: 'IS-SEY',
    label: 'Seyðisfjarðarkaupstaður',
    country: 'IS',
  },
  {
    code: 'IS-SFA',
    label: 'Sveitarfélagið Árborg',
    country: 'IS',
  },
  {
    code: 'IS-SHF',
    label: 'Sveitarfélagið Hornafjörður',
    country: 'IS',
  },
  {
    code: 'IS-SKF',
    label: 'Skaftárhreppur',
    country: 'IS',
  },
  {
    code: 'IS-SKG',
    label: 'Skagabyggð',
    country: 'IS',
  },
  {
    code: 'IS-SKO',
    label: 'Skorradalshreppur',
    country: 'IS',
  },
  {
    code: 'IS-SKU',
    label: 'Skútustaðahreppur',
    country: 'IS',
  },
  {
    code: 'IS-SNF',
    label: 'Snæfellsbær',
    country: 'IS',
  },
  {
    code: 'IS-SOG',
    label: 'Skeiða- og Gnúpverjahreppur',
    country: 'IS',
  },
  {
    code: 'IS-SOL',
    label: 'Sveitarfélagið Ölfus',
    country: 'IS',
  },
  {
    code: 'IS-SSF',
    label: 'Sveitarfélagið Skagafjörður',
    country: 'IS',
  },
  {
    code: 'IS-SSS',
    label: 'Sveitarfélagið Skagaströnd',
    country: 'IS',
  },
  {
    code: 'IS-STR',
    label: 'Strandabyggð',
    country: 'IS',
  },
  {
    code: 'IS-STY',
    label: 'Stykkishólmsbær',
    country: 'IS',
  },
  {
    code: 'IS-SVG',
    label: 'Sveitarfélagið Vogar',
    country: 'IS',
  },
  {
    code: 'IS-TAL',
    label: 'Tálknafjarðarhreppur',
    country: 'IS',
  },
  {
    code: 'IS-THG',
    label: 'Þingeyjarsveit',
    country: 'IS',
  },
  {
    code: 'IS-TJO',
    label: 'Tjörneshreppur',
    country: 'IS',
  },
  {
    code: 'IS-VEM',
    label: 'Vestmannaeyjabær',
    country: 'IS',
  },
  {
    code: 'IS-VER',
    label: 'Vesturbyggð',
    country: 'IS',
  },
  {
    code: 'IS-VOP',
    label: 'Vopnafjarðarhreppur',
    country: 'IS',
  },
  {
    code: 'IT-21',
    label: 'Piemonte',
    country: 'IT',
  },
  {
    code: 'IT-23',
    label: "Valle d'Aosta, Val d'Aoste",
    country: 'IT',
  },
  {
    code: 'IT-25',
    label: 'Lombardia',
    country: 'IT',
  },
  {
    code: 'IT-32',
    label: 'Trentino-Alto Adige, Trentino-Südtirol',
    country: 'IT',
  },
  {
    code: 'IT-34',
    label: 'Veneto',
    country: 'IT',
  },
  {
    code: 'IT-36',
    label: 'Friuli Venezia Giulia',
    country: 'IT',
  },
  {
    code: 'IT-42',
    label: 'Liguria',
    country: 'IT',
  },
  {
    code: 'IT-45',
    label: 'Emilia-Romagna',
    country: 'IT',
  },
  {
    code: 'IT-52',
    label: 'Toscana',
    country: 'IT',
  },
  {
    code: 'IT-55',
    label: 'Umbria',
    country: 'IT',
  },
  {
    code: 'IT-57',
    label: 'Marche',
    country: 'IT',
  },
  {
    code: 'IT-62',
    label: 'Lazio',
    country: 'IT',
  },
  {
    code: 'IT-65',
    label: 'Abruzzo',
    country: 'IT',
  },
  {
    code: 'IT-67',
    label: 'Molise',
    country: 'IT',
  },
  {
    code: 'IT-72',
    label: 'Campania',
    country: 'IT',
  },
  {
    code: 'IT-75',
    label: 'Puglia',
    country: 'IT',
  },
  {
    code: 'IT-77',
    label: 'Basilicata',
    country: 'IT',
  },
  {
    code: 'IT-78',
    label: 'Calabria',
    country: 'IT',
  },
  {
    code: 'IT-82',
    label: 'Sicilia',
    country: 'IT',
  },
  {
    code: 'IT-88',
    label: 'Sardegna',
    country: 'IT',
  },
  {
    code: 'IT-AG',
    label: 'Agrigento',
    country: 'IT-82',
  },
  {
    code: 'IT-AL',
    label: 'Alessandria',
    country: 'IT-21',
  },
  {
    code: 'IT-AN',
    label: 'Ancona',
    country: 'IT-57',
  },
  {
    code: 'IT-AP',
    label: 'Ascoli Piceno',
    country: 'IT-57',
  },
  {
    code: 'IT-AQ',
    label: "L'Aquila",
    country: 'IT-65',
  },
  {
    code: 'IT-AR',
    label: 'Arezzo',
    country: 'IT-52',
  },
  {
    code: 'IT-AT',
    label: 'Asti',
    country: 'IT-21',
  },
  {
    code: 'IT-AV',
    label: 'Avellino',
    country: 'IT-72',
  },
  {
    code: 'IT-BA',
    label: 'Bari',
    country: 'IT-75',
  },
  {
    code: 'IT-BG',
    label: 'Bergamo',
    country: 'IT-25',
  },
  {
    code: 'IT-BI',
    label: 'Biella',
    country: 'IT-21',
  },
  {
    code: 'IT-BL',
    label: 'Belluno',
    country: 'IT-34',
  },
  {
    code: 'IT-BN',
    label: 'Benevento',
    country: 'IT-72',
  },
  {
    code: 'IT-BO',
    label: 'Bologna',
    country: 'IT-45',
  },
  {
    code: 'IT-BR',
    label: 'Brindisi',
    country: 'IT-75',
  },
  {
    code: 'IT-BS',
    label: 'Brescia',
    country: 'IT-25',
  },
  {
    code: 'IT-BT',
    label: 'Barletta-Andria-Trani',
    country: 'IT-75',
  },
  {
    code: 'IT-BZ',
    label: 'Bolzano, Bozen',
    country: 'IT-32',
  },
  {
    code: 'IT-CA',
    label: 'Cagliari',
    country: 'IT-88',
  },
  {
    code: 'IT-CB',
    label: 'Campobasso',
    country: 'IT-67',
  },
  {
    code: 'IT-CE',
    label: 'Caserta',
    country: 'IT-72',
  },
  {
    code: 'IT-CH',
    label: 'Chieti',
    country: 'IT-65',
  },
  {
    code: 'IT-CL',
    label: 'Caltanissetta',
    country: 'IT-82',
  },
  {
    code: 'IT-CN',
    label: 'Cuneo',
    country: 'IT-21',
  },
  {
    code: 'IT-CO',
    label: 'Como',
    country: 'IT-25',
  },
  {
    code: 'IT-CR',
    label: 'Cremona',
    country: 'IT-25',
  },
  {
    code: 'IT-CS',
    label: 'Cosenza',
    country: 'IT-78',
  },
  {
    code: 'IT-CT',
    label: 'Catania',
    country: 'IT-82',
  },
  {
    code: 'IT-CZ',
    label: 'Catanzaro',
    country: 'IT-78',
  },
  {
    code: 'IT-EN',
    label: 'Enna',
    country: 'IT-82',
  },
  {
    code: 'IT-FC',
    label: 'Forlì-Cesena',
    country: 'IT-45',
  },
  {
    code: 'IT-FE',
    label: 'Ferrara',
    country: 'IT-45',
  },
  {
    code: 'IT-FG',
    label: 'Foggia',
    country: 'IT-75',
  },
  {
    code: 'IT-FI',
    label: 'Firenze',
    country: 'IT-52',
  },
  {
    code: 'IT-FM',
    label: 'Fermo',
    country: 'IT-57',
  },
  {
    code: 'IT-FR',
    label: 'Frosinone',
    country: 'IT-62',
  },
  {
    code: 'IT-GE',
    label: 'Genova',
    country: 'IT-42',
  },
  {
    code: 'IT-GO',
    label: 'Gorizia',
    country: 'IT-36',
  },
  {
    code: 'IT-GR',
    label: 'Grosseto',
    country: 'IT-52',
  },
  {
    code: 'IT-IM',
    label: 'Imperia',
    country: 'IT-42',
  },
  {
    code: 'IT-IS',
    label: 'Isernia',
    country: 'IT-67',
  },
  {
    code: 'IT-KR',
    label: 'Crotone',
    country: 'IT-78',
  },
  {
    code: 'IT-LC',
    label: 'Lecco',
    country: 'IT-25',
  },
  {
    code: 'IT-LE',
    label: 'Lecce',
    country: 'IT-75',
  },
  {
    code: 'IT-LI',
    label: 'Livorno',
    country: 'IT-52',
  },
  {
    code: 'IT-LO',
    label: 'Lodi',
    country: 'IT-25',
  },
  {
    code: 'IT-LT',
    label: 'Latina',
    country: 'IT-62',
  },
  {
    code: 'IT-LU',
    label: 'Lucca',
    country: 'IT-52',
  },
  {
    code: 'IT-MB',
    label: 'Monza e Brianza',
    country: 'IT-25',
  },
  {
    code: 'IT-MC',
    label: 'Macerata',
    country: 'IT-57',
  },
  {
    code: 'IT-ME',
    label: 'Messina',
    country: 'IT-82',
  },
  {
    code: 'IT-MI',
    label: 'Milano',
    country: 'IT-25',
  },
  {
    code: 'IT-MN',
    label: 'Mantova',
    country: 'IT-25',
  },
  {
    code: 'IT-MO',
    label: 'Modena',
    country: 'IT-45',
  },
  {
    code: 'IT-MS',
    label: 'Massa-Carrara',
    country: 'IT-52',
  },
  {
    code: 'IT-MT',
    label: 'Matera',
    country: 'IT-77',
  },
  {
    code: 'IT-NA',
    label: 'Napoli',
    country: 'IT-72',
  },
  {
    code: 'IT-NO',
    label: 'Novara',
    country: 'IT-21',
  },
  {
    code: 'IT-NU',
    label: 'Nuoro',
    country: 'IT-88',
  },
  {
    code: 'IT-OR',
    label: 'Oristano',
    country: 'IT-88',
  },
  {
    code: 'IT-PA',
    label: 'Palermo',
    country: 'IT-82',
  },
  {
    code: 'IT-PC',
    label: 'Piacenza',
    country: 'IT-45',
  },
  {
    code: 'IT-PD',
    label: 'Padova',
    country: 'IT-34',
  },
  {
    code: 'IT-PE',
    label: 'Pescara',
    country: 'IT-65',
  },
  {
    code: 'IT-PG',
    label: 'Perugia',
    country: 'IT-55',
  },
  {
    code: 'IT-PI',
    label: 'Pisa',
    country: 'IT-52',
  },
  {
    code: 'IT-PN',
    label: 'Pordenone',
    country: 'IT-36',
  },
  {
    code: 'IT-PO',
    label: 'Prato',
    country: 'IT-52',
  },
  {
    code: 'IT-PR',
    label: 'Parma',
    country: 'IT-45',
  },
  {
    code: 'IT-PT',
    label: 'Pistoia',
    country: 'IT-52',
  },
  {
    code: 'IT-PU',
    label: 'Pesaro e Urbino',
    country: 'IT-57',
  },
  {
    code: 'IT-PV',
    label: 'Pavia',
    country: 'IT-25',
  },
  {
    code: 'IT-PZ',
    label: 'Potenza',
    country: 'IT-77',
  },
  {
    code: 'IT-RA',
    label: 'Ravenna',
    country: 'IT-45',
  },
  {
    code: 'IT-RC',
    label: 'Reggio Calabria',
    country: 'IT-78',
  },
  {
    code: 'IT-RE',
    label: 'Reggio Emilia',
    country: 'IT-45',
  },
  {
    code: 'IT-RG',
    label: 'Ragusa',
    country: 'IT-82',
  },
  {
    code: 'IT-RI',
    label: 'Rieti',
    country: 'IT-62',
  },
  {
    code: 'IT-RM',
    label: 'Roma',
    country: 'IT-62',
  },
  {
    code: 'IT-RN',
    label: 'Rimini',
    country: 'IT-45',
  },
  {
    code: 'IT-RO',
    label: 'Rovigo',
    country: 'IT-34',
  },
  {
    code: 'IT-SA',
    label: 'Salerno',
    country: 'IT-72',
  },
  {
    code: 'IT-SI',
    label: 'Siena',
    country: 'IT-52',
  },
  {
    code: 'IT-SO',
    label: 'Sondrio',
    country: 'IT-25',
  },
  {
    code: 'IT-SP',
    label: 'La Spezia',
    country: 'IT-42',
  },
  {
    code: 'IT-SR',
    label: 'Siracusa',
    country: 'IT-82',
  },
  {
    code: 'IT-SS',
    label: 'Sassari',
    country: 'IT-88',
  },
  {
    code: 'IT-SU',
    label: 'Sud Sardegna',
    country: 'IT-88',
  },
  {
    code: 'IT-SV',
    label: 'Savona',
    country: 'IT-42',
  },
  {
    code: 'IT-TA',
    label: 'Taranto',
    country: 'IT-75',
  },
  {
    code: 'IT-TE',
    label: 'Teramo',
    country: 'IT-65',
  },
  {
    code: 'IT-TN',
    label: 'Trento',
    country: 'IT-32',
  },
  {
    code: 'IT-TO',
    label: 'Torino',
    country: 'IT-21',
  },
  {
    code: 'IT-TP',
    label: 'Trapani',
    country: 'IT-82',
  },
  {
    code: 'IT-TR',
    label: 'Terni',
    country: 'IT-55',
  },
  {
    code: 'IT-TS',
    label: 'Trieste',
    country: 'IT-36',
  },
  {
    code: 'IT-TV',
    label: 'Treviso',
    country: 'IT-34',
  },
  {
    code: 'IT-UD',
    label: 'Udine',
    country: 'IT-36',
  },
  {
    code: 'IT-VA',
    label: 'Varese',
    country: 'IT-25',
  },
  {
    code: 'IT-VB',
    label: 'Verbano-Cusio-Ossola',
    country: 'IT-21',
  },
  {
    code: 'IT-VC',
    label: 'Vercelli',
    country: 'IT-21',
  },
  {
    code: 'IT-VE',
    label: 'Venezia',
    country: 'IT-34',
  },
  {
    code: 'IT-VI',
    label: 'Vicenza',
    country: 'IT-34',
  },
  {
    code: 'IT-VR',
    label: 'Verona',
    country: 'IT-34',
  },
  {
    code: 'IT-VT',
    label: 'Viterbo',
    country: 'IT-62',
  },
  {
    code: 'IT-VV',
    label: 'Vibo Valentia',
    country: 'IT-78',
  },
  {
    code: 'JM-01',
    label: 'Kingston',
    country: 'JM',
  },
  {
    code: 'JM-02',
    label: 'Saint Andrew',
    country: 'JM',
  },
  {
    code: 'JM-03',
    label: 'Saint Thomas',
    country: 'JM',
  },
  {
    code: 'JM-04',
    label: 'Portland',
    country: 'JM',
  },
  {
    code: 'JM-05',
    label: 'Saint Mary',
    country: 'JM',
  },
  {
    code: 'JM-06',
    label: 'Saint Ann',
    country: 'JM',
  },
  {
    code: 'JM-07',
    label: 'Trelawny',
    country: 'JM',
  },
  {
    code: 'JM-08',
    label: 'Saint James',
    country: 'JM',
  },
  {
    code: 'JM-09',
    label: 'Hanover',
    country: 'JM',
  },
  {
    code: 'JM-10',
    label: 'Westmoreland',
    country: 'JM',
  },
  {
    code: 'JM-11',
    label: 'Saint Elizabeth',
    country: 'JM',
  },
  {
    code: 'JM-12',
    label: 'Manchester',
    country: 'JM',
  },
  {
    code: 'JM-13',
    label: 'Clarendon',
    country: 'JM',
  },
  {
    code: 'JM-14',
    label: 'Saint Catherine',
    country: 'JM',
  },
  {
    code: 'JO-AJ',
    label: '‘Ajlūn',
    country: 'JO',
  },
  {
    code: 'JO-AM',
    label: 'Al ‘A̅şimah',
    country: 'JO',
  },
  {
    code: 'JO-AQ',
    label: 'Al ‘Aqabah',
    country: 'JO',
  },
  {
    code: 'JO-AT',
    label: 'Aţ Ţafīlah',
    country: 'JO',
  },
  {
    code: 'JO-AZ',
    label: 'Az Zarqā’',
    country: 'JO',
  },
  {
    code: 'JO-BA',
    label: 'Al Balqā’',
    country: 'JO',
  },
  {
    code: 'JO-IR',
    label: 'Irbid',
    country: 'JO',
  },
  {
    code: 'JO-JA',
    label: 'Jarash',
    country: 'JO',
  },
  {
    code: 'JO-KA',
    label: 'Al Karak',
    country: 'JO',
  },
  {
    code: 'JO-MA',
    label: 'Al Mafraq',
    country: 'JO',
  },
  {
    code: 'JO-MD',
    label: 'Mādabā',
    country: 'JO',
  },
  {
    code: 'JO-MN',
    label: 'Ma‘ān',
    country: 'JO',
  },
  {
    code: 'JP-01',
    label: 'Hokkaidô',
    country: 'JP',
  },
  {
    code: 'JP-02',
    label: 'Aomori',
    country: 'JP',
  },
  {
    code: 'JP-03',
    label: 'Iwate',
    country: 'JP',
  },
  {
    code: 'JP-04',
    label: 'Miyagi',
    country: 'JP',
  },
  {
    code: 'JP-05',
    label: 'Akita',
    country: 'JP',
  },
  {
    code: 'JP-06',
    label: 'Yamagata',
    country: 'JP',
  },
  {
    code: 'JP-07',
    label: 'Hukusima',
    country: 'JP',
  },
  {
    code: 'JP-08',
    label: 'Ibaraki',
    country: 'JP',
  },
  {
    code: 'JP-09',
    label: 'Totigi',
    country: 'JP',
  },
  {
    code: 'JP-10',
    label: 'Gunma',
    country: 'JP',
  },
  {
    code: 'JP-11',
    label: 'Saitama',
    country: 'JP',
  },
  {
    code: 'JP-12',
    label: 'Tiba',
    country: 'JP',
  },
  {
    code: 'JP-13',
    label: 'Tôkyô',
    country: 'JP',
  },
  {
    code: 'JP-14',
    label: 'Kanagawa',
    country: 'JP',
  },
  {
    code: 'JP-15',
    label: 'Niigata',
    country: 'JP',
  },
  {
    code: 'JP-16',
    label: 'Toyama',
    country: 'JP',
  },
  {
    code: 'JP-17',
    label: 'Isikawa',
    country: 'JP',
  },
  {
    code: 'JP-18',
    label: 'Hukui',
    country: 'JP',
  },
  {
    code: 'JP-19',
    label: 'Yamanasi',
    country: 'JP',
  },
  {
    code: 'JP-20',
    label: 'Nagano',
    country: 'JP',
  },
  {
    code: 'JP-21',
    label: 'Gihu',
    country: 'JP',
  },
  {
    code: 'JP-22',
    label: 'Sizuoka',
    country: 'JP',
  },
  {
    code: 'JP-23',
    label: 'Aiti',
    country: 'JP',
  },
  {
    code: 'JP-24',
    label: 'Mie',
    country: 'JP',
  },
  {
    code: 'JP-25',
    label: 'Siga',
    country: 'JP',
  },
  {
    code: 'JP-26',
    label: 'Kyôto',
    country: 'JP',
  },
  {
    code: 'JP-27',
    label: 'Ôsaka',
    country: 'JP',
  },
  {
    code: 'JP-28',
    label: 'Hyôgo',
    country: 'JP',
  },
  {
    code: 'JP-29',
    label: 'Nara',
    country: 'JP',
  },
  {
    code: 'JP-30',
    label: 'Wakayama',
    country: 'JP',
  },
  {
    code: 'JP-31',
    label: 'Tottori',
    country: 'JP',
  },
  {
    code: 'JP-32',
    label: 'Simane',
    country: 'JP',
  },
  {
    code: 'JP-33',
    label: 'Okayama',
    country: 'JP',
  },
  {
    code: 'JP-34',
    label: 'Hirosima',
    country: 'JP',
  },
  {
    code: 'JP-35',
    label: 'Yamaguti',
    country: 'JP',
  },
  {
    code: 'JP-36',
    label: 'Tokusima',
    country: 'JP',
  },
  {
    code: 'JP-37',
    label: 'Kagawa',
    country: 'JP',
  },
  {
    code: 'JP-38',
    label: 'Ehime',
    country: 'JP',
  },
  {
    code: 'JP-39',
    label: 'Kôti',
    country: 'JP',
  },
  {
    code: 'JP-40',
    label: 'Hukuoka',
    country: 'JP',
  },
  {
    code: 'JP-41',
    label: 'Saga',
    country: 'JP',
  },
  {
    code: 'JP-42',
    label: 'Nagasaki',
    country: 'JP',
  },
  {
    code: 'JP-43',
    label: 'Kumamoto',
    country: 'JP',
  },
  {
    code: 'JP-44',
    label: 'Ôita',
    country: 'JP',
  },
  {
    code: 'JP-45',
    label: 'Miyazaki',
    country: 'JP',
  },
  {
    code: 'JP-46',
    label: 'Kagosima',
    country: 'JP',
  },
  {
    code: 'JP-47',
    label: 'Okinawa',
    country: 'JP',
  },
  {
    code: 'KE-01',
    label: 'Baringo',
    country: 'KE',
  },
  {
    code: 'KE-02',
    label: 'Bomet',
    country: 'KE',
  },
  {
    code: 'KE-03',
    label: 'Bungoma',
    country: 'KE',
  },
  {
    code: 'KE-04',
    label: 'Busia',
    country: 'KE',
  },
  {
    code: 'KE-05',
    label: 'Elgeyo/Marakwet',
    country: 'KE',
  },
  {
    code: 'KE-06',
    label: 'Embu',
    country: 'KE',
  },
  {
    code: 'KE-07',
    label: 'Garissa',
    country: 'KE',
  },
  {
    code: 'KE-08',
    label: 'Homa Bay',
    country: 'KE',
  },
  {
    code: 'KE-09',
    label: 'Isiolo',
    country: 'KE',
  },
  {
    code: 'KE-10',
    label: 'Kajiado',
    country: 'KE',
  },
  {
    code: 'KE-11',
    label: 'Kakamega',
    country: 'KE',
  },
  {
    code: 'KE-12',
    label: 'Kericho',
    country: 'KE',
  },
  {
    code: 'KE-13',
    label: 'Kiambu',
    country: 'KE',
  },
  {
    code: 'KE-14',
    label: 'Kilifi',
    country: 'KE',
  },
  {
    code: 'KE-15',
    label: 'Kirinyaga',
    country: 'KE',
  },
  {
    code: 'KE-16',
    label: 'Kisii',
    country: 'KE',
  },
  {
    code: 'KE-17',
    label: 'Kisumu',
    country: 'KE',
  },
  {
    code: 'KE-18',
    label: 'Kitui',
    country: 'KE',
  },
  {
    code: 'KE-19',
    label: 'Kwale',
    country: 'KE',
  },
  {
    code: 'KE-20',
    label: 'Laikipia',
    country: 'KE',
  },
  {
    code: 'KE-21',
    label: 'Lamu',
    country: 'KE',
  },
  {
    code: 'KE-22',
    label: 'Machakos',
    country: 'KE',
  },
  {
    code: 'KE-23',
    label: 'Makueni',
    country: 'KE',
  },
  {
    code: 'KE-24',
    label: 'Mandera',
    country: 'KE',
  },
  {
    code: 'KE-25',
    label: 'Marsabit',
    country: 'KE',
  },
  {
    code: 'KE-26',
    label: 'Meru',
    country: 'KE',
  },
  {
    code: 'KE-27',
    label: 'Migori',
    country: 'KE',
  },
  {
    code: 'KE-28',
    label: 'Mombasa',
    country: 'KE',
  },
  {
    code: 'KE-29',
    label: "Murang'a",
    country: 'KE',
  },
  {
    code: 'KE-30',
    label: 'Nairobi City',
    country: 'KE',
  },
  {
    code: 'KE-31',
    label: 'Nakuru',
    country: 'KE',
  },
  {
    code: 'KE-32',
    label: 'Nandi',
    country: 'KE',
  },
  {
    code: 'KE-33',
    label: 'Narok',
    country: 'KE',
  },
  {
    code: 'KE-34',
    label: 'Nyamira',
    country: 'KE',
  },
  {
    code: 'KE-35',
    label: 'Nyandarua',
    country: 'KE',
  },
  {
    code: 'KE-36',
    label: 'Nyeri',
    country: 'KE',
  },
  {
    code: 'KE-37',
    label: 'Samburu',
    country: 'KE',
  },
  {
    code: 'KE-38',
    label: 'Siaya',
    country: 'KE',
  },
  {
    code: 'KE-39',
    label: 'Taita/Taveta',
    country: 'KE',
  },
  {
    code: 'KE-40',
    label: 'Tana River',
    country: 'KE',
  },
  {
    code: 'KE-41',
    label: 'Tharaka-Nithi',
    country: 'KE',
  },
  {
    code: 'KE-42',
    label: 'Trans Nzoia',
    country: 'KE',
  },
  {
    code: 'KE-43',
    label: 'Turkana',
    country: 'KE',
  },
  {
    code: 'KE-44',
    label: 'Uasin Gishu',
    country: 'KE',
  },
  {
    code: 'KE-45',
    label: 'Vihiga',
    country: 'KE',
  },
  {
    code: 'KE-46',
    label: 'Wajir',
    country: 'KE',
  },
  {
    code: 'KE-47',
    label: 'West Pokot',
    country: 'KE',
  },
  {
    code: 'KG-B',
    label: 'Batken',
    country: 'KG',
  },
  {
    code: 'KG-C',
    label: 'Chüy',
    country: 'KG',
  },
  {
    code: 'KG-GB',
    label: 'Bishkek Shaary',
    country: 'KG',
  },
  {
    code: 'KG-GO',
    label: 'Osh Shaary',
    country: 'KG',
  },
  {
    code: 'KG-J',
    label: 'Jalal-Abad',
    country: 'KG',
  },
  {
    code: 'KG-N',
    label: 'Naryn',
    country: 'KG',
  },
  {
    code: 'KG-O',
    label: 'Osh',
    country: 'KG',
  },
  {
    code: 'KG-T',
    label: 'Talas',
    country: 'KG',
  },
  {
    code: 'KG-Y',
    label: 'Ysyk-Köl',
    country: 'KG',
  },
  {
    code: 'KH-1',
    label: 'Banteay Mean Choăy',
    country: 'KH',
  },
  {
    code: 'KH-10',
    label: 'Kracheh',
    country: 'KH',
  },
  {
    code: 'KH-11',
    label: 'Mondol Kiri',
    country: 'KH',
  },
  {
    code: 'KH-12',
    label: 'Phnom Penh',
    country: 'KH',
  },
  {
    code: 'KH-13',
    label: 'Preah Vihear',
    country: 'KH',
  },
  {
    code: 'KH-14',
    label: 'Prey Veaeng',
    country: 'KH',
  },
  {
    code: 'KH-15',
    label: 'Pousaat',
    country: 'KH',
  },
  {
    code: 'KH-16',
    label: 'Rotanak Kiri',
    country: 'KH',
  },
  {
    code: 'KH-17',
    label: 'Siem Reab',
    country: 'KH',
  },
  {
    code: 'KH-18',
    label: 'Preah Sihanouk',
    country: 'KH',
  },
  {
    code: 'KH-19',
    label: 'Stueng Traeng',
    country: 'KH',
  },
  {
    code: 'KH-2',
    label: 'Baat Dambang',
    country: 'KH',
  },
  {
    code: 'KH-20',
    label: 'Svaay Rieng',
    country: 'KH',
  },
  {
    code: 'KH-21',
    label: 'Taakaev',
    country: 'KH',
  },
  {
    code: 'KH-22',
    label: 'Otdar Mean Chey',
    country: 'KH',
  },
  {
    code: 'KH-23',
    label: 'Kaeb',
    country: 'KH',
  },
  {
    code: 'KH-24',
    label: 'Pailin',
    country: 'KH',
  },
  {
    code: 'KH-25',
    label: 'Tbong Khmum',
    country: 'KH',
  },
  {
    code: 'KH-3',
    label: 'Kampong Chaam',
    country: 'KH',
  },
  {
    code: 'KH-4',
    label: 'Kampong Chhnang',
    country: 'KH',
  },
  {
    code: 'KH-5',
    label: 'Kampong Spueu',
    country: 'KH',
  },
  {
    code: 'KH-6',
    label: 'Kampong Thum',
    country: 'KH',
  },
  {
    code: 'KH-7',
    label: 'Kampot',
    country: 'KH',
  },
  {
    code: 'KH-8',
    label: 'Kandaal',
    country: 'KH',
  },
  {
    code: 'KH-9',
    label: 'Kaoh Kong',
    country: 'KH',
  },
  {
    code: 'KI-G',
    label: 'Gilbert Islands',
    country: 'KI',
  },
  {
    code: 'KI-L',
    label: 'Line Islands',
    country: 'KI',
  },
  {
    code: 'KI-P',
    label: 'Phoenix Islands',
    country: 'KI',
  },
  {
    code: 'KM-A',
    label: 'Anjouan',
    country: 'KM',
  },
  {
    code: 'KM-G',
    label: 'Grande Comore',
    country: 'KM',
  },
  {
    code: 'KM-M',
    label: 'Mohéli',
    country: 'KM',
  },
  {
    code: 'KN-01',
    label: 'Christ Church Nichola Town',
    country: 'KN-K',
  },
  {
    code: 'KN-02',
    label: 'Saint Anne Sandy Point',
    country: 'KN-K',
  },
  {
    code: 'KN-03',
    label: 'Saint George Basseterre',
    country: 'KN-K',
  },
  {
    code: 'KN-04',
    label: 'Saint George Gingerland',
    country: 'KN-N',
  },
  {
    code: 'KN-05',
    label: 'Saint James Windward',
    country: 'KN-N',
  },
  {
    code: 'KN-06',
    label: 'Saint John Capisterre',
    country: 'KN-K',
  },
  {
    code: 'KN-07',
    label: 'Saint John Figtree',
    country: 'KN-N',
  },
  {
    code: 'KN-08',
    label: 'Saint Mary Cayon',
    country: 'KN-K',
  },
  {
    code: 'KN-09',
    label: 'Saint Paul Capisterre',
    country: 'KN-K',
  },
  {
    code: 'KN-10',
    label: 'Saint Paul Charlestown',
    country: 'KN-N',
  },
  {
    code: 'KN-11',
    label: 'Saint Peter Basseterre',
    country: 'KN-K',
  },
  {
    code: 'KN-12',
    label: 'Saint Thomas Lowland',
    country: 'KN-N',
  },
  {
    code: 'KN-13',
    label: 'Saint Thomas Middle Island',
    country: 'KN-K',
  },
  {
    code: 'KN-15',
    label: 'Trinity Palmetto Point',
    country: 'KN-K',
  },
  {
    code: 'KN-K',
    label: 'Saint Kitts',
    country: 'KN',
  },
  {
    code: 'KN-N',
    label: 'Nevis',
    country: 'KN',
  },
  {
    code: 'KP-01',
    label: 'Pyongyang',
    country: 'KP',
  },
  {
    code: 'KP-02',
    label: 'South Pyongan',
    country: 'KP',
  },
  {
    code: 'KP-03',
    label: 'North Pyongan',
    country: 'KP',
  },
  {
    code: 'KP-04',
    label: 'Chagang',
    country: 'KP',
  },
  {
    code: 'KP-05',
    label: 'South Hwanghae',
    country: 'KP',
  },
  {
    code: 'KP-06',
    label: 'North Hwanghae',
    country: 'KP',
  },
  {
    code: 'KP-07',
    label: 'Kangwon',
    country: 'KP',
  },
  {
    code: 'KP-08',
    label: 'South Hamgyong',
    country: 'KP',
  },
  {
    code: 'KP-09',
    label: 'North Hamgyong',
    country: 'KP',
  },
  {
    code: 'KP-10',
    label: 'Ryanggang',
    country: 'KP',
  },
  {
    code: 'KP-13',
    label: 'Rason',
    country: 'KP',
  },
  {
    code: 'KP-14',
    label: 'Nampo',
    country: 'KP',
  },
  {
    code: 'KR-11',
    label: 'Seoul',
    country: 'KR',
  },
  {
    code: 'KR-26',
    label: 'Busan',
    country: 'KR',
  },
  {
    code: 'KR-27',
    label: 'Daegu',
    country: 'KR',
  },
  {
    code: 'KR-28',
    label: 'Incheon',
    country: 'KR',
  },
  {
    code: 'KR-29',
    label: 'Gwangju',
    country: 'KR',
  },
  {
    code: 'KR-30',
    label: 'Daejeon',
    country: 'KR',
  },
  {
    code: 'KR-31',
    label: 'Ulsan',
    country: 'KR',
  },
  {
    code: 'KR-41',
    label: 'Gyeonggi',
    country: 'KR',
  },
  {
    code: 'KR-42',
    label: 'Gangwon',
    country: 'KR',
  },
  {
    code: 'KR-43',
    label: 'North Chungcheong',
    country: 'KR',
  },
  {
    code: 'KR-44',
    label: 'South Chungcheong',
    country: 'KR',
  },
  {
    code: 'KR-45',
    label: 'North Jeolla',
    country: 'KR',
  },
  {
    code: 'KR-46',
    label: 'South Jeolla',
    country: 'KR',
  },
  {
    code: 'KR-47',
    label: 'North Gyeongsang',
    country: 'KR',
  },
  {
    code: 'KR-48',
    label: 'South Gyeongsang',
    country: 'KR',
  },
  {
    code: 'KR-49',
    label: 'Jeju',
    country: 'KR',
  },
  {
    code: 'KR-50',
    label: 'Sejong',
    country: 'KR',
  },
  {
    code: 'KW-AH',
    label: 'Al Aḩmadī',
    country: 'KW',
  },
  {
    code: 'KW-FA',
    label: 'Al Farwānīyah',
    country: 'KW',
  },
  {
    code: 'KW-HA',
    label: 'Ḩawallī',
    country: 'KW',
  },
  {
    code: 'KW-JA',
    label: 'Al Jahrā’',
    country: 'KW',
  },
  {
    code: 'KW-KU',
    label: 'Al ‘Āşimah',
    country: 'KW',
  },
  {
    code: 'KW-MU',
    label: 'Mubārak al Kabīr',
    country: 'KW',
  },
  {
    code: 'KZ-AKM',
    label: 'Aqmola oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-AKT',
    label: 'Aqtöbe oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-ALA',
    label: 'Almaty',
    country: 'KZ',
  },
  {
    code: 'KZ-ALM',
    label: 'Almaty oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-AST',
    label: 'Nur-Sultan',
    country: 'KZ',
  },
  {
    code: 'KZ-ATY',
    label: 'Atyraū oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-KAR',
    label: 'Qaraghandy oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-KUS',
    label: 'Qostanay oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-KZY',
    label: 'Qyzylorda oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-MAN',
    label: 'Mangghystaū oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-PAV',
    label: 'Pavlodar oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-SEV',
    label: 'Soltüstik Qazaqstan oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-SHY',
    label: 'Shymkent',
    country: 'KZ',
  },
  {
    code: 'KZ-VOS',
    label: 'Shyghys Qazaqstan oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-YUZ',
    label: 'Türkistan oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-ZAP',
    label: 'Batys Qazaqstan oblysy',
    country: 'KZ',
  },
  {
    code: 'KZ-ZHA',
    label: 'Zhambyl oblysy',
    country: 'KZ',
  },
  {
    code: 'LA-AT',
    label: 'Attapu',
    country: 'LA',
  },
  {
    code: 'LA-BK',
    label: 'Bokèo',
    country: 'LA',
  },
  {
    code: 'LA-BL',
    label: 'Bolikhamxai',
    country: 'LA',
  },
  {
    code: 'LA-CH',
    label: 'Champasak',
    country: 'LA',
  },
  {
    code: 'LA-HO',
    label: 'Houaphan',
    country: 'LA',
  },
  {
    code: 'LA-KH',
    label: 'Khammouan',
    country: 'LA',
  },
  {
    code: 'LA-LM',
    label: 'Louang Namtha',
    country: 'LA',
  },
  {
    code: 'LA-LP',
    label: 'Louangphabang',
    country: 'LA',
  },
  {
    code: 'LA-OU',
    label: 'Oudômxai',
    country: 'LA',
  },
  {
    code: 'LA-PH',
    label: 'Phôngsali',
    country: 'LA',
  },
  {
    code: 'LA-SL',
    label: 'Salavan',
    country: 'LA',
  },
  {
    code: 'LA-SV',
    label: 'Savannakhét',
    country: 'LA',
  },
  {
    code: 'LA-VI',
    label: 'Viangchan',
    country: 'LA',
  },
  {
    code: 'LA-VT',
    label: 'Viangchan',
    country: 'LA',
  },
  {
    code: 'LA-XA',
    label: 'Xaignabouli',
    country: 'LA',
  },
  {
    code: 'LA-XE',
    label: 'Xékong',
    country: 'LA',
  },
  {
    code: 'LA-XI',
    label: 'Xiangkhouang',
    country: 'LA',
  },
  {
    code: 'LA-XS',
    label: 'Xaisômboun',
    country: 'LA',
  },
  {
    code: 'LB-AK',
    label: 'Aakkâr',
    country: 'LB',
  },
  {
    code: 'LB-AS',
    label: 'Liban-Nord',
    country: 'LB',
  },
  {
    code: 'LB-BA',
    label: 'Beyrouth',
    country: 'LB',
  },
  {
    code: 'LB-BH',
    label: 'Baalbek-Hermel',
    country: 'LB',
  },
  {
    code: 'LB-BI',
    label: 'Béqaa',
    country: 'LB',
  },
  {
    code: 'LB-JA',
    label: 'Liban-Sud',
    country: 'LB',
  },
  {
    code: 'LB-JL',
    label: 'Mont-Liban',
    country: 'LB',
  },
  {
    code: 'LB-NA',
    label: 'Nabatîyé',
    country: 'LB',
  },
  {
    code: 'LC-01',
    label: 'Anse la Raye',
    country: 'LC',
  },
  {
    code: 'LC-02',
    label: 'Castries',
    country: 'LC',
  },
  {
    code: 'LC-03',
    label: 'Choiseul',
    country: 'LC',
  },
  {
    code: 'LC-05',
    label: 'Dennery',
    country: 'LC',
  },
  {
    code: 'LC-06',
    label: 'Gros Islet',
    country: 'LC',
  },
  {
    code: 'LC-07',
    label: 'Laborie',
    country: 'LC',
  },
  {
    code: 'LC-08',
    label: 'Micoud',
    country: 'LC',
  },
  {
    code: 'LC-10',
    label: 'Soufrière',
    country: 'LC',
  },
  {
    code: 'LC-11',
    label: 'Vieux Fort',
    country: 'LC',
  },
  {
    code: 'LC-12',
    label: 'Canaries',
    country: 'LC',
  },
  {
    code: 'LI-01',
    label: 'Balzers',
    country: 'LI',
  },
  {
    code: 'LI-02',
    label: 'Eschen',
    country: 'LI',
  },
  {
    code: 'LI-03',
    label: 'Gamprin',
    country: 'LI',
  },
  {
    code: 'LI-04',
    label: 'Mauren',
    country: 'LI',
  },
  {
    code: 'LI-05',
    label: 'Planken',
    country: 'LI',
  },
  {
    code: 'LI-06',
    label: 'Ruggell',
    country: 'LI',
  },
  {
    code: 'LI-07',
    label: 'Schaan',
    country: 'LI',
  },
  {
    code: 'LI-08',
    label: 'Schellenberg',
    country: 'LI',
  },
  {
    code: 'LI-09',
    label: 'Triesen',
    country: 'LI',
  },
  {
    code: 'LI-10',
    label: 'Triesenberg',
    country: 'LI',
  },
  {
    code: 'LI-11',
    label: 'Vaduz',
    country: 'LI',
  },
  {
    code: 'LK-1',
    label: 'Western Province',
    country: 'LK',
  },
  {
    code: 'LK-11',
    label: 'Colombo',
    country: 'LK-1',
  },
  {
    code: 'LK-12',
    label: 'Gampaha',
    country: 'LK-1',
  },
  {
    code: 'LK-13',
    label: 'Kalutara',
    country: 'LK-1',
  },
  {
    code: 'LK-2',
    label: 'Central Province',
    country: 'LK',
  },
  {
    code: 'LK-21',
    label: 'Kandy',
    country: 'LK-2',
  },
  {
    code: 'LK-22',
    label: 'Matale',
    country: 'LK-2',
  },
  {
    code: 'LK-23',
    label: 'Nuwara Eliya',
    country: 'LK-2',
  },
  {
    code: 'LK-3',
    label: 'Southern Province',
    country: 'LK',
  },
  {
    code: 'LK-31',
    label: 'Galle',
    country: 'LK-3',
  },
  {
    code: 'LK-32',
    label: 'Matara',
    country: 'LK-3',
  },
  {
    code: 'LK-33',
    label: 'Hambantota',
    country: 'LK-3',
  },
  {
    code: 'LK-4',
    label: 'Northern Province',
    country: 'LK',
  },
  {
    code: 'LK-41',
    label: 'Jaffna',
    country: 'LK-4',
  },
  {
    code: 'LK-42',
    label: 'Kilinochchi',
    country: 'LK-4',
  },
  {
    code: 'LK-43',
    label: 'Mannar',
    country: 'LK-4',
  },
  {
    code: 'LK-44',
    label: 'Vavuniya',
    country: 'LK-4',
  },
  {
    code: 'LK-45',
    label: 'Mullaittivu',
    country: 'LK-4',
  },
  {
    code: 'LK-5',
    label: 'Eastern Province',
    country: 'LK',
  },
  {
    code: 'LK-51',
    label: 'Batticaloa',
    country: 'LK-5',
  },
  {
    code: 'LK-52',
    label: 'Ampara',
    country: 'LK-5',
  },
  {
    code: 'LK-53',
    label: 'Trincomalee',
    country: 'LK-5',
  },
  {
    code: 'LK-6',
    label: 'North Western Province',
    country: 'LK',
  },
  {
    code: 'LK-61',
    label: 'Kurunegala',
    country: 'LK-6',
  },
  {
    code: 'LK-62',
    label: 'Puttalam',
    country: 'LK-6',
  },
  {
    code: 'LK-7',
    label: 'North Central Province',
    country: 'LK',
  },
  {
    code: 'LK-71',
    label: 'Anuradhapura',
    country: 'LK-7',
  },
  {
    code: 'LK-72',
    label: 'Polonnaruwa',
    country: 'LK-7',
  },
  {
    code: 'LK-8',
    label: 'Uva Province',
    country: 'LK',
  },
  {
    code: 'LK-81',
    label: 'Badulla',
    country: 'LK-8',
  },
  {
    code: 'LK-82',
    label: 'Monaragala',
    country: 'LK-8',
  },
  {
    code: 'LK-9',
    label: 'Sabaragamuwa Province',
    country: 'LK',
  },
  {
    code: 'LK-91',
    label: 'Ratnapura',
    country: 'LK-9',
  },
  {
    code: 'LK-92',
    label: 'Kegalla',
    country: 'LK-9',
  },
  {
    code: 'LR-BG',
    label: 'Bong',
    country: 'LR',
  },
  {
    code: 'LR-BM',
    label: 'Bomi',
    country: 'LR',
  },
  {
    code: 'LR-CM',
    label: 'Grand Cape Mount',
    country: 'LR',
  },
  {
    code: 'LR-GB',
    label: 'Grand Bassa',
    country: 'LR',
  },
  {
    code: 'LR-GG',
    label: 'Grand Gedeh',
    country: 'LR',
  },
  {
    code: 'LR-GK',
    label: 'Grand Kru',
    country: 'LR',
  },
  {
    code: 'LR-GP',
    label: 'Gbarpolu',
    country: 'LR',
  },
  {
    code: 'LR-LO',
    label: 'Lofa',
    country: 'LR',
  },
  {
    code: 'LR-MG',
    label: 'Margibi',
    country: 'LR',
  },
  {
    code: 'LR-MO',
    label: 'Montserrado',
    country: 'LR',
  },
  {
    code: 'LR-MY',
    label: 'Maryland',
    country: 'LR',
  },
  {
    code: 'LR-NI',
    label: 'Nimba',
    country: 'LR',
  },
  {
    code: 'LR-RG',
    label: 'River Gee',
    country: 'LR',
  },
  {
    code: 'LR-RI',
    label: 'River Cess(local variant is Rivercess)',
    country: 'LR',
  },
  {
    code: 'LR-SI',
    label: 'Sinoe',
    country: 'LR',
  },
  {
    code: 'LS-A',
    label: 'Maseru',
    country: 'LS',
  },
  {
    code: 'LS-B',
    label: 'Botha-Bothe',
    country: 'LS',
  },
  {
    code: 'LS-C',
    label: 'Leribe',
    country: 'LS',
  },
  {
    code: 'LS-D',
    label: 'Berea',
    country: 'LS',
  },
  {
    code: 'LS-E',
    label: 'Mafeteng',
    country: 'LS',
  },
  {
    code: 'LS-F',
    label: "Mohale's Hoek",
    country: 'LS',
  },
  {
    code: 'LS-G',
    label: 'Quthing',
    country: 'LS',
  },
  {
    code: 'LS-H',
    label: "Qacha's Nek",
    country: 'LS',
  },
  {
    code: 'LS-J',
    label: 'Mokhotlong',
    country: 'LS',
  },
  {
    code: 'LS-K',
    label: 'Thaba-Tseka',
    country: 'LS',
  },
  {
    code: 'LT-01',
    label: 'Akmenė',
    country: 'LT',
  },
  {
    code: 'LT-02',
    label: 'Alytaus miestas',
    country: 'LT',
  },
  {
    code: 'LT-03',
    label: 'Alytus',
    country: 'LT',
  },
  {
    code: 'LT-04',
    label: 'Anykščiai',
    country: 'LT',
  },
  {
    code: 'LT-05',
    label: 'Birštono',
    country: 'LT',
  },
  {
    code: 'LT-06',
    label: 'Biržai',
    country: 'LT',
  },
  {
    code: 'LT-07',
    label: 'Druskininkai',
    country: 'LT',
  },
  {
    code: 'LT-08',
    label: 'Elektrėnai',
    country: 'LT',
  },
  {
    code: 'LT-09',
    label: 'Ignalina',
    country: 'LT',
  },
  {
    code: 'LT-10',
    label: 'Jonava',
    country: 'LT',
  },
  {
    code: 'LT-11',
    label: 'Joniškis',
    country: 'LT',
  },
  {
    code: 'LT-12',
    label: 'Jurbarkas',
    country: 'LT',
  },
  {
    code: 'LT-13',
    label: 'Kaišiadorys',
    country: 'LT',
  },
  {
    code: 'LT-14',
    label: 'Kalvarijos',
    country: 'LT',
  },
  {
    code: 'LT-15',
    label: 'Kauno miestas',
    country: 'LT',
  },
  {
    code: 'LT-16',
    label: 'Kaunas',
    country: 'LT',
  },
  {
    code: 'LT-17',
    label: 'Kazlų Rūdos',
    country: 'LT',
  },
  {
    code: 'LT-18',
    label: 'Kėdainiai',
    country: 'LT',
  },
  {
    code: 'LT-19',
    label: 'Kelmė',
    country: 'LT',
  },
  {
    code: 'LT-20',
    label: 'Klaipėdos miestas',
    country: 'LT',
  },
  {
    code: 'LT-21',
    label: 'Klaipėda',
    country: 'LT',
  },
  {
    code: 'LT-22',
    label: 'Kretinga',
    country: 'LT',
  },
  {
    code: 'LT-23',
    label: 'Kupiškis',
    country: 'LT',
  },
  {
    code: 'LT-24',
    label: 'Lazdijai',
    country: 'LT',
  },
  {
    code: 'LT-25',
    label: 'Marijampolė',
    country: 'LT',
  },
  {
    code: 'LT-26',
    label: 'Mažeikiai',
    country: 'LT',
  },
  {
    code: 'LT-27',
    label: 'Molėtai',
    country: 'LT',
  },
  {
    code: 'LT-28',
    label: 'Neringa',
    country: 'LT',
  },
  {
    code: 'LT-29',
    label: 'Pagėgiai',
    country: 'LT',
  },
  {
    code: 'LT-30',
    label: 'Pakruojis',
    country: 'LT',
  },
  {
    code: 'LT-31',
    label: 'Palangos miestas',
    country: 'LT',
  },
  {
    code: 'LT-32',
    label: 'Panevėžio miestas',
    country: 'LT',
  },
  {
    code: 'LT-33',
    label: 'Panevėžys',
    country: 'LT',
  },
  {
    code: 'LT-34',
    label: 'Pasvalys',
    country: 'LT',
  },
  {
    code: 'LT-35',
    label: 'Plungė',
    country: 'LT',
  },
  {
    code: 'LT-36',
    label: 'Prienai',
    country: 'LT',
  },
  {
    code: 'LT-37',
    label: 'Radviliškis',
    country: 'LT',
  },
  {
    code: 'LT-38',
    label: 'Raseiniai',
    country: 'LT',
  },
  {
    code: 'LT-39',
    label: 'Rietavo',
    country: 'LT',
  },
  {
    code: 'LT-40',
    label: 'Rokiškis',
    country: 'LT',
  },
  {
    code: 'LT-41',
    label: 'Šakiai',
    country: 'LT',
  },
  {
    code: 'LT-42',
    label: 'Šalčininkai',
    country: 'LT',
  },
  {
    code: 'LT-43',
    label: 'Šiaulių miestas',
    country: 'LT',
  },
  {
    code: 'LT-44',
    label: 'Šiauliai',
    country: 'LT',
  },
  {
    code: 'LT-45',
    label: 'Šilalė',
    country: 'LT',
  },
  {
    code: 'LT-46',
    label: 'Šilutė',
    country: 'LT',
  },
  {
    code: 'LT-47',
    label: 'Širvintos',
    country: 'LT',
  },
  {
    code: 'LT-48',
    label: 'Skuodas',
    country: 'LT',
  },
  {
    code: 'LT-49',
    label: 'Švenčionys',
    country: 'LT',
  },
  {
    code: 'LT-50',
    label: 'Tauragė',
    country: 'LT',
  },
  {
    code: 'LT-51',
    label: 'Telšiai',
    country: 'LT',
  },
  {
    code: 'LT-52',
    label: 'Trakai',
    country: 'LT',
  },
  {
    code: 'LT-53',
    label: 'Ukmergė',
    country: 'LT',
  },
  {
    code: 'LT-54',
    label: 'Utena',
    country: 'LT',
  },
  {
    code: 'LT-55',
    label: 'Varėna',
    country: 'LT',
  },
  {
    code: 'LT-56',
    label: 'Vilkaviškis',
    country: 'LT',
  },
  {
    code: 'LT-57',
    label: 'Vilniaus miestas',
    country: 'LT',
  },
  {
    code: 'LT-58',
    label: 'Vilnius',
    country: 'LT',
  },
  {
    code: 'LT-59',
    label: 'Visaginas',
    country: 'LT',
  },
  {
    code: 'LT-60',
    label: 'Zarasai',
    country: 'LT',
  },
  {
    code: 'LT-AL',
    label: 'Alytaus apskritis',
    country: 'LT',
  },
  {
    code: 'LT-KL',
    label: 'Klaipėdos apskritis',
    country: 'LT',
  },
  {
    code: 'LT-KU',
    label: 'Kauno apskritis',
    country: 'LT',
  },
  {
    code: 'LT-MR',
    label: 'Marijampolės apskritis',
    country: 'LT',
  },
  {
    code: 'LT-PN',
    label: 'Panevėžio apskritis',
    country: 'LT',
  },
  {
    code: 'LT-SA',
    label: 'Šiaulių apskritis',
    country: 'LT',
  },
  {
    code: 'LT-TA',
    label: 'Tauragės apskritis',
    country: 'LT',
  },
  {
    code: 'LT-TE',
    label: 'Telšių apskritis',
    country: 'LT',
  },
  {
    code: 'LT-UT',
    label: 'Utenos apskritis',
    country: 'LT',
  },
  {
    code: 'LT-VL',
    label: 'Vilniaus apskritis',
    country: 'LT',
  },
  {
    code: 'LU-CA',
    label: 'Capellen',
    country: 'LU',
  },
  {
    code: 'LU-CL',
    label: 'Clervaux',
    country: 'LU',
  },
  {
    code: 'LU-DI',
    label: 'Diekirch',
    country: 'LU',
  },
  {
    code: 'LU-EC',
    label: 'Echternach',
    country: 'LU',
  },
  {
    code: 'LU-ES',
    label: 'Esch-sur-Alzette',
    country: 'LU',
  },
  {
    code: 'LU-GR',
    label: 'Grevenmacher',
    country: 'LU',
  },
  {
    code: 'LU-LU',
    label: 'Luxembourg',
    country: 'LU',
  },
  {
    code: 'LU-ME',
    label: 'Mersch',
    country: 'LU',
  },
  {
    code: 'LU-RD',
    label: 'Redange',
    country: 'LU',
  },
  {
    code: 'LU-RM',
    label: 'Remich',
    country: 'LU',
  },
  {
    code: 'LU-VD',
    label: 'Vianden',
    country: 'LU',
  },
  {
    code: 'LU-WI',
    label: 'Wiltz',
    country: 'LU',
  },
  {
    code: 'LV-001',
    label: 'Aglonas novads',
    country: 'LV',
  },
  {
    code: 'LV-002',
    label: 'Aizkraukles novads',
    country: 'LV',
  },
  {
    code: 'LV-003',
    label: 'Aizputes novads',
    country: 'LV',
  },
  {
    code: 'LV-004',
    label: 'Aknīstes novads',
    country: 'LV',
  },
  {
    code: 'LV-005',
    label: 'Alojas novads',
    country: 'LV',
  },
  {
    code: 'LV-006',
    label: 'Alsungas novads',
    country: 'LV',
  },
  {
    code: 'LV-007',
    label: 'Alūksnes novads',
    country: 'LV',
  },
  {
    code: 'LV-008',
    label: 'Amatas novads',
    country: 'LV',
  },
  {
    code: 'LV-009',
    label: 'Apes novads',
    country: 'LV',
  },
  {
    code: 'LV-010',
    label: 'Auces novads',
    country: 'LV',
  },
  {
    code: 'LV-011',
    label: 'Ādažu novads',
    country: 'LV',
  },
  {
    code: 'LV-012',
    label: 'Babītes novads',
    country: 'LV',
  },
  {
    code: 'LV-013',
    label: 'Baldones novads',
    country: 'LV',
  },
  {
    code: 'LV-014',
    label: 'Baltinavas novads',
    country: 'LV',
  },
  {
    code: 'LV-015',
    label: 'Balvu novads',
    country: 'LV',
  },
  {
    code: 'LV-016',
    label: 'Bauskas novads',
    country: 'LV',
  },
  {
    code: 'LV-017',
    label: 'Beverīnas novads',
    country: 'LV',
  },
  {
    code: 'LV-018',
    label: 'Brocēnu novads',
    country: 'LV',
  },
  {
    code: 'LV-019',
    label: 'Burtnieku novads',
    country: 'LV',
  },
  {
    code: 'LV-020',
    label: 'Carnikavas novads',
    country: 'LV',
  },
  {
    code: 'LV-021',
    label: 'Cesvaines novads',
    country: 'LV',
  },
  {
    code: 'LV-022',
    label: 'Cēsu novads',
    country: 'LV',
  },
  {
    code: 'LV-023',
    label: 'Ciblas novads',
    country: 'LV',
  },
  {
    code: 'LV-024',
    label: 'Dagdas novads',
    country: 'LV',
  },
  {
    code: 'LV-025',
    label: 'Daugavpils novads',
    country: 'LV',
  },
  {
    code: 'LV-026',
    label: 'Dobeles novads',
    country: 'LV',
  },
  {
    code: 'LV-027',
    label: 'Dundagas novads',
    country: 'LV',
  },
  {
    code: 'LV-028',
    label: 'Durbes novads',
    country: 'LV',
  },
  {
    code: 'LV-029',
    label: 'Engures novads',
    country: 'LV',
  },
  {
    code: 'LV-030',
    label: 'Ērgļu novads',
    country: 'LV',
  },
  {
    code: 'LV-031',
    label: 'Garkalnes novads',
    country: 'LV',
  },
  {
    code: 'LV-032',
    label: 'Grobiņas novads',
    country: 'LV',
  },
  {
    code: 'LV-033',
    label: 'Gulbenes novads',
    country: 'LV',
  },
  {
    code: 'LV-034',
    label: 'Iecavas novads',
    country: 'LV',
  },
  {
    code: 'LV-035',
    label: 'Ikšķiles novads',
    country: 'LV',
  },
  {
    code: 'LV-036',
    label: 'Ilūkstes novads',
    country: 'LV',
  },
  {
    code: 'LV-037',
    label: 'Inčukalna novads',
    country: 'LV',
  },
  {
    code: 'LV-038',
    label: 'Jaunjelgavas novads',
    country: 'LV',
  },
  {
    code: 'LV-039',
    label: 'Jaunpiebalgas novads',
    country: 'LV',
  },
  {
    code: 'LV-040',
    label: 'Jaunpils novads',
    country: 'LV',
  },
  {
    code: 'LV-041',
    label: 'Jelgavas novads',
    country: 'LV',
  },
  {
    code: 'LV-042',
    label: 'Jēkabpils novads',
    country: 'LV',
  },
  {
    code: 'LV-043',
    label: 'Kandavas novads',
    country: 'LV',
  },
  {
    code: 'LV-044',
    label: 'Kārsavas novads',
    country: 'LV',
  },
  {
    code: 'LV-045',
    label: 'Kocēnu novads',
    country: 'LV',
  },
  {
    code: 'LV-046',
    label: 'Kokneses novads',
    country: 'LV',
  },
  {
    code: 'LV-047',
    label: 'Krāslavas novads',
    country: 'LV',
  },
  {
    code: 'LV-048',
    label: 'Krimuldas novads',
    country: 'LV',
  },
  {
    code: 'LV-049',
    label: 'Krustpils novads',
    country: 'LV',
  },
  {
    code: 'LV-050',
    label: 'Kuldīgas novads',
    country: 'LV',
  },
  {
    code: 'LV-051',
    label: 'Ķeguma novads',
    country: 'LV',
  },
  {
    code: 'LV-052',
    label: 'Ķekavas novads',
    country: 'LV',
  },
  {
    code: 'LV-053',
    label: 'Lielvārdes novads',
    country: 'LV',
  },
  {
    code: 'LV-054',
    label: 'Limbažu novads',
    country: 'LV',
  },
  {
    code: 'LV-055',
    label: 'Līgatnes novads',
    country: 'LV',
  },
  {
    code: 'LV-056',
    label: 'Līvānu novads',
    country: 'LV',
  },
  {
    code: 'LV-057',
    label: 'Lubānas novads',
    country: 'LV',
  },
  {
    code: 'LV-058',
    label: 'Ludzas novads',
    country: 'LV',
  },
  {
    code: 'LV-059',
    label: 'Madonas novads',
    country: 'LV',
  },
  {
    code: 'LV-060',
    label: 'Mazsalacas novads',
    country: 'LV',
  },
  {
    code: 'LV-061',
    label: 'Mālpils novads',
    country: 'LV',
  },
  {
    code: 'LV-062',
    label: 'Mārupes novads',
    country: 'LV',
  },
  {
    code: 'LV-063',
    label: 'Mērsraga novads',
    country: 'LV',
  },
  {
    code: 'LV-064',
    label: 'Naukšēnu novads',
    country: 'LV',
  },
  {
    code: 'LV-065',
    label: 'Neretas novads',
    country: 'LV',
  },
  {
    code: 'LV-066',
    label: 'Nīcas novads',
    country: 'LV',
  },
  {
    code: 'LV-067',
    label: 'Ogres novads',
    country: 'LV',
  },
  {
    code: 'LV-068',
    label: 'Olaines novads',
    country: 'LV',
  },
  {
    code: 'LV-069',
    label: 'Ozolnieku novads',
    country: 'LV',
  },
  {
    code: 'LV-070',
    label: 'Pārgaujas novads',
    country: 'LV',
  },
  {
    code: 'LV-071',
    label: 'Pāvilostas novads',
    country: 'LV',
  },
  {
    code: 'LV-072',
    label: 'Pļaviņu novads',
    country: 'LV',
  },
  {
    code: 'LV-073',
    label: 'Preiļu novads',
    country: 'LV',
  },
  {
    code: 'LV-074',
    label: 'Priekules novads',
    country: 'LV',
  },
  {
    code: 'LV-075',
    label: 'Priekuļu novads',
    country: 'LV',
  },
  {
    code: 'LV-076',
    label: 'Raunas novads',
    country: 'LV',
  },
  {
    code: 'LV-077',
    label: 'Rēzeknes novads',
    country: 'LV',
  },
  {
    code: 'LV-078',
    label: 'Riebiņu novads',
    country: 'LV',
  },
  {
    code: 'LV-079',
    label: 'Rojas novads',
    country: 'LV',
  },
  {
    code: 'LV-080',
    label: 'Ropažu novads',
    country: 'LV',
  },
  {
    code: 'LV-081',
    label: 'Rucavas novads',
    country: 'LV',
  },
  {
    code: 'LV-082',
    label: 'Rugāju novads',
    country: 'LV',
  },
  {
    code: 'LV-083',
    label: 'Rundāles novads',
    country: 'LV',
  },
  {
    code: 'LV-084',
    label: 'Rūjienas novads',
    country: 'LV',
  },
  {
    code: 'LV-085',
    label: 'Salas novads',
    country: 'LV',
  },
  {
    code: 'LV-086',
    label: 'Salacgrīvas novads',
    country: 'LV',
  },
  {
    code: 'LV-087',
    label: 'Salaspils novads',
    country: 'LV',
  },
  {
    code: 'LV-088',
    label: 'Saldus novads',
    country: 'LV',
  },
  {
    code: 'LV-089',
    label: 'Saulkrastu novads',
    country: 'LV',
  },
  {
    code: 'LV-090',
    label: 'Sējas novads',
    country: 'LV',
  },
  {
    code: 'LV-091',
    label: 'Siguldas novads',
    country: 'LV',
  },
  {
    code: 'LV-092',
    label: 'Skrīveru novads',
    country: 'LV',
  },
  {
    code: 'LV-093',
    label: 'Skrundas novads',
    country: 'LV',
  },
  {
    code: 'LV-094',
    label: 'Smiltenes novads',
    country: 'LV',
  },
  {
    code: 'LV-095',
    label: 'Stopiņu novads',
    country: 'LV',
  },
  {
    code: 'LV-096',
    label: 'Strenču novads',
    country: 'LV',
  },
  {
    code: 'LV-097',
    label: 'Talsu novads',
    country: 'LV',
  },
  {
    code: 'LV-098',
    label: 'Tērvetes novads',
    country: 'LV',
  },
  {
    code: 'LV-099',
    label: 'Tukuma novads',
    country: 'LV',
  },
  {
    code: 'LV-100',
    label: 'Vaiņodes novads',
    country: 'LV',
  },
  {
    code: 'LV-101',
    label: 'Valkas novads',
    country: 'LV',
  },
  {
    code: 'LV-102',
    label: 'Varakļānu novads',
    country: 'LV',
  },
  {
    code: 'LV-103',
    label: 'Vārkavas novads',
    country: 'LV',
  },
  {
    code: 'LV-104',
    label: 'Vecpiebalgas novads',
    country: 'LV',
  },
  {
    code: 'LV-105',
    label: 'Vecumnieku novads',
    country: 'LV',
  },
  {
    code: 'LV-106',
    label: 'Ventspils novads',
    country: 'LV',
  },
  {
    code: 'LV-107',
    label: 'Viesītes novads',
    country: 'LV',
  },
  {
    code: 'LV-108',
    label: 'Viļakas novads',
    country: 'LV',
  },
  {
    code: 'LV-109',
    label: 'Viļānu novads',
    country: 'LV',
  },
  {
    code: 'LV-110',
    label: 'Zilupes novads',
    country: 'LV',
  },
  {
    code: 'LV-DGV',
    label: 'Daugavpils',
    country: 'LV',
  },
  {
    code: 'LV-JEL',
    label: 'Jelgava',
    country: 'LV',
  },
  {
    code: 'LV-JKB',
    label: 'Jēkabpils',
    country: 'LV',
  },
  {
    code: 'LV-JUR',
    label: 'Jūrmala',
    country: 'LV',
  },
  {
    code: 'LV-LPX',
    label: 'Liepāja',
    country: 'LV',
  },
  {
    code: 'LV-REZ',
    label: 'Rēzekne',
    country: 'LV',
  },
  {
    code: 'LV-RIX',
    label: 'Rīga',
    country: 'LV',
  },
  {
    code: 'LV-VEN',
    label: 'Ventspils',
    country: 'LV',
  },
  {
    code: 'LV-VMR',
    label: 'Valmiera',
    country: 'LV',
  },
  {
    code: 'LY-BA',
    label: 'Banghāzī',
    country: 'LY',
  },
  {
    code: 'LY-BU',
    label: 'Al Buţnān',
    country: 'LY',
  },
  {
    code: 'LY-DR',
    label: 'Darnah',
    country: 'LY',
  },
  {
    code: 'LY-GT',
    label: 'Ghāt',
    country: 'LY',
  },
  {
    code: 'LY-JA',
    label: 'Al Jabal al Akhḑar',
    country: 'LY',
  },
  {
    code: 'LY-JG',
    label: 'Al Jabal al Gharbī',
    country: 'LY',
  },
  {
    code: 'LY-JI',
    label: 'Al Jafārah',
    country: 'LY',
  },
  {
    code: 'LY-JU',
    label: 'Al Jufrah',
    country: 'LY',
  },
  {
    code: 'LY-KF',
    label: 'Al Kufrah',
    country: 'LY',
  },
  {
    code: 'LY-MB',
    label: 'Al Marqab',
    country: 'LY',
  },
  {
    code: 'LY-MI',
    label: 'Mişrātah',
    country: 'LY',
  },
  {
    code: 'LY-MJ',
    label: 'Al Marj',
    country: 'LY',
  },
  {
    code: 'LY-MQ',
    label: 'Murzuq',
    country: 'LY',
  },
  {
    code: 'LY-NL',
    label: 'Nālūt',
    country: 'LY',
  },
  {
    code: 'LY-NQ',
    label: 'An Nuqāţ al Khams',
    country: 'LY',
  },
  {
    code: 'LY-SB',
    label: 'Sabhā',
    country: 'LY',
  },
  {
    code: 'LY-SR',
    label: 'Surt',
    country: 'LY',
  },
  {
    code: 'LY-TB',
    label: 'Ţarābulus',
    country: 'LY',
  },
  {
    code: 'LY-WA',
    label: 'Al Wāḩāt',
    country: 'LY',
  },
  {
    code: 'LY-WD',
    label: 'Wādī al Ḩayāt',
    country: 'LY',
  },
  {
    code: 'LY-WS',
    label: 'Wādī ash Shāţi’',
    country: 'LY',
  },
  {
    code: 'LY-ZA',
    label: 'Az Zāwiyah',
    country: 'LY',
  },
  {
    code: 'MA-01',
    label: 'Tanger-Tétouan-Al Hoceïma',
    country: 'MA',
  },
  {
    code: 'MA-02',
    label: "L'Oriental",
    country: 'MA',
  },
  {
    code: 'MA-03',
    label: 'Fès-Meknès',
    country: 'MA',
  },
  {
    code: 'MA-04',
    label: 'Rabat-Salé-Kénitra',
    country: 'MA',
  },
  {
    code: 'MA-05',
    label: 'Béni Mellal-Khénifra',
    country: 'MA',
  },
  {
    code: 'MA-06',
    label: 'Casablanca-Settat',
    country: 'MA',
  },
  {
    code: 'MA-07',
    label: 'Marrakech-Safi',
    country: 'MA',
  },
  {
    code: 'MA-08',
    label: 'Drâa-Tafilalet',
    country: 'MA',
  },
  {
    code: 'MA-09',
    label: 'Souss-Massa',
    country: 'MA',
  },
  {
    code: 'MA-10',
    label: 'Guelmim-Oued Noun (EH-partial)',
    country: 'MA',
  },
  {
    code: 'MA-11',
    label: 'Laâyoune-Sakia El Hamra (EH-partial)',
    country: 'MA',
  },
  {
    code: 'MA-12',
    label: 'Dakhla-Oued Ed-Dahab (EH)',
    country: 'MA',
  },
  {
    code: 'MA-AGD',
    label: 'Agadir-Ida-Ou-Tanane',
    country: 'MA-09',
  },
  {
    code: 'MA-AOU',
    label: 'Aousserd (EH)',
    country: 'MA-12',
  },
  {
    code: 'MA-ASZ',
    label: 'Assa-Zag (EH-partial)',
    country: 'MA-10',
  },
  {
    code: 'MA-AZI',
    label: 'Azilal',
    country: 'MA-05',
  },
  {
    code: 'MA-BEM',
    label: 'Béni Mellal',
    country: 'MA-05',
  },
  {
    code: 'MA-BER',
    label: 'Berkane',
    country: 'MA-02',
  },
  {
    code: 'MA-BES',
    label: 'Benslimane',
    country: 'MA-06',
  },
  {
    code: 'MA-BOD',
    label: 'Boujdour (EH)',
    country: 'MA-11',
  },
  {
    code: 'MA-BOM',
    label: 'Boulemane',
    country: 'MA-03',
  },
  {
    code: 'MA-BRR',
    label: 'Berrechid',
    country: 'MA-06',
  },
  {
    code: 'MA-CAS',
    label: 'Casablanca',
    country: 'MA-06',
  },
  {
    code: 'MA-CHE',
    label: 'Chefchaouen',
    country: 'MA-01',
  },
  {
    code: 'MA-CHI',
    label: 'Chichaoua',
    country: 'MA-07',
  },
  {
    code: 'MA-CHT',
    label: 'Chtouka-Ait Baha',
    country: 'MA-06',
  },
  {
    code: 'MA-DRI',
    label: 'Driouch',
    country: 'MA-02',
  },
  {
    code: 'MA-ERR',
    label: 'Errachidia',
    country: 'MA-08',
  },
  {
    code: 'MA-ESI',
    label: 'Essaouira',
    country: 'MA-07',
  },
  {
    code: 'MA-ESM',
    label: 'Es-Semara (EH-partial)',
    country: 'MA-11',
  },
  {
    code: 'MA-FAH',
    label: 'Fahs-Anjra',
    country: 'MA-01',
  },
  {
    code: 'MA-FES',
    label: 'Fès',
    country: 'MA-03',
  },
  {
    code: 'MA-FIG',
    label: 'Figuig',
    country: 'MA-02',
  },
  {
    code: 'MA-FQH',
    label: 'Fquih Ben Salah',
    country: 'MA-05',
  },
  {
    code: 'MA-GUE',
    label: 'Guelmim',
    country: 'MA-10',
  },
  {
    code: 'MA-GUF',
    label: 'Guercif',
    country: 'MA-02',
  },
  {
    code: 'MA-HAJ',
    label: 'El Hajeb',
    country: 'MA-03',
  },
  {
    code: 'MA-HAO',
    label: 'Al Haouz',
    country: 'MA-07',
  },
  {
    code: 'MA-HOC',
    label: 'Al Hoceïma',
    country: 'MA-01',
  },
  {
    code: 'MA-IFR',
    label: 'Ifrane',
    country: 'MA-03',
  },
  {
    code: 'MA-INE',
    label: 'Inezgane-Ait Melloul',
    country: 'MA-09',
  },
  {
    code: 'MA-JDI',
    label: 'El Jadida',
    country: 'MA-06',
  },
  {
    code: 'MA-JRA',
    label: 'Jerada',
    country: 'MA-02',
  },
  {
    code: 'MA-KEN',
    label: 'Kénitra',
    country: 'MA-04',
  },
  {
    code: 'MA-KES',
    label: 'El Kelâa des Sraghna',
    country: 'MA-07',
  },
  {
    code: 'MA-KHE',
    label: 'Khémisset',
    country: 'MA-04',
  },
  {
    code: 'MA-KHN',
    label: 'Khénifra',
    country: 'MA-05',
  },
  {
    code: 'MA-KHO',
    label: 'Khouribga',
    country: 'MA-05',
  },
  {
    code: 'MA-LAA',
    label: 'Laâyoune (EH)',
    country: 'MA-11',
  },
  {
    code: 'MA-LAR',
    label: 'Larache',
    country: 'MA-01',
  },
  {
    code: 'MA-MAR',
    label: 'Marrakech',
    country: 'MA-07',
  },
  {
    code: 'MA-MDF',
    label: 'M’diq-Fnideq',
    country: 'MA-01',
  },
  {
    code: 'MA-MED',
    label: 'Médiouna',
    country: 'MA-06',
  },
  {
    code: 'MA-MEK',
    label: 'Meknès',
    country: 'MA-03',
  },
  {
    code: 'MA-MID',
    label: 'Midelt',
    country: 'MA-08',
  },
  {
    code: 'MA-MOH',
    label: 'Mohammadia',
    country: 'MA-06',
  },
  {
    code: 'MA-MOU',
    label: 'Moulay Yacoub',
    country: 'MA-03',
  },
  {
    code: 'MA-NAD',
    label: 'Nador',
    country: 'MA-02',
  },
  {
    code: 'MA-NOU',
    label: 'Nouaceur',
    country: 'MA-04',
  },
  {
    code: 'MA-OUA',
    label: 'Ouarzazate',
    country: 'MA-08',
  },
  {
    code: 'MA-OUD',
    label: 'Oued Ed-Dahab (EH)',
    country: 'MA-12',
  },
  {
    code: 'MA-OUJ',
    label: 'Oujda-Angad',
    country: 'MA-02',
  },
  {
    code: 'MA-OUZ',
    label: 'Ouezzane',
    country: 'MA-01',
  },
  {
    code: 'MA-RAB',
    label: 'Rabat',
    country: 'MA-04',
  },
  {
    code: 'MA-REH',
    label: 'Rehamna',
    country: 'MA-07',
  },
  {
    code: 'MA-SAF',
    label: 'Safi',
    country: 'MA-07',
  },
  {
    code: 'MA-SAL',
    label: 'Salé',
    country: 'MA-04',
  },
  {
    code: 'MA-SEF',
    label: 'Sefrou',
    country: 'MA-03',
  },
  {
    code: 'MA-SET',
    label: 'Settat',
    country: 'MA-06',
  },
  {
    code: 'MA-SIB',
    label: 'Sidi Bennour',
    country: 'MA-06',
  },
  {
    code: 'MA-SIF',
    label: 'Sidi Ifni',
    country: 'MA-10',
  },
  {
    code: 'MA-SIK',
    label: 'Sidi Kacem',
    country: 'MA-04',
  },
  {
    code: 'MA-SIL',
    label: 'Sidi Slimane',
    country: 'MA-04',
  },
  {
    code: 'MA-SKH',
    label: 'Skhirate-Témara',
    country: 'MA-04',
  },
  {
    code: 'MA-TAF',
    label: 'Tarfaya (EH-partial)',
    country: 'MA-11',
  },
  {
    code: 'MA-TAI',
    label: 'Taourirt',
    country: 'MA-02',
  },
  {
    code: 'MA-TAO',
    label: 'Taounate',
    country: 'MA-03',
  },
  {
    code: 'MA-TAR',
    label: 'Taroudannt',
    country: 'MA-09',
  },
  {
    code: 'MA-TAT',
    label: 'Tata',
    country: 'MA-09',
  },
  {
    code: 'MA-TAZ',
    label: 'Taza',
    country: 'MA-03',
  },
  {
    code: 'MA-TET',
    label: 'Tétouan',
    country: 'MA-01',
  },
  {
    code: 'MA-TIN',
    label: 'Tinghir',
    country: 'MA-08',
  },
  {
    code: 'MA-TIZ',
    label: 'Tiznit',
    country: 'MA-09',
  },
  {
    code: 'MA-TNG',
    label: 'Tanger-Assilah',
    country: 'MA-01',
  },
  {
    code: 'MA-TNT',
    label: 'Tan-Tan (EH-partial)',
    country: 'MA-10',
  },
  {
    code: 'MA-YUS',
    label: 'Youssoufia',
    country: 'MA-07',
  },
  {
    code: 'MA-ZAG',
    label: 'Zagora',
    country: 'MA-08',
  },
  {
    code: 'MC-CL',
    label: 'La Colle',
    country: 'MC',
  },
  {
    code: 'MC-CO',
    label: 'La Condamine',
    country: 'MC',
  },
  {
    code: 'MC-FO',
    label: 'Fontvieille',
    country: 'MC',
  },
  {
    code: 'MC-GA',
    label: 'La Gare',
    country: 'MC',
  },
  {
    code: 'MC-JE',
    label: 'Jardin Exotique',
    country: 'MC',
  },
  {
    code: 'MC-LA',
    label: 'Larvotto',
    country: 'MC',
  },
  {
    code: 'MC-MA',
    label: 'Malbousquet',
    country: 'MC',
  },
  {
    code: 'MC-MC',
    label: 'Monte-Carlo',
    country: 'MC',
  },
  {
    code: 'MC-MG',
    label: 'Moneghetti',
    country: 'MC',
  },
  {
    code: 'MC-MO',
    label: 'Monaco-Ville',
    country: 'MC',
  },
  {
    code: 'MC-MU',
    label: 'Moulins',
    country: 'MC',
  },
  {
    code: 'MC-PH',
    label: 'Port-Hercule',
    country: 'MC',
  },
  {
    code: 'MC-SD',
    label: 'Sainte-Dévote',
    country: 'MC',
  },
  {
    code: 'MC-SO',
    label: 'La Source',
    country: 'MC',
  },
  {
    code: 'MC-SP',
    label: 'Spélugues',
    country: 'MC',
  },
  {
    code: 'MC-SR',
    label: 'Saint-Roman',
    country: 'MC',
  },
  {
    code: 'MC-VR',
    label: 'Vallon de la Rousse',
    country: 'MC',
  },
  {
    code: 'MD-AN',
    label: 'Anenii Noi',
    country: 'MD',
  },
  {
    code: 'MD-BA',
    label: 'Bălți',
    country: 'MD',
  },
  {
    code: 'MD-BD',
    label: 'Bender',
    country: 'MD',
  },
  {
    code: 'MD-BR',
    label: 'Briceni',
    country: 'MD',
  },
  {
    code: 'MD-BS',
    label: 'Basarabeasca',
    country: 'MD',
  },
  {
    code: 'MD-CA',
    label: 'Cahul',
    country: 'MD',
  },
  {
    code: 'MD-CL',
    label: 'Călărași',
    country: 'MD',
  },
  {
    code: 'MD-CM',
    label: 'Cimișlia',
    country: 'MD',
  },
  {
    code: 'MD-CR',
    label: 'Criuleni',
    country: 'MD',
  },
  {
    code: 'MD-CS',
    label: 'Căușeni',
    country: 'MD',
  },
  {
    code: 'MD-CT',
    label: 'Cantemir',
    country: 'MD',
  },
  {
    code: 'MD-CU',
    label: 'Chișinău',
    country: 'MD',
  },
  {
    code: 'MD-DO',
    label: 'Dondușeni',
    country: 'MD',
  },
  {
    code: 'MD-DR',
    label: 'Drochia',
    country: 'MD',
  },
  {
    code: 'MD-DU',
    label: 'Dubăsari',
    country: 'MD',
  },
  {
    code: 'MD-ED',
    label: 'Edineț',
    country: 'MD',
  },
  {
    code: 'MD-FA',
    label: 'Fălești',
    country: 'MD',
  },
  {
    code: 'MD-FL',
    label: 'Florești',
    country: 'MD',
  },
  {
    code: 'MD-GA',
    label: 'Găgăuzia, Unitatea teritorială autonomă (UTAG)',
    country: 'MD',
  },
  {
    code: 'MD-GL',
    label: 'Glodeni',
    country: 'MD',
  },
  {
    code: 'MD-HI',
    label: 'Hîncești',
    country: 'MD',
  },
  {
    code: 'MD-IA',
    label: 'Ialoveni',
    country: 'MD',
  },
  {
    code: 'MD-LE',
    label: 'Leova',
    country: 'MD',
  },
  {
    code: 'MD-NI',
    label: 'Nisporeni',
    country: 'MD',
  },
  {
    code: 'MD-OC',
    label: 'Ocnița',
    country: 'MD',
  },
  {
    code: 'MD-OR',
    label: 'Orhei',
    country: 'MD',
  },
  {
    code: 'MD-RE',
    label: 'Rezina',
    country: 'MD',
  },
  {
    code: 'MD-RI',
    label: 'Rîșcani',
    country: 'MD',
  },
  {
    code: 'MD-SD',
    label: 'Șoldănești',
    country: 'MD',
  },
  {
    code: 'MD-SI',
    label: 'Sîngerei',
    country: 'MD',
  },
  {
    code: 'MD-SN',
    label: 'Stînga Nistrului, unitatea teritorială din',
    country: 'MD',
  },
  {
    code: 'MD-SO',
    label: 'Soroca',
    country: 'MD',
  },
  {
    code: 'MD-ST',
    label: 'Strășeni',
    country: 'MD',
  },
  {
    code: 'MD-SV',
    label: 'Ștefan Vodă',
    country: 'MD',
  },
  {
    code: 'MD-TA',
    label: 'Taraclia',
    country: 'MD',
  },
  {
    code: 'MD-TE',
    label: 'Telenești',
    country: 'MD',
  },
  {
    code: 'MD-UN',
    label: 'Ungheni',
    country: 'MD',
  },
  {
    code: 'ME-01',
    label: 'Andrijevica',
    country: 'ME',
  },
  {
    code: 'ME-02',
    label: 'Bar',
    country: 'ME',
  },
  {
    code: 'ME-03',
    label: 'Berane',
    country: 'ME',
  },
  {
    code: 'ME-04',
    label: 'Bijelo Polje',
    country: 'ME',
  },
  {
    code: 'ME-05',
    label: 'Budva',
    country: 'ME',
  },
  {
    code: 'ME-06',
    label: 'Cetinje',
    country: 'ME',
  },
  {
    code: 'ME-07',
    label: 'Danilovgrad',
    country: 'ME',
  },
  {
    code: 'ME-08',
    label: 'Herceg-Novi',
    country: 'ME',
  },
  {
    code: 'ME-09',
    label: 'Kolašin',
    country: 'ME',
  },
  {
    code: 'ME-10',
    label: 'Kotor',
    country: 'ME',
  },
  {
    code: 'ME-11',
    label: 'Mojkovac',
    country: 'ME',
  },
  {
    code: 'ME-12',
    label: 'Nikšić',
    country: 'ME',
  },
  {
    code: 'ME-13',
    label: 'Plav',
    country: 'ME',
  },
  {
    code: 'ME-14',
    label: 'Pljevlja',
    country: 'ME',
  },
  {
    code: 'ME-15',
    label: 'Plužine',
    country: 'ME',
  },
  {
    code: 'ME-16',
    label: 'Podgorica',
    country: 'ME',
  },
  {
    code: 'ME-17',
    label: 'Rožaje',
    country: 'ME',
  },
  {
    code: 'ME-18',
    label: 'Šavnik',
    country: 'ME',
  },
  {
    code: 'ME-19',
    label: 'Tivat',
    country: 'ME',
  },
  {
    code: 'ME-20',
    label: 'Ulcinj',
    country: 'ME',
  },
  {
    code: 'ME-21',
    label: 'Žabljak',
    country: 'ME',
  },
  {
    code: 'ME-22',
    label: 'Gusinje',
    country: 'ME',
  },
  {
    code: 'ME-23',
    label: 'Petnjica',
    country: 'ME',
  },
  {
    code: 'ME-24',
    label: 'Tuzi',
    country: 'ME',
  },
  {
    code: 'MG-A',
    label: 'Toamasina',
    country: 'MG',
  },
  {
    code: 'MG-D',
    label: 'Antsiranana',
    country: 'MG',
  },
  {
    code: 'MG-F',
    label: 'Fianarantsoa',
    country: 'MG',
  },
  {
    code: 'MG-M',
    label: 'Mahajanga',
    country: 'MG',
  },
  {
    code: 'MG-T',
    label: 'Antananarivo',
    country: 'MG',
  },
  {
    code: 'MG-U',
    label: 'Toliara',
    country: 'MG',
  },
  {
    code: 'MH-ALK',
    label: 'Ailuk',
    country: 'MH-T',
  },
  {
    code: 'MH-ALL',
    label: 'Ailinglaplap',
    country: 'MH-L',
  },
  {
    code: 'MH-ARN',
    label: 'Arno',
    country: 'MH-T',
  },
  {
    code: 'MH-AUR',
    label: 'Aur',
    country: 'MH-T',
  },
  {
    code: 'MH-EBO',
    label: 'Ebon',
    country: 'MH-L',
  },
  {
    code: 'MH-ENI',
    label: 'Enewetak & Ujelang',
    country: 'MH-L',
  },
  {
    code: 'MH-JAB',
    label: 'Jabat',
    country: 'MH-L',
  },
  {
    code: 'MH-JAL',
    label: 'Jaluit',
    country: 'MH-L',
  },
  {
    code: 'MH-KIL',
    label: 'Bikini & Kili',
    country: 'MH-L',
  },
  {
    code: 'MH-KWA',
    label: 'Kwajalein',
    country: 'MH-L',
  },
  {
    code: 'MH-L',
    label: 'Ralik chain',
    country: 'MH',
  },
  {
    code: 'MH-LAE',
    label: 'Lae',
    country: 'MH-L',
  },
  {
    code: 'MH-LIB',
    label: 'Lib',
    country: 'MH-L',
  },
  {
    code: 'MH-LIK',
    label: 'Likiep',
    country: 'MH-T',
  },
  {
    code: 'MH-MAJ',
    label: 'Majuro',
    country: 'MH-T',
  },
  {
    code: 'MH-MAL',
    label: 'Maloelap',
    country: 'MH-T',
  },
  {
    code: 'MH-MEJ',
    label: 'Mejit',
    country: 'MH-T',
  },
  {
    code: 'MH-MIL',
    label: 'Mili',
    country: 'MH-T',
  },
  {
    code: 'MH-NMK',
    label: 'Namdrik',
    country: 'MH-L',
  },
  {
    code: 'MH-NMU',
    label: 'Namu',
    country: 'MH-L',
  },
  {
    code: 'MH-RON',
    label: 'Rongelap',
    country: 'MH-L',
  },
  {
    code: 'MH-T',
    label: 'Ratak chain',
    country: 'MH',
  },
  {
    code: 'MH-UJA',
    label: 'Ujae',
    country: 'MH-L',
  },
  {
    code: 'MH-UTI',
    label: 'Utrik',
    country: 'MH-T',
  },
  {
    code: 'MH-WTH',
    label: 'Wotho',
    country: 'MH-L',
  },
  {
    code: 'MH-WTJ',
    label: 'Wotje',
    country: 'MH-T',
  },
  {
    code: 'MK-101',
    label: 'Veles',
    country: 'MK',
  },
  {
    code: 'MK-102',
    label: 'Gradsko',
    country: 'MK',
  },
  {
    code: 'MK-103',
    label: 'Demir Kapija',
    country: 'MK',
  },
  {
    code: 'MK-104',
    label: 'Kavadarci',
    country: 'MK',
  },
  {
    code: 'MK-105',
    label: 'Lozovo',
    country: 'MK',
  },
  {
    code: 'MK-106',
    label: 'Negotino',
    country: 'MK',
  },
  {
    code: 'MK-107',
    label: 'Rosoman',
    country: 'MK',
  },
  {
    code: 'MK-108',
    label: 'Sveti Nikole',
    country: 'MK',
  },
  {
    code: 'MK-109',
    label: 'Čaška',
    country: 'MK',
  },
  {
    code: 'MK-201',
    label: 'Berovo',
    country: 'MK',
  },
  {
    code: 'MK-202',
    label: 'Vinica',
    country: 'MK',
  },
  {
    code: 'MK-203',
    label: 'Delčevo',
    country: 'MK',
  },
  {
    code: 'MK-204',
    label: 'Zrnovci',
    country: 'MK',
  },
  {
    code: 'MK-205',
    label: 'Karbinci',
    country: 'MK',
  },
  {
    code: 'MK-206',
    label: 'Kočani',
    country: 'MK',
  },
  {
    code: 'MK-207',
    label: 'Makedonska Kamenica',
    country: 'MK',
  },
  {
    code: 'MK-208',
    label: 'Pehčevo',
    country: 'MK',
  },
  {
    code: 'MK-209',
    label: 'Probištip',
    country: 'MK',
  },
  {
    code: 'MK-210',
    label: 'Češinovo-Obleševo',
    country: 'MK',
  },
  {
    code: 'MK-211',
    label: 'Štip',
    country: 'MK',
  },
  {
    code: 'MK-301',
    label: 'Vevčani',
    country: 'MK',
  },
  {
    code: 'MK-303',
    label: 'Debar',
    country: 'MK',
  },
  {
    code: 'MK-304',
    label: 'Debarca',
    country: 'MK',
  },
  {
    code: 'MK-307',
    label: 'Kičevo',
    country: 'MK',
  },
  {
    code: 'MK-308',
    label: 'Makedonski Brod',
    country: 'MK',
  },
  {
    code: 'MK-310',
    label: 'Ohrid',
    country: 'MK',
  },
  {
    code: 'MK-311',
    label: 'Plasnica',
    country: 'MK',
  },
  {
    code: 'MK-312',
    label: 'Struga',
    country: 'MK',
  },
  {
    code: 'MK-313',
    label: 'Centar Župa',
    country: 'MK',
  },
  {
    code: 'MK-401',
    label: 'Bogdanci',
    country: 'MK',
  },
  {
    code: 'MK-402',
    label: 'Bosilovo',
    country: 'MK',
  },
  {
    code: 'MK-403',
    label: 'Valandovo',
    country: 'MK',
  },
  {
    code: 'MK-404',
    label: 'Vasilevo',
    country: 'MK',
  },
  {
    code: 'MK-405',
    label: 'Gevgelija',
    country: 'MK',
  },
  {
    code: 'MK-406',
    label: 'Dojran',
    country: 'MK',
  },
  {
    code: 'MK-407',
    label: 'Konče',
    country: 'MK',
  },
  {
    code: 'MK-408',
    label: 'Novo Selo',
    country: 'MK',
  },
  {
    code: 'MK-409',
    label: 'Radoviš',
    country: 'MK',
  },
  {
    code: 'MK-410',
    label: 'Strumica',
    country: 'MK',
  },
  {
    code: 'MK-501',
    label: 'Bitola',
    country: 'MK',
  },
  {
    code: 'MK-502',
    label: 'Demir Hisar',
    country: 'MK',
  },
  {
    code: 'MK-503',
    label: 'Dolneni',
    country: 'MK',
  },
  {
    code: 'MK-504',
    label: 'Krivogaštani',
    country: 'MK',
  },
  {
    code: 'MK-505',
    label: 'Kruševo',
    country: 'MK',
  },
  {
    code: 'MK-506',
    label: 'Mogila',
    country: 'MK',
  },
  {
    code: 'MK-507',
    label: 'Novaci',
    country: 'MK',
  },
  {
    code: 'MK-508',
    label: 'Prilep',
    country: 'MK',
  },
  {
    code: 'MK-509',
    label: 'Resen',
    country: 'MK',
  },
  {
    code: 'MK-601',
    label: 'Bogovinje',
    country: 'MK',
  },
  {
    code: 'MK-602',
    label: 'Brvenica',
    country: 'MK',
  },
  {
    code: 'MK-603',
    label: 'Vrapčište',
    country: 'MK',
  },
  {
    code: 'MK-604',
    label: 'Gostivar',
    country: 'MK',
  },
  {
    code: 'MK-605',
    label: 'Želino',
    country: 'MK',
  },
  {
    code: 'MK-606',
    label: 'Jegunovce',
    country: 'MK',
  },
  {
    code: 'MK-607',
    label: 'Mavrovo i Rostuše',
    country: 'MK',
  },
  {
    code: 'MK-608',
    label: 'Tearce',
    country: 'MK',
  },
  {
    code: 'MK-609',
    label: 'Tetovo',
    country: 'MK',
  },
  {
    code: 'MK-701',
    label: 'Kratovo',
    country: 'MK',
  },
  {
    code: 'MK-702',
    label: 'Kriva Palanka',
    country: 'MK',
  },
  {
    code: 'MK-703',
    label: 'Kumanovo',
    country: 'MK',
  },
  {
    code: 'MK-704',
    label: 'Lipkovo',
    country: 'MK',
  },
  {
    code: 'MK-705',
    label: 'Rankovce',
    country: 'MK',
  },
  {
    code: 'MK-706',
    label: 'Staro Nagoričane',
    country: 'MK',
  },
  {
    code: 'MK-801',
    label: 'Aerodrom',
    country: 'MK',
  },
  {
    code: 'MK-802',
    label: 'Aračinovo',
    country: 'MK',
  },
  {
    code: 'MK-803',
    label: 'Butel',
    country: 'MK',
  },
  {
    code: 'MK-804',
    label: 'Gazi Baba',
    country: 'MK',
  },
  {
    code: 'MK-805',
    label: 'Gjorče Petrov',
    country: 'MK',
  },
  {
    code: 'MK-806',
    label: 'Zelenikovo',
    country: 'MK',
  },
  {
    code: 'MK-807',
    label: 'Ilinden',
    country: 'MK',
  },
  {
    code: 'MK-808',
    label: 'Karpoš',
    country: 'MK',
  },
  {
    code: 'MK-809',
    label: 'Kisela Voda',
    country: 'MK',
  },
  {
    code: 'MK-810',
    label: 'Petrovec',
    country: 'MK',
  },
  {
    code: 'MK-811',
    label: 'Saraj',
    country: 'MK',
  },
  {
    code: 'MK-812',
    label: 'Sopište',
    country: 'MK',
  },
  {
    code: 'MK-813',
    label: 'Studeničani',
    country: 'MK',
  },
  {
    code: 'MK-814',
    label: 'Centar',
    country: 'MK',
  },
  {
    code: 'MK-815',
    label: 'Čair',
    country: 'MK',
  },
  {
    code: 'MK-816',
    label: 'Čučer-Sandevo',
    country: 'MK',
  },
  {
    code: 'MK-817',
    label: 'Šuto Orizari',
    country: 'MK',
  },
  {
    code: 'ML-1',
    label: 'Kayes',
    country: 'ML',
  },
  {
    code: 'ML-10',
    label: 'Taoudénit(local variants are Taoudenni, Taoudéni)',
    country: 'ML',
  },
  {
    code: 'ML-2',
    label: 'Koulikoro',
    country: 'ML',
  },
  {
    code: 'ML-3',
    label: 'Sikasso',
    country: 'ML',
  },
  {
    code: 'ML-4',
    label: 'Ségou',
    country: 'ML',
  },
  {
    code: 'ML-5',
    label: 'Mopti',
    country: 'ML',
  },
  {
    code: 'ML-6',
    label: 'Tombouctou',
    country: 'ML',
  },
  {
    code: 'ML-7',
    label: 'Gao',
    country: 'ML',
  },
  {
    code: 'ML-8',
    label: 'Kidal',
    country: 'ML',
  },
  {
    code: 'ML-9',
    label: 'Ménaka',
    country: 'ML',
  },
  {
    code: 'ML-BKO',
    label: 'Bamako',
    country: 'ML',
  },
  {
    code: 'MM-01',
    label: 'Sagaing',
    country: 'MM',
  },
  {
    code: 'MM-02',
    label: 'Bago',
    country: 'MM',
  },
  {
    code: 'MM-03',
    label: 'Magway',
    country: 'MM',
  },
  {
    code: 'MM-04',
    label: 'Mandalay',
    country: 'MM',
  },
  {
    code: 'MM-05',
    label: 'Tanintharyi',
    country: 'MM',
  },
  {
    code: 'MM-06',
    label: 'Yangon',
    country: 'MM',
  },
  {
    code: 'MM-07',
    label: 'Ayeyarwady',
    country: 'MM',
  },
  {
    code: 'MM-11',
    label: 'Kachin',
    country: 'MM',
  },
  {
    code: 'MM-12',
    label: 'Kayah',
    country: 'MM',
  },
  {
    code: 'MM-13',
    label: 'Kayin',
    country: 'MM',
  },
  {
    code: 'MM-14',
    label: 'Chin',
    country: 'MM',
  },
  {
    code: 'MM-15',
    label: 'Mon',
    country: 'MM',
  },
  {
    code: 'MM-16',
    label: 'Rakhine',
    country: 'MM',
  },
  {
    code: 'MM-17',
    label: 'Shan',
    country: 'MM',
  },
  {
    code: 'MM-18',
    label: 'Nay Pyi Taw',
    country: 'MM',
  },
  {
    code: 'MN-035',
    label: 'Orhon',
    country: 'MN',
  },
  {
    code: 'MN-037',
    label: 'Darhan uul',
    country: 'MN',
  },
  {
    code: 'MN-039',
    label: 'Hentiy',
    country: 'MN',
  },
  {
    code: 'MN-041',
    label: 'Hövsgöl',
    country: 'MN',
  },
  {
    code: 'MN-043',
    label: 'Hovd',
    country: 'MN',
  },
  {
    code: 'MN-046',
    label: 'Uvs',
    country: 'MN',
  },
  {
    code: 'MN-047',
    label: 'Töv',
    country: 'MN',
  },
  {
    code: 'MN-049',
    label: 'Selenge',
    country: 'MN',
  },
  {
    code: 'MN-051',
    label: 'Sühbaatar',
    country: 'MN',
  },
  {
    code: 'MN-053',
    label: 'Ömnögovĭ',
    country: 'MN',
  },
  {
    code: 'MN-055',
    label: 'Övörhangay',
    country: 'MN',
  },
  {
    code: 'MN-057',
    label: 'Dzavhan',
    country: 'MN',
  },
  {
    code: 'MN-059',
    label: 'Dundgovĭ',
    country: 'MN',
  },
  {
    code: 'MN-061',
    label: 'Dornod',
    country: 'MN',
  },
  {
    code: 'MN-063',
    label: 'Dornogovĭ',
    country: 'MN',
  },
  {
    code: 'MN-064',
    label: 'Govĭ-Sümber',
    country: 'MN',
  },
  {
    code: 'MN-065',
    label: 'Govĭ-Altay',
    country: 'MN',
  },
  {
    code: 'MN-067',
    label: 'Bulgan',
    country: 'MN',
  },
  {
    code: 'MN-069',
    label: 'Bayanhongor',
    country: 'MN',
  },
  {
    code: 'MN-071',
    label: 'Bayan-Ölgiy',
    country: 'MN',
  },
  {
    code: 'MN-073',
    label: 'Arhangay',
    country: 'MN',
  },
  {
    code: 'MN-1',
    label: 'Ulaanbaatar',
    country: 'MN',
  },
  {
    code: 'MR-01',
    label: 'Hodh ech Chargui',
    country: 'MR',
  },
  {
    code: 'MR-02',
    label: 'Hodh el Gharbi',
    country: 'MR',
  },
  {
    code: 'MR-03',
    label: 'Assaba',
    country: 'MR',
  },
  {
    code: 'MR-04',
    label: 'Gorgol',
    country: 'MR',
  },
  {
    code: 'MR-05',
    label: 'Brakna',
    country: 'MR',
  },
  {
    code: 'MR-06',
    label: 'Trarza',
    country: 'MR',
  },
  {
    code: 'MR-07',
    label: 'Adrar',
    country: 'MR',
  },
  {
    code: 'MR-08',
    label: 'Dakhlet Nouâdhibou',
    country: 'MR',
  },
  {
    code: 'MR-09',
    label: 'Tagant',
    country: 'MR',
  },
  {
    code: 'MR-10',
    label: 'Guidimaka',
    country: 'MR',
  },
  {
    code: 'MR-11',
    label: 'Tiris Zemmour',
    country: 'MR',
  },
  {
    code: 'MR-12',
    label: 'Inchiri',
    country: 'MR',
  },
  {
    code: 'MR-13',
    label: 'Nuwākshūţ al Gharbīyah',
    country: 'MR',
  },
  {
    code: 'MR-14',
    label: 'Nuwākshūţ ash Shamālīyah',
    country: 'MR',
  },
  {
    code: 'MR-15',
    label: 'Nuwākshūţ al Janūbīyah',
    country: 'MR',
  },
  {
    code: 'MT-01',
    label: 'Attard',
    country: 'MT',
  },
  {
    code: 'MT-02',
    label: 'Balzan',
    country: 'MT',
  },
  {
    code: 'MT-03',
    label: 'Birgu',
    country: 'MT',
  },
  {
    code: 'MT-04',
    label: 'Birkirkara',
    country: 'MT',
  },
  {
    code: 'MT-05',
    label: 'Birżebbuġa',
    country: 'MT',
  },
  {
    code: 'MT-06',
    label: 'Bormla',
    country: 'MT',
  },
  {
    code: 'MT-07',
    label: 'Dingli',
    country: 'MT',
  },
  {
    code: 'MT-08',
    label: 'Fgura',
    country: 'MT',
  },
  {
    code: 'MT-09',
    label: 'Floriana',
    country: 'MT',
  },
  {
    code: 'MT-10',
    label: 'Fontana',
    country: 'MT',
  },
  {
    code: 'MT-11',
    label: 'Gudja',
    country: 'MT',
  },
  {
    code: 'MT-12',
    label: 'Gżira',
    country: 'MT',
  },
  {
    code: 'MT-13',
    label: 'Għajnsielem',
    country: 'MT',
  },
  {
    code: 'MT-14',
    label: 'Għarb',
    country: 'MT',
  },
  {
    code: 'MT-15',
    label: 'Għargħur',
    country: 'MT',
  },
  {
    code: 'MT-16',
    label: 'Għasri',
    country: 'MT',
  },
  {
    code: 'MT-17',
    label: 'Għaxaq',
    country: 'MT',
  },
  {
    code: 'MT-18',
    label: 'Ħamrun',
    country: 'MT',
  },
  {
    code: 'MT-19',
    label: 'Iklin',
    country: 'MT',
  },
  {
    code: 'MT-20',
    label: 'Isla',
    country: 'MT',
  },
  {
    code: 'MT-21',
    label: 'Kalkara',
    country: 'MT',
  },
  {
    code: 'MT-22',
    label: 'Kerċem',
    country: 'MT',
  },
  {
    code: 'MT-23',
    label: 'Kirkop',
    country: 'MT',
  },
  {
    code: 'MT-24',
    label: 'Lija',
    country: 'MT',
  },
  {
    code: 'MT-25',
    label: 'Luqa',
    country: 'MT',
  },
  {
    code: 'MT-26',
    label: 'Marsa',
    country: 'MT',
  },
  {
    code: 'MT-27',
    label: 'Marsaskala',
    country: 'MT',
  },
  {
    code: 'MT-28',
    label: 'Marsaxlokk',
    country: 'MT',
  },
  {
    code: 'MT-29',
    label: 'Mdina',
    country: 'MT',
  },
  {
    code: 'MT-30',
    label: 'Mellieħa',
    country: 'MT',
  },
  {
    code: 'MT-31',
    label: 'Mġarr',
    country: 'MT',
  },
  {
    code: 'MT-32',
    label: 'Mosta',
    country: 'MT',
  },
  {
    code: 'MT-33',
    label: 'Mqabba',
    country: 'MT',
  },
  {
    code: 'MT-34',
    label: 'Msida',
    country: 'MT',
  },
  {
    code: 'MT-35',
    label: 'Mtarfa',
    country: 'MT',
  },
  {
    code: 'MT-36',
    label: 'Munxar',
    country: 'MT',
  },
  {
    code: 'MT-37',
    label: 'Nadur',
    country: 'MT',
  },
  {
    code: 'MT-38',
    label: 'Naxxar',
    country: 'MT',
  },
  {
    code: 'MT-39',
    label: 'Paola',
    country: 'MT',
  },
  {
    code: 'MT-40',
    label: 'Pembroke',
    country: 'MT',
  },
  {
    code: 'MT-41',
    label: 'Pietà',
    country: 'MT',
  },
  {
    code: 'MT-42',
    label: 'Qala',
    country: 'MT',
  },
  {
    code: 'MT-43',
    label: 'Qormi',
    country: 'MT',
  },
  {
    code: 'MT-44',
    label: 'Qrendi',
    country: 'MT',
  },
  {
    code: 'MT-45',
    label: 'Rabat Gozo',
    country: 'MT',
  },
  {
    code: 'MT-46',
    label: 'Rabat Malta',
    country: 'MT',
  },
  {
    code: 'MT-47',
    label: 'Safi',
    country: 'MT',
  },
  {
    code: 'MT-48',
    label: "Saint Julian's",
    country: 'MT',
  },
  {
    code: 'MT-49',
    label: 'Saint John',
    country: 'MT',
  },
  {
    code: 'MT-50',
    label: 'Saint Lawrence',
    country: 'MT',
  },
  {
    code: 'MT-51',
    label: "Saint Paul's Bay",
    country: 'MT',
  },
  {
    code: 'MT-52',
    label: 'Sannat',
    country: 'MT',
  },
  {
    code: 'MT-53',
    label: "Saint Lucia's",
    country: 'MT',
  },
  {
    code: 'MT-54',
    label: 'Santa Venera',
    country: 'MT',
  },
  {
    code: 'MT-55',
    label: 'Siġġiewi',
    country: 'MT',
  },
  {
    code: 'MT-56',
    label: 'Sliema',
    country: 'MT',
  },
  {
    code: 'MT-57',
    label: 'Swieqi',
    country: 'MT',
  },
  {
    code: 'MT-58',
    label: "Ta' Xbiex",
    country: 'MT',
  },
  {
    code: 'MT-59',
    label: 'Tarxien',
    country: 'MT',
  },
  {
    code: 'MT-60',
    label: 'Valletta',
    country: 'MT',
  },
  {
    code: 'MT-61',
    label: 'Xagħra',
    country: 'MT',
  },
  {
    code: 'MT-62',
    label: 'Xewkija',
    country: 'MT',
  },
  {
    code: 'MT-63',
    label: 'Xgħajra',
    country: 'MT',
  },
  {
    code: 'MT-64',
    label: 'Żabbar',
    country: 'MT',
  },
  {
    code: 'MT-65',
    label: 'Żebbuġ Gozo',
    country: 'MT',
  },
  {
    code: 'MT-66',
    label: 'Żebbuġ Malta',
    country: 'MT',
  },
  {
    code: 'MT-67',
    label: 'Żejtun',
    country: 'MT',
  },
  {
    code: 'MT-68',
    label: 'Żurrieq',
    country: 'MT',
  },
  {
    code: 'MU-AG',
    label: 'Agalega Islands',
    country: 'MU',
  },
  {
    code: 'MU-BL',
    label: 'Black River',
    country: 'MU',
  },
  {
    code: 'MU-CC',
    label: 'Cargados Carajos Shoals(local variant is Saint Brandon Islands)',
    country: 'MU',
  },
  {
    code: 'MU-FL',
    label: 'Flacq',
    country: 'MU',
  },
  {
    code: 'MU-GP',
    label: 'Grand Port',
    country: 'MU',
  },
  {
    code: 'MU-MO',
    label: 'Moka',
    country: 'MU',
  },
  {
    code: 'MU-PA',
    label: 'Pamplemousses',
    country: 'MU',
  },
  {
    code: 'MU-PL',
    label: 'Port Louis',
    country: 'MU',
  },
  {
    code: 'MU-PW',
    label: 'Plaines Wilhems',
    country: 'MU',
  },
  {
    code: 'MU-RO',
    label: 'Rodrigues Island',
    country: 'MU',
  },
  {
    code: 'MU-RR',
    label: 'Rivière du Rempart',
    country: 'MU',
  },
  {
    code: 'MU-SA',
    label: 'Savanne',
    country: 'MU',
  },
  {
    code: 'MV-00',
    label: 'South Ari Atoll',
    country: 'MV',
  },
  {
    code: 'MV-01',
    label: 'Addu City',
    country: 'MV',
  },
  {
    code: 'MV-02',
    label: 'North Ari Atoll',
    country: 'MV',
  },
  {
    code: 'MV-03',
    label: 'Faadhippolhu',
    country: 'MV',
  },
  {
    code: 'MV-04',
    label: 'Felidhu Atoll',
    country: 'MV',
  },
  {
    code: 'MV-05',
    label: 'Hahdhunmathi',
    country: 'MV',
  },
  {
    code: 'MV-07',
    label: 'North Thiladhunmathi',
    country: 'MV',
  },
  {
    code: 'MV-08',
    label: 'Kolhumadulu',
    country: 'MV',
  },
  {
    code: 'MV-12',
    label: 'Mulaku Atoll',
    country: 'MV',
  },
  {
    code: 'MV-13',
    label: 'North Maalhosmadulu',
    country: 'MV',
  },
  {
    code: 'MV-14',
    label: 'North Nilandhe Atoll',
    country: 'MV',
  },
  {
    code: 'MV-17',
    label: 'South Nilandhe Atoll',
    country: 'MV',
  },
  {
    code: 'MV-20',
    label: 'South Maalhosmadulu',
    country: 'MV',
  },
  {
    code: 'MV-23',
    label: 'South Thiladhunmathi',
    country: 'MV',
  },
  {
    code: 'MV-24',
    label: 'North Miladhunmadulu',
    country: 'MV',
  },
  {
    code: 'MV-25',
    label: 'South Miladhunmadulu',
    country: 'MV',
  },
  {
    code: 'MV-26',
    label: 'Male Atoll',
    country: 'MV',
  },
  {
    code: 'MV-27',
    label: 'North Huvadhu Atoll',
    country: 'MV',
  },
  {
    code: 'MV-28',
    label: 'South Huvadhu Atoll',
    country: 'MV',
  },
  {
    code: 'MV-29',
    label: 'Fuvammulah',
    country: 'MV',
  },
  {
    code: 'MV-MLE',
    label: 'Male',
    country: 'MV',
  },
  {
    code: 'MW-BA',
    label: 'Balaka',
    country: 'MW-S',
  },
  {
    code: 'MW-BL',
    label: 'Blantyre',
    country: 'MW-S',
  },
  {
    code: 'MW-C',
    label: 'Central Region',
    country: 'MW',
  },
  {
    code: 'MW-CK',
    label: 'Chikwawa',
    country: 'MW-S',
  },
  {
    code: 'MW-CR',
    label: 'Chiradzulu',
    country: 'MW-S',
  },
  {
    code: 'MW-CT',
    label: 'Chitipa',
    country: 'MW-N',
  },
  {
    code: 'MW-DE',
    label: 'Dedza',
    country: 'MW-C',
  },
  {
    code: 'MW-DO',
    label: 'Dowa',
    country: 'MW-C',
  },
  {
    code: 'MW-KR',
    label: 'Karonga',
    country: 'MW-N',
  },
  {
    code: 'MW-KS',
    label: 'Kasungu',
    country: 'MW-C',
  },
  {
    code: 'MW-LI',
    label: 'Lilongwe',
    country: 'MW-C',
  },
  {
    code: 'MW-LK',
    label: 'Likoma',
    country: 'MW-N',
  },
  {
    code: 'MW-MC',
    label: 'Mchinji',
    country: 'MW-C',
  },
  {
    code: 'MW-MG',
    label: 'Mangochi',
    country: 'MW-S',
  },
  {
    code: 'MW-MH',
    label: 'Machinga',
    country: 'MW-S',
  },
  {
    code: 'MW-MU',
    label: 'Mulanje',
    country: 'MW-S',
  },
  {
    code: 'MW-MW',
    label: 'Mwanza',
    country: 'MW-S',
  },
  {
    code: 'MW-MZ',
    label: 'Mzimba',
    country: 'MW-N',
  },
  {
    code: 'MW-N',
    label: 'Northern Region',
    country: 'MW',
  },
  {
    code: 'MW-NB',
    label: 'Nkhata Bay',
    country: 'MW-N',
  },
  {
    code: 'MW-NE',
    label: 'Neno',
    country: 'MW-S',
  },
  {
    code: 'MW-NI',
    label: 'Ntchisi',
    country: 'MW-C',
  },
  {
    code: 'MW-NK',
    label: 'Nkhotakota',
    country: 'MW-C',
  },
  {
    code: 'MW-NS',
    label: 'Nsanje',
    country: 'MW-S',
  },
  {
    code: 'MW-NU',
    label: 'Ntcheu',
    country: 'MW-C',
  },
  {
    code: 'MW-PH',
    label: 'Phalombe',
    country: 'MW-S',
  },
  {
    code: 'MW-RU',
    label: 'Rumphi',
    country: 'MW-N',
  },
  {
    code: 'MW-S',
    label: 'Southern Region',
    country: 'MW',
  },
  {
    code: 'MW-SA',
    label: 'Salima',
    country: 'MW-C',
  },
  {
    code: 'MW-TH',
    label: 'Thyolo',
    country: 'MW-S',
  },
  {
    code: 'MW-ZO',
    label: 'Zomba',
    country: 'MW-S',
  },
  {
    code: 'MX-AGU',
    label: 'Aguascalientes',
    country: 'MX',
  },
  {
    code: 'MX-BCN',
    label: 'Baja California',
    country: 'MX',
  },
  {
    code: 'MX-BCS',
    label: 'Baja California Sur',
    country: 'MX',
  },
  {
    code: 'MX-CAM',
    label: 'Campeche',
    country: 'MX',
  },
  {
    code: 'MX-CHH',
    label: 'Chihuahua',
    country: 'MX',
  },
  {
    code: 'MX-CHP',
    label: 'Chiapas',
    country: 'MX',
  },
  {
    code: 'MX-CMX',
    label: 'Ciudad de México',
    country: 'MX',
  },
  {
    code: 'MX-COA',
    label: 'Coahuila de Zaragoza',
    country: 'MX',
  },
  {
    code: 'MX-COL',
    label: 'Colima',
    country: 'MX',
  },
  {
    code: 'MX-DUR',
    label: 'Durango',
    country: 'MX',
  },
  {
    code: 'MX-GRO',
    label: 'Guerrero',
    country: 'MX',
  },
  {
    code: 'MX-GUA',
    label: 'Guanajuato',
    country: 'MX',
  },
  {
    code: 'MX-HID',
    label: 'Hidalgo',
    country: 'MX',
  },
  {
    code: 'MX-JAL',
    label: 'Jalisco',
    country: 'MX',
  },
  {
    code: 'MX-MEX',
    label: 'México',
    country: 'MX',
  },
  {
    code: 'MX-MIC',
    label: 'Michoacán de Ocampo',
    country: 'MX',
  },
  {
    code: 'MX-MOR',
    label: 'Morelos',
    country: 'MX',
  },
  {
    code: 'MX-NAY',
    label: 'Nayarit',
    country: 'MX',
  },
  {
    code: 'MX-NLE',
    label: 'Nuevo León',
    country: 'MX',
  },
  {
    code: 'MX-OAX',
    label: 'Oaxaca',
    country: 'MX',
  },
  {
    code: 'MX-PUE',
    label: 'Puebla',
    country: 'MX',
  },
  {
    code: 'MX-QUE',
    label: 'Querétaro',
    country: 'MX',
  },
  {
    code: 'MX-ROO',
    label: 'Quintana Roo',
    country: 'MX',
  },
  {
    code: 'MX-SIN',
    label: 'Sinaloa',
    country: 'MX',
  },
  {
    code: 'MX-SLP',
    label: 'San Luis Potosí',
    country: 'MX',
  },
  {
    code: 'MX-SON',
    label: 'Sonora',
    country: 'MX',
  },
  {
    code: 'MX-TAB',
    label: 'Tabasco',
    country: 'MX',
  },
  {
    code: 'MX-TAM',
    label: 'Tamaulipas',
    country: 'MX',
  },
  {
    code: 'MX-TLA',
    label: 'Tlaxcala',
    country: 'MX',
  },
  {
    code: 'MX-VER',
    label: 'Veracruz de Ignacio de la Llave',
    country: 'MX',
  },
  {
    code: 'MX-YUC',
    label: 'Yucatán',
    country: 'MX',
  },
  {
    code: 'MX-ZAC',
    label: 'Zacatecas',
    country: 'MX',
  },
  {
    code: 'MY-01',
    label: 'Johor',
    country: 'MY',
  },
  {
    code: 'MY-02',
    label: 'Kedah',
    country: 'MY',
  },
  {
    code: 'MY-03',
    label: 'Kelantan',
    country: 'MY',
  },
  {
    code: 'MY-04',
    label: 'Melaka',
    country: 'MY',
  },
  {
    code: 'MY-05',
    label: 'Negeri Sembilan',
    country: 'MY',
  },
  {
    code: 'MY-06',
    label: 'Pahang',
    country: 'MY',
  },
  {
    code: 'MY-07',
    label: 'Pulau Pinang',
    country: 'MY',
  },
  {
    code: 'MY-08',
    label: 'Perak',
    country: 'MY',
  },
  {
    code: 'MY-09',
    label: 'Perlis',
    country: 'MY',
  },
  {
    code: 'MY-10',
    label: 'Selangor',
    country: 'MY',
  },
  {
    code: 'MY-11',
    label: 'Terengganu',
    country: 'MY',
  },
  {
    code: 'MY-12',
    label: 'Sabah',
    country: 'MY',
  },
  {
    code: 'MY-13',
    label: 'Sarawak',
    country: 'MY',
  },
  {
    code: 'MY-14',
    label: 'Wilayah Persekutuan Kuala Lumpur',
    country: 'MY',
  },
  {
    code: 'MY-15',
    label: 'Wilayah Persekutuan Labuan',
    country: 'MY',
  },
  {
    code: 'MY-16',
    label: 'Wilayah Persekutuan Putrajaya',
    country: 'MY',
  },
  {
    code: 'MZ-A',
    label: 'Niassa',
    country: 'MZ',
  },
  {
    code: 'MZ-B',
    label: 'Manica',
    country: 'MZ',
  },
  {
    code: 'MZ-G',
    label: 'Gaza',
    country: 'MZ',
  },
  {
    code: 'MZ-I',
    label: 'Inhambane',
    country: 'MZ',
  },
  {
    code: 'MZ-L',
    label: 'Maputo',
    country: 'MZ',
  },
  {
    code: 'MZ-MPM',
    label: 'Maputo',
    country: 'MZ',
  },
  {
    code: 'MZ-N',
    label: 'Nampula',
    country: 'MZ',
  },
  {
    code: 'MZ-P',
    label: 'Cabo Delgado',
    country: 'MZ',
  },
  {
    code: 'MZ-Q',
    label: 'Zambézia',
    country: 'MZ',
  },
  {
    code: 'MZ-S',
    label: 'Sofala',
    country: 'MZ',
  },
  {
    code: 'MZ-T',
    label: 'Tete',
    country: 'MZ',
  },
  {
    code: 'NA-CA',
    label: 'Zambezi',
    country: 'NA',
  },
  {
    code: 'NA-ER',
    label: 'Erongo',
    country: 'NA',
  },
  {
    code: 'NA-HA',
    label: 'Hardap',
    country: 'NA',
  },
  {
    code: 'NA-KA',
    label: '//Karas(local variants are Karas and !Karas)',
    country: 'NA',
  },
  {
    code: 'NA-KE',
    label: 'Kavango East',
    country: 'NA',
  },
  {
    code: 'NA-KH',
    label: 'Khomas',
    country: 'NA',
  },
  {
    code: 'NA-KU',
    label: 'Kunene',
    country: 'NA',
  },
  {
    code: 'NA-KW',
    label: 'Kavango West',
    country: 'NA',
  },
  {
    code: 'NA-OD',
    label: 'Otjozondjupa',
    country: 'NA',
  },
  {
    code: 'NA-OH',
    label: 'Omaheke',
    country: 'NA',
  },
  {
    code: 'NA-ON',
    label: 'Oshana',
    country: 'NA',
  },
  {
    code: 'NA-OS',
    label: 'Omusati',
    country: 'NA',
  },
  {
    code: 'NA-OT',
    label: 'Oshikoto',
    country: 'NA',
  },
  {
    code: 'NA-OW',
    label: 'Ohangwena',
    country: 'NA',
  },
  {
    code: 'NE-1',
    label: 'Agadez',
    country: 'NE',
  },
  {
    code: 'NE-2',
    label: 'Diffa',
    country: 'NE',
  },
  {
    code: 'NE-3',
    label: 'Dosso',
    country: 'NE',
  },
  {
    code: 'NE-4',
    label: 'Maradi',
    country: 'NE',
  },
  {
    code: 'NE-5',
    label: 'Tahoua',
    country: 'NE',
  },
  {
    code: 'NE-6',
    label: 'Tillabéri',
    country: 'NE',
  },
  {
    code: 'NE-7',
    label: 'Zinder',
    country: 'NE',
  },
  {
    code: 'NE-8',
    label: 'Niamey',
    country: 'NE',
  },
  {
    code: 'NG-AB',
    label: 'Abia',
    country: 'NG',
  },
  {
    code: 'NG-AD',
    label: 'Adamawa',
    country: 'NG',
  },
  {
    code: 'NG-AK',
    label: 'Akwa Ibom',
    country: 'NG',
  },
  {
    code: 'NG-AN',
    label: 'Anambra',
    country: 'NG',
  },
  {
    code: 'NG-BA',
    label: 'Bauchi',
    country: 'NG',
  },
  {
    code: 'NG-BE',
    label: 'Benue',
    country: 'NG',
  },
  {
    code: 'NG-BO',
    label: 'Borno',
    country: 'NG',
  },
  {
    code: 'NG-BY',
    label: 'Bayelsa',
    country: 'NG',
  },
  {
    code: 'NG-CR',
    label: 'Cross River',
    country: 'NG',
  },
  {
    code: 'NG-DE',
    label: 'Delta',
    country: 'NG',
  },
  {
    code: 'NG-EB',
    label: 'Ebonyi',
    country: 'NG',
  },
  {
    code: 'NG-ED',
    label: 'Edo',
    country: 'NG',
  },
  {
    code: 'NG-EK',
    label: 'Ekiti',
    country: 'NG',
  },
  {
    code: 'NG-EN',
    label: 'Enugu',
    country: 'NG',
  },
  {
    code: 'NG-FC',
    label: 'Abuja Federal Capital Territory',
    country: 'NG',
  },
  {
    code: 'NG-GO',
    label: 'Gombe',
    country: 'NG',
  },
  {
    code: 'NG-IM',
    label: 'Imo',
    country: 'NG',
  },
  {
    code: 'NG-JI',
    label: 'Jigawa',
    country: 'NG',
  },
  {
    code: 'NG-KD',
    label: 'Kaduna',
    country: 'NG',
  },
  {
    code: 'NG-KE',
    label: 'Kebbi',
    country: 'NG',
  },
  {
    code: 'NG-KN',
    label: 'Kano',
    country: 'NG',
  },
  {
    code: 'NG-KO',
    label: 'Kogi',
    country: 'NG',
  },
  {
    code: 'NG-KT',
    label: 'Katsina',
    country: 'NG',
  },
  {
    code: 'NG-KW',
    label: 'Kwara',
    country: 'NG',
  },
  {
    code: 'NG-LA',
    label: 'Lagos',
    country: 'NG',
  },
  {
    code: 'NG-NA',
    label: 'Nasarawa',
    country: 'NG',
  },
  {
    code: 'NG-NI',
    label: 'Niger',
    country: 'NG',
  },
  {
    code: 'NG-OG',
    label: 'Ogun',
    country: 'NG',
  },
  {
    code: 'NG-ON',
    label: 'Ondo',
    country: 'NG',
  },
  {
    code: 'NG-OS',
    label: 'Osun',
    country: 'NG',
  },
  {
    code: 'NG-OY',
    label: 'Oyo',
    country: 'NG',
  },
  {
    code: 'NG-PL',
    label: 'Plateau',
    country: 'NG',
  },
  {
    code: 'NG-RI',
    label: 'Rivers',
    country: 'NG',
  },
  {
    code: 'NG-SO',
    label: 'Sokoto',
    country: 'NG',
  },
  {
    code: 'NG-TA',
    label: 'Taraba',
    country: 'NG',
  },
  {
    code: 'NG-YO',
    label: 'Yobe',
    country: 'NG',
  },
  {
    code: 'NG-ZA',
    label: 'Zamfara',
    country: 'NG',
  },
  {
    code: 'NI-AN',
    label: 'Costa Caribe Norte',
    country: 'NI',
  },
  {
    code: 'NI-AS',
    label: 'Costa Caribe Sur',
    country: 'NI',
  },
  {
    code: 'NI-BO',
    label: 'Boaco',
    country: 'NI',
  },
  {
    code: 'NI-CA',
    label: 'Carazo',
    country: 'NI',
  },
  {
    code: 'NI-CI',
    label: 'Chinandega',
    country: 'NI',
  },
  {
    code: 'NI-CO',
    label: 'Chontales',
    country: 'NI',
  },
  {
    code: 'NI-ES',
    label: 'Estelí',
    country: 'NI',
  },
  {
    code: 'NI-GR',
    label: 'Granada',
    country: 'NI',
  },
  {
    code: 'NI-JI',
    label: 'Jinotega',
    country: 'NI',
  },
  {
    code: 'NI-LE',
    label: 'León',
    country: 'NI',
  },
  {
    code: 'NI-MD',
    label: 'Madriz',
    country: 'NI',
  },
  {
    code: 'NI-MN',
    label: 'Managua',
    country: 'NI',
  },
  {
    code: 'NI-MS',
    label: 'Masaya',
    country: 'NI',
  },
  {
    code: 'NI-MT',
    label: 'Matagalpa',
    country: 'NI',
  },
  {
    code: 'NI-NS',
    label: 'Nueva Segovia',
    country: 'NI',
  },
  {
    code: 'NI-RI',
    label: 'Rivas',
    country: 'NI',
  },
  {
    code: 'NI-SJ',
    label: 'Río San Juan',
    country: 'NI',
  },
  {
    code: 'NL-AW',
    label: 'Aruba',
    country: 'NL',
  },
  {
    code: 'NL-BQ1',
    label: 'Bonaire',
    country: 'NL',
  },
  {
    code: 'NL-BQ2',
    label: 'Saba',
    country: 'NL',
  },
  {
    code: 'NL-BQ3',
    label: 'Sint Eustatius',
    country: 'NL',
  },
  {
    code: 'NL-CW',
    label: 'Curaçao',
    country: 'NL',
  },
  {
    code: 'NL-DR',
    label: 'Drenthe',
    country: 'NL',
  },
  {
    code: 'NL-FL',
    label: 'Flevoland',
    country: 'NL',
  },
  {
    code: 'NL-FR',
    label: 'Fryslân',
    country: 'NL',
  },
  {
    code: 'NL-GE',
    label: 'Gelderland',
    country: 'NL',
  },
  {
    code: 'NL-GR',
    label: 'Groningen',
    country: 'NL',
  },
  {
    code: 'NL-LI',
    label: 'Limburg',
    country: 'NL',
  },
  {
    code: 'NL-NB',
    label: 'Noord-Brabant',
    country: 'NL',
  },
  {
    code: 'NL-NH',
    label: 'Noord-Holland',
    country: 'NL',
  },
  {
    code: 'NL-OV',
    label: 'Overijssel',
    country: 'NL',
  },
  {
    code: 'NL-SX',
    label: 'Sint Maarten',
    country: 'NL',
  },
  {
    code: 'NL-UT',
    label: 'Utrecht',
    country: 'NL',
  },
  {
    code: 'NL-ZE',
    label: 'Zeeland',
    country: 'NL',
  },
  {
    code: 'NL-ZH',
    label: 'Zuid-Holland',
    country: 'NL',
  },
  {
    code: 'NO-03',
    label: 'Oslo',
    country: 'NO',
  },
  {
    code: 'NO-11',
    label: 'Rogaland',
    country: 'NO',
  },
  {
    code: 'NO-15',
    label: 'Møre og Romsdal',
    country: 'NO',
  },
  {
    code: 'NO-18',
    label: 'Nordland',
    country: 'NO',
  },
  {
    code: 'NO-21',
    label: 'Svalbard',
    country: 'NO',
  },
  {
    code: 'NO-22',
    label: 'Jan Mayen',
    country: 'NO',
  },
  {
    code: 'NO-30',
    label: 'Viken',
    country: 'NO',
  },
  {
    code: 'NO-34',
    label: 'Innlandet',
    country: 'NO',
  },
  {
    code: 'NO-38',
    label: 'Vestfold og Telemark',
    country: 'NO',
  },
  {
    code: 'NO-42',
    label: 'Agder',
    country: 'NO',
  },
  {
    code: 'NO-46',
    label: 'Vestland',
    country: 'NO',
  },
  {
    code: 'NO-50',
    label: 'Trøndelag / Trööndelage (-)',
    country: 'NO',
  },
  {
    code: 'NO-54',
    label: 'Troms og Finnmark / Romsa ja Finnmárku',
    country: 'NO',
  },
  {
    code: 'NP-1',
    label: 'Madhyamanchal',
    country: 'NP',
  },
  {
    code: 'NP-2',
    label: 'Madhya Pashchimanchal',
    country: 'NP',
  },
  {
    code: 'NP-3',
    label: 'Pashchimanchal',
    country: 'NP',
  },
  {
    code: 'NP-4',
    label: 'Purwanchal',
    country: 'NP',
  },
  {
    code: 'NP-5',
    label: 'Sudur Pashchimanchal',
    country: 'NP',
  },
  {
    code: 'NP-BA',
    label: 'Bagmati',
    country: 'NP-1',
  },
  {
    code: 'NP-BH',
    label: 'Bheri',
    country: 'NP-2',
  },
  {
    code: 'NP-DH',
    label: 'Dhawalagiri',
    country: 'NP-3',
  },
  {
    code: 'NP-GA',
    label: 'Gandaki',
    country: 'NP-3',
  },
  {
    code: 'NP-JA',
    label: 'Janakpur',
    country: 'NP-1',
  },
  {
    code: 'NP-KA',
    label: 'Karnali',
    country: 'NP-2',
  },
  {
    code: 'NP-KO',
    label: 'Kosi(local variant is Koshi)',
    country: 'NP-4',
  },
  {
    code: 'NP-LU',
    label: 'Lumbini',
    country: 'NP-3',
  },
  {
    code: 'NP-MA',
    label: 'Mahakali',
    country: 'NP-5',
  },
  {
    code: 'NP-ME',
    label: 'Mechi',
    country: 'NP-4',
  },
  {
    code: 'NP-NA',
    label: 'Narayani',
    country: 'NP-1',
  },
  {
    code: 'NP-P1',
    label: 'Pradesh 1',
    country: 'NP',
  },
  {
    code: 'NP-P2',
    label: 'Pradesh 2',
    country: 'NP',
  },
  {
    code: 'NP-P3',
    label: 'Bāgmatī',
    country: 'NP',
  },
  {
    code: 'NP-P4',
    label: 'Gandaki',
    country: 'NP',
  },
  {
    code: 'NP-P5',
    label: 'Lumbini',
    country: 'NP',
  },
  {
    code: 'NP-P6',
    label: 'Karnali',
    country: 'NP',
  },
  {
    code: 'NP-P7',
    label: 'Sudūr Pashchim',
    country: 'NP',
  },
  {
    code: 'NP-RA',
    label: 'Rapti',
    country: 'NP-2',
  },
  {
    code: 'NP-SA',
    label: 'Sagarmatha',
    country: 'NP-4',
  },
  {
    code: 'NP-SE',
    label: 'Seti',
    country: 'NP-5',
  },
  {
    code: 'NR-01',
    label: 'Aiwo',
    country: 'NR',
  },
  {
    code: 'NR-02',
    label: 'Anabar',
    country: 'NR',
  },
  {
    code: 'NR-03',
    label: 'Anetan',
    country: 'NR',
  },
  {
    code: 'NR-04',
    label: 'Anibare',
    country: 'NR',
  },
  {
    code: 'NR-05',
    label: 'Baitsi (local variant is Baiti)',
    country: 'NR',
  },
  {
    code: 'NR-06',
    label: 'Boe',
    country: 'NR',
  },
  {
    code: 'NR-07',
    label: 'Buada',
    country: 'NR',
  },
  {
    code: 'NR-08',
    label: 'Denigomodu',
    country: 'NR',
  },
  {
    code: 'NR-09',
    label: 'Ewa',
    country: 'NR',
  },
  {
    code: 'NR-10',
    label: 'Ijuw',
    country: 'NR',
  },
  {
    code: 'NR-11',
    label: 'Meneng',
    country: 'NR',
  },
  {
    code: 'NR-12',
    label: 'Nibok',
    country: 'NR',
  },
  {
    code: 'NR-13',
    label: 'Uaboe',
    country: 'NR',
  },
  {
    code: 'NR-14',
    label: 'Yaren',
    country: 'NR',
  },
  {
    code: 'NZ-AUK',
    label: 'Auckland',
    country: 'NZ',
  },
  {
    code: 'NZ-BOP',
    label: 'Bay of Plenty',
    country: 'NZ',
  },
  {
    code: 'NZ-CAN',
    label: 'Canterbury',
    country: 'NZ',
  },
  {
    code: 'NZ-CIT',
    label: 'Chatham Islands Territory',
    country: 'NZ',
  },
  {
    code: 'NZ-GIS',
    label: 'Gisborne',
    country: 'NZ',
  },
  {
    code: 'NZ-HKB',
    label: "Hawke's Bay",
    country: 'NZ',
  },
  {
    code: 'NZ-MBH',
    label: 'Marlborough',
    country: 'NZ',
  },
  {
    code: 'NZ-MWT',
    label: 'Manawatu-Wanganui',
    country: 'NZ',
  },
  {
    code: 'NZ-NSN',
    label: 'Nelson',
    country: 'NZ',
  },
  {
    code: 'NZ-NTL',
    label: 'Northland',
    country: 'NZ',
  },
  {
    code: 'NZ-OTA',
    label: 'Otago',
    country: 'NZ',
  },
  {
    code: 'NZ-STL',
    label: 'Southland',
    country: 'NZ',
  },
  {
    code: 'NZ-TAS',
    label: 'Tasman',
    country: 'NZ',
  },
  {
    code: 'NZ-TKI',
    label: 'Taranaki',
    country: 'NZ',
  },
  {
    code: 'NZ-WGN',
    label: 'Wellington',
    country: 'NZ',
  },
  {
    code: 'NZ-WKO',
    label: 'Waikato',
    country: 'NZ',
  },
  {
    code: 'NZ-WTC',
    label: 'West Coast',
    country: 'NZ',
  },
  {
    code: 'OM-BJ',
    label: 'Janūb al Bāţinah',
    country: 'OM',
  },
  {
    code: 'OM-BS',
    label: 'Shamāl al Bāţinah',
    country: 'OM',
  },
  {
    code: 'OM-BU',
    label: 'Al Buraymī',
    country: 'OM',
  },
  {
    code: 'OM-DA',
    label: 'Ad Dākhilīyah',
    country: 'OM',
  },
  {
    code: 'OM-MA',
    label: 'Masqaţ',
    country: 'OM',
  },
  {
    code: 'OM-MU',
    label: 'Musandam',
    country: 'OM',
  },
  {
    code: 'OM-SJ',
    label: 'Janūb ash Sharqīyah',
    country: 'OM',
  },
  {
    code: 'OM-SS',
    label: 'Shamāl ash Sharqīyah',
    country: 'OM',
  },
  {
    code: 'OM-WU',
    label: 'Al Wusţá',
    country: 'OM',
  },
  {
    code: 'OM-ZA',
    label: 'Az̧ Z̧āhirah',
    country: 'OM',
  },
  {
    code: 'OM-ZU',
    label: 'Z̧ufār',
    country: 'OM',
  },
  {
    code: 'PA-1',
    label: 'Bocas del Toro',
    country: 'PA',
  },
  {
    code: 'PA-10',
    label: 'Panamá Oeste',
    country: 'PA',
  },
  {
    code: 'PA-2',
    label: 'Coclé',
    country: 'PA',
  },
  {
    code: 'PA-3',
    label: 'Colón',
    country: 'PA',
  },
  {
    code: 'PA-4',
    label: 'Chiriquí',
    country: 'PA',
  },
  {
    code: 'PA-5',
    label: 'Darién',
    country: 'PA',
  },
  {
    code: 'PA-6',
    label: 'Herrera',
    country: 'PA',
  },
  {
    code: 'PA-7',
    label: 'Los Santos',
    country: 'PA',
  },
  {
    code: 'PA-8',
    label: 'Panamá',
    country: 'PA',
  },
  {
    code: 'PA-9',
    label: 'Veraguas',
    country: 'PA',
  },
  {
    code: 'PA-EM',
    label: 'Emberá',
    country: 'PA',
  },
  {
    code: 'PA-KY',
    label: 'Guna Yala(local variant is Kuna Yala)',
    country: 'PA',
  },
  {
    code: 'PA-NB',
    label: 'Ngöbe-Buglé',
    country: 'PA',
  },
  {
    code: 'PE-AMA',
    label: 'Amazonas',
    country: 'PE',
  },
  {
    code: 'PE-ANC',
    label: 'Ancash',
    country: 'PE',
  },
  {
    code: 'PE-APU',
    label: 'Apurímac',
    country: 'PE',
  },
  {
    code: 'PE-ARE',
    label: 'Arequipa',
    country: 'PE',
  },
  {
    code: 'PE-AYA',
    label: 'Ayacucho',
    country: 'PE',
  },
  {
    code: 'PE-CAJ',
    label: 'Cajamarca',
    country: 'PE',
  },
  {
    code: 'PE-CAL',
    label: 'El Callao',
    country: 'PE',
  },
  {
    code: 'PE-CUS',
    label: 'Cusco(local variant is Cuzco)',
    country: 'PE',
  },
  {
    code: 'PE-HUC',
    label: 'Huánuco',
    country: 'PE',
  },
  {
    code: 'PE-HUV',
    label: 'Huancavelica',
    country: 'PE',
  },
  {
    code: 'PE-ICA',
    label: 'Ica',
    country: 'PE',
  },
  {
    code: 'PE-JUN',
    label: 'Junín',
    country: 'PE',
  },
  {
    code: 'PE-LAL',
    label: 'La Libertad',
    country: 'PE',
  },
  {
    code: 'PE-LAM',
    label: 'Lambayeque',
    country: 'PE',
  },
  {
    code: 'PE-LIM',
    label: 'Lima',
    country: 'PE',
  },
  {
    code: 'PE-LMA',
    label: 'Municipalidad Metropolitana de Lima',
    country: 'PE',
  },
  {
    code: 'PE-LOR',
    label: 'Loreto',
    country: 'PE',
  },
  {
    code: 'PE-MDD',
    label: 'Madre de Dios',
    country: 'PE',
  },
  {
    code: 'PE-MOQ',
    label: 'Moquegua',
    country: 'PE',
  },
  {
    code: 'PE-PAS',
    label: 'Pasco',
    country: 'PE',
  },
  {
    code: 'PE-PIU',
    label: 'Piura',
    country: 'PE',
  },
  {
    code: 'PE-PUN',
    label: 'Puno',
    country: 'PE',
  },
  {
    code: 'PE-SAM',
    label: 'San Martín',
    country: 'PE',
  },
  {
    code: 'PE-TAC',
    label: 'Tacna',
    country: 'PE',
  },
  {
    code: 'PE-TUM',
    label: 'Tumbes',
    country: 'PE',
  },
  {
    code: 'PE-UCA',
    label: 'Ucayali',
    country: 'PE',
  },
  {
    code: 'PG-CPK',
    label: 'Chimbu',
    country: 'PG',
  },
  {
    code: 'PG-CPM',
    label: 'Central',
    country: 'PG',
  },
  {
    code: 'PG-EBR',
    label: 'East New Britain',
    country: 'PG',
  },
  {
    code: 'PG-EHG',
    label: 'Eastern Highlands',
    country: 'PG',
  },
  {
    code: 'PG-EPW',
    label: 'Enga',
    country: 'PG',
  },
  {
    code: 'PG-ESW',
    label: 'East Sepik',
    country: 'PG',
  },
  {
    code: 'PG-GPK',
    label: 'Gulf',
    country: 'PG',
  },
  {
    code: 'PG-HLA',
    label: 'Hela',
    country: 'PG',
  },
  {
    code: 'PG-JWK',
    label: 'Jiwaka',
    country: 'PG',
  },
  {
    code: 'PG-MBA',
    label: 'Milne Bay',
    country: 'PG',
  },
  {
    code: 'PG-MPL',
    label: 'Morobe',
    country: 'PG',
  },
  {
    code: 'PG-MPM',
    label: 'Madang',
    country: 'PG',
  },
  {
    code: 'PG-MRL',
    label: 'Manus',
    country: 'PG',
  },
  {
    code: 'PG-NCD',
    label: 'National Capital District (Port Moresby)',
    country: 'PG',
  },
  {
    code: 'PG-NIK',
    label: 'New Ireland',
    country: 'PG',
  },
  {
    code: 'PG-NPP',
    label: 'Northern',
    country: 'PG',
  },
  {
    code: 'PG-NSB',
    label: 'Bougainville',
    country: 'PG',
  },
  {
    code: 'PG-SAN',
    label: 'West Sepik',
    country: 'PG',
  },
  {
    code: 'PG-SHM',
    label: 'Southern Highlands',
    country: 'PG',
  },
  {
    code: 'PG-WBK',
    label: 'West New Britain',
    country: 'PG',
  },
  {
    code: 'PG-WHM',
    label: 'Western Highlands',
    country: 'PG',
  },
  {
    code: 'PG-WPD',
    label: 'Western',
    country: 'PG',
  },
  {
    code: 'PH-00',
    label: 'National Capital Region',
    country: 'PH',
  },
  {
    code: 'PH-01',
    label: 'Ilocos',
    country: 'PH',
  },
  {
    code: 'PH-02',
    label: 'Cagayan Valley',
    country: 'PH',
  },
  {
    code: 'PH-03',
    label: 'Central Luzon',
    country: 'PH',
  },
  {
    code: 'PH-05',
    label: 'Bicol',
    country: 'PH',
  },
  {
    code: 'PH-06',
    label: 'Western Visayas',
    country: 'PH',
  },
  {
    code: 'PH-07',
    label: 'Central Visayas',
    country: 'PH',
  },
  {
    code: 'PH-08',
    label: 'Eastern Visayas',
    country: 'PH',
  },
  {
    code: 'PH-09',
    label: 'Zamboanga Peninsula',
    country: 'PH',
  },
  {
    code: 'PH-10',
    label: 'Northern Mindanao',
    country: 'PH',
  },
  {
    code: 'PH-11',
    label: 'Davao',
    country: 'PH',
  },
  {
    code: 'PH-12',
    label: 'Soccsksargen',
    country: 'PH',
  },
  {
    code: 'PH-13',
    label: 'Caraga',
    country: 'PH',
  },
  {
    code: 'PH-14',
    label: 'Autonomous Region in Muslim Mindanao',
    country: 'PH',
  },
  {
    code: 'PH-15',
    label: 'Cordillera Administrative Region',
    country: 'PH',
  },
  {
    code: 'PH-40',
    label: 'Calabarzon',
    country: 'PH',
  },
  {
    code: 'PH-41',
    label: 'Mimaropa',
    country: 'PH',
  },
  {
    code: 'PH-ABR',
    label: 'Abra',
    country: 'PH-15',
  },
  {
    code: 'PH-AGN',
    label: 'Agusan del Norte',
    country: 'PH-13',
  },
  {
    code: 'PH-AGS',
    label: 'Agusan del Sur',
    country: 'PH-13',
  },
  {
    code: 'PH-AKL',
    label: 'Aklan',
    country: 'PH-06',
  },
  {
    code: 'PH-ALB',
    label: 'Albay',
    country: 'PH-05',
  },
  {
    code: 'PH-ANT',
    label: 'Antique',
    country: 'PH-06',
  },
  {
    code: 'PH-APA',
    label: 'Apayao',
    country: 'PH-15',
  },
  {
    code: 'PH-AUR',
    label: 'Aurora',
    country: 'PH-03',
  },
  {
    code: 'PH-BAN',
    label: 'Bataan',
    country: 'PH-03',
  },
  {
    code: 'PH-BAS',
    label: 'Basilan',
    country: 'PH-09',
  },
  {
    code: 'PH-BEN',
    label: 'Benguet',
    country: 'PH-15',
  },
  {
    code: 'PH-BIL',
    label: 'Biliran',
    country: 'PH-08',
  },
  {
    code: 'PH-BOH',
    label: 'Bohol',
    country: 'PH-07',
  },
  {
    code: 'PH-BTG',
    label: 'Batangas',
    country: 'PH-40',
  },
  {
    code: 'PH-BTN',
    label: 'Batanes',
    country: 'PH-02',
  },
  {
    code: 'PH-BUK',
    label: 'Bukidnon',
    country: 'PH-10',
  },
  {
    code: 'PH-BUL',
    label: 'Bulacan',
    country: 'PH-03',
  },
  {
    code: 'PH-CAG',
    label: 'Cagayan',
    country: 'PH-02',
  },
  {
    code: 'PH-CAM',
    label: 'Camiguin',
    country: 'PH-10',
  },
  {
    code: 'PH-CAN',
    label: 'Camarines Norte',
    country: 'PH-05',
  },
  {
    code: 'PH-CAP',
    label: 'Capiz',
    country: 'PH-06',
  },
  {
    code: 'PH-CAS',
    label: 'Camarines Sur',
    country: 'PH-05',
  },
  {
    code: 'PH-CAT',
    label: 'Catanduanes',
    country: 'PH-05',
  },
  {
    code: 'PH-CAV',
    label: 'Cavite',
    country: 'PH-40',
  },
  {
    code: 'PH-CEB',
    label: 'Cebu',
    country: 'PH-07',
  },
  {
    code: 'PH-COM',
    label: 'Davao de Oro',
    country: 'PH-11',
  },
  {
    code: 'PH-DAO',
    label: 'Davao Oriental',
    country: 'PH-11',
  },
  {
    code: 'PH-DAS',
    label: 'Davao del Sur',
    country: 'PH-11',
  },
  {
    code: 'PH-DAV',
    label: 'Davao del Norte',
    country: 'PH-11',
  },
  {
    code: 'PH-DIN',
    label: 'Dinagat Islands',
    country: 'PH-13',
  },
  {
    code: 'PH-DVO',
    label: 'Davao Occidental',
    country: 'PH-11',
  },
  {
    code: 'PH-EAS',
    label: 'Eastern Samar',
    country: 'PH-08',
  },
  {
    code: 'PH-GUI',
    label: 'Guimaras',
    country: 'PH-06',
  },
  {
    code: 'PH-IFU',
    label: 'Ifugao',
    country: 'PH-15',
  },
  {
    code: 'PH-ILI',
    label: 'Iloilo',
    country: 'PH-06',
  },
  {
    code: 'PH-ILN',
    label: 'Ilocos Norte',
    country: 'PH-01',
  },
  {
    code: 'PH-ILS',
    label: 'Ilocos Sur',
    country: 'PH-01',
  },
  {
    code: 'PH-ISA',
    label: 'Isabela',
    country: 'PH-02',
  },
  {
    code: 'PH-KAL',
    label: 'Kalinga',
    country: 'PH-15',
  },
  {
    code: 'PH-LAG',
    label: 'Laguna',
    country: 'PH-40',
  },
  {
    code: 'PH-LAN',
    label: 'Lanao del Norte',
    country: 'PH-12',
  },
  {
    code: 'PH-LAS',
    label: 'Lanao del Sur',
    country: 'PH-14',
  },
  {
    code: 'PH-LEY',
    label: 'Leyte',
    country: 'PH-08',
  },
  {
    code: 'PH-LUN',
    label: 'La Union',
    country: 'PH-01',
  },
  {
    code: 'PH-MAD',
    label: 'Marinduque',
    country: 'PH-41',
  },
  {
    code: 'PH-MAG',
    label: 'Maguindanao',
    country: 'PH-14',
  },
  {
    code: 'PH-MAS',
    label: 'Masbate',
    country: 'PH-05',
  },
  {
    code: 'PH-MDC',
    label: 'Mindoro Occidental',
    country: 'PH-41',
  },
  {
    code: 'PH-MDR',
    label: 'Mindoro Oriental',
    country: 'PH-41',
  },
  {
    code: 'PH-MOU',
    label: 'Mountain Province',
    country: 'PH-15',
  },
  {
    code: 'PH-MSC',
    label: 'Misamis Occidental',
    country: 'PH-10',
  },
  {
    code: 'PH-MSR',
    label: 'Misamis Oriental',
    country: 'PH-10',
  },
  {
    code: 'PH-NCO',
    label: 'Cotabato',
    country: 'PH-12',
  },
  {
    code: 'PH-NEC',
    label: 'Negros Occidental',
    country: 'PH-06',
  },
  {
    code: 'PH-NER',
    label: 'Negros Oriental',
    country: 'PH-07',
  },
  {
    code: 'PH-NSA',
    label: 'Northern Samar',
    country: 'PH-08',
  },
  {
    code: 'PH-NUE',
    label: 'Nueva Ecija',
    country: 'PH-03',
  },
  {
    code: 'PH-NUV',
    label: 'Nueva Vizcaya',
    country: 'PH-02',
  },
  {
    code: 'PH-PAM',
    label: 'Pampanga',
    country: 'PH-03',
  },
  {
    code: 'PH-PAN',
    label: 'Pangasinan',
    country: 'PH-01',
  },
  {
    code: 'PH-PLW',
    label: 'Palawan',
    country: 'PH-41',
  },
  {
    code: 'PH-QUE',
    label: 'Quezon',
    country: 'PH-40',
  },
  {
    code: 'PH-QUI',
    label: 'Quirino',
    country: 'PH-02',
  },
  {
    code: 'PH-RIZ',
    label: 'Rizal',
    country: 'PH-40',
  },
  {
    code: 'PH-ROM',
    label: 'Romblon',
    country: 'PH-41',
  },
  {
    code: 'PH-SAR',
    label: 'Sarangani',
    country: 'PH-11',
  },
  {
    code: 'PH-SCO',
    label: 'South Cotabato',
    country: 'PH-11',
  },
  {
    code: 'PH-SIG',
    label: 'Siquijor',
    country: 'PH-07',
  },
  {
    code: 'PH-SLE',
    label: 'Southern Leyte',
    country: 'PH-08',
  },
  {
    code: 'PH-SLU',
    label: 'Sulu',
    country: 'PH-14',
  },
  {
    code: 'PH-SOR',
    label: 'Sorsogon',
    country: 'PH-05',
  },
  {
    code: 'PH-SUK',
    label: 'Sultan Kudarat',
    country: 'PH-12',
  },
  {
    code: 'PH-SUN',
    label: 'Surigao del Norte',
    country: 'PH-13',
  },
  {
    code: 'PH-SUR',
    label: 'Surigao del Sur',
    country: 'PH-13',
  },
  {
    code: 'PH-TAR',
    label: 'Tarlac',
    country: 'PH-03',
  },
  {
    code: 'PH-TAW',
    label: 'Tawi-Tawi',
    country: 'PH-14',
  },
  {
    code: 'PH-WSA',
    label: 'Samar (Western Samar)',
    country: 'PH-08',
  },
  {
    code: 'PH-ZAN',
    label: 'Zamboanga del Norte',
    country: 'PH-09',
  },
  {
    code: 'PH-ZAS',
    label: 'Zamboanga del Sur',
    country: 'PH-09',
  },
  {
    code: 'PH-ZMB',
    label: 'Zambales',
    country: 'PH-03',
  },
  {
    code: 'PH-ZSI',
    label: 'Zamboanga Sibugay',
    country: 'PH-09',
  },
  {
    code: 'PK-BA',
    label: 'Balochistan',
    country: 'PK',
  },
  {
    code: 'PK-GB',
    label: 'Gilgit-Baltistan',
    country: 'PK',
  },
  {
    code: 'PK-IS',
    label: 'Islamabad',
    country: 'PK',
  },
  {
    code: 'PK-JK',
    label: 'Azad Jammu and Kashmir(local variant is AJ&K)',
    country: 'PK',
  },
  {
    code: 'PK-KP',
    label: 'Khyber Pakhtunkhwa',
    country: 'PK',
  },
  {
    code: 'PK-PB',
    label: 'Punjab',
    country: 'PK',
  },
  {
    code: 'PK-SD',
    label: 'Sindh',
    country: 'PK',
  },
  {
    code: 'PK-TA',
    label: 'Federally Administered Tribal Areas',
    country: 'PK',
  },
  {
    code: 'PL-02',
    label: 'Dolnośląskie',
    country: 'PL',
  },
  {
    code: 'PL-04',
    label: 'Kujawsko-pomorskie',
    country: 'PL',
  },
  {
    code: 'PL-06',
    label: 'Lubelskie',
    country: 'PL',
  },
  {
    code: 'PL-08',
    label: 'Lubuskie',
    country: 'PL',
  },
  {
    code: 'PL-10',
    label: 'Łódzkie',
    country: 'PL',
  },
  {
    code: 'PL-12',
    label: 'Małopolskie',
    country: 'PL',
  },
  {
    code: 'PL-14',
    label: 'Mazowieckie',
    country: 'PL',
  },
  {
    code: 'PL-16',
    label: 'Opolskie',
    country: 'PL',
  },
  {
    code: 'PL-18',
    label: 'Podkarpackie',
    country: 'PL',
  },
  {
    code: 'PL-20',
    label: 'Podlaskie',
    country: 'PL',
  },
  {
    code: 'PL-22',
    label: 'Pomorskie',
    country: 'PL',
  },
  {
    code: 'PL-24',
    label: 'Śląskie',
    country: 'PL',
  },
  {
    code: 'PL-26',
    label: 'Świętokrzyskie',
    country: 'PL',
  },
  {
    code: 'PL-28',
    label: 'Warmińsko-mazurskie',
    country: 'PL',
  },
  {
    code: 'PL-30',
    label: 'Wielkopolskie',
    country: 'PL',
  },
  {
    code: 'PL-32',
    label: 'Zachodniopomorskie',
    country: 'PL',
  },
  {
    code: 'PS-BTH',
    label: 'Bethlehem',
    country: 'PS',
  },
  {
    code: 'PS-DEB',
    label: 'Deir El Balah',
    country: 'PS',
  },
  {
    code: 'PS-GZA',
    label: 'Gaza',
    country: 'PS',
  },
  {
    code: 'PS-HBN',
    label: 'Hebron',
    country: 'PS',
  },
  {
    code: 'PS-JEM',
    label: 'Jerusalem',
    country: 'PS',
  },
  {
    code: 'PS-JEN',
    label: 'Jenin',
    country: 'PS',
  },
  {
    code: 'PS-JRH',
    label: 'Jericho and Al Aghwar',
    country: 'PS',
  },
  {
    code: 'PS-KYS',
    label: 'Khan Yunis',
    country: 'PS',
  },
  {
    code: 'PS-NBS',
    label: 'Nablus',
    country: 'PS',
  },
  {
    code: 'PS-NGZ',
    label: 'North Gaza',
    country: 'PS',
  },
  {
    code: 'PS-QQA',
    label: 'Qalqilya',
    country: 'PS',
  },
  {
    code: 'PS-RBH',
    label: 'Ramallah',
    country: 'PS',
  },
  {
    code: 'PS-RFH',
    label: 'Rafah',
    country: 'PS',
  },
  {
    code: 'PS-SLT',
    label: 'Salfit',
    country: 'PS',
  },
  {
    code: 'PS-TBS',
    label: 'Tubas',
    country: 'PS',
  },
  {
    code: 'PS-TKM',
    label: 'Tulkarm',
    country: 'PS',
  },
  {
    code: 'PT-01',
    label: 'Aveiro',
    country: 'PT',
  },
  {
    code: 'PT-02',
    label: 'Beja',
    country: 'PT',
  },
  {
    code: 'PT-03',
    label: 'Braga',
    country: 'PT',
  },
  {
    code: 'PT-04',
    label: 'Bragança',
    country: 'PT',
  },
  {
    code: 'PT-05',
    label: 'Castelo Branco',
    country: 'PT',
  },
  {
    code: 'PT-06',
    label: 'Coimbra',
    country: 'PT',
  },
  {
    code: 'PT-07',
    label: 'Évora',
    country: 'PT',
  },
  {
    code: 'PT-08',
    label: 'Faro',
    country: 'PT',
  },
  {
    code: 'PT-09',
    label: 'Guarda',
    country: 'PT',
  },
  {
    code: 'PT-10',
    label: 'Leiria',
    country: 'PT',
  },
  {
    code: 'PT-11',
    label: 'Lisboa',
    country: 'PT',
  },
  {
    code: 'PT-12',
    label: 'Portalegre',
    country: 'PT',
  },
  {
    code: 'PT-13',
    label: 'Porto',
    country: 'PT',
  },
  {
    code: 'PT-14',
    label: 'Santarém',
    country: 'PT',
  },
  {
    code: 'PT-15',
    label: 'Setúbal',
    country: 'PT',
  },
  {
    code: 'PT-16',
    label: 'Viana do Castelo',
    country: 'PT',
  },
  {
    code: 'PT-17',
    label: 'Vila Real',
    country: 'PT',
  },
  {
    code: 'PT-18',
    label: 'Viseu',
    country: 'PT',
  },
  {
    code: 'PT-20',
    label: 'Região Autónoma dos Açores',
    country: 'PT',
  },
  {
    code: 'PT-30',
    label: 'Região Autónoma da Madeira',
    country: 'PT',
  },
  {
    code: 'PW-002',
    label: 'Aimeliik',
    country: 'PW',
  },
  {
    code: 'PW-004',
    label: 'Airai',
    country: 'PW',
  },
  {
    code: 'PW-010',
    label: 'Angaur',
    country: 'PW',
  },
  {
    code: 'PW-050',
    label: 'Hatohobei',
    country: 'PW',
  },
  {
    code: 'PW-100',
    label: 'Kayangel',
    country: 'PW',
  },
  {
    code: 'PW-150',
    label: 'Koror',
    country: 'PW',
  },
  {
    code: 'PW-212',
    label: 'Melekeok',
    country: 'PW',
  },
  {
    code: 'PW-214',
    label: 'Ngaraard',
    country: 'PW',
  },
  {
    code: 'PW-218',
    label: 'Ngarchelong',
    country: 'PW',
  },
  {
    code: 'PW-222',
    label: 'Ngardmau',
    country: 'PW',
  },
  {
    code: 'PW-224',
    label: 'Ngatpang',
    country: 'PW',
  },
  {
    code: 'PW-226',
    label: 'Ngchesar',
    country: 'PW',
  },
  {
    code: 'PW-227',
    label: 'Ngeremlengui',
    country: 'PW',
  },
  {
    code: 'PW-228',
    label: 'Ngiwal',
    country: 'PW',
  },
  {
    code: 'PW-350',
    label: 'Peleliu',
    country: 'PW',
  },
  {
    code: 'PW-370',
    label: 'Sonsorol',
    country: 'PW',
  },
  {
    code: 'PY-1',
    label: 'Concepción',
    country: 'PY',
  },
  {
    code: 'PY-10',
    label: 'Alto Paraná',
    country: 'PY',
  },
  {
    code: 'PY-11',
    label: 'Central',
    country: 'PY',
  },
  {
    code: 'PY-12',
    label: 'Ñeembucú',
    country: 'PY',
  },
  {
    code: 'PY-13',
    label: 'Amambay',
    country: 'PY',
  },
  {
    code: 'PY-14',
    label: 'Canindeyú',
    country: 'PY',
  },
  {
    code: 'PY-15',
    label: 'Presidente Hayes',
    country: 'PY',
  },
  {
    code: 'PY-16',
    label: 'Alto Paraguay',
    country: 'PY',
  },
  {
    code: 'PY-19',
    label: 'Boquerón',
    country: 'PY',
  },
  {
    code: 'PY-2',
    label: 'San Pedro',
    country: 'PY',
  },
  {
    code: 'PY-3',
    label: 'Cordillera',
    country: 'PY',
  },
  {
    code: 'PY-4',
    label: 'Guairá',
    country: 'PY',
  },
  {
    code: 'PY-5',
    label: 'Caaguazú',
    country: 'PY',
  },
  {
    code: 'PY-6',
    label: 'Caazapá',
    country: 'PY',
  },
  {
    code: 'PY-7',
    label: 'Itapúa',
    country: 'PY',
  },
  {
    code: 'PY-8',
    label: 'Misiones',
    country: 'PY',
  },
  {
    code: 'PY-9',
    label: 'Paraguarí',
    country: 'PY',
  },
  {
    code: 'PY-ASU',
    label: 'Asunción',
    country: 'PY',
  },
  {
    code: 'QA-DA',
    label: 'Ad Dawḩah',
    country: 'QA',
  },
  {
    code: 'QA-KH',
    label: 'Al Khawr wa adh Dhakhīrah',
    country: 'QA',
  },
  {
    code: 'QA-MS',
    label: 'Ash Shamāl',
    country: 'QA',
  },
  {
    code: 'QA-RA',
    label: 'Ar Rayyān',
    country: 'QA',
  },
  {
    code: 'QA-SH',
    label: 'Ash Shīḩānīyah',
    country: 'QA',
  },
  {
    code: 'QA-US',
    label: 'Umm Şalāl',
    country: 'QA',
  },
  {
    code: 'QA-WA',
    label: 'Al Wakrah',
    country: 'QA',
  },
  {
    code: 'QA-ZA',
    label: 'Az̧ Z̧a‘āyin',
    country: 'QA',
  },
  {
    code: 'RO-AB',
    label: 'Alba',
    country: 'RO',
  },
  {
    code: 'RO-AG',
    label: 'Argeș',
    country: 'RO',
  },
  {
    code: 'RO-AR',
    label: 'Arad',
    country: 'RO',
  },
  {
    code: 'RO-B',
    label: 'București',
    country: 'RO',
  },
  {
    code: 'RO-BC',
    label: 'Bacău',
    country: 'RO',
  },
  {
    code: 'RO-BH',
    label: 'Bihor',
    country: 'RO',
  },
  {
    code: 'RO-BN',
    label: 'Bistrița-Năsăud',
    country: 'RO',
  },
  {
    code: 'RO-BR',
    label: 'Brăila',
    country: 'RO',
  },
  {
    code: 'RO-BT',
    label: 'Botoșani',
    country: 'RO',
  },
  {
    code: 'RO-BV',
    label: 'Brașov',
    country: 'RO',
  },
  {
    code: 'RO-BZ',
    label: 'Buzău',
    country: 'RO',
  },
  {
    code: 'RO-CJ',
    label: 'Cluj',
    country: 'RO',
  },
  {
    code: 'RO-CL',
    label: 'Călărași',
    country: 'RO',
  },
  {
    code: 'RO-CS',
    label: 'Caraș-Severin',
    country: 'RO',
  },
  {
    code: 'RO-CT',
    label: 'Constanța',
    country: 'RO',
  },
  {
    code: 'RO-CV',
    label: 'Covasna',
    country: 'RO',
  },
  {
    code: 'RO-DB',
    label: 'Dâmbovița',
    country: 'RO',
  },
  {
    code: 'RO-DJ',
    label: 'Dolj',
    country: 'RO',
  },
  {
    code: 'RO-GJ',
    label: 'Gorj',
    country: 'RO',
  },
  {
    code: 'RO-GL',
    label: 'Galați',
    country: 'RO',
  },
  {
    code: 'RO-GR',
    label: 'Giurgiu',
    country: 'RO',
  },
  {
    code: 'RO-HD',
    label: 'Hunedoara',
    country: 'RO',
  },
  {
    code: 'RO-HR',
    label: 'Harghita',
    country: 'RO',
  },
  {
    code: 'RO-IF',
    label: 'Ilfov',
    country: 'RO',
  },
  {
    code: 'RO-IL',
    label: 'Ialomița',
    country: 'RO',
  },
  {
    code: 'RO-IS',
    label: 'Iași',
    country: 'RO',
  },
  {
    code: 'RO-MH',
    label: 'Mehedinți',
    country: 'RO',
  },
  {
    code: 'RO-MM',
    label: 'Maramureș',
    country: 'RO',
  },
  {
    code: 'RO-MS',
    label: 'Mureș',
    country: 'RO',
  },
  {
    code: 'RO-NT',
    label: 'Neamț',
    country: 'RO',
  },
  {
    code: 'RO-OT',
    label: 'Olt',
    country: 'RO',
  },
  {
    code: 'RO-PH',
    label: 'Prahova',
    country: 'RO',
  },
  {
    code: 'RO-SB',
    label: 'Sibiu',
    country: 'RO',
  },
  {
    code: 'RO-SJ',
    label: 'Sălaj',
    country: 'RO',
  },
  {
    code: 'RO-SM',
    label: 'Satu Mare',
    country: 'RO',
  },
  {
    code: 'RO-SV',
    label: 'Suceava',
    country: 'RO',
  },
  {
    code: 'RO-TL',
    label: 'Tulcea',
    country: 'RO',
  },
  {
    code: 'RO-TM',
    label: 'Timiș',
    country: 'RO',
  },
  {
    code: 'RO-TR',
    label: 'Teleorman',
    country: 'RO',
  },
  {
    code: 'RO-VL',
    label: 'Vâlcea',
    country: 'RO',
  },
  {
    code: 'RO-VN',
    label: 'Vrancea',
    country: 'RO',
  },
  {
    code: 'RO-VS',
    label: 'Vaslui',
    country: 'RO',
  },
  {
    code: 'RS-00',
    label: 'Beograd',
    country: 'RS',
  },
  {
    code: 'RS-01',
    label: 'Severnobački okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-02',
    label: 'Srednjebanatski okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-03',
    label: 'Severnobanatski okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-04',
    label: 'Južnobanatski okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-05',
    label: 'Zapadnobački okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-06',
    label: 'Južnobački okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-07',
    label: 'Sremski okrug',
    country: 'RS-VO',
  },
  {
    code: 'RS-08',
    label: 'Mačvanski okrug',
    country: 'RS',
  },
  {
    code: 'RS-09',
    label: 'Kolubarski okrug',
    country: 'RS',
  },
  {
    code: 'RS-10',
    label: 'Podunavski okrug',
    country: 'RS',
  },
  {
    code: 'RS-11',
    label: 'Braničevski okrug',
    country: 'RS',
  },
  {
    code: 'RS-12',
    label: 'Šumadijski okrug',
    country: 'RS',
  },
  {
    code: 'RS-13',
    label: 'Pomoravski okrug',
    country: 'RS',
  },
  {
    code: 'RS-14',
    label: 'Borski okrug',
    country: 'RS',
  },
  {
    code: 'RS-15',
    label: 'Zaječarski okrug',
    country: 'RS',
  },
  {
    code: 'RS-16',
    label: 'Zlatiborski okrug',
    country: 'RS',
  },
  {
    code: 'RS-17',
    label: 'Moravički okrug',
    country: 'RS',
  },
  {
    code: 'RS-18',
    label: 'Raški okrug',
    country: 'RS',
  },
  {
    code: 'RS-19',
    label: 'Rasinski okrug',
    country: 'RS',
  },
  {
    code: 'RS-20',
    label: 'Nišavski okrug',
    country: 'RS',
  },
  {
    code: 'RS-21',
    label: 'Toplički okrug',
    country: 'RS',
  },
  {
    code: 'RS-22',
    label: 'Pirotski okrug',
    country: 'RS',
  },
  {
    code: 'RS-23',
    label: 'Jablanički okrug',
    country: 'RS',
  },
  {
    code: 'RS-24',
    label: 'Pčinjski okrug',
    country: 'RS',
  },
  {
    code: 'RS-25',
    label: 'Kosovski okrug',
    country: 'RS-KM',
  },
  {
    code: 'RS-26',
    label: 'Pećki okrug',
    country: 'RS-KM',
  },
  {
    code: 'RS-27',
    label: 'Prizrenski okrug',
    country: 'RS-KM',
  },
  {
    code: 'RS-28',
    label: 'Kosovsko-Mitrovački okrug',
    country: 'RS-KM',
  },
  {
    code: 'RS-29',
    label: 'Kosovsko-Pomoravski okrug',
    country: 'RS-KM',
  },
  {
    code: 'RS-KM',
    label: 'Kosovo-Metohija',
    country: 'RS',
  },
  {
    code: 'RS-VO',
    label: 'Vojvodina',
    country: 'RS',
  },
  {
    code: 'RU-AD',
    label: 'Adygeya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-AL',
    label: 'Altay, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-ALT',
    label: 'Altayskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-AMU',
    label: "Amurskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-ARK',
    label: "Arkhangel'skaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-AST',
    label: "Astrakhanskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-BA',
    label: 'Bashkortostan, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-BEL',
    label: "Belgorodskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-BRY',
    label: "Bryanskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-BU',
    label: 'Buryatiya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-CE',
    label: 'Chechenskaya Respublika',
    country: 'RU',
  },
  {
    code: 'RU-CHE',
    label: "Chelyabinskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-CHU',
    label: 'Chukotskiy avtonomnyy okrug',
    country: 'RU',
  },
  {
    code: 'RU-CU',
    label: 'Chuvashskaya Respublika',
    country: 'RU',
  },
  {
    code: 'RU-DA',
    label: 'Dagestan, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-IN',
    label: 'Ingushetiya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-IRK',
    label: "Irkutskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-IVA',
    label: "Ivanovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KAM',
    label: 'Kamchatskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-KB',
    label: 'Kabardino-Balkarskaya Respublika',
    country: 'RU',
  },
  {
    code: 'RU-KC',
    label: 'Karachayevo-Cherkesskaya Respublika',
    country: 'RU',
  },
  {
    code: 'RU-KDA',
    label: 'Krasnodarskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-KEM',
    label: "Kemerovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KGD',
    label: "Kaliningradskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KGN',
    label: "Kurganskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KHA',
    label: 'Khabarovskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-KHM',
    label: 'Khanty-Mansiyskiy avtonomnyy okrug(local variant is Yugra)',
    country: 'RU',
  },
  {
    code: 'RU-KIR',
    label: "Kirovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KK',
    label: 'Khakasiya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-KL',
    label: 'Kalmykiya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-KLU',
    label: "Kaluzhskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KO',
    label: 'Komi, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-KOS',
    label: "Kostromskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KR',
    label: 'Kareliya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-KRS',
    label: "Kurskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-KYA',
    label: 'Krasnoyarskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-LEN',
    label: "Leningradskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-LIP',
    label: "Lipetskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-MAG',
    label: "Magadanskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-ME',
    label: 'Mariy El, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-MO',
    label: 'Mordoviya, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-MOS',
    label: "Moskovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-MOW',
    label: 'Moskva',
    country: 'RU',
  },
  {
    code: 'RU-MUR',
    label: "Murmanskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-NEN',
    label: 'Nenetskiy avtonomnyy okrug',
    country: 'RU',
  },
  {
    code: 'RU-NGR',
    label: "Novgorodskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-NIZ',
    label: "Nizhegorodskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-NVS',
    label: "Novosibirskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-OMS',
    label: "Omskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-ORE',
    label: "Orenburgskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-ORL',
    label: "Orlovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-PER',
    label: 'Permskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-PNZ',
    label: "Penzenskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-PRI',
    label: 'Primorskiy kray',
    country: 'RU',
  },
  {
    code: 'RU-PSK',
    label: "Pskovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-ROS',
    label: "Rostovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-RYA',
    label: "Ryazanskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-SA',
    label: 'Saha, Respublika(local variant is Jakutija)',
    country: 'RU',
  },
  {
    code: 'RU-SAK',
    label: "Sakhalinskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-SAM',
    label: "Samarskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-SAR',
    label: "Saratovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-SE',
    label: 'Severnaya Osetiya, Respublika(local variant is Alaniya )',
    country: 'RU',
  },
  {
    code: 'RU-SMO',
    label: "Smolenskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-SPE',
    label: 'Sankt-Peterburg',
    country: 'RU',
  },
  {
    code: 'RU-STA',
    label: "Stavropol'skiy kray",
    country: 'RU',
  },
  {
    code: 'RU-SVE',
    label: "Sverdlovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-TA',
    label: 'Tatarstan, Respublika',
    country: 'RU',
  },
  {
    code: 'RU-TAM',
    label: "Tambovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-TOM',
    label: "Tomskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-TUL',
    label: "Tul'skaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-TVE',
    label: "Tverskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-TY',
    label: 'Tyva, Respublika(local variant is Tuva)',
    country: 'RU',
  },
  {
    code: 'RU-TYU',
    label: "Tyumenskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-UD',
    label: 'Udmurtskaya Respublika',
    country: 'RU',
  },
  {
    code: 'RU-ULY',
    label: "Ul'yanovskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-VGG',
    label: "Volgogradskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-VLA',
    label: "Vladimirskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-VLG',
    label: "Vologodskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-VOR',
    label: "Voronezhskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-YAN',
    label: 'Yamalo-Nenetskiy avtonomnyy okrug',
    country: 'RU',
  },
  {
    code: 'RU-YAR',
    label: "Yaroslavskaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-YEV',
    label: "Yevreyskaya avtonomnaya oblast'",
    country: 'RU',
  },
  {
    code: 'RU-ZAB',
    label: "Zabaykal'skiy kray",
    country: 'RU',
  },
  {
    code: 'RW-01',
    label: 'City of Kigali',
    country: 'RW',
  },
  {
    code: 'RW-02',
    label: 'Eastern',
    country: 'RW',
  },
  {
    code: 'RW-03',
    label: 'Northern',
    country: 'RW',
  },
  {
    code: 'RW-04',
    label: 'Western',
    country: 'RW',
  },
  {
    code: 'RW-05',
    label: 'Southern',
    country: 'RW',
  },
  {
    code: 'SA-01',
    label: 'Ar Riyāḑ',
    country: 'SA',
  },
  {
    code: 'SA-02',
    label: 'Makkah al Mukarramah',
    country: 'SA',
  },
  {
    code: 'SA-03',
    label: 'Al Madīnah al Munawwarah',
    country: 'SA',
  },
  {
    code: 'SA-04',
    label: 'Ash Sharqīyah',
    country: 'SA',
  },
  {
    code: 'SA-05',
    label: 'Al Qaşīm',
    country: 'SA',
  },
  {
    code: 'SA-06',
    label: "Ḩā'il",
    country: 'SA',
  },
  {
    code: 'SA-07',
    label: 'Tabūk',
    country: 'SA',
  },
  {
    code: 'SA-08',
    label: 'Al Ḩudūd ash Shamālīyah',
    country: 'SA',
  },
  {
    code: 'SA-09',
    label: 'Jāzān',
    country: 'SA',
  },
  {
    code: 'SA-10',
    label: 'Najrān',
    country: 'SA',
  },
  {
    code: 'SA-11',
    label: 'Al Bāḩah',
    country: 'SA',
  },
  {
    code: 'SA-12',
    label: 'Al Jawf',
    country: 'SA',
  },
  {
    code: 'SA-14',
    label: "'Asīr",
    country: 'SA',
  },
  {
    code: 'SB-CE',
    label: 'Central',
    country: 'SB',
  },
  {
    code: 'SB-CH',
    label: 'Choiseul',
    country: 'SB',
  },
  {
    code: 'SB-CT',
    label: 'Capital Territory (Honiara)',
    country: 'SB',
  },
  {
    code: 'SB-GU',
    label: 'Guadalcanal',
    country: 'SB',
  },
  {
    code: 'SB-IS',
    label: 'Isabel',
    country: 'SB',
  },
  {
    code: 'SB-MK',
    label: 'Makira-Ulawa',
    country: 'SB',
  },
  {
    code: 'SB-ML',
    label: 'Malaita',
    country: 'SB',
  },
  {
    code: 'SB-RB',
    label: 'Rennell and Bellona',
    country: 'SB',
  },
  {
    code: 'SB-TE',
    label: 'Temotu',
    country: 'SB',
  },
  {
    code: 'SB-WE',
    label: 'Western',
    country: 'SB',
  },
  {
    code: 'SC-01',
    label: 'Anse aux Pins',
    country: 'SC',
  },
  {
    code: 'SC-02',
    label: 'Anse Boileau',
    country: 'SC',
  },
  {
    code: 'SC-03',
    label: 'Anse Etoile',
    country: 'SC',
  },
  {
    code: 'SC-04',
    label: 'Au Cap',
    country: 'SC',
  },
  {
    code: 'SC-05',
    label: 'Anse Royale',
    country: 'SC',
  },
  {
    code: 'SC-06',
    label: 'Baie Lazare',
    country: 'SC',
  },
  {
    code: 'SC-07',
    label: 'Baie Sainte Anne',
    country: 'SC',
  },
  {
    code: 'SC-08',
    label: 'Beau Vallon',
    country: 'SC',
  },
  {
    code: 'SC-09',
    label: 'Bel Air',
    country: 'SC',
  },
  {
    code: 'SC-10',
    label: 'Bel Ombre',
    country: 'SC',
  },
  {
    code: 'SC-11',
    label: 'Cascade',
    country: 'SC',
  },
  {
    code: 'SC-12',
    label: 'Glacis',
    country: 'SC',
  },
  {
    code: 'SC-13',
    label: 'Grand Anse Mahe',
    country: 'SC',
  },
  {
    code: 'SC-14',
    label: 'Grand Anse Praslin',
    country: 'SC',
  },
  {
    code: 'SC-15',
    label: 'La Digue',
    country: 'SC',
  },
  {
    code: 'SC-16',
    label: 'English River',
    country: 'SC',
  },
  {
    code: 'SC-17',
    label: 'Mont Buxton',
    country: 'SC',
  },
  {
    code: 'SC-18',
    label: 'Mont Fleuri',
    country: 'SC',
  },
  {
    code: 'SC-19',
    label: 'Plaisance',
    country: 'SC',
  },
  {
    code: 'SC-20',
    label: 'Pointe Larue',
    country: 'SC',
  },
  {
    code: 'SC-21',
    label: 'Port Glaud',
    country: 'SC',
  },
  {
    code: 'SC-22',
    label: 'Saint Louis',
    country: 'SC',
  },
  {
    code: 'SC-23',
    label: 'Takamaka',
    country: 'SC',
  },
  {
    code: 'SC-24',
    label: 'Les Mamelles',
    country: 'SC',
  },
  {
    code: 'SC-25',
    label: 'Roche Caiman',
    country: 'SC',
  },
  {
    code: 'SC-26',
    label: 'Ile Perseverance I',
    country: 'SC',
  },
  {
    code: 'SC-27',
    label: 'Ile Perseverance II',
    country: 'SC',
  },
  {
    code: 'SD-DC',
    label: 'Wasaţ Dārfūr(local variant is Zālinjay)',
    country: 'SD',
  },
  {
    code: 'SD-DE',
    label: 'Sharq Dārfūr',
    country: 'SD',
  },
  {
    code: 'SD-DN',
    label: 'Shamāl Dārfūr',
    country: 'SD',
  },
  {
    code: 'SD-DS',
    label: 'Janūb Dārfūr',
    country: 'SD',
  },
  {
    code: 'SD-DW',
    label: 'Gharb Dārfūr',
    country: 'SD',
  },
  {
    code: 'SD-GD',
    label: 'Al Qaḑārif',
    country: 'SD',
  },
  {
    code: 'SD-GK',
    label: 'Gharb Kurdufān',
    country: 'SD',
  },
  {
    code: 'SD-GZ',
    label: 'Al Jazīrah',
    country: 'SD',
  },
  {
    code: 'SD-KA',
    label: 'Kassalā',
    country: 'SD',
  },
  {
    code: 'SD-KH',
    label: 'Al Kharţūm',
    country: 'SD',
  },
  {
    code: 'SD-KN',
    label: 'Shamāl Kurdufān',
    country: 'SD',
  },
  {
    code: 'SD-KS',
    label: 'Janūb Kurdufān',
    country: 'SD',
  },
  {
    code: 'SD-NB',
    label: 'An Nīl al Azraq',
    country: 'SD',
  },
  {
    code: 'SD-NO',
    label: 'Ash Shamālīyah',
    country: 'SD',
  },
  {
    code: 'SD-NR',
    label: 'Nahr an Nīl',
    country: 'SD',
  },
  {
    code: 'SD-NW',
    label: 'An Nīl al Abyaḑ',
    country: 'SD',
  },
  {
    code: 'SD-RS',
    label: 'Al Baḩr al Aḩmar',
    country: 'SD',
  },
  {
    code: 'SD-SI',
    label: 'Sinnār',
    country: 'SD',
  },
  {
    code: 'SE-AB',
    label: 'Stockholms län',
    country: 'SE',
  },
  {
    code: 'SE-AC',
    label: 'Västerbottens län',
    country: 'SE',
  },
  {
    code: 'SE-BD',
    label: 'Norrbottens län',
    country: 'SE',
  },
  {
    code: 'SE-C',
    label: 'Uppsala län',
    country: 'SE',
  },
  {
    code: 'SE-D',
    label: 'Södermanlands län',
    country: 'SE',
  },
  {
    code: 'SE-E',
    label: 'Östergötlands län',
    country: 'SE',
  },
  {
    code: 'SE-F',
    label: 'Jönköpings län',
    country: 'SE',
  },
  {
    code: 'SE-G',
    label: 'Kronobergs län',
    country: 'SE',
  },
  {
    code: 'SE-H',
    label: 'Kalmar län',
    country: 'SE',
  },
  {
    code: 'SE-I',
    label: 'Gotlands län',
    country: 'SE',
  },
  {
    code: 'SE-K',
    label: 'Blekinge län',
    country: 'SE',
  },
  {
    code: 'SE-M',
    label: 'Skåne län',
    country: 'SE',
  },
  {
    code: 'SE-N',
    label: 'Hallands län',
    country: 'SE',
  },
  {
    code: 'SE-O',
    label: 'Västra Götalands län',
    country: 'SE',
  },
  {
    code: 'SE-S',
    label: 'Värmlands län',
    country: 'SE',
  },
  {
    code: 'SE-T',
    label: 'Örebro län',
    country: 'SE',
  },
  {
    code: 'SE-U',
    label: 'Västmanlands län',
    country: 'SE',
  },
  {
    code: 'SE-W',
    label: 'Dalarnas län',
    country: 'SE',
  },
  {
    code: 'SE-X',
    label: 'Gävleborgs län',
    country: 'SE',
  },
  {
    code: 'SE-Y',
    label: 'Västernorrlands län',
    country: 'SE',
  },
  {
    code: 'SE-Z',
    label: 'Jämtlands län',
    country: 'SE',
  },
  {
    code: 'SG-01',
    label: 'Central Singapore',
    country: 'SG',
  },
  {
    code: 'SG-02',
    label: 'North East',
    country: 'SG',
  },
  {
    code: 'SG-03',
    label: 'North West',
    country: 'SG',
  },
  {
    code: 'SG-04',
    label: 'South East',
    country: 'SG',
  },
  {
    code: 'SG-05',
    label: 'South West',
    country: 'SG',
  },
  {
    code: 'SH-AC',
    label: 'Ascension',
    country: 'SH',
  },
  {
    code: 'SH-HL',
    label: 'Saint Helena',
    country: 'SH',
  },
  {
    code: 'SH-TA',
    label: 'Tristan da Cunha',
    country: 'SH',
  },
  {
    code: 'SI-001',
    label: 'Ajdovščina',
    country: 'SI',
  },
  {
    code: 'SI-002',
    label: 'Beltinci',
    country: 'SI',
  },
  {
    code: 'SI-003',
    label: 'Bled',
    country: 'SI',
  },
  {
    code: 'SI-004',
    label: 'Bohinj',
    country: 'SI',
  },
  {
    code: 'SI-005',
    label: 'Borovnica',
    country: 'SI',
  },
  {
    code: 'SI-006',
    label: 'Bovec',
    country: 'SI',
  },
  {
    code: 'SI-007',
    label: 'Brda',
    country: 'SI',
  },
  {
    code: 'SI-008',
    label: 'Brezovica',
    country: 'SI',
  },
  {
    code: 'SI-009',
    label: 'Brežice',
    country: 'SI',
  },
  {
    code: 'SI-010',
    label: 'Tišina',
    country: 'SI',
  },
  {
    code: 'SI-011',
    label: 'Celje',
    country: 'SI',
  },
  {
    code: 'SI-012',
    label: 'Cerklje na Gorenjskem',
    country: 'SI',
  },
  {
    code: 'SI-013',
    label: 'Cerknica',
    country: 'SI',
  },
  {
    code: 'SI-014',
    label: 'Cerkno',
    country: 'SI',
  },
  {
    code: 'SI-015',
    label: 'Črenšovci',
    country: 'SI',
  },
  {
    code: 'SI-016',
    label: 'Črna na Koroškem',
    country: 'SI',
  },
  {
    code: 'SI-017',
    label: 'Črnomelj',
    country: 'SI',
  },
  {
    code: 'SI-018',
    label: 'Destrnik',
    country: 'SI',
  },
  {
    code: 'SI-019',
    label: 'Divača',
    country: 'SI',
  },
  {
    code: 'SI-020',
    label: 'Dobrepolje',
    country: 'SI',
  },
  {
    code: 'SI-021',
    label: 'Dobrova-Polhov Gradec',
    country: 'SI',
  },
  {
    code: 'SI-022',
    label: 'Dol pri Ljubljani',
    country: 'SI',
  },
  {
    code: 'SI-023',
    label: 'Domžale',
    country: 'SI',
  },
  {
    code: 'SI-024',
    label: 'Dornava',
    country: 'SI',
  },
  {
    code: 'SI-025',
    label: 'Dravograd',
    country: 'SI',
  },
  {
    code: 'SI-026',
    label: 'Duplek',
    country: 'SI',
  },
  {
    code: 'SI-027',
    label: 'Gorenja vas-Poljane',
    country: 'SI',
  },
  {
    code: 'SI-028',
    label: 'Gorišnica',
    country: 'SI',
  },
  {
    code: 'SI-029',
    label: 'Gornja Radgona',
    country: 'SI',
  },
  {
    code: 'SI-030',
    label: 'Gornji Grad',
    country: 'SI',
  },
  {
    code: 'SI-031',
    label: 'Gornji Petrovci',
    country: 'SI',
  },
  {
    code: 'SI-032',
    label: 'Grosuplje',
    country: 'SI',
  },
  {
    code: 'SI-033',
    label: 'Šalovci',
    country: 'SI',
  },
  {
    code: 'SI-034',
    label: 'Hrastnik',
    country: 'SI',
  },
  {
    code: 'SI-035',
    label: 'Hrpelje-Kozina',
    country: 'SI',
  },
  {
    code: 'SI-036',
    label: 'Idrija',
    country: 'SI',
  },
  {
    code: 'SI-037',
    label: 'Ig',
    country: 'SI',
  },
  {
    code: 'SI-038',
    label: 'Ilirska Bistrica',
    country: 'SI',
  },
  {
    code: 'SI-039',
    label: 'Ivančna Gorica',
    country: 'SI',
  },
  {
    code: 'SI-040',
    label: 'Izola',
    country: 'SI',
  },
  {
    code: 'SI-041',
    label: 'Jesenice',
    country: 'SI',
  },
  {
    code: 'SI-042',
    label: 'Juršinci',
    country: 'SI',
  },
  {
    code: 'SI-043',
    label: 'Kamnik',
    country: 'SI',
  },
  {
    code: 'SI-044',
    label: 'Kanal',
    country: 'SI',
  },
  {
    code: 'SI-045',
    label: 'Kidričevo',
    country: 'SI',
  },
  {
    code: 'SI-046',
    label: 'Kobarid',
    country: 'SI',
  },
  {
    code: 'SI-047',
    label: 'Kobilje',
    country: 'SI',
  },
  {
    code: 'SI-048',
    label: 'Kočevje',
    country: 'SI',
  },
  {
    code: 'SI-049',
    label: 'Komen',
    country: 'SI',
  },
  {
    code: 'SI-050',
    label: 'Koper',
    country: 'SI',
  },
  {
    code: 'SI-051',
    label: 'Kozje',
    country: 'SI',
  },
  {
    code: 'SI-052',
    label: 'Kranj',
    country: 'SI',
  },
  {
    code: 'SI-053',
    label: 'Kranjska Gora',
    country: 'SI',
  },
  {
    code: 'SI-054',
    label: 'Krško',
    country: 'SI',
  },
  {
    code: 'SI-055',
    label: 'Kungota',
    country: 'SI',
  },
  {
    code: 'SI-056',
    label: 'Kuzma',
    country: 'SI',
  },
  {
    code: 'SI-057',
    label: 'Laško',
    country: 'SI',
  },
  {
    code: 'SI-058',
    label: 'Lenart',
    country: 'SI',
  },
  {
    code: 'SI-059',
    label: 'Lendava',
    country: 'SI',
  },
  {
    code: 'SI-060',
    label: 'Litija',
    country: 'SI',
  },
  {
    code: 'SI-061',
    label: 'Ljubljana',
    country: 'SI',
  },
  {
    code: 'SI-062',
    label: 'Ljubno',
    country: 'SI',
  },
  {
    code: 'SI-063',
    label: 'Ljutomer',
    country: 'SI',
  },
  {
    code: 'SI-064',
    label: 'Logatec',
    country: 'SI',
  },
  {
    code: 'SI-065',
    label: 'Loška dolina',
    country: 'SI',
  },
  {
    code: 'SI-066',
    label: 'Loški Potok',
    country: 'SI',
  },
  {
    code: 'SI-067',
    label: 'Luče',
    country: 'SI',
  },
  {
    code: 'SI-068',
    label: 'Lukovica',
    country: 'SI',
  },
  {
    code: 'SI-069',
    label: 'Majšperk',
    country: 'SI',
  },
  {
    code: 'SI-070',
    label: 'Maribor',
    country: 'SI',
  },
  {
    code: 'SI-071',
    label: 'Medvode',
    country: 'SI',
  },
  {
    code: 'SI-072',
    label: 'Mengeš',
    country: 'SI',
  },
  {
    code: 'SI-073',
    label: 'Metlika',
    country: 'SI',
  },
  {
    code: 'SI-074',
    label: 'Mežica',
    country: 'SI',
  },
  {
    code: 'SI-075',
    label: 'Miren-Kostanjevica',
    country: 'SI',
  },
  {
    code: 'SI-076',
    label: 'Mislinja',
    country: 'SI',
  },
  {
    code: 'SI-077',
    label: 'Moravče',
    country: 'SI',
  },
  {
    code: 'SI-078',
    label: 'Moravske Toplice',
    country: 'SI',
  },
  {
    code: 'SI-079',
    label: 'Mozirje',
    country: 'SI',
  },
  {
    code: 'SI-080',
    label: 'Murska Sobota',
    country: 'SI',
  },
  {
    code: 'SI-081',
    label: 'Muta',
    country: 'SI',
  },
  {
    code: 'SI-082',
    label: 'Naklo',
    country: 'SI',
  },
  {
    code: 'SI-083',
    label: 'Nazarje',
    country: 'SI',
  },
  {
    code: 'SI-084',
    label: 'Nova Gorica',
    country: 'SI',
  },
  {
    code: 'SI-085',
    label: 'Novo Mesto',
    country: 'SI',
  },
  {
    code: 'SI-086',
    label: 'Odranci',
    country: 'SI',
  },
  {
    code: 'SI-087',
    label: 'Ormož',
    country: 'SI',
  },
  {
    code: 'SI-088',
    label: 'Osilnica',
    country: 'SI',
  },
  {
    code: 'SI-089',
    label: 'Pesnica',
    country: 'SI',
  },
  {
    code: 'SI-090',
    label: 'Piran',
    country: 'SI',
  },
  {
    code: 'SI-091',
    label: 'Pivka',
    country: 'SI',
  },
  {
    code: 'SI-092',
    label: 'Podčetrtek',
    country: 'SI',
  },
  {
    code: 'SI-093',
    label: 'Podvelka',
    country: 'SI',
  },
  {
    code: 'SI-094',
    label: 'Postojna',
    country: 'SI',
  },
  {
    code: 'SI-095',
    label: 'Preddvor',
    country: 'SI',
  },
  {
    code: 'SI-096',
    label: 'Ptuj',
    country: 'SI',
  },
  {
    code: 'SI-097',
    label: 'Puconci',
    country: 'SI',
  },
  {
    code: 'SI-098',
    label: 'Rače-Fram',
    country: 'SI',
  },
  {
    code: 'SI-099',
    label: 'Radeče',
    country: 'SI',
  },
  {
    code: 'SI-100',
    label: 'Radenci',
    country: 'SI',
  },
  {
    code: 'SI-101',
    label: 'Radlje ob Dravi',
    country: 'SI',
  },
  {
    code: 'SI-102',
    label: 'Radovljica',
    country: 'SI',
  },
  {
    code: 'SI-103',
    label: 'Ravne na Koroškem',
    country: 'SI',
  },
  {
    code: 'SI-104',
    label: 'Ribnica',
    country: 'SI',
  },
  {
    code: 'SI-105',
    label: 'Rogašovci',
    country: 'SI',
  },
  {
    code: 'SI-106',
    label: 'Rogaška Slatina',
    country: 'SI',
  },
  {
    code: 'SI-107',
    label: 'Rogatec',
    country: 'SI',
  },
  {
    code: 'SI-108',
    label: 'Ruše',
    country: 'SI',
  },
  {
    code: 'SI-109',
    label: 'Semič',
    country: 'SI',
  },
  {
    code: 'SI-110',
    label: 'Sevnica',
    country: 'SI',
  },
  {
    code: 'SI-111',
    label: 'Sežana',
    country: 'SI',
  },
  {
    code: 'SI-112',
    label: 'Slovenj Gradec',
    country: 'SI',
  },
  {
    code: 'SI-113',
    label: 'Slovenska Bistrica',
    country: 'SI',
  },
  {
    code: 'SI-114',
    label: 'Slovenske Konjice',
    country: 'SI',
  },
  {
    code: 'SI-115',
    label: 'Starše',
    country: 'SI',
  },
  {
    code: 'SI-116',
    label: 'Sveti Jurij ob Ščavnici',
    country: 'SI',
  },
  {
    code: 'SI-117',
    label: 'Šenčur',
    country: 'SI',
  },
  {
    code: 'SI-118',
    label: 'Šentilj',
    country: 'SI',
  },
  {
    code: 'SI-119',
    label: 'Šentjernej',
    country: 'SI',
  },
  {
    code: 'SI-120',
    label: 'Šentjur',
    country: 'SI',
  },
  {
    code: 'SI-121',
    label: 'Škocjan',
    country: 'SI',
  },
  {
    code: 'SI-122',
    label: 'Škofja Loka',
    country: 'SI',
  },
  {
    code: 'SI-123',
    label: 'Škofljica',
    country: 'SI',
  },
  {
    code: 'SI-124',
    label: 'Šmarje pri Jelšah',
    country: 'SI',
  },
  {
    code: 'SI-125',
    label: 'Šmartno ob Paki',
    country: 'SI',
  },
  {
    code: 'SI-126',
    label: 'Šoštanj',
    country: 'SI',
  },
  {
    code: 'SI-127',
    label: 'Štore',
    country: 'SI',
  },
  {
    code: 'SI-128',
    label: 'Tolmin',
    country: 'SI',
  },
  {
    code: 'SI-129',
    label: 'Trbovlje',
    country: 'SI',
  },
  {
    code: 'SI-130',
    label: 'Trebnje',
    country: 'SI',
  },
  {
    code: 'SI-131',
    label: 'Tržič',
    country: 'SI',
  },
  {
    code: 'SI-132',
    label: 'Turnišče',
    country: 'SI',
  },
  {
    code: 'SI-133',
    label: 'Velenje',
    country: 'SI',
  },
  {
    code: 'SI-134',
    label: 'Velike Lašče',
    country: 'SI',
  },
  {
    code: 'SI-135',
    label: 'Videm',
    country: 'SI',
  },
  {
    code: 'SI-136',
    label: 'Vipava',
    country: 'SI',
  },
  {
    code: 'SI-137',
    label: 'Vitanje',
    country: 'SI',
  },
  {
    code: 'SI-138',
    label: 'Vodice',
    country: 'SI',
  },
  {
    code: 'SI-139',
    label: 'Vojnik',
    country: 'SI',
  },
  {
    code: 'SI-140',
    label: 'Vrhnika',
    country: 'SI',
  },
  {
    code: 'SI-141',
    label: 'Vuzenica',
    country: 'SI',
  },
  {
    code: 'SI-142',
    label: 'Zagorje ob Savi',
    country: 'SI',
  },
  {
    code: 'SI-143',
    label: 'Zavrč',
    country: 'SI',
  },
  {
    code: 'SI-144',
    label: 'Zreče',
    country: 'SI',
  },
  {
    code: 'SI-146',
    label: 'Železniki',
    country: 'SI',
  },
  {
    code: 'SI-147',
    label: 'Žiri',
    country: 'SI',
  },
  {
    code: 'SI-148',
    label: 'Benedikt',
    country: 'SI',
  },
  {
    code: 'SI-149',
    label: 'Bistrica ob Sotli',
    country: 'SI',
  },
  {
    code: 'SI-150',
    label: 'Bloke',
    country: 'SI',
  },
  {
    code: 'SI-151',
    label: 'Braslovče',
    country: 'SI',
  },
  {
    code: 'SI-152',
    label: 'Cankova',
    country: 'SI',
  },
  {
    code: 'SI-153',
    label: 'Cerkvenjak',
    country: 'SI',
  },
  {
    code: 'SI-154',
    label: 'Dobje',
    country: 'SI',
  },
  {
    code: 'SI-155',
    label: 'Dobrna',
    country: 'SI',
  },
  {
    code: 'SI-156',
    label: 'Dobrovnik',
    country: 'SI',
  },
  {
    code: 'SI-157',
    label: 'Dolenjske Toplice',
    country: 'SI',
  },
  {
    code: 'SI-158',
    label: 'Grad',
    country: 'SI',
  },
  {
    code: 'SI-159',
    label: 'Hajdina',
    country: 'SI',
  },
  {
    code: 'SI-160',
    label: 'Hoče-Slivnica',
    country: 'SI',
  },
  {
    code: 'SI-161',
    label: 'Hodoš',
    country: 'SI',
  },
  {
    code: 'SI-162',
    label: 'Horjul',
    country: 'SI',
  },
  {
    code: 'SI-163',
    label: 'Jezersko',
    country: 'SI',
  },
  {
    code: 'SI-164',
    label: 'Komenda',
    country: 'SI',
  },
  {
    code: 'SI-165',
    label: 'Kostel',
    country: 'SI',
  },
  {
    code: 'SI-166',
    label: 'Križevci',
    country: 'SI',
  },
  {
    code: 'SI-167',
    label: 'Lovrenc na Pohorju',
    country: 'SI',
  },
  {
    code: 'SI-168',
    label: 'Markovci',
    country: 'SI',
  },
  {
    code: 'SI-169',
    label: 'Miklavž na Dravskem polju',
    country: 'SI',
  },
  {
    code: 'SI-170',
    label: 'Mirna Peč',
    country: 'SI',
  },
  {
    code: 'SI-171',
    label: 'Oplotnica',
    country: 'SI',
  },
  {
    code: 'SI-172',
    label: 'Podlehnik',
    country: 'SI',
  },
  {
    code: 'SI-173',
    label: 'Polzela',
    country: 'SI',
  },
  {
    code: 'SI-174',
    label: 'Prebold',
    country: 'SI',
  },
  {
    code: 'SI-175',
    label: 'Prevalje',
    country: 'SI',
  },
  {
    code: 'SI-176',
    label: 'Razkrižje',
    country: 'SI',
  },
  {
    code: 'SI-177',
    label: 'Ribnica na Pohorju',
    country: 'SI',
  },
  {
    code: 'SI-178',
    label: 'Selnica ob Dravi',
    country: 'SI',
  },
  {
    code: 'SI-179',
    label: 'Sodražica',
    country: 'SI',
  },
  {
    code: 'SI-180',
    label: 'Solčava',
    country: 'SI',
  },
  {
    code: 'SI-181',
    label: 'Sveta Ana',
    country: 'SI',
  },
  {
    code: 'SI-182',
    label: 'Sveti Andraž v Slovenskih goricah',
    country: 'SI',
  },
  {
    code: 'SI-183',
    label: 'Šempeter-Vrtojba',
    country: 'SI',
  },
  {
    code: 'SI-184',
    label: 'Tabor',
    country: 'SI',
  },
  {
    code: 'SI-185',
    label: 'Trnovska Vas',
    country: 'SI',
  },
  {
    code: 'SI-186',
    label: 'Trzin',
    country: 'SI',
  },
  {
    code: 'SI-187',
    label: 'Velika Polana',
    country: 'SI',
  },
  {
    code: 'SI-188',
    label: 'Veržej',
    country: 'SI',
  },
  {
    code: 'SI-189',
    label: 'Vransko',
    country: 'SI',
  },
  {
    code: 'SI-190',
    label: 'Žalec',
    country: 'SI',
  },
  {
    code: 'SI-191',
    label: 'Žetale',
    country: 'SI',
  },
  {
    code: 'SI-192',
    label: 'Žirovnica',
    country: 'SI',
  },
  {
    code: 'SI-193',
    label: 'Žužemberk',
    country: 'SI',
  },
  {
    code: 'SI-194',
    label: 'Šmartno pri Litiji',
    country: 'SI',
  },
  {
    code: 'SI-195',
    label: 'Apače',
    country: 'SI',
  },
  {
    code: 'SI-196',
    label: 'Cirkulane',
    country: 'SI',
  },
  {
    code: 'SI-197',
    label: 'Kosanjevica na Krki',
    country: 'SI',
  },
  {
    code: 'SI-198',
    label: 'Makole',
    country: 'SI',
  },
  {
    code: 'SI-199',
    label: 'Mokronog-Trebelno',
    country: 'SI',
  },
  {
    code: 'SI-200',
    label: 'Poljčane',
    country: 'SI',
  },
  {
    code: 'SI-201',
    label: 'Renče-Vogrsko',
    country: 'SI',
  },
  {
    code: 'SI-202',
    label: 'Središče ob Dravi',
    country: 'SI',
  },
  {
    code: 'SI-203',
    label: 'Straža',
    country: 'SI',
  },
  {
    code: 'SI-204',
    label: 'Sveta Trojica v Slovenskih goricah',
    country: 'SI',
  },
  {
    code: 'SI-205',
    label: 'Sveti Tomaž',
    country: 'SI',
  },
  {
    code: 'SI-206',
    label: 'Šmarješke Toplice',
    country: 'SI',
  },
  {
    code: 'SI-207',
    label: 'Gorje',
    country: 'SI',
  },
  {
    code: 'SI-208',
    label: 'Log-Dragomer',
    country: 'SI',
  },
  {
    code: 'SI-209',
    label: 'Rečica ob Savinji',
    country: 'SI',
  },
  {
    code: 'SI-210',
    label: 'Sveti Jurij v Slovenskih goricah',
    country: 'SI',
  },
  {
    code: 'SI-211',
    label: 'Šentrupert',
    country: 'SI',
  },
  {
    code: 'SI-212',
    label: 'Mirna',
    country: 'SI',
  },
  {
    code: 'SI-213',
    label: 'Ankaran',
    country: 'SI',
  },
  {
    code: 'SK-BC',
    label: 'Banskobystrický kraj',
    country: 'SK',
  },
  {
    code: 'SK-BL',
    label: 'Bratislavský kraj',
    country: 'SK',
  },
  {
    code: 'SK-KI',
    label: 'Košický kraj',
    country: 'SK',
  },
  {
    code: 'SK-NI',
    label: 'Nitriansky kraj',
    country: 'SK',
  },
  {
    code: 'SK-PV',
    label: 'Prešovský kraj',
    country: 'SK',
  },
  {
    code: 'SK-TA',
    label: 'Trnavský kraj',
    country: 'SK',
  },
  {
    code: 'SK-TC',
    label: 'Trenčiansky kraj',
    country: 'SK',
  },
  {
    code: 'SK-ZI',
    label: 'Žilinský kraj',
    country: 'SK',
  },
  {
    code: 'SL-E',
    label: 'Eastern',
    country: 'SL',
  },
  {
    code: 'SL-N',
    label: 'Northern',
    country: 'SL',
  },
  {
    code: 'SL-NW',
    label: 'North Western',
    country: 'SL',
  },
  {
    code: 'SL-S',
    label: 'Southern',
    country: 'SL',
  },
  {
    code: 'SL-W',
    label: 'Western Area (Freetown)',
    country: 'SL',
  },
  {
    code: 'SM-01',
    label: 'Acquaviva',
    country: 'SM',
  },
  {
    code: 'SM-02',
    label: 'Chiesanuova',
    country: 'SM',
  },
  {
    code: 'SM-03',
    label: 'Domagnano',
    country: 'SM',
  },
  {
    code: 'SM-04',
    label: 'Faetano',
    country: 'SM',
  },
  {
    code: 'SM-05',
    label: 'Fiorentino',
    country: 'SM',
  },
  {
    code: 'SM-06',
    label: 'Borgo Maggiore',
    country: 'SM',
  },
  {
    code: 'SM-07',
    label: 'Città di San Marino(local variant is San Marino)',
    country: 'SM',
  },
  {
    code: 'SM-08',
    label: 'Montegiardino',
    country: 'SM',
  },
  {
    code: 'SM-09',
    label: 'Serravalle',
    country: 'SM',
  },
  {
    code: 'SN-DB',
    label: 'Diourbel',
    country: 'SN',
  },
  {
    code: 'SN-DK',
    label: 'Dakar',
    country: 'SN',
  },
  {
    code: 'SN-FK',
    label: 'Fatick',
    country: 'SN',
  },
  {
    code: 'SN-KA',
    label: 'Kaffrine',
    country: 'SN',
  },
  {
    code: 'SN-KD',
    label: 'Kolda',
    country: 'SN',
  },
  {
    code: 'SN-KE',
    label: 'Kédougou',
    country: 'SN',
  },
  {
    code: 'SN-KL',
    label: 'Kaolack',
    country: 'SN',
  },
  {
    code: 'SN-LG',
    label: 'Louga',
    country: 'SN',
  },
  {
    code: 'SN-MT',
    label: 'Matam',
    country: 'SN',
  },
  {
    code: 'SN-SE',
    label: 'Sédhiou',
    country: 'SN',
  },
  {
    code: 'SN-SL',
    label: 'Saint-Louis',
    country: 'SN',
  },
  {
    code: 'SN-TC',
    label: 'Tambacounda',
    country: 'SN',
  },
  {
    code: 'SN-TH',
    label: 'Thiès',
    country: 'SN',
  },
  {
    code: 'SN-ZG',
    label: 'Ziguinchor',
    country: 'SN',
  },
  {
    code: 'SO-AW',
    label: 'Awdal',
    country: 'SO',
  },
  {
    code: 'SO-BK',
    label: 'Bakool',
    country: 'SO',
  },
  {
    code: 'SO-BN',
    label: 'Banaadir',
    country: 'SO',
  },
  {
    code: 'SO-BR',
    label: 'Bari',
    country: 'SO',
  },
  {
    code: 'SO-BY',
    label: 'Bay',
    country: 'SO',
  },
  {
    code: 'SO-GA',
    label: 'Galguduud',
    country: 'SO',
  },
  {
    code: 'SO-GE',
    label: 'Gedo',
    country: 'SO',
  },
  {
    code: 'SO-HI',
    label: 'Hiiraan',
    country: 'SO',
  },
  {
    code: 'SO-JD',
    label: 'Jubbada Dhexe',
    country: 'SO',
  },
  {
    code: 'SO-JH',
    label: 'Jubbada Hoose',
    country: 'SO',
  },
  {
    code: 'SO-MU',
    label: 'Mudug',
    country: 'SO',
  },
  {
    code: 'SO-NU',
    label: 'Nugaal',
    country: 'SO',
  },
  {
    code: 'SO-SA',
    label: 'Sanaag',
    country: 'SO',
  },
  {
    code: 'SO-SD',
    label: 'Shabeellaha Dhexe',
    country: 'SO',
  },
  {
    code: 'SO-SH',
    label: 'Shabeellaha Hoose',
    country: 'SO',
  },
  {
    code: 'SO-SO',
    label: 'Sool',
    country: 'SO',
  },
  {
    code: 'SO-TO',
    label: 'Togdheer',
    country: 'SO',
  },
  {
    code: 'SO-WO',
    label: 'Woqooyi Galbeed',
    country: 'SO',
  },
  {
    code: 'SR-BR',
    label: 'Brokopondo',
    country: 'SR',
  },
  {
    code: 'SR-CM',
    label: 'Commewijne',
    country: 'SR',
  },
  {
    code: 'SR-CR',
    label: 'Coronie',
    country: 'SR',
  },
  {
    code: 'SR-MA',
    label: 'Marowijne',
    country: 'SR',
  },
  {
    code: 'SR-NI',
    label: 'Nickerie',
    country: 'SR',
  },
  {
    code: 'SR-PM',
    label: 'Paramaribo',
    country: 'SR',
  },
  {
    code: 'SR-PR',
    label: 'Para',
    country: 'SR',
  },
  {
    code: 'SR-SA',
    label: 'Saramacca',
    country: 'SR',
  },
  {
    code: 'SR-SI',
    label: 'Sipaliwini',
    country: 'SR',
  },
  {
    code: 'SR-WA',
    label: 'Wanica',
    country: 'SR',
  },
  {
    code: 'SS-BN',
    label: 'Northern Bahr el Ghazal',
    country: 'SS',
  },
  {
    code: 'SS-BW',
    label: 'Western Bahr el Ghazal',
    country: 'SS',
  },
  {
    code: 'SS-EC',
    label: 'Central Equatoria',
    country: 'SS',
  },
  {
    code: 'SS-EE',
    label: 'Eastern Equatoria',
    country: 'SS',
  },
  {
    code: 'SS-EW',
    label: 'Western Equatoria',
    country: 'SS',
  },
  {
    code: 'SS-JG',
    label: 'Jonglei',
    country: 'SS',
  },
  {
    code: 'SS-LK',
    label: 'Lakes',
    country: 'SS',
  },
  {
    code: 'SS-NU',
    label: 'Upper Nile',
    country: 'SS',
  },
  {
    code: 'SS-UY',
    label: 'Unity',
    country: 'SS',
  },
  {
    code: 'SS-WR',
    label: 'Warrap',
    country: 'SS',
  },
  {
    code: 'ST-01',
    label: 'Água Grande',
    country: 'ST',
  },
  {
    code: 'ST-02',
    label: 'Cantagalo',
    country: 'ST',
  },
  {
    code: 'ST-03',
    label: 'Caué',
    country: 'ST',
  },
  {
    code: 'ST-04',
    label: 'Lembá',
    country: 'ST',
  },
  {
    code: 'ST-05',
    label: 'Lobata',
    country: 'ST',
  },
  {
    code: 'ST-06',
    label: 'Mé-Zóchi',
    country: 'ST',
  },
  {
    code: 'ST-P',
    label: 'Príncipe',
    country: 'ST',
  },
  {
    code: 'SV-AH',
    label: 'Ahuachapán',
    country: 'SV',
  },
  {
    code: 'SV-CA',
    label: 'Cabañas',
    country: 'SV',
  },
  {
    code: 'SV-CH',
    label: 'Chalatenango',
    country: 'SV',
  },
  {
    code: 'SV-CU',
    label: 'Cuscatlán',
    country: 'SV',
  },
  {
    code: 'SV-LI',
    label: 'La Libertad',
    country: 'SV',
  },
  {
    code: 'SV-MO',
    label: 'Morazán',
    country: 'SV',
  },
  {
    code: 'SV-PA',
    label: 'La Paz',
    country: 'SV',
  },
  {
    code: 'SV-SA',
    label: 'Santa Ana',
    country: 'SV',
  },
  {
    code: 'SV-SM',
    label: 'San Miguel',
    country: 'SV',
  },
  {
    code: 'SV-SO',
    label: 'Sonsonate',
    country: 'SV',
  },
  {
    code: 'SV-SS',
    label: 'San Salvador',
    country: 'SV',
  },
  {
    code: 'SV-SV',
    label: 'San Vicente',
    country: 'SV',
  },
  {
    code: 'SV-UN',
    label: 'La Unión',
    country: 'SV',
  },
  {
    code: 'SV-US',
    label: 'Usulután',
    country: 'SV',
  },
  {
    code: 'SY-DI',
    label: 'Dimashq',
    country: 'SY',
  },
  {
    code: 'SY-DR',
    label: "Dar'ā",
    country: 'SY',
  },
  {
    code: 'SY-DY',
    label: 'Dayr az Zawr',
    country: 'SY',
  },
  {
    code: 'SY-HA',
    label: 'Al Ḩasakah',
    country: 'SY',
  },
  {
    code: 'SY-HI',
    label: 'Ḩimş',
    country: 'SY',
  },
  {
    code: 'SY-HL',
    label: 'Ḩalab',
    country: 'SY',
  },
  {
    code: 'SY-HM',
    label: 'Ḩamāh',
    country: 'SY',
  },
  {
    code: 'SY-ID',
    label: 'Idlib',
    country: 'SY',
  },
  {
    code: 'SY-LA',
    label: 'Al Lādhiqīyah',
    country: 'SY',
  },
  {
    code: 'SY-QU',
    label: 'Al Qunayţirah',
    country: 'SY',
  },
  {
    code: 'SY-RA',
    label: 'Ar Raqqah',
    country: 'SY',
  },
  {
    code: 'SY-RD',
    label: 'Rīf Dimashq',
    country: 'SY',
  },
  {
    code: 'SY-SU',
    label: "As Suwaydā'",
    country: 'SY',
  },
  {
    code: 'SY-TA',
    label: 'Ţarţūs',
    country: 'SY',
  },
  {
    code: 'SZ-HH',
    label: 'Hhohho',
    country: 'SZ',
  },
  {
    code: 'SZ-LU',
    label: 'Lubombo',
    country: 'SZ',
  },
  {
    code: 'SZ-MA',
    label: 'Manzini',
    country: 'SZ',
  },
  {
    code: 'SZ-SH',
    label: 'Shiselweni',
    country: 'SZ',
  },
  {
    code: 'TD-BA',
    label: 'Al Baţḩā’',
    country: 'TD',
  },
  {
    code: 'TD-BG',
    label: 'Baḩr al Ghazāl',
    country: 'TD',
  },
  {
    code: 'TD-BO',
    label: 'Būrkū',
    country: 'TD',
  },
  {
    code: 'TD-CB',
    label: 'Shārī Bāqirmī',
    country: 'TD',
  },
  {
    code: 'TD-EE',
    label: 'Inīdī ash Sharqī',
    country: 'TD',
  },
  {
    code: 'TD-EO',
    label: 'Inīdī al Gharbī',
    country: 'TD',
  },
  {
    code: 'TD-GR',
    label: 'Qīrā',
    country: 'TD',
  },
  {
    code: 'TD-HL',
    label: 'Ḩajjar Lamīs',
    country: 'TD',
  },
  {
    code: 'TD-KA',
    label: 'Kānim',
    country: 'TD',
  },
  {
    code: 'TD-LC',
    label: 'Al Buḩayrah',
    country: 'TD',
  },
  {
    code: 'TD-LO',
    label: 'Lūghūn al Gharbī',
    country: 'TD',
  },
  {
    code: 'TD-LR',
    label: 'Lūghūn ash Sharqī',
    country: 'TD',
  },
  {
    code: 'TD-MA',
    label: 'Māndūl',
    country: 'TD',
  },
  {
    code: 'TD-MC',
    label: 'Shārī al Awsaţ',
    country: 'TD',
  },
  {
    code: 'TD-ME',
    label: 'Māyū Kībbī ash Sharqī',
    country: 'TD',
  },
  {
    code: 'TD-MO',
    label: 'Māyū Kībbī al Gharbī',
    country: 'TD',
  },
  {
    code: 'TD-ND',
    label: 'Madīnat Injamīnā',
    country: 'TD',
  },
  {
    code: 'TD-OD',
    label: 'Waddāy',
    country: 'TD',
  },
  {
    code: 'TD-SA',
    label: 'Salāmāt',
    country: 'TD',
  },
  {
    code: 'TD-SI',
    label: 'Sīlā',
    country: 'TD',
  },
  {
    code: 'TD-TA',
    label: 'Tānjīlī',
    country: 'TD',
  },
  {
    code: 'TD-TI',
    label: 'Tibastī',
    country: 'TD',
  },
  {
    code: 'TD-WF',
    label: 'Wādī Fīrā’',
    country: 'TD',
  },
  {
    code: 'TG-C',
    label: 'Centrale',
    country: 'TG',
  },
  {
    code: 'TG-K',
    label: 'Kara',
    country: 'TG',
  },
  {
    code: 'TG-M',
    label: 'Maritime (Région)',
    country: 'TG',
  },
  {
    code: 'TG-P',
    label: 'Plateaux',
    country: 'TG',
  },
  {
    code: 'TG-S',
    label: 'Savanes',
    country: 'TG',
  },
  {
    code: 'TH-10',
    label: 'Krung Thep Maha Nakhon(local variant is Bangkok)',
    country: 'TH',
  },
  {
    code: 'TH-11',
    label: 'Samut Prakan',
    country: 'TH',
  },
  {
    code: 'TH-12',
    label: 'Nonthaburi',
    country: 'TH',
  },
  {
    code: 'TH-13',
    label: 'Pathum Thani',
    country: 'TH',
  },
  {
    code: 'TH-14',
    label: 'Phra Nakhon Si Ayutthaya',
    country: 'TH',
  },
  {
    code: 'TH-15',
    label: 'Ang Thong',
    country: 'TH',
  },
  {
    code: 'TH-16',
    label: 'Lop Buri',
    country: 'TH',
  },
  {
    code: 'TH-17',
    label: 'Sing Buri',
    country: 'TH',
  },
  {
    code: 'TH-18',
    label: 'Chai Nat',
    country: 'TH',
  },
  {
    code: 'TH-19',
    label: 'Saraburi',
    country: 'TH',
  },
  {
    code: 'TH-20',
    label: 'Chon Buri',
    country: 'TH',
  },
  {
    code: 'TH-21',
    label: 'Rayong',
    country: 'TH',
  },
  {
    code: 'TH-22',
    label: 'Chanthaburi',
    country: 'TH',
  },
  {
    code: 'TH-23',
    label: 'Trat',
    country: 'TH',
  },
  {
    code: 'TH-24',
    label: 'Chachoengsao',
    country: 'TH',
  },
  {
    code: 'TH-25',
    label: 'Prachin Buri',
    country: 'TH',
  },
  {
    code: 'TH-26',
    label: 'Nakhon Nayok',
    country: 'TH',
  },
  {
    code: 'TH-27',
    label: 'Sa Kaeo',
    country: 'TH',
  },
  {
    code: 'TH-30',
    label: 'Nakhon Ratchasima',
    country: 'TH',
  },
  {
    code: 'TH-31',
    label: 'Buri Ram',
    country: 'TH',
  },
  {
    code: 'TH-32',
    label: 'Surin',
    country: 'TH',
  },
  {
    code: 'TH-33',
    label: 'Si Sa Ket',
    country: 'TH',
  },
  {
    code: 'TH-34',
    label: 'Ubon Ratchathani',
    country: 'TH',
  },
  {
    code: 'TH-35',
    label: 'Yasothon',
    country: 'TH',
  },
  {
    code: 'TH-36',
    label: 'Chaiyaphum',
    country: 'TH',
  },
  {
    code: 'TH-37',
    label: 'Amnat Charoen',
    country: 'TH',
  },
  {
    code: 'TH-38',
    label: 'Bueng Kan',
    country: 'TH',
  },
  {
    code: 'TH-39',
    label: 'Nong Bua Lam Phu',
    country: 'TH',
  },
  {
    code: 'TH-40',
    label: 'Khon Kaen',
    country: 'TH',
  },
  {
    code: 'TH-41',
    label: 'Udon Thani',
    country: 'TH',
  },
  {
    code: 'TH-42',
    label: 'Loei',
    country: 'TH',
  },
  {
    code: 'TH-43',
    label: 'Nong Khai',
    country: 'TH',
  },
  {
    code: 'TH-44',
    label: 'Maha Sarakham',
    country: 'TH',
  },
  {
    code: 'TH-45',
    label: 'Roi Et',
    country: 'TH',
  },
  {
    code: 'TH-46',
    label: 'Kalasin',
    country: 'TH',
  },
  {
    code: 'TH-47',
    label: 'Sakon Nakhon',
    country: 'TH',
  },
  {
    code: 'TH-48',
    label: 'Nakhon Phanom',
    country: 'TH',
  },
  {
    code: 'TH-49',
    label: 'Mukdahan',
    country: 'TH',
  },
  {
    code: 'TH-50',
    label: 'Chiang Mai',
    country: 'TH',
  },
  {
    code: 'TH-51',
    label: 'Lamphun',
    country: 'TH',
  },
  {
    code: 'TH-52',
    label: 'Lampang',
    country: 'TH',
  },
  {
    code: 'TH-53',
    label: 'Uttaradit',
    country: 'TH',
  },
  {
    code: 'TH-54',
    label: 'Phrae',
    country: 'TH',
  },
  {
    code: 'TH-55',
    label: 'Nan',
    country: 'TH',
  },
  {
    code: 'TH-56',
    label: 'Phayao',
    country: 'TH',
  },
  {
    code: 'TH-57',
    label: 'Chiang Rai',
    country: 'TH',
  },
  {
    code: 'TH-58',
    label: 'Mae Hong Son',
    country: 'TH',
  },
  {
    code: 'TH-60',
    label: 'Nakhon Sawan',
    country: 'TH',
  },
  {
    code: 'TH-61',
    label: 'Uthai Thani',
    country: 'TH',
  },
  {
    code: 'TH-62',
    label: 'Kamphaeng Phet',
    country: 'TH',
  },
  {
    code: 'TH-63',
    label: 'Tak',
    country: 'TH',
  },
  {
    code: 'TH-64',
    label: 'Sukhothai',
    country: 'TH',
  },
  {
    code: 'TH-65',
    label: 'Phitsanulok',
    country: 'TH',
  },
  {
    code: 'TH-66',
    label: 'Phichit',
    country: 'TH',
  },
  {
    code: 'TH-67',
    label: 'Phetchabun',
    country: 'TH',
  },
  {
    code: 'TH-70',
    label: 'Ratchaburi',
    country: 'TH',
  },
  {
    code: 'TH-71',
    label: 'Kanchanaburi',
    country: 'TH',
  },
  {
    code: 'TH-72',
    label: 'Suphan Buri',
    country: 'TH',
  },
  {
    code: 'TH-73',
    label: 'Nakhon Pathom',
    country: 'TH',
  },
  {
    code: 'TH-74',
    label: 'Samut Sakhon',
    country: 'TH',
  },
  {
    code: 'TH-75',
    label: 'Samut Songkhram',
    country: 'TH',
  },
  {
    code: 'TH-76',
    label: 'Phetchaburi',
    country: 'TH',
  },
  {
    code: 'TH-77',
    label: 'Prachuap Khiri Khan',
    country: 'TH',
  },
  {
    code: 'TH-80',
    label: 'Nakhon Si Thammarat',
    country: 'TH',
  },
  {
    code: 'TH-81',
    label: 'Krabi',
    country: 'TH',
  },
  {
    code: 'TH-82',
    label: 'Phangnga',
    country: 'TH',
  },
  {
    code: 'TH-83',
    label: 'Phuket',
    country: 'TH',
  },
  {
    code: 'TH-84',
    label: 'Surat Thani',
    country: 'TH',
  },
  {
    code: 'TH-85',
    label: 'Ranong',
    country: 'TH',
  },
  {
    code: 'TH-86',
    label: 'Chumphon',
    country: 'TH',
  },
  {
    code: 'TH-90',
    label: 'Songkhla',
    country: 'TH',
  },
  {
    code: 'TH-91',
    label: 'Satun',
    country: 'TH',
  },
  {
    code: 'TH-92',
    label: 'Trang',
    country: 'TH',
  },
  {
    code: 'TH-93',
    label: 'Phatthalung',
    country: 'TH',
  },
  {
    code: 'TH-94',
    label: 'Pattani',
    country: 'TH',
  },
  {
    code: 'TH-95',
    label: 'Yala',
    country: 'TH',
  },
  {
    code: 'TH-96',
    label: 'Narathiwat',
    country: 'TH',
  },
  {
    code: 'TH-S',
    label: 'Phatthaya',
    country: 'TH',
  },
  {
    code: 'TJ-DU',
    label: 'Dushanbe',
    country: 'TJ',
  },
  {
    code: 'TJ-GB',
    label: 'Kŭhistoni Badakhshon',
    country: 'TJ',
  },
  {
    code: 'TJ-KT',
    label: 'Khatlon',
    country: 'TJ',
  },
  {
    code: 'TJ-RA',
    label: 'nohiyahoi tobei jumhurí',
    country: 'TJ',
  },
  {
    code: 'TJ-SU',
    label: 'Sughd',
    country: 'TJ',
  },
  {
    code: 'TL-AL',
    label: 'Aileu',
    country: 'TL',
  },
  {
    code: 'TL-AN',
    label: 'Ainaro',
    country: 'TL',
  },
  {
    code: 'TL-BA',
    label: 'Baucau',
    country: 'TL',
  },
  {
    code: 'TL-BO',
    label: 'Bobonaro',
    country: 'TL',
  },
  {
    code: 'TL-CO',
    label: 'Cova Lima',
    country: 'TL',
  },
  {
    code: 'TL-DI',
    label: 'Díli',
    country: 'TL',
  },
  {
    code: 'TL-ER',
    label: 'Ermera',
    country: 'TL',
  },
  {
    code: 'TL-LA',
    label: 'Lautém',
    country: 'TL',
  },
  {
    code: 'TL-LI',
    label: 'Liquiça',
    country: 'TL',
  },
  {
    code: 'TL-MF',
    label: 'Manufahi',
    country: 'TL',
  },
  {
    code: 'TL-MT',
    label: 'Manatuto',
    country: 'TL',
  },
  {
    code: 'TL-OE',
    label: 'Oé-Cusse Ambeno(local variant is Oecussi)',
    country: 'TL',
  },
  {
    code: 'TL-VI',
    label: 'Viqueque',
    country: 'TL',
  },
  {
    code: 'TM-A',
    label: 'Ahal',
    country: 'TM',
  },
  {
    code: 'TM-B',
    label: 'Balkan',
    country: 'TM',
  },
  {
    code: 'TM-D',
    label: 'Daşoguz',
    country: 'TM',
  },
  {
    code: 'TM-L',
    label: 'Lebap',
    country: 'TM',
  },
  {
    code: 'TM-M',
    label: 'Mary',
    country: 'TM',
  },
  {
    code: 'TM-S',
    label: 'Aşgabat',
    country: 'TM',
  },
  {
    code: 'TN-11',
    label: 'Tunis',
    country: 'TN',
  },
  {
    code: 'TN-12',
    label: "L'Ariana",
    country: 'TN',
  },
  {
    code: 'TN-13',
    label: 'Ben Arous',
    country: 'TN',
  },
  {
    code: 'TN-14',
    label: 'La Manouba',
    country: 'TN',
  },
  {
    code: 'TN-21',
    label: 'Nabeul',
    country: 'TN',
  },
  {
    code: 'TN-22',
    label: 'Zaghouan',
    country: 'TN',
  },
  {
    code: 'TN-23',
    label: 'Bizerte',
    country: 'TN',
  },
  {
    code: 'TN-31',
    label: 'Béja',
    country: 'TN',
  },
  {
    code: 'TN-32',
    label: 'Jendouba',
    country: 'TN',
  },
  {
    code: 'TN-33',
    label: 'Le Kef',
    country: 'TN',
  },
  {
    code: 'TN-34',
    label: 'Siliana',
    country: 'TN',
  },
  {
    code: 'TN-41',
    label: 'Kairouan',
    country: 'TN',
  },
  {
    code: 'TN-42',
    label: 'Kasserine',
    country: 'TN',
  },
  {
    code: 'TN-43',
    label: 'Sidi Bouzid',
    country: 'TN',
  },
  {
    code: 'TN-51',
    label: 'Sousse',
    country: 'TN',
  },
  {
    code: 'TN-52',
    label: 'Monastir',
    country: 'TN',
  },
  {
    code: 'TN-53',
    label: 'Mahdia',
    country: 'TN',
  },
  {
    code: 'TN-61',
    label: 'Sfax',
    country: 'TN',
  },
  {
    code: 'TN-71',
    label: 'Gafsa',
    country: 'TN',
  },
  {
    code: 'TN-72',
    label: 'Tozeur',
    country: 'TN',
  },
  {
    code: 'TN-73',
    label: 'Kébili',
    country: 'TN',
  },
  {
    code: 'TN-81',
    label: 'Gabès',
    country: 'TN',
  },
  {
    code: 'TN-82',
    label: 'Médenine',
    country: 'TN',
  },
  {
    code: 'TN-83',
    label: 'Tataouine',
    country: 'TN',
  },
  {
    code: 'TO-01',
    label: "'Eua",
    country: 'TO',
  },
  {
    code: 'TO-02',
    label: "Ha'apai",
    country: 'TO',
  },
  {
    code: 'TO-03',
    label: 'Niuas',
    country: 'TO',
  },
  {
    code: 'TO-04',
    label: 'Tongatapu',
    country: 'TO',
  },
  {
    code: 'TO-05',
    label: "Vava'u",
    country: 'TO',
  },
  {
    code: 'TR-01',
    label: 'Adana',
    country: 'TR',
  },
  {
    code: 'TR-02',
    label: 'Adıyaman',
    country: 'TR',
  },
  {
    code: 'TR-03',
    label: 'Afyonkarahisar',
    country: 'TR',
  },
  {
    code: 'TR-04',
    label: 'Ağrı',
    country: 'TR',
  },
  {
    code: 'TR-05',
    label: 'Amasya',
    country: 'TR',
  },
  {
    code: 'TR-06',
    label: 'Ankara',
    country: 'TR',
  },
  {
    code: 'TR-07',
    label: 'Antalya',
    country: 'TR',
  },
  {
    code: 'TR-08',
    label: 'Artvin',
    country: 'TR',
  },
  {
    code: 'TR-09',
    label: 'Aydın',
    country: 'TR',
  },
  {
    code: 'TR-10',
    label: 'Balıkesir',
    country: 'TR',
  },
  {
    code: 'TR-11',
    label: 'Bilecik',
    country: 'TR',
  },
  {
    code: 'TR-12',
    label: 'Bingöl',
    country: 'TR',
  },
  {
    code: 'TR-13',
    label: 'Bitlis',
    country: 'TR',
  },
  {
    code: 'TR-14',
    label: 'Bolu',
    country: 'TR',
  },
  {
    code: 'TR-15',
    label: 'Burdur',
    country: 'TR',
  },
  {
    code: 'TR-16',
    label: 'Bursa',
    country: 'TR',
  },
  {
    code: 'TR-17',
    label: 'Çanakkale',
    country: 'TR',
  },
  {
    code: 'TR-18',
    label: 'Çankırı',
    country: 'TR',
  },
  {
    code: 'TR-19',
    label: 'Çorum',
    country: 'TR',
  },
  {
    code: 'TR-20',
    label: 'Denizli',
    country: 'TR',
  },
  {
    code: 'TR-21',
    label: 'Diyarbakır',
    country: 'TR',
  },
  {
    code: 'TR-22',
    label: 'Edirne',
    country: 'TR',
  },
  {
    code: 'TR-23',
    label: 'Elazığ',
    country: 'TR',
  },
  {
    code: 'TR-24',
    label: 'Erzincan',
    country: 'TR',
  },
  {
    code: 'TR-25',
    label: 'Erzurum',
    country: 'TR',
  },
  {
    code: 'TR-26',
    label: 'Eskişehir',
    country: 'TR',
  },
  {
    code: 'TR-27',
    label: 'Gaziantep',
    country: 'TR',
  },
  {
    code: 'TR-28',
    label: 'Giresun',
    country: 'TR',
  },
  {
    code: 'TR-29',
    label: 'Gümüşhane',
    country: 'TR',
  },
  {
    code: 'TR-30',
    label: 'Hakkâri',
    country: 'TR',
  },
  {
    code: 'TR-31',
    label: 'Hatay',
    country: 'TR',
  },
  {
    code: 'TR-32',
    label: 'Isparta',
    country: 'TR',
  },
  {
    code: 'TR-33',
    label: 'Mersin',
    country: 'TR',
  },
  {
    code: 'TR-34',
    label: 'İstanbul',
    country: 'TR',
  },
  {
    code: 'TR-35',
    label: 'İzmir',
    country: 'TR',
  },
  {
    code: 'TR-36',
    label: 'Kars',
    country: 'TR',
  },
  {
    code: 'TR-37',
    label: 'Kastamonu',
    country: 'TR',
  },
  {
    code: 'TR-38',
    label: 'Kayseri',
    country: 'TR',
  },
  {
    code: 'TR-39',
    label: 'Kırklareli',
    country: 'TR',
  },
  {
    code: 'TR-40',
    label: 'Kırşehir',
    country: 'TR',
  },
  {
    code: 'TR-41',
    label: 'Kocaeli',
    country: 'TR',
  },
  {
    code: 'TR-42',
    label: 'Konya',
    country: 'TR',
  },
  {
    code: 'TR-43',
    label: 'Kütahya',
    country: 'TR',
  },
  {
    code: 'TR-44',
    label: 'Malatya',
    country: 'TR',
  },
  {
    code: 'TR-45',
    label: 'Manisa',
    country: 'TR',
  },
  {
    code: 'TR-46',
    label: 'Kahramanmaraş',
    country: 'TR',
  },
  {
    code: 'TR-47',
    label: 'Mardin',
    country: 'TR',
  },
  {
    code: 'TR-48',
    label: 'Muğla',
    country: 'TR',
  },
  {
    code: 'TR-49',
    label: 'Muş',
    country: 'TR',
  },
  {
    code: 'TR-50',
    label: 'Nevşehir',
    country: 'TR',
  },
  {
    code: 'TR-51',
    label: 'Niğde',
    country: 'TR',
  },
  {
    code: 'TR-52',
    label: 'Ordu',
    country: 'TR',
  },
  {
    code: 'TR-53',
    label: 'Rize',
    country: 'TR',
  },
  {
    code: 'TR-54',
    label: 'Sakarya',
    country: 'TR',
  },
  {
    code: 'TR-55',
    label: 'Samsun',
    country: 'TR',
  },
  {
    code: 'TR-56',
    label: 'Siirt',
    country: 'TR',
  },
  {
    code: 'TR-57',
    label: 'Sinop',
    country: 'TR',
  },
  {
    code: 'TR-58',
    label: 'Sivas',
    country: 'TR',
  },
  {
    code: 'TR-59',
    label: 'Tekirdağ',
    country: 'TR',
  },
  {
    code: 'TR-60',
    label: 'Tokat',
    country: 'TR',
  },
  {
    code: 'TR-61',
    label: 'Trabzon',
    country: 'TR',
  },
  {
    code: 'TR-62',
    label: 'Tunceli',
    country: 'TR',
  },
  {
    code: 'TR-63',
    label: 'Şanlıurfa',
    country: 'TR',
  },
  {
    code: 'TR-64',
    label: 'Uşak',
    country: 'TR',
  },
  {
    code: 'TR-65',
    label: 'Van',
    country: 'TR',
  },
  {
    code: 'TR-66',
    label: 'Yozgat',
    country: 'TR',
  },
  {
    code: 'TR-67',
    label: 'Zonguldak',
    country: 'TR',
  },
  {
    code: 'TR-68',
    label: 'Aksaray',
    country: 'TR',
  },
  {
    code: 'TR-69',
    label: 'Bayburt',
    country: 'TR',
  },
  {
    code: 'TR-70',
    label: 'Karaman',
    country: 'TR',
  },
  {
    code: 'TR-71',
    label: 'Kırıkkale',
    country: 'TR',
  },
  {
    code: 'TR-72',
    label: 'Batman',
    country: 'TR',
  },
  {
    code: 'TR-73',
    label: 'Şırnak',
    country: 'TR',
  },
  {
    code: 'TR-74',
    label: 'Bartın',
    country: 'TR',
  },
  {
    code: 'TR-75',
    label: 'Ardahan',
    country: 'TR',
  },
  {
    code: 'TR-76',
    label: 'Iğdır',
    country: 'TR',
  },
  {
    code: 'TR-77',
    label: 'Yalova',
    country: 'TR',
  },
  {
    code: 'TR-78',
    label: 'Karabük',
    country: 'TR',
  },
  {
    code: 'TR-79',
    label: 'Kilis',
    country: 'TR',
  },
  {
    code: 'TR-80',
    label: 'Osmaniye',
    country: 'TR',
  },
  {
    code: 'TR-81',
    label: 'Düzce',
    country: 'TR',
  },
  {
    code: 'TT-ARI',
    label: 'Arima',
    country: 'TT',
  },
  {
    code: 'TT-CHA',
    label: 'Chaguanas',
    country: 'TT',
  },
  {
    code: 'TT-CTT',
    label: 'Couva–Tabaquite–Talparo',
    country: 'TT',
  },
  {
    code: 'TT-DMN',
    label: 'Diego Martin',
    country: 'TT',
  },
  {
    code: 'TT-MRC',
    label: 'Mayaro-Rio Claro',
    country: 'TT',
  },
  {
    code: 'TT-PED',
    label: 'Penal-Debe',
    country: 'TT',
  },
  {
    code: 'TT-POS',
    label: 'Port of Spain',
    country: 'TT',
  },
  {
    code: 'TT-PRT',
    label: 'Princes Town',
    country: 'TT',
  },
  {
    code: 'TT-PTF',
    label: 'Point Fortin',
    country: 'TT',
  },
  {
    code: 'TT-SFO',
    label: 'San Fernando',
    country: 'TT',
  },
  {
    code: 'TT-SGE',
    label: 'Sangre Grande',
    country: 'TT',
  },
  {
    code: 'TT-SIP',
    label: 'Siparia',
    country: 'TT',
  },
  {
    code: 'TT-SJL',
    label: 'San Juan-Laventille',
    country: 'TT',
  },
  {
    code: 'TT-TOB',
    label: 'Tobago',
    country: 'TT',
  },
  {
    code: 'TT-TUP',
    label: 'Tunapuna-Piarco',
    country: 'TT',
  },
  {
    code: 'TV-FUN',
    label: 'Funafuti',
    country: 'TV',
  },
  {
    code: 'TV-NIT',
    label: 'Niutao',
    country: 'TV',
  },
  {
    code: 'TV-NKF',
    label: 'Nukufetau',
    country: 'TV',
  },
  {
    code: 'TV-NKL',
    label: 'Nukulaelae',
    country: 'TV',
  },
  {
    code: 'TV-NMA',
    label: 'Nanumea',
    country: 'TV',
  },
  {
    code: 'TV-NMG',
    label: 'Nanumaga',
    country: 'TV',
  },
  {
    code: 'TV-NUI',
    label: 'Nui',
    country: 'TV',
  },
  {
    code: 'TV-VAI',
    label: 'Vaitupu',
    country: 'TV',
  },
  {
    code: 'TW-CHA',
    label: 'Changhua',
    country: 'TW',
  },
  {
    code: 'TW-CYI',
    label: 'Chiayi',
    country: 'TW',
  },
  {
    code: 'TW-CYQ',
    label: 'Chiayi',
    country: 'TW',
  },
  {
    code: 'TW-HSQ',
    label: 'Hsinchu',
    country: 'TW',
  },
  {
    code: 'TW-HSZ',
    label: 'Hsinchu',
    country: 'TW',
  },
  {
    code: 'TW-HUA',
    label: 'Hualien',
    country: 'TW',
  },
  {
    code: 'TW-ILA',
    label: 'Yilan',
    country: 'TW',
  },
  {
    code: 'TW-KEE',
    label: 'Keelung',
    country: 'TW',
  },
  {
    code: 'TW-KHH',
    label: 'Kaohsiung',
    country: 'TW',
  },
  {
    code: 'TW-KIN',
    label: 'Kinmen',
    country: 'TW',
  },
  {
    code: 'TW-LIE',
    label: 'Lienchiang',
    country: 'TW',
  },
  {
    code: 'TW-MIA',
    label: 'Miaoli',
    country: 'TW',
  },
  {
    code: 'TW-NAN',
    label: 'Nantou',
    country: 'TW',
  },
  {
    code: 'TW-NWT',
    label: 'New Taipei',
    country: 'TW',
  },
  {
    code: 'TW-PEN',
    label: 'Penghu',
    country: 'TW',
  },
  {
    code: 'TW-PIF',
    label: 'Pingtung',
    country: 'TW',
  },
  {
    code: 'TW-TAO',
    label: 'Taoyuan',
    country: 'TW',
  },
  {
    code: 'TW-TNN',
    label: 'Tainan',
    country: 'TW',
  },
  {
    code: 'TW-TPE',
    label: 'Taipei',
    country: 'TW',
  },
  {
    code: 'TW-TTT',
    label: 'Taitung',
    country: 'TW',
  },
  {
    code: 'TW-TXG',
    label: 'Taichung',
    country: 'TW',
  },
  {
    code: 'TW-YUN',
    label: 'Yunlin',
    country: 'TW',
  },
  {
    code: 'TZ-01',
    label: 'Arusha',
    country: 'TZ',
  },
  {
    code: 'TZ-02',
    label: 'Dar es Salaam',
    country: 'TZ',
  },
  {
    code: 'TZ-03',
    label: 'Dodoma',
    country: 'TZ',
  },
  {
    code: 'TZ-04',
    label: 'Iringa',
    country: 'TZ',
  },
  {
    code: 'TZ-05',
    label: 'Kagera',
    country: 'TZ',
  },
  {
    code: 'TZ-06',
    label: 'Kaskazini Pemba',
    country: 'TZ',
  },
  {
    code: 'TZ-07',
    label: 'Kaskazini Unguja',
    country: 'TZ',
  },
  {
    code: 'TZ-08',
    label: 'Kigoma',
    country: 'TZ',
  },
  {
    code: 'TZ-09',
    label: 'Kilimanjaro',
    country: 'TZ',
  },
  {
    code: 'TZ-10',
    label: 'Kusini Pemba',
    country: 'TZ',
  },
  {
    code: 'TZ-11',
    label: 'Kusini Unguja',
    country: 'TZ',
  },
  {
    code: 'TZ-12',
    label: 'Lindi',
    country: 'TZ',
  },
  {
    code: 'TZ-13',
    label: 'Mara',
    country: 'TZ',
  },
  {
    code: 'TZ-14',
    label: 'Mbeya',
    country: 'TZ',
  },
  {
    code: 'TZ-15',
    label: 'Mjini Magharibi',
    country: 'TZ',
  },
  {
    code: 'TZ-16',
    label: 'Morogoro',
    country: 'TZ',
  },
  {
    code: 'TZ-17',
    label: 'Mtwara',
    country: 'TZ',
  },
  {
    code: 'TZ-18',
    label: 'Mwanza',
    country: 'TZ',
  },
  {
    code: 'TZ-19',
    label: 'Pwani',
    country: 'TZ',
  },
  {
    code: 'TZ-20',
    label: 'Rukwa',
    country: 'TZ',
  },
  {
    code: 'TZ-21',
    label: 'Ruvuma',
    country: 'TZ',
  },
  {
    code: 'TZ-22',
    label: 'Shinyanga',
    country: 'TZ',
  },
  {
    code: 'TZ-23',
    label: 'Singida',
    country: 'TZ',
  },
  {
    code: 'TZ-24',
    label: 'Tabora',
    country: 'TZ',
  },
  {
    code: 'TZ-25',
    label: 'Tanga',
    country: 'TZ',
  },
  {
    code: 'TZ-26',
    label: 'Manyara',
    country: 'TZ',
  },
  {
    code: 'TZ-27',
    label: 'Geita',
    country: 'TZ',
  },
  {
    code: 'TZ-28',
    label: 'Katavi',
    country: 'TZ',
  },
  {
    code: 'TZ-29',
    label: 'Njombe',
    country: 'TZ',
  },
  {
    code: 'TZ-30',
    label: 'Simiyu',
    country: 'TZ',
  },
  {
    code: 'TZ-31',
    label: 'Songwe',
    country: 'TZ',
  },
  {
    code: 'UA-05',
    label: 'Vinnytska oblast',
    country: 'UA',
  },
  {
    code: 'UA-07',
    label: 'Volynska oblast',
    country: 'UA',
  },
  {
    code: 'UA-09',
    label: 'Luhanska oblast',
    country: 'UA',
  },
  {
    code: 'UA-12',
    label: 'Dnipropetrovska oblast',
    country: 'UA',
  },
  {
    code: 'UA-14',
    label: 'Donetska oblast',
    country: 'UA',
  },
  {
    code: 'UA-18',
    label: 'Zhytomyrska oblast',
    country: 'UA',
  },
  {
    code: 'UA-21',
    label: 'Zakarpatska oblast',
    country: 'UA',
  },
  {
    code: 'UA-23',
    label: 'Zaporizka oblast',
    country: 'UA',
  },
  {
    code: 'UA-26',
    label: 'Ivano-Frankivska oblast',
    country: 'UA',
  },
  {
    code: 'UA-30',
    label: 'Kyiv',
    country: 'UA',
  },
  {
    code: 'UA-32',
    label: 'Kyivska oblast',
    country: 'UA',
  },
  {
    code: 'UA-35',
    label: 'Kirovohradska oblast',
    country: 'UA',
  },
  {
    code: 'UA-40',
    label: 'Sevastopol',
    country: 'UA',
  },
  {
    code: 'UA-43',
    label: 'Avtonomna Respublika Krym',
    country: 'UA',
  },
  {
    code: 'UA-46',
    label: 'Lvivska oblast',
    country: 'UA',
  },
  {
    code: 'UA-48',
    label: 'Mykolaivska oblast',
    country: 'UA',
  },
  {
    code: 'UA-51',
    label: 'Odeska oblast',
    country: 'UA',
  },
  {
    code: 'UA-53',
    label: 'Poltavska oblast',
    country: 'UA',
  },
  {
    code: 'UA-56',
    label: 'Rivnenska oblast',
    country: 'UA',
  },
  {
    code: 'UA-59',
    label: 'Sumska oblast',
    country: 'UA',
  },
  {
    code: 'UA-61',
    label: 'Ternopilska oblast',
    country: 'UA',
  },
  {
    code: 'UA-63',
    label: 'Kharkivska oblast',
    country: 'UA',
  },
  {
    code: 'UA-65',
    label: 'Khersonska oblast',
    country: 'UA',
  },
  {
    code: 'UA-68',
    label: 'Khmelnytska oblast',
    country: 'UA',
  },
  {
    code: 'UA-71',
    label: 'Cherkaska oblast',
    country: 'UA',
  },
  {
    code: 'UA-74',
    label: 'Chernihivska oblast',
    country: 'UA',
  },
  {
    code: 'UA-77',
    label: 'Chernivetska oblast',
    country: 'UA',
  },
  {
    code: 'UG-101',
    label: 'Kalangala',
    country: 'UG-C',
  },
  {
    code: 'UG-102',
    label: 'Kampala',
    country: 'UG-C',
  },
  {
    code: 'UG-103',
    label: 'Kiboga',
    country: 'UG-C',
  },
  {
    code: 'UG-104',
    label: 'Luwero',
    country: 'UG-C',
  },
  {
    code: 'UG-105',
    label: 'Masaka',
    country: 'UG-C',
  },
  {
    code: 'UG-106',
    label: 'Mpigi',
    country: 'UG-C',
  },
  {
    code: 'UG-107',
    label: 'Mubende',
    country: 'UG-C',
  },
  {
    code: 'UG-108',
    label: 'Mukono',
    country: 'UG-C',
  },
  {
    code: 'UG-109',
    label: 'Nakasongola',
    country: 'UG-C',
  },
  {
    code: 'UG-110',
    label: 'Rakai',
    country: 'UG-C',
  },
  {
    code: 'UG-111',
    label: 'Sembabule',
    country: 'UG-C',
  },
  {
    code: 'UG-112',
    label: 'Kayunga',
    country: 'UG-C',
  },
  {
    code: 'UG-113',
    label: 'Wakiso',
    country: 'UG-C',
  },
  {
    code: 'UG-114',
    label: 'Lyantonde',
    country: 'UG-C',
  },
  {
    code: 'UG-115',
    label: 'Mityana',
    country: 'UG-C',
  },
  {
    code: 'UG-116',
    label: 'Nakaseke',
    country: 'UG-C',
  },
  {
    code: 'UG-117',
    label: 'Buikwe',
    country: 'UG-C',
  },
  {
    code: 'UG-118',
    label: 'Bukomansibi',
    country: 'UG-C',
  },
  {
    code: 'UG-119',
    label: 'Butambala',
    country: 'UG-C',
  },
  {
    code: 'UG-120',
    label: 'Buvuma',
    country: 'UG-C',
  },
  {
    code: 'UG-121',
    label: 'Gomba',
    country: 'UG-C',
  },
  {
    code: 'UG-122',
    label: 'Kalungu',
    country: 'UG-C',
  },
  {
    code: 'UG-123',
    label: 'Kyankwanzi',
    country: 'UG-C',
  },
  {
    code: 'UG-124',
    label: 'Lwengo',
    country: 'UG-C',
  },
  {
    code: 'UG-125',
    label: 'Kyotera',
    country: 'UG-C',
  },
  {
    code: 'UG-126',
    label: 'Kasanda',
    country: 'UG-C',
  },
  {
    code: 'UG-201',
    label: 'Bugiri',
    country: 'UG-E',
  },
  {
    code: 'UG-202',
    label: 'Busia',
    country: 'UG-E',
  },
  {
    code: 'UG-203',
    label: 'Iganga',
    country: 'UG-E',
  },
  {
    code: 'UG-204',
    label: 'Jinja',
    country: 'UG-E',
  },
  {
    code: 'UG-205',
    label: 'Kamuli',
    country: 'UG-E',
  },
  {
    code: 'UG-206',
    label: 'Kapchorwa',
    country: 'UG-E',
  },
  {
    code: 'UG-207',
    label: 'Katakwi',
    country: 'UG-E',
  },
  {
    code: 'UG-208',
    label: 'Kumi',
    country: 'UG-E',
  },
  {
    code: 'UG-209',
    label: 'Mbale',
    country: 'UG-E',
  },
  {
    code: 'UG-210',
    label: 'Pallisa',
    country: 'UG-E',
  },
  {
    code: 'UG-211',
    label: 'Soroti',
    country: 'UG-E',
  },
  {
    code: 'UG-212',
    label: 'Tororo',
    country: 'UG-E',
  },
  {
    code: 'UG-213',
    label: 'Kaberamaido',
    country: 'UG-E',
  },
  {
    code: 'UG-214',
    label: 'Mayuge',
    country: 'UG-E',
  },
  {
    code: 'UG-215',
    label: 'Sironko',
    country: 'UG-E',
  },
  {
    code: 'UG-216',
    label: 'Amuria',
    country: 'UG-E',
  },
  {
    code: 'UG-217',
    label: 'Budaka',
    country: 'UG-E',
  },
  {
    code: 'UG-218',
    label: 'Bududa',
    country: 'UG-E',
  },
  {
    code: 'UG-219',
    label: 'Bukedea',
    country: 'UG-E',
  },
  {
    code: 'UG-220',
    label: 'Bukwo',
    country: 'UG-E',
  },
  {
    code: 'UG-221',
    label: 'Butaleja',
    country: 'UG-E',
  },
  {
    code: 'UG-222',
    label: 'Kaliro',
    country: 'UG-E',
  },
  {
    code: 'UG-223',
    label: 'Manafwa',
    country: 'UG-E',
  },
  {
    code: 'UG-224',
    label: 'Namutumba',
    country: 'UG-E',
  },
  {
    code: 'UG-225',
    label: 'Bulambuli',
    country: 'UG-E',
  },
  {
    code: 'UG-226',
    label: 'Buyende',
    country: 'UG-E',
  },
  {
    code: 'UG-227',
    label: 'Kibuku',
    country: 'UG-E',
  },
  {
    code: 'UG-228',
    label: 'Kween',
    country: 'UG-E',
  },
  {
    code: 'UG-229',
    label: 'Luuka',
    country: 'UG-E',
  },
  {
    code: 'UG-230',
    label: 'Namayingo',
    country: 'UG-E',
  },
  {
    code: 'UG-231',
    label: 'Ngora',
    country: 'UG-E',
  },
  {
    code: 'UG-232',
    label: 'Serere',
    country: 'UG-E',
  },
  {
    code: 'UG-233',
    label: 'Butebo',
    country: 'UG-E',
  },
  {
    code: 'UG-234',
    label: 'Namisindwa',
    country: 'UG-E',
  },
  {
    code: 'UG-235',
    label: 'Bugweri',
    country: 'UG-E',
  },
  {
    code: 'UG-236',
    label: 'Kapelebyong',
    country: 'UG-E',
  },
  {
    code: 'UG-237',
    label: 'Kalaki',
    country: 'UG-E',
  },
  {
    code: 'UG-301',
    label: 'Adjumani',
    country: 'UG-N',
  },
  {
    code: 'UG-302',
    label: 'Apac',
    country: 'UG-N',
  },
  {
    code: 'UG-303',
    label: 'Arua',
    country: 'UG-N',
  },
  {
    code: 'UG-304',
    label: 'Gulu',
    country: 'UG-N',
  },
  {
    code: 'UG-305',
    label: 'Kitgum',
    country: 'UG-N',
  },
  {
    code: 'UG-306',
    label: 'Kotido',
    country: 'UG-N',
  },
  {
    code: 'UG-307',
    label: 'Lira',
    country: 'UG-N',
  },
  {
    code: 'UG-308',
    label: 'Moroto',
    country: 'UG-N',
  },
  {
    code: 'UG-309',
    label: 'Moyo',
    country: 'UG-N',
  },
  {
    code: 'UG-310',
    label: 'Nebbi',
    country: 'UG-N',
  },
  {
    code: 'UG-311',
    label: 'Nakapiripirit',
    country: 'UG-N',
  },
  {
    code: 'UG-312',
    label: 'Pader',
    country: 'UG-N',
  },
  {
    code: 'UG-313',
    label: 'Yumbe',
    country: 'UG-N',
  },
  {
    code: 'UG-314',
    label: 'Abim',
    country: 'UG-N',
  },
  {
    code: 'UG-315',
    label: 'Amolatar',
    country: 'UG-N',
  },
  {
    code: 'UG-316',
    label: 'Amuru',
    country: 'UG-N',
  },
  {
    code: 'UG-317',
    label: 'Dokolo',
    country: 'UG-N',
  },
  {
    code: 'UG-318',
    label: 'Kaabong',
    country: 'UG-N',
  },
  {
    code: 'UG-319',
    label: 'Koboko',
    country: 'UG-N',
  },
  {
    code: 'UG-320',
    label: 'Maracha',
    country: 'UG-N',
  },
  {
    code: 'UG-321',
    label: 'Oyam',
    country: 'UG-N',
  },
  {
    code: 'UG-322',
    label: 'Agago',
    country: 'UG-N',
  },
  {
    code: 'UG-323',
    label: 'Alebtong',
    country: 'UG-N',
  },
  {
    code: 'UG-324',
    label: 'Amudat',
    country: 'UG-N',
  },
  {
    code: 'UG-325',
    label: 'Kole',
    country: 'UG-N',
  },
  {
    code: 'UG-326',
    label: 'Lamwo',
    country: 'UG-N',
  },
  {
    code: 'UG-327',
    label: 'Napak',
    country: 'UG-N',
  },
  {
    code: 'UG-328',
    label: 'Nwoya',
    country: 'UG-N',
  },
  {
    code: 'UG-329',
    label: 'Otuke',
    country: 'UG-N',
  },
  {
    code: 'UG-330',
    label: 'Zombo',
    country: 'UG-N',
  },
  {
    code: 'UG-331',
    label: 'Omoro',
    country: 'UG-N',
  },
  {
    code: 'UG-332',
    label: 'Pakwach',
    country: 'UG-N',
  },
  {
    code: 'UG-333',
    label: 'Kwania',
    country: 'UG-N',
  },
  {
    code: 'UG-334',
    label: 'Nabilatuk',
    country: 'UG-N',
  },
  {
    code: 'UG-335',
    label: 'Karenga',
    country: 'UG-N',
  },
  {
    code: 'UG-336',
    label: 'Madi-Okollo',
    country: 'UG-N',
  },
  {
    code: 'UG-337',
    label: 'Obongi',
    country: 'UG-N',
  },
  {
    code: 'UG-401',
    label: 'Bundibugyo',
    country: 'UG-W',
  },
  {
    code: 'UG-402',
    label: 'Bushenyi',
    country: 'UG-W',
  },
  {
    code: 'UG-403',
    label: 'Hoima',
    country: 'UG-W',
  },
  {
    code: 'UG-404',
    label: 'Kabale',
    country: 'UG-W',
  },
  {
    code: 'UG-405',
    label: 'Kabarole',
    country: 'UG-W',
  },
  {
    code: 'UG-406',
    label: 'Kasese',
    country: 'UG-W',
  },
  {
    code: 'UG-407',
    label: 'Kibaale',
    country: 'UG-W',
  },
  {
    code: 'UG-408',
    label: 'Kisoro',
    country: 'UG-W',
  },
  {
    code: 'UG-409',
    label: 'Masindi',
    country: 'UG-W',
  },
  {
    code: 'UG-410',
    label: 'Mbarara',
    country: 'UG-W',
  },
  {
    code: 'UG-411',
    label: 'Ntungamo',
    country: 'UG-W',
  },
  {
    code: 'UG-412',
    label: 'Rukungiri',
    country: 'UG-W',
  },
  {
    code: 'UG-413',
    label: 'Kamwenge',
    country: 'UG-W',
  },
  {
    code: 'UG-414',
    label: 'Kanungu',
    country: 'UG-W',
  },
  {
    code: 'UG-415',
    label: 'Kyenjojo',
    country: 'UG-W',
  },
  {
    code: 'UG-416',
    label: 'Buliisa',
    country: 'UG-W',
  },
  {
    code: 'UG-417',
    label: 'Ibanda',
    country: 'UG-W',
  },
  {
    code: 'UG-418',
    label: 'Isingiro',
    country: 'UG-W',
  },
  {
    code: 'UG-419',
    label: 'Kiruhura',
    country: 'UG-W',
  },
  {
    code: 'UG-420',
    label: 'Buhweju',
    country: 'UG-W',
  },
  {
    code: 'UG-421',
    label: 'Kiryandongo',
    country: 'UG-W',
  },
  {
    code: 'UG-422',
    label: 'Kyegegwa',
    country: 'UG-W',
  },
  {
    code: 'UG-423',
    label: 'Mitooma',
    country: 'UG-W',
  },
  {
    code: 'UG-424',
    label: 'Ntoroko',
    country: 'UG-W',
  },
  {
    code: 'UG-425',
    label: 'Rubirizi',
    country: 'UG-W',
  },
  {
    code: 'UG-426',
    label: 'Sheema',
    country: 'UG-W',
  },
  {
    code: 'UG-427',
    label: 'Kagadi',
    country: 'UG-W',
  },
  {
    code: 'UG-428',
    label: 'Kakumiro',
    country: 'UG-W',
  },
  {
    code: 'UG-429',
    label: 'Rubanda',
    country: 'UG-W',
  },
  {
    code: 'UG-430',
    label: 'Bunyangabu',
    country: 'UG-W',
  },
  {
    code: 'UG-431',
    label: 'Rukiga',
    country: 'UG-W',
  },
  {
    code: 'UG-432',
    label: 'Kikuube',
    country: 'UG-W',
  },
  {
    code: 'UG-433',
    label: 'Kazo',
    country: 'UG-W',
  },
  {
    code: 'UG-434',
    label: 'Kitagwenda',
    country: 'UG-W',
  },
  {
    code: 'UG-435',
    label: 'Rwampara',
    country: 'UG-W',
  },
  {
    code: 'UG-C',
    label: 'Central',
    country: 'UG',
  },
  {
    code: 'UG-E',
    label: 'Eastern',
    country: 'UG',
  },
  {
    code: 'UG-N',
    label: 'Northern',
    country: 'UG',
  },
  {
    code: 'UG-W',
    label: 'Western',
    country: 'UG',
  },
  {
    code: 'UM-67',
    label: 'Johnston Atoll',
    country: 'UM',
  },
  {
    code: 'UM-71',
    label: 'Midway Islands',
    country: 'UM',
  },
  {
    code: 'UM-76',
    label: 'Navassa Island',
    country: 'UM',
  },
  {
    code: 'UM-79',
    label: 'Wake Island',
    country: 'UM',
  },
  {
    code: 'UM-81',
    label: 'Baker Island',
    country: 'UM',
  },
  {
    code: 'UM-84',
    label: 'Howland Island',
    country: 'UM',
  },
  {
    code: 'UM-86',
    label: 'Jarvis Island',
    country: 'UM',
  },
  {
    code: 'UM-89',
    label: 'Kingman Reef',
    country: 'UM',
  },
  {
    code: 'UM-95',
    label: 'Palmyra Atoll',
    country: 'UM',
  },
  {
    code: 'US-AK',
    label: 'Alaska',
    country: 'US',
  },
  {
    code: 'US-AL',
    label: 'Alabama',
    country: 'US',
  },
  {
    code: 'US-AR',
    label: 'Arkansas',
    country: 'US',
  },
  {
    code: 'US-AS',
    label: 'American Samoa',
    country: 'US',
  },
  {
    code: 'US-AZ',
    label: 'Arizona',
    country: 'US',
  },
  {
    code: 'US-CA',
    label: 'California',
    country: 'US',
  },
  {
    code: 'US-CO',
    label: 'Colorado',
    country: 'US',
  },
  {
    code: 'US-CT',
    label: 'Connecticut',
    country: 'US',
  },
  {
    code: 'US-DC',
    label: 'District of Columbia',
    country: 'US',
  },
  {
    code: 'US-DE',
    label: 'Delaware',
    country: 'US',
  },
  {
    code: 'US-FL',
    label: 'Florida',
    country: 'US',
  },
  {
    code: 'US-GA',
    label: 'Georgia',
    country: 'US',
  },
  {
    code: 'US-GU',
    label: 'Guam',
    country: 'US',
  },
  {
    code: 'US-HI',
    label: 'Hawaii',
    country: 'US',
  },
  {
    code: 'US-IA',
    label: 'Iowa',
    country: 'US',
  },
  {
    code: 'US-ID',
    label: 'Idaho',
    country: 'US',
  },
  {
    code: 'US-IL',
    label: 'Illinois',
    country: 'US',
  },
  {
    code: 'US-IN',
    label: 'Indiana',
    country: 'US',
  },
  {
    code: 'US-KS',
    label: 'Kansas',
    country: 'US',
  },
  {
    code: 'US-KY',
    label: 'Kentucky',
    country: 'US',
  },
  {
    code: 'US-LA',
    label: 'Louisiana',
    country: 'US',
  },
  {
    code: 'US-MA',
    label: 'Massachusetts',
    country: 'US',
  },
  {
    code: 'US-MD',
    label: 'Maryland',
    country: 'US',
  },
  {
    code: 'US-ME',
    label: 'Maine',
    country: 'US',
  },
  {
    code: 'US-MI',
    label: 'Michigan',
    country: 'US',
  },
  {
    code: 'US-MN',
    label: 'Minnesota',
    country: 'US',
  },
  {
    code: 'US-MO',
    label: 'Missouri',
    country: 'US',
  },
  {
    code: 'US-MP',
    label: 'Northern Mariana Islands',
    country: 'US',
  },
  {
    code: 'US-MS',
    label: 'Mississippi',
    country: 'US',
  },
  {
    code: 'US-MT',
    label: 'Montana',
    country: 'US',
  },
  {
    code: 'US-NC',
    label: 'North Carolina',
    country: 'US',
  },
  {
    code: 'US-ND',
    label: 'North Dakota',
    country: 'US',
  },
  {
    code: 'US-NE',
    label: 'Nebraska',
    country: 'US',
  },
  {
    code: 'US-NH',
    label: 'New Hampshire',
    country: 'US',
  },
  {
    code: 'US-NJ',
    label: 'New Jersey',
    country: 'US',
  },
  {
    code: 'US-NM',
    label: 'New Mexico',
    country: 'US',
  },
  {
    code: 'US-NV',
    label: 'Nevada',
    country: 'US',
  },
  {
    code: 'US-NY',
    label: 'New York',
    country: 'US',
  },
  {
    code: 'US-OH',
    label: 'Ohio',
    country: 'US',
  },
  {
    code: 'US-OK',
    label: 'Oklahoma',
    country: 'US',
  },
  {
    code: 'US-OR',
    label: 'Oregon',
    country: 'US',
  },
  {
    code: 'US-PA',
    label: 'Pennsylvania',
    country: 'US',
  },
  {
    code: 'US-PR',
    label: 'Puerto Rico',
    country: 'US',
  },
  {
    code: 'US-RI',
    label: 'Rhode Island',
    country: 'US',
  },
  {
    code: 'US-SC',
    label: 'South Carolina',
    country: 'US',
  },
  {
    code: 'US-SD',
    label: 'South Dakota',
    country: 'US',
  },
  {
    code: 'US-TN',
    label: 'Tennessee',
    country: 'US',
  },
  {
    code: 'US-TX',
    label: 'Texas',
    country: 'US',
  },
  {
    code: 'US-UM',
    label: 'United States Minor Outlying Islands',
    country: 'US',
  },
  {
    code: 'US-UT',
    label: 'Utah',
    country: 'US',
  },
  {
    code: 'US-VA',
    label: 'Virginia',
    country: 'US',
  },
  {
    code: 'US-VI',
    label: 'U.S. Virgin Islands',
    country: 'US',
  },
  {
    code: 'US-VT',
    label: 'Vermont',
    country: 'US',
  },
  {
    code: 'US-WA',
    label: 'Washington',
    country: 'US',
  },
  {
    code: 'US-WI',
    label: 'Wisconsin',
    country: 'US',
  },
  {
    code: 'US-WV',
    label: 'West Virginia',
    country: 'US',
  },
  {
    code: 'US-WY',
    label: 'Wyoming',
    country: 'US',
  },
  {
    code: 'UY-AR',
    label: 'Artigas',
    country: 'UY',
  },
  {
    code: 'UY-CA',
    label: 'Canelones',
    country: 'UY',
  },
  {
    code: 'UY-CL',
    label: 'Cerro Largo',
    country: 'UY',
  },
  {
    code: 'UY-CO',
    label: 'Colonia',
    country: 'UY',
  },
  {
    code: 'UY-DU',
    label: 'Durazno',
    country: 'UY',
  },
  {
    code: 'UY-FD',
    label: 'Florida',
    country: 'UY',
  },
  {
    code: 'UY-FS',
    label: 'Flores',
    country: 'UY',
  },
  {
    code: 'UY-LA',
    label: 'Lavalleja',
    country: 'UY',
  },
  {
    code: 'UY-MA',
    label: 'Maldonado',
    country: 'UY',
  },
  {
    code: 'UY-MO',
    label: 'Montevideo',
    country: 'UY',
  },
  {
    code: 'UY-PA',
    label: 'Paysandú',
    country: 'UY',
  },
  {
    code: 'UY-RN',
    label: 'Río Negro',
    country: 'UY',
  },
  {
    code: 'UY-RO',
    label: 'Rocha',
    country: 'UY',
  },
  {
    code: 'UY-RV',
    label: 'Rivera',
    country: 'UY',
  },
  {
    code: 'UY-SA',
    label: 'Salto',
    country: 'UY',
  },
  {
    code: 'UY-SJ',
    label: 'San José',
    country: 'UY',
  },
  {
    code: 'UY-SO',
    label: 'Soriano',
    country: 'UY',
  },
  {
    code: 'UY-TA',
    label: 'Tacuarembó',
    country: 'UY',
  },
  {
    code: 'UY-TT',
    label: 'Treinta y Tres',
    country: 'UY',
  },
  {
    code: 'UZ-AN',
    label: 'Andijon',
    country: 'UZ',
  },
  {
    code: 'UZ-BU',
    label: 'Buxoro',
    country: 'UZ',
  },
  {
    code: 'UZ-FA',
    label: 'Farg‘ona',
    country: 'UZ',
  },
  {
    code: 'UZ-JI',
    label: 'Jizzax',
    country: 'UZ',
  },
  {
    code: 'UZ-NG',
    label: 'Namangan',
    country: 'UZ',
  },
  {
    code: 'UZ-NW',
    label: 'Navoiy',
    country: 'UZ',
  },
  {
    code: 'UZ-QA',
    label: 'Qashqadaryo',
    country: 'UZ',
  },
  {
    code: 'UZ-QR',
    label: 'Qoraqalpog‘iston Respublikasi',
    country: 'UZ',
  },
  {
    code: 'UZ-SA',
    label: 'Samarqand',
    country: 'UZ',
  },
  {
    code: 'UZ-SI',
    label: 'Sirdaryo',
    country: 'UZ',
  },
  {
    code: 'UZ-SU',
    label: 'Surxondaryo',
    country: 'UZ',
  },
  {
    code: 'UZ-TK',
    label: 'Toshkent',
    country: 'UZ',
  },
  {
    code: 'UZ-TO',
    label: 'Toshkent',
    country: 'UZ',
  },
  {
    code: 'UZ-XO',
    label: 'Xorazm',
    country: 'UZ',
  },
  {
    code: 'VC-01',
    label: 'Charlotte',
    country: 'VC',
  },
  {
    code: 'VC-02',
    label: 'Saint Andrew',
    country: 'VC',
  },
  {
    code: 'VC-03',
    label: 'Saint David',
    country: 'VC',
  },
  {
    code: 'VC-04',
    label: 'Saint George',
    country: 'VC',
  },
  {
    code: 'VC-05',
    label: 'Saint Patrick',
    country: 'VC',
  },
  {
    code: 'VC-06',
    label: 'Grenadines',
    country: 'VC',
  },
  {
    code: 'VE-A',
    label: 'Distrito Capital',
    country: 'VE',
  },
  {
    code: 'VE-B',
    label: 'Anzoátegui',
    country: 'VE',
  },
  {
    code: 'VE-C',
    label: 'Apure',
    country: 'VE',
  },
  {
    code: 'VE-D',
    label: 'Aragua',
    country: 'VE',
  },
  {
    code: 'VE-E',
    label: 'Barinas',
    country: 'VE',
  },
  {
    code: 'VE-F',
    label: 'Bolívar',
    country: 'VE',
  },
  {
    code: 'VE-G',
    label: 'Carabobo',
    country: 'VE',
  },
  {
    code: 'VE-H',
    label: 'Cojedes',
    country: 'VE',
  },
  {
    code: 'VE-I',
    label: 'Falcón',
    country: 'VE',
  },
  {
    code: 'VE-J',
    label: 'Guárico',
    country: 'VE',
  },
  {
    code: 'VE-K',
    label: 'Lara',
    country: 'VE',
  },
  {
    code: 'VE-L',
    label: 'Mérida',
    country: 'VE',
  },
  {
    code: 'VE-M',
    label: 'Miranda',
    country: 'VE',
  },
  {
    code: 'VE-N',
    label: 'Monagas',
    country: 'VE',
  },
  {
    code: 'VE-O',
    label: 'Nueva Esparta',
    country: 'VE',
  },
  {
    code: 'VE-P',
    label: 'Portuguesa',
    country: 'VE',
  },
  {
    code: 'VE-R',
    label: 'Sucre',
    country: 'VE',
  },
  {
    code: 'VE-S',
    label: 'Táchira',
    country: 'VE',
  },
  {
    code: 'VE-T',
    label: 'Trujillo',
    country: 'VE',
  },
  {
    code: 'VE-U',
    label: 'Yaracuy',
    country: 'VE',
  },
  {
    code: 'VE-V',
    label: 'Zulia',
    country: 'VE',
  },
  {
    code: 'VE-W',
    label: 'Dependencias Federales',
    country: 'VE',
  },
  {
    code: 'VE-X',
    label: 'Vargas',
    country: 'VE',
  },
  {
    code: 'VE-Y',
    label: 'Delta Amacuro',
    country: 'VE',
  },
  {
    code: 'VE-Z',
    label: 'Amazonas',
    country: 'VE',
  },
  {
    code: 'VN-01',
    label: 'Lai Châu',
    country: 'VN',
  },
  {
    code: 'VN-02',
    label: 'Lào Cai',
    country: 'VN',
  },
  {
    code: 'VN-03',
    label: 'Hà Giang',
    country: 'VN',
  },
  {
    code: 'VN-04',
    label: 'Cao Bằng',
    country: 'VN',
  },
  {
    code: 'VN-05',
    label: 'Sơn La',
    country: 'VN',
  },
  {
    code: 'VN-06',
    label: 'Yên Bái',
    country: 'VN',
  },
  {
    code: 'VN-07',
    label: 'Tuyên Quang',
    country: 'VN',
  },
  {
    code: 'VN-09',
    label: 'Lạng Sơn',
    country: 'VN',
  },
  {
    code: 'VN-13',
    label: 'Quảng Ninh',
    country: 'VN',
  },
  {
    code: 'VN-14',
    label: 'Hòa Bình',
    country: 'VN',
  },
  {
    code: 'VN-18',
    label: 'Ninh Bình',
    country: 'VN',
  },
  {
    code: 'VN-20',
    label: 'Thái Bình',
    country: 'VN',
  },
  {
    code: 'VN-21',
    label: 'Thanh Hóa',
    country: 'VN',
  },
  {
    code: 'VN-22',
    label: 'Nghệ An',
    country: 'VN',
  },
  {
    code: 'VN-23',
    label: 'Hà Tĩnh',
    country: 'VN',
  },
  {
    code: 'VN-24',
    label: 'Quảng Bình',
    country: 'VN',
  },
  {
    code: 'VN-25',
    label: 'Quảng Trị',
    country: 'VN',
  },
  {
    code: 'VN-26',
    label: 'Thừa Thiên-Huế',
    country: 'VN',
  },
  {
    code: 'VN-27',
    label: 'Quảng Nam',
    country: 'VN',
  },
  {
    code: 'VN-28',
    label: 'Kon Tum',
    country: 'VN',
  },
  {
    code: 'VN-29',
    label: 'Quảng Ngãi',
    country: 'VN',
  },
  {
    code: 'VN-30',
    label: 'Gia Lai',
    country: 'VN',
  },
  {
    code: 'VN-31',
    label: 'Bình Định',
    country: 'VN',
  },
  {
    code: 'VN-32',
    label: 'Phú Yên',
    country: 'VN',
  },
  {
    code: 'VN-33',
    label: 'Đắk Lắk',
    country: 'VN',
  },
  {
    code: 'VN-34',
    label: 'Khánh Hòa',
    country: 'VN',
  },
  {
    code: 'VN-35',
    label: 'Lâm Đồng',
    country: 'VN',
  },
  {
    code: 'VN-36',
    label: 'Ninh Thuận',
    country: 'VN',
  },
  {
    code: 'VN-37',
    label: 'Tây Ninh',
    country: 'VN',
  },
  {
    code: 'VN-39',
    label: 'Đồng Nai',
    country: 'VN',
  },
  {
    code: 'VN-40',
    label: 'Bình Thuận',
    country: 'VN',
  },
  {
    code: 'VN-41',
    label: 'Long An',
    country: 'VN',
  },
  {
    code: 'VN-43',
    label: 'Bà Rịa - Vũng Tàu',
    country: 'VN',
  },
  {
    code: 'VN-44',
    label: 'An Giang',
    country: 'VN',
  },
  {
    code: 'VN-45',
    label: 'Đồng Tháp',
    country: 'VN',
  },
  {
    code: 'VN-46',
    label: 'Tiền Giang',
    country: 'VN',
  },
  {
    code: 'VN-47',
    label: 'Kiến Giang',
    country: 'VN',
  },
  {
    code: 'VN-49',
    label: 'Vĩnh Long',
    country: 'VN',
  },
  {
    code: 'VN-50',
    label: 'Bến Tre',
    country: 'VN',
  },
  {
    code: 'VN-51',
    label: 'Trà Vinh',
    country: 'VN',
  },
  {
    code: 'VN-52',
    label: 'Sóc Trăng',
    country: 'VN',
  },
  {
    code: 'VN-53',
    label: 'Bắc Kạn',
    country: 'VN',
  },
  {
    code: 'VN-54',
    label: 'Bắc Giang',
    country: 'VN',
  },
  {
    code: 'VN-55',
    label: 'Bạc Liêu',
    country: 'VN',
  },
  {
    code: 'VN-56',
    label: 'Bắc Ninh',
    country: 'VN',
  },
  {
    code: 'VN-57',
    label: 'Bình Dương',
    country: 'VN',
  },
  {
    code: 'VN-58',
    label: 'Bình Phước',
    country: 'VN',
  },
  {
    code: 'VN-59',
    label: 'Cà Mau',
    country: 'VN',
  },
  {
    code: 'VN-61',
    label: 'Hải Dương',
    country: 'VN',
  },
  {
    code: 'VN-63',
    label: 'Hà Nam',
    country: 'VN',
  },
  {
    code: 'VN-66',
    label: 'Hưng Yên',
    country: 'VN',
  },
  {
    code: 'VN-67',
    label: 'Nam Định',
    country: 'VN',
  },
  {
    code: 'VN-68',
    label: 'Phú Thọ',
    country: 'VN',
  },
  {
    code: 'VN-69',
    label: 'Thái Nguyên',
    country: 'VN',
  },
  {
    code: 'VN-70',
    label: 'Vĩnh Phúc',
    country: 'VN',
  },
  {
    code: 'VN-71',
    label: 'Điện Biên',
    country: 'VN',
  },
  {
    code: 'VN-72',
    label: 'Đắk Nông',
    country: 'VN',
  },
  {
    code: 'VN-73',
    label: 'Hậu Giang',
    country: 'VN',
  },
  {
    code: 'VN-CT',
    label: 'Cần Thơ',
    country: 'VN',
  },
  {
    code: 'VN-DN',
    label: 'Đà Nẵng',
    country: 'VN',
  },
  {
    code: 'VN-HN',
    label: 'Hà Nội',
    country: 'VN',
  },
  {
    code: 'VN-HP',
    label: 'Hải Phòng',
    country: 'VN',
  },
  {
    code: 'VN-SG',
    label: 'Hồ Chí Minh(local variant is Sai Gon)',
    country: 'VN',
  },
  {
    code: 'VU-MAP',
    label: 'Malampa',
    country: 'VU',
  },
  {
    code: 'VU-PAM',
    label: 'Pénama',
    country: 'VU',
  },
  {
    code: 'VU-SAM',
    label: 'Sanma',
    country: 'VU',
  },
  {
    code: 'VU-SEE',
    label: 'Shéfa',
    country: 'VU',
  },
  {
    code: 'VU-TAE',
    label: 'Taféa',
    country: 'VU',
  },
  {
    code: 'VU-TOB',
    label: 'Torba',
    country: 'VU',
  },
  {
    code: 'WF-AL',
    label: 'Alo',
    country: 'WF',
  },
  {
    code: 'WF-SG',
    label: 'Sigave',
    country: 'WF',
  },
  {
    code: 'WF-UV',
    label: 'Uvea',
    country: 'WF',
  },
  {
    code: 'WS-AA',
    label: "A'ana",
    country: 'WS',
  },
  {
    code: 'WS-AL',
    label: 'Aiga-i-le-Tai',
    country: 'WS',
  },
  {
    code: 'WS-AT',
    label: 'Atua',
    country: 'WS',
  },
  {
    code: 'WS-FA',
    label: "Fa'asaleleaga",
    country: 'WS',
  },
  {
    code: 'WS-GE',
    label: "Gaga'emauga",
    country: 'WS',
  },
  {
    code: 'WS-GI',
    label: 'Gagaifomauga',
    country: 'WS',
  },
  {
    code: 'WS-PA',
    label: 'Palauli',
    country: 'WS',
  },
  {
    code: 'WS-SA',
    label: "Satupa'itea",
    country: 'WS',
  },
  {
    code: 'WS-TU',
    label: 'Tuamasaga',
    country: 'WS',
  },
  {
    code: 'WS-VF',
    label: "Va'a-o-Fonoti",
    country: 'WS',
  },
  {
    code: 'WS-VS',
    label: 'Vaisigano',
    country: 'WS',
  },
  {
    code: 'YE-AB',
    label: 'Abyan',
    country: 'YE',
  },
  {
    code: 'YE-AD',
    label: '‘Adan',
    country: 'YE',
  },
  {
    code: 'YE-AM',
    label: '‘Amrān',
    country: 'YE',
  },
  {
    code: 'YE-BA',
    label: 'Al Bayḑā’',
    country: 'YE',
  },
  {
    code: 'YE-DA',
    label: 'Aḑ Ḑāli‘',
    country: 'YE',
  },
  {
    code: 'YE-DH',
    label: 'Dhamār',
    country: 'YE',
  },
  {
    code: 'YE-HD',
    label: 'Ḩaḑramawt',
    country: 'YE',
  },
  {
    code: 'YE-HJ',
    label: 'Ḩajjah',
    country: 'YE',
  },
  {
    code: 'YE-HU',
    label: 'Al Ḩudaydah',
    country: 'YE',
  },
  {
    code: 'YE-IB',
    label: 'Ibb',
    country: 'YE',
  },
  {
    code: 'YE-JA',
    label: 'Al Jawf',
    country: 'YE',
  },
  {
    code: 'YE-LA',
    label: 'Laḩij',
    country: 'YE',
  },
  {
    code: 'YE-MA',
    label: 'Ma’rib',
    country: 'YE',
  },
  {
    code: 'YE-MR',
    label: 'Al Mahrah',
    country: 'YE',
  },
  {
    code: 'YE-MW',
    label: 'Al Maḩwīt',
    country: 'YE',
  },
  {
    code: 'YE-RA',
    label: 'Raymah',
    country: 'YE',
  },
  {
    code: 'YE-SA',
    label: 'Amānat al ‘Āşimah',
    country: 'YE',
  },
  {
    code: 'YE-SD',
    label: 'Şāʻdah',
    country: 'YE',
  },
  {
    code: 'YE-SH',
    label: 'Shabwah',
    country: 'YE',
  },
  {
    code: 'YE-SN',
    label: 'Şanʻā’',
    country: 'YE',
  },
  {
    code: 'YE-SU',
    label: 'Arkhabīl Suquţrá',
    country: 'YE',
  },
  {
    code: 'YE-TA',
    label: 'Tāʻizz',
    country: 'YE',
  },
  {
    code: 'ZA-EC',
    label: 'Kapa-Vuxa',
    country: 'ZA',
  },
  {
    code: 'ZA-FS',
    label: 'Free State',
    country: 'ZA',
  },
  {
    code: 'ZA-GP',
    label: 'Gauteng',
    country: 'ZA',
  },
  {
    code: 'ZA-KZN',
    label: 'Kwazulu-Natal',
    country: 'ZA',
  },
  {
    code: 'ZA-LP',
    label: 'Limpopo',
    country: 'ZA',
  },
  {
    code: 'ZA-MP',
    label: 'Mpumalanga',
    country: 'ZA',
  },
  {
    code: 'ZA-NC',
    label: "Kapa-N'walungu",
    country: 'ZA',
  },
  {
    code: 'ZA-NW',
    label: "N'walungu-Vupeladyambu",
    country: 'ZA',
  },
  {
    code: 'ZA-WC',
    label: 'Kapa-Vupeladyambu',
    country: 'ZA',
  },
  {
    code: 'ZM-01',
    label: 'Western',
    country: 'ZM',
  },
  {
    code: 'ZM-02',
    label: 'Central',
    country: 'ZM',
  },
  {
    code: 'ZM-03',
    label: 'Eastern',
    country: 'ZM',
  },
  {
    code: 'ZM-04',
    label: 'Luapula',
    country: 'ZM',
  },
  {
    code: 'ZM-05',
    label: 'Northern',
    country: 'ZM',
  },
  {
    code: 'ZM-06',
    label: 'North-Western',
    country: 'ZM',
  },
  {
    code: 'ZM-07',
    label: 'Southern',
    country: 'ZM',
  },
  {
    code: 'ZM-08',
    label: 'Copperbelt',
    country: 'ZM',
  },
  {
    code: 'ZM-09',
    label: 'Lusaka',
    country: 'ZM',
  },
  {
    code: 'ZM-10',
    label: 'Muchinga',
    country: 'ZM',
  },
  {
    code: 'ZW-BU',
    label: 'Bulawayo',
    country: 'ZW',
  },
  {
    code: 'ZW-HA',
    label: 'Harare',
    country: 'ZW',
  },
  {
    code: 'ZW-MA',
    label: 'Manicaland',
    country: 'ZW',
  },
  {
    code: 'ZW-MC',
    label: 'Mashonaland Central',
    country: 'ZW',
  },
  {
    code: 'ZW-ME',
    label: 'Mashonaland East',
    country: 'ZW',
  },
  {
    code: 'ZW-MI',
    label: 'Midlands',
    country: 'ZW',
  },
  {
    code: 'ZW-MN',
    label: 'Matabeleland North',
    country: 'ZW',
  },
  {
    code: 'ZW-MS',
    label: 'Matabeleland South',
    country: 'ZW',
  },
  {
    code: 'ZW-MV',
    label: 'Masvingo',
    country: 'ZW',
  },
  {
    code: 'ZW-MW',
    label: 'Mashonaland West',
    country: 'ZW',
  },
];
