import create from 'zustand';
import { configureWindowStore } from 'evergine/window-store';

export type EvergineState = {
  isExperienceStarted: boolean;
  isWebAssemblyLoaded: boolean;
  isEvergineReady: boolean;

  setWebAssemblyLoaded: (loaded: boolean) => void;
  setExperienceStarted: (started: boolean) => void;
  setEvergineReady: (ready: boolean) => void;
};

const useStore = create<EvergineState>((set) => ({
  isWebAssemblyLoaded: false,
  isExperienceStarted: false,
  isEvergineReady: false,

  setWebAssemblyLoaded: (value: boolean): void =>
    set(() => ({ isWebAssemblyLoaded: value })),

  setExperienceStarted: (value: boolean): void =>
    set(() => ({ isExperienceStarted: value })),

  setEvergineReady: (value: boolean): void =>
    set(() => ({ isEvergineReady: value })),
}));

configureWindowStore(useStore, 'evergineStore');

export { useStore as useEvergineStore };
