import * as localizedYup from 'yup';

localizedYup.setLocale({
  mixed: {
    default: 'Este campo no es válido',
    required: 'Este campo es obligatorio',
  },
  string: {
    max: 'Este campo no puede ser mayor que ${max}',
    min: 'Este campo no puede ser menor que ${min}',
  },
});

export default localizedYup;
