import { DeleteOutlined, Edit } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { constants } from 'config/constants';
import { Hospital } from 'models/hospital';
import ConfirmationDialog from 'modules/components/dialogs/confirmation-dialog';
import { EditDrawer } from 'modules/components/drawers/edit-drawer';
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteApi, getApi, postApi, putApi } from 'services/http/axios-client';
import HospitalForm from 'modules/app/administration/hospital/hospital-form';
import { EntityCreated } from 'models/entity-created';
import { countries } from 'config/countries';
import { countrySubdivisions } from 'config/country-subdivisions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HospitalListPage = React.forwardRef((_props, ref: any): JSX.Element => {
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [hospitalToDelete, setHospitalToDelete] = useState<Hospital>();
  const [hospitalToEdit, setHospitalToEdit] = useState<Hospital>(
    {} as Hospital
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [countriesDictionary] = useState(
    Object.assign({}, ...countries.map((x) => ({ [x.code]: x.label })))
  );
  const [statesDictionary] = useState(
    countrySubdivisions.reduce((acc, x) => {
      acc[x.country] = acc[x.country] || [];
      acc[x.country].push(x);
      return acc;
    }, Object.create(null))
  );

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const response = await getApi<Hospital[]>(constants.api.hospitals);
      setHospitals(response);
      setLoading(false);
    };
    loadData();
  }, []);

  const columns: GridColumns<Hospital> = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 200 },
    {
      field: 'countryDesc',
      headerName: 'País',
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        countriesDictionary[params.row.country],
    },
    {
      field: 'state',
      headerName: 'Provincia',
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        statesDictionary[params.row.country].find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any): boolean => x.code === params.row.state
        )?.label,
    },
    { field: 'city', headerName: 'Ciudad', width: 200 },
    {
      field: 'Acciones',
      type: 'actions',
      getActions: gridActions,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: `calc(100% - ${ref?.current?.clientWidth + 1 || 162}px)`,
          display: 'inline-flex',
          flexWrap: 'wrap',
          justifyContent: 'end',
        }}
      >
        <Button
          variant="contained"
          onClick={(): void => {
            setHospitalToEdit(getNewHospital());
            setOpenDrawer(true);
          }}
        >
          Nuevo centro
        </Button>
      </Box>

      <Box sx={{ marginTop: 1, height: '80vh', width: '100%' }}>
        <DataGrid
          loading={loading}
          rows={hospitals}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      <ConfirmationDialog
        title="Eliminar centro"
        message={`El centro '${hospitalToDelete?.name}' será eliminado. ¿Está seguro?`}
        open={openDialog}
        onClose={async (confirm: boolean): Promise<void> => {
          setOpenDialog(false);
          if (confirm && hospitalToDelete !== undefined) {
            await deleteHospital(hospitalToDelete);
          }
          setHospitalToDelete(undefined);
        }}
      />

      <EditDrawer open={openDrawer}>
        <HospitalForm
          hospital={hospitalToEdit}
          onClose={async (save: boolean): Promise<void> => {
            if (save) {
              await createOrUpdateHospital(hospitalToEdit);
            }
            setOpenDrawer(false);
            setHospitalToEdit({} as Hospital);
          }}
        />
      </EditDrawer>
    </>
  );

  function gridActions(params: GridRowParams): JSX.Element[] {
    return [
      <GridActionsCellItem
        icon={<Edit color="primary" />}
        label="Editar"
        onClick={(): void => {
          setHospitalToEdit(params.row);
          setOpenDrawer(true);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteOutlined color="primary" />}
        label="Eliminar"
        onClick={(): void => {
          setHospitalToDelete(params.row);
          setOpenDialog(true);
        }}
      />,
    ];
  }

  async function createOrUpdateHospital(
    hospitalToEdit: Hospital
  ): Promise<void> {
    if (hospitalToEdit.id === 0) {
      const response = await postApi<EntityCreated, Hospital>(
        constants.api.hospitals,
        hospitalToEdit
      );
      hospitalToEdit.id = response.id;
      setHospitals([...hospitals, hospitalToEdit]);
    } else {
      await putApi<void, Hospital>(
        constants.api.hospitals + '/' + hospitalToEdit.id,
        hospitalToEdit
      );
    }

    toast.success(`Centro guardado: ${hospitalToEdit.name}`);
  }

  async function deleteHospital(hospitalToDelete: Hospital): Promise<void> {
    await deleteApi<void>(constants.api.hospitals + '/' + hospitalToDelete?.id);

    toast.success(`Centro eliminado: ${hospitalToDelete.name}`);
    setHospitals(hospitals.filter((h) => h.id !== hospitalToDelete?.id));
  }

  function getNewHospital(): Hospital {
    return {
      id: 0,
      name: '',
      country: null!,
      state: null!,
      city: '',
    };
  }
});

export default HospitalListPage;
