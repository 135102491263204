import { ToggleButton } from '@mui/material';
import { PageUrl } from 'models/page-url';
import { StyledToggleButtonGroup } from 'modules/components/buttons/styled-toggle-button-group';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AdministrationToolbar(): JSX.Element {
  const pages: PageUrl[] = [
    {
      name: 'Centros',
      url: 'center',
    },
    {
      name: 'Usuarios',
      url: 'user',
    },
    {
      name: 'Perfil Profesional',
      url: 'professionalprofile',
    },
    {
      name: 'Especialidad',
      url: 'specialfield',
    },
    {
      name: 'Etiquetas',
      url: 'tag',
    },
  ];

  const [choice, setChoice] = React.useState('Centros');
  const navigate = useNavigate();

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newChoice: string
  ): void => {
    if (newChoice != null) {
      setChoice(newChoice);
    }
  };

  return (
    <StyledToggleButtonGroup
      size="small"
      color="primary"
      value={choice}
      exclusive
      onChange={handleChange}
    >
      {pages.map((page) => (
        <ToggleButton
          key={page.name}
          value={page.name}
          onClick={(): void => navigate(page.url)}
        >
          {page.name}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
