import { toast } from 'react-toastify';
import { AxiosInstance } from 'axios';

export interface ApiError {
  response: {
    data: {
      title: string;
      detail: string;
      errors: { [key: string]: string[] };
    };
    status: number;
    statusText: string;
  };
  message: string;
}

const buildErrorMessage = (error: ApiError): string => {
  const statusText =
    error.response.statusText !== '' ? ` (${error.response.statusText})` : '';

  const detail =
    error.response?.data?.detail !== undefined
      ? `: ${error.response?.data?.detail}`
      : `: ${error.response?.data?.title || ''}`;

  return `${error.message}${statusText}${detail}`;
};

export const errorHandler = (error: ApiError): void => {
  toast.error(buildErrorMessage(error));
  throw error;
};

export const configureHttpClientErrorHandler = (
  client: AxiosInstance
): number => {
  return client.interceptors.response.use(undefined, errorHandler);
};
